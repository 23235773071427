import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useGroupService, { useBasicFilterGroup } from './services/Group';
import useUserService, { useBasicFilterUser } from './services/User';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem, ToggleButton, ToggleButtonGroup } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IGroup, defaultGroup, IGroupUser } from './models/Group';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration from 'features/configuration/models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IUser } from './models/User';

export const GroupForm: FC<IGroup> = (props: IGroup = defaultGroup) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createGroup, updateGroup } = useGroupService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);
  

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterGroup = useBasicFilterGroup( 
    (event: React.MouseEvent<unknown>, row: IGroup) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openUserFilter, setOpenUserFilter] = useState(false);
  const basicFilterUser = useBasicFilterUser( 
      (event: React.MouseEvent<unknown>, row: IUser) => {
          const {id ,userName,userDescription} = row;

          if(getValues().groupUsers.some( x => x.userId === id)) return;       
            
                (refAppendGroupUsers.current??emptyFunc)({ userId: id, groupId: _id,
                    userName, userDescription, type: 'base' });

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenUserFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IGroup>({defaultValues:defaultGroup});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchUsage = watch('usage');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IGroup>,Error,IGroup>(
      _id>0?updateGroup:createGroup, {   
        onSuccess: (data: IResult<IGroup>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Group',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IGroup>(['Group', _id], () => retrieveEntity('Group',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const getGroupUserType = (row: IGroupUser, cellId: keyof IGroupUser, 
        opts: {value: string, name: string}[]) => {        
        
        return [{value: 'base', name: `${t('Base')} (${t('User')})` }, {value: 'expression', name: `${t('Expression')}` }];
      }

      const cellEditableGroupUser = (row: IGroupUser, cellId: keyof IGroupUser) => {
        return true;
      }

    const [headGroupUserCells, setHeadGroupUserCells]  = useState<HeadCell<IGroupUser>[]>([      
        //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
        {id:'type', label : t('Type'),  display: true, type: 'string', width: 25, isEditable: cellEditableGroupUser,
          getOptions: getGroupUserType },
        {id:'userName', label : t('Name'),  display: true, type: 'string', width: 35 },
        {id:'userDescription', label : t('Description'),  display: true, type: 'string', width: 40 },
        
      ]);

    const refAppendGroupUsers = useRef<(value: Partial<FieldArray<IGroup>> | Partial<FieldArray<IGroup>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateGroupUser = useRef<(index: number,value: Partial<FieldArray<IGroup>> ) => void>(null);
    const refRemoveGroupUser = useRef<(index: number ) => void>(null);
      
    const handleAddUsers = (event: any) => {
      setOpenUserFilter(true);
    }   

    const handleOkFilterUser = () => {
      setOpenUserFilter(false);
    }

    const groupUserRowActionIcon = ( tarificationMatrix: IGroupUser) : ActionIconTableRow<IGroup,IGroupUser> => {
  
      const res: ActionIconTableRow<IGroup,IGroupUser> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IGroupUser) => {
          
           (refRemoveGroupUser.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  

      useEffect( () => {        
        setCurrentFormNameAtom(t('Group'));
        setCurrentBasicTextFilterProps(basicFilterGroup);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Group',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultGroup);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    

        if(!checkEntitySaveAuthorization('Group', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Group', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                
                                <TextField sx={{width:'calc(40% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(50% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                                
                            </Box>
                            
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<IGroup,IGroupUser,'id'> 
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headGroupUserCells} rowsPathName='groupUsers' 
                                      title={t('Users')} rowActionIcon={groupUserRowActionIcon}  
                                      //onRowSelected={handleRoleEntitySelected}
                                                          
                                      refAppend={refAppendGroupUsers as MutableRefObject<(value: Partial<FieldArray<IGroup>> | Partial<FieldArray<IGroup>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateGroupUser as MutableRefObject<(index: number,value: Partial<FieldArray<IGroup>>) => void>}
                                      refRemove={refRemoveGroupUser as MutableRefObject<(index: number) => void>}

                                      //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                      //displayMore={undefined}
                                      toolbarActions={[
                                      { toolTip: `${t('Add')}...`, onClickIcon: handleAddUsers ,icon: AddCircleIcon,  },
                                      
                                      ]}
                                      canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                  { openUserFilter && <FormDialog open={openUserFilter} maxWidth='md'
                                      okText={t('OK')} cancelText='' title={t('User filter')} onCancel={()=> {}} 
                                      onClose={()=> {setOpenUserFilter(false);}} onOk={handleOkFilterUser}  >
                                          <BasicTextFilterForm<IUser> {...basicFilterUser } />
                                  </FormDialog> }
                            </Box>                  
                        </Stack>
                    </Grid>                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

