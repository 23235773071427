import { IAppTheme, defaultAppTheme } from "themes/commonStyles"


export interface IApplicationSetup {
    id: number,
    companyName: string,
    description: string,
    defaultLanguage: string,

    isPasswordComplexityAllowed: boolean,
    passwordDuration: number,
    passwordRotation: number,
    maxAccessFailedCount: number,
    accessLockoutDuration: number,

    applicationApiToken: string,
    websiteApplicationApiToken: string,
    
    whatsAppBaseUrl: string,
    whatsAppIdentityToken: string,
    whatsAppLogin: string,
    whatsAppPasswordMd5: string,

    whatsAppPassword: string,
    isPasswordChanged: boolean,

    webhookToken: string,
    webhookUrl: string,

    isCommissionDisburseOn: boolean,
    minimumDisbursementCommissionAmount: number,

    valueOfPoint: number,
    minimumDisbursementPointCount: number,

    minAppointmentStartTime: Date,
    maxAppointmentStartTime: Date,
    onlineAppointmentNotificationMode: string,

    appointmentValidationNotificationReportId: number,
    appointmentValidationNotificationTransmissionMode: string,
    appointmentValidationNotificationRetentionMode: string,
    appointmentValidationNotificationReportName: string,
    appointmentContactAllowedForTechnician: boolean,
    appointmentAllAvailableForTechnician: boolean,

    certificateSmsExpression: string,

    certificateDocumentExpression: string,
    certificateImageExpression: string,
    certificateQrCodeExpression: string,    
    
    isSmsOn: boolean,
    isWhatsAppOn: boolean,
    isReportScheduleOn: boolean,
    isFeatureScheduleOn: boolean,
    notificationWhatsAppNumber1: string,
    notificationWhatsAppNumber2: string,

    issuePeriod: number,
    isAutomaticIssueDate: boolean,
    issueDateMaxDelayMonthClosing: number,
    issueDateMaxDelayYearClosing: number,
    isAutomaticClosedAfterDelay: boolean,

    proformaNumberExpression: string,
    billingNumberExpression: string,
    deliveryNumberExpression: string,
    purchaseOrderNumberExpression: string,
    expenseNumberExpression: string,

    resetProformaSequenceByYear: boolean,
    resetBillingSequenceByYear: boolean,
    resetDeliverySequenceByYear: boolean,
    resetPurchaseOrderSequenceByYear: boolean,
    resetExpenseSequenceByYear: boolean,

    resetProformaSequenceByMonth: boolean,
    resetBillingSequenceByMonth: boolean,
    resetDeliverySequenceByMonth: boolean,
    resetPurchaseOrderSequenceByMonth: boolean,
    resetExpenseSequenceByMonth: boolean,

    defaultCurrencyCode: string,
    defaultOnlineStoreId: number,
    defaultTaxRate: number,
    isTaxRateVariable: boolean,

    smtpServer: string,
    smtpPort: number,
    smtpUsername: string,
    smtpPassword: string,
    smtpSenderEmail: string,
    smtpUseTls: boolean,
    smtpServerType: string,

    ticketStartResolutionCode: string,
    ticketEndResolutionCode: string,
    defaultTicketDurationHours: number,

    hasService: boolean,
    hasFee: boolean,
    hasArticle: boolean,
    hasPackaging: boolean,
    hasFormation: boolean,
    hasMaintenance: boolean,
    hasContract: boolean,

    hasRental: boolean,
    hasConsumption: boolean,

    applicationTheme: IAppTheme
  }


  export const defaultApplicationSetup : IApplicationSetup = {
    id: 0,
    companyName: '',
    description: '',
    defaultLanguage: '',
    
    isPasswordComplexityAllowed: false,
    passwordDuration: 0,
    passwordRotation: 0,
    maxAccessFailedCount: 0,
    accessLockoutDuration: 0,

    applicationApiToken: '',
    websiteApplicationApiToken: '',
    
    whatsAppBaseUrl: '',
    whatsAppIdentityToken: '',
    whatsAppLogin: '',
    whatsAppPasswordMd5: '',

    whatsAppPassword: '',
    isPasswordChanged: false,

    webhookToken: '',
    webhookUrl: '',

    isCommissionDisburseOn: true,
    minimumDisbursementCommissionAmount: 10000,

    valueOfPoint: 0,
    minimumDisbursementPointCount: 0,

    minAppointmentStartTime: new Date(),
    maxAppointmentStartTime: new Date(),
    onlineAppointmentNotificationMode: '',

    appointmentValidationNotificationReportId: 0,
    appointmentValidationNotificationTransmissionMode: '',
    appointmentValidationNotificationRetentionMode: '',
    appointmentValidationNotificationReportName: '',
    appointmentContactAllowedForTechnician: false,
    appointmentAllAvailableForTechnician: false,

    certificateSmsExpression: '',

    certificateDocumentExpression: '',
    certificateImageExpression: '',
    certificateQrCodeExpression: '',

    isSmsOn: true,
    isWhatsAppOn: true,
    isReportScheduleOn: true,
    isFeatureScheduleOn: true,
    notificationWhatsAppNumber1: '',
    notificationWhatsAppNumber2: '',

    issuePeriod: (new Date()).getFullYear()*100+1,
    isAutomaticIssueDate: true,
    issueDateMaxDelayMonthClosing: 0,
    issueDateMaxDelayYearClosing: 0,
    isAutomaticClosedAfterDelay: false,

    proformaNumberExpression: '',
    billingNumberExpression: '',
    deliveryNumberExpression: '',
    purchaseOrderNumberExpression: '',
    expenseNumberExpression: '',

    resetProformaSequenceByYear: true,
    resetBillingSequenceByYear: true,
    resetDeliverySequenceByYear: true,
    resetPurchaseOrderSequenceByYear: true,
    resetExpenseSequenceByYear: true,

    resetProformaSequenceByMonth: true,
    resetBillingSequenceByMonth: true,
    resetDeliverySequenceByMonth: true,
    resetPurchaseOrderSequenceByMonth: true,
    resetExpenseSequenceByMonth: true,

    defaultCurrencyCode: '',
    defaultOnlineStoreId: 0,
    defaultTaxRate: 0,
    isTaxRateVariable: false,

    smtpServer: '',
    smtpPort: 0,
    smtpUsername: '',
    smtpPassword: '',
    smtpSenderEmail: '',
    smtpUseTls: false,
    smtpServerType: '',

    ticketStartResolutionCode: '',
    ticketEndResolutionCode: '',
    defaultTicketDurationHours: 0,

    hasService: false,
    hasFee: false,
    hasArticle: false,
    hasPackaging: false,
    hasFormation: false,
    hasMaintenance: false,
    hasContract: false,
    hasRental: false,
    hasConsumption: false,

    applicationTheme: defaultAppTheme
  }

  export interface IMailRequest {
    recipient: string,
    messageObject: string,
    messageBody: string,
  }

  export const defaultMailRequest : IMailRequest = {
    recipient: '',
    messageBody: '',
    messageObject: ''
  }

  

  export interface IApplicationSetupSearch {
    companyName: string
  }