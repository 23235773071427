
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { DatePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useBillingService from 'features/finance/services/Billing';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import { IEnumerationItem, Enum_SERVICE_TASK } from 'features/configuration/models/Enumeration';
import useEnumerationService from 'features/configuration/services/Enumeration';


import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


import {IAmount, IBillingDetail, IBillingServiceTask, IEmployeeBillingDashboard, IFinanceDashboard, defaultDashboardDate, defaultEmployeeBillingDashboard, defaultFinanceDashboard } from 'features/finance/models/Billing';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBilling } from 'features/finance/models/Billing';
import { useQuery } from 'react-query';



export const EmployeeBillingDashboardForm: FC<IEmployeeBillingDashboard> = (props: IEmployeeBillingDashboard = defaultEmployeeBillingDashboard) => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();


  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const {getEmployeeBillingDashboard} = useBillingService();
  const [dashboard, setDashboard] = useState<IEmployeeBillingDashboard>(defaultEmployeeBillingDashboard);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<IDashboardMonthClass[]>([]);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<IDashboardMonthClass[]>([]);

  // const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  // const basicFilterSchoolYear = useBasicFilterSchoolYear( 
  //     async (event: React.MouseEvent<unknown>, row: IDashboardMonth) => {
  //         const {id, name } = row;

  //         const dash = await getSchoolYearDashboard(id);
  //         setSchoolYearName(name);

  //         setDashboard(dash);
  //         setOpenSchoolYearFilter(false);
  //     }
  // );

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'EmployeeBillingDashboard'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_SERVICE_TASK] ));



  const handleClickSelectSchoolYear = (event: any) => {
    //setOpenSchoolYearFilter(true);
  }


  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  const [date, setDate] = React.useState<Date>(new Date());

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);


  useEffect(() => {
    async function _() {
             
      const dash = await getEmployeeBillingDashboard(date); 
            
      setDashboard(dash);  
    }
    _();
  }, [date]);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The month of')} :::::`}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                //views={["year", "month"]}
                renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(35% - 8px)':'calc(15% - 8px)'}} />}
                onChange={(newValue) => setDate(newValue||new Date())}                    
                value={date}                      
              /> 
            
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={12} >
          <Box sx={{ mt: 0.25, width: '100%' }} > 
            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                  sx={{...typographyGroupBoxStyling}}>
              {`${t(('Day'))} `}
            </Typography>                                                       
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount')}-${t('Tasks')}`} paletteColor={theme.palette['success']} 
                 iconName='IoSendSharp' total={dashboard.taskAmountDay} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unvalidated amount')}-${t('Tasks')}`} paletteColor={theme.palette['warning']} 
                  iconName='IoSendSharp' total={dashboard.taskUnvalidatedAmountDay}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Cancelled amount')}-${t('Tasks')}`} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.taskCancelledAmountDay}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount')}-${t('Customer')} `} paletteColor={theme.palette['info']} 
                   iconName='IoSendSharp' total={dashboard.customerAmountDay} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Cancelled amount')}-${t('Customer')}`} paletteColor={theme.palette['error']} 
                 iconName='IoSendSharp' total={dashboard.customerCancelledAmountDay} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Commission')}`} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.commissionAmountDay} decimalScale={3} />
        </Grid> 
        <Grid item xs={12} >
          <Box sx={{ mt: 0.25, width: '100%' }} > 
            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                  sx={{...typographyGroupBoxStyling}}>
              {`${t(('Month'))} `}
            </Typography>                                                       
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount')}-${t('Tasks')}`} paletteColor={theme.palette['success']} 
                 iconName='IoSendSharp' total={dashboard.taskAmountMonth} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unvalidated amount')}-${t('Tasks')}`} paletteColor={theme.palette['warning']} 
                  iconName='IoSendSharp' total={dashboard.taskUnvalidatedAmountMonth}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Cancelled amount')}-${t('Tasks')}`} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.taskCancelledAmountMonth}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount')}-${t('Customer')} `} paletteColor={theme.palette['info']} 
                   iconName='IoSendSharp' total={dashboard.customerAmountMonth} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Cancelled amount')}-${t('Customer')}`} paletteColor={theme.palette['error']} 
                 iconName='IoSendSharp' total={dashboard.customerCancelledAmountMonth} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Commission')}`} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.commissionAmountMonth} decimalScale={3}  />
        </Grid>
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBillingServiceTask> 
                  rows={dashboard.billingTasksByEmployee} 
                  headCells={[            
                    
                    {id:lg.includes('en')?'customerFirstName':'customerLastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 30 },
                    {id:lg.includes('en')?'customerLastName':'customerFirstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 30},
                
                    {id:'taskCode', label : t('Task'),  display: true, type: 'string', width: 30, 
                        getOptions: (row: IBillingServiceTask, cellId: keyof IBillingServiceTask, 
                          opts: {value: string, name: string}[]) => {        
                          return getAsOptions(refEnumItems.current ?? [],Enum_SERVICE_TASK);
                        }},
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 10},                                                    
                    
                  ]} 
                  title={`${t('Billing')} (${t('Customer')})`} objKey={'id'} 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy={lg.includes('en')?'customerFirstName':'customerLastName'}
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBilling> 
                  rows={dashboard.billingsByCustomer} 
                  headCells={[            
                    
                    {id:lg.includes('en')?'customerFirstName':'customerLastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 35 },
                    {id:lg.includes('en')?'customerLastName':'customerFirstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 35},
                
                    
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 10},
                    {id:'unpaidAmount', label : t('Unpaid'),  display: true, type: 'numeric', width: 10},    
                    {id:'commissionAmount', label : t('Commission'),  display: true, type: 'numeric', width: 10, decimalScale: 4},                                  
                    
                  ]} 
                  title={`${t('Billing')} (${t('Customer')})`} objKey={lg.includes('en')?'customerFirstName':'customerLastName'} 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy={lg.includes('en')?'customerFirstName':'customerLastName'}
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>            
          </Stack>
        </Grid>
        
      </Grid>
      
    </Box>
  )
}
