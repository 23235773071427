import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import RepeatOneIcon from '@mui/icons-material/RepeatOne';
import FunctionsIcon from '@mui/icons-material/Functions';
import VerticalAlignTopIcon from '@mui/icons-material/VerticalAlignTop';
import VerticalAlignBottomIcon from '@mui/icons-material/VerticalAlignBottom';
import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';


import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import TableRowsIcon from '@mui/icons-material/TableRows';
import MoreIcon from '@mui/icons-material/More';


import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import { IEntity, IResult } from 'library/interface';

import useProblemService, { useBasicFilterProblem } from './services/Problem';

import {  IProblem, defaultProblem } from './models/Problem';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';

import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Typography } from '@mui/material';
import IEnumeration, { Enum_OPERATION_STATUS, Enum_TICKET_CATEGORY, Enum_TICKET_RESOLUTION, Enum_TICKET_SEVERITY, Enum_TICKET_STATUS, IEnumerationItem } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';

import { typographyGroupBoxStyling, justifyCenter } from 'themes/commonStyles';
import { MdOutlineAdd } from 'react-icons/md';

import EnhancedTable from 'components/ui/EnhancedTable';
import { useBasicFilterUser } from 'features/security/services/User';
import { IUser } from 'features/security/models/User';
// import { useBasicFilterContact } from './services/Contact';
// import { IContact } from './models/Contact';
import { DatePicker } from '@mui/x-date-pickers';


export const ProblemForm: FC<IProblem> = (props: IProblem = defaultProblem) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createProblem, updateProblem} = useProblemService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterProblem = useBasicFilterProblem( 
    
    async (event: React.MouseEvent<unknown>, row: IProblem) => {
      const {id, title } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  
    
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IProblem>({defaultValues:defaultProblem});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;
    
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IProblem>,Error,IProblem>(
      _id>0?updateProblem:createProblem, {   
        onSuccess: (data: IResult<IProblem>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Problem')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Problem',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IProblem>(['Problem', _id], () => retrieveEntity('Problem',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Problem'], () => getEnumerationItemsByEnumerationCodes
        ( [Enum_OPERATION_STATUS ] ));
       
           

      

  useEffect( () => {              
    setCurrentFormNameAtom(t('Problem'));  
    setCurrentBasicTextFilterProps(basicFilterProblem);
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    setCurrentFormNameAtom(_id>0?`${t('Problem')} - # ${_id} # -`: `${t('Problem')}` );
    if(_id > 0)
      retrieveData('Problem',_id, refetch);  
  }, [_id] );


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultProblem);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
  
  if(!checkEntitySaveAuthorization('Problem', _id)){
    setIsSaveLoading(false);
    return;
  }
    
  const data = getValues(); 
  if(data.title.trim() === '' || data.description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('Problem', _id);
}



const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                              <TextField sx={{width:'calc(50% - 8px)'}} id="title" label={t('Title')} {...register('title')} 
                                  inputProps={ {  autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                              <Controller control={control}
                                  name='issueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(17% - 8px)'}  }} }
                                    /> )}
                                />
                              <Controller 
                                  name={`status`}
                                  control={control}
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(20% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: false}}>                                
                                      {enumItems && enumItems.filter( e => 
                                                  e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                              (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                            }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                  name='statusDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}  readOnly disableOpenPicker                   
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(13% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(13% - 8px)'}} />}
                                    /> )}
                                />
                                 
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} 
                              {...register('description')} multiline rows={2} 
                              inputProps={ {  autoComplete: 'new-password', style: {textTransform: 'none'} } }/>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="rootCause" label={t('Root cause')} 
                              {...register('rootCause')} multiline rows={2} 
                              inputProps={ { readOnly: false,  autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                            
                            
                            
                            
                                                       
                            
                            
                            
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={5} component={Paper} >
                        <Stack flexDirection='column'>      
                                      
                            <Box sx={{ mt: 1, width: '100%' }} >
                                                  
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                                    
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              
                            </Box>
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
