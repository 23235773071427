import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';


import { IEntity, IResult } from 'library/interface';

import useStockTransferService, { useBasicFilterStockTransfer } from './services/StockTransfer';

import useStoreService from './services/Store';

import {  IStockTransfer, IStockTransferArticle, defaultStockTransfer,defaultStockTransferArticle } from './models/StockTransfer';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';

import { useBasicFilterProduct } from 'features/setup/services/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IEnumeration, {Enum_OPERATION_STATUS, Enum_ARTICLE_OPTION, Enum_STOCK_TRANSFER_STATUS_PURPOSE, IEnumerationItem} from 'features/configuration/models/Enumeration';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { DatePicker } from '@mui/x-date-pickers';
import EnhancedTable from 'components/ui/EnhancedTable';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from 'features/production/services/Person';

import { IPerson, PersonType } from 'features/production/models/Person';
import { IProduct } from 'features/setup/models/Product';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import TextFieldRight from 'components/ui/TextFieldRight';
import { GrClose, GrSearch } from 'react-icons/gr';
import { debounce, sum } from 'lodash';
import { MdOutlineAdd } from 'react-icons/md';
import { IStore } from './models/Store';

export const StockTransferForm: FC<IStockTransfer> = (props: IStockTransfer = defaultStockTransfer) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createStockTransfer, updateStockTransfer } = useStockTransferService();

  const {getStores } = useStoreService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterStockTransfer = useBasicFilterStockTransfer( 
    
    async (event: React.MouseEvent<unknown>, row: IStockTransfer) => {
      const {id, reference } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const refArticleIndex = useRef<number>(0);

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          // setParameterEnumeration(id, name);                                      
          // setOpenEnumerationFilter(false);
      }
  );

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterProduct = useBasicFilterProduct( 2,
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, type, description, filterOption} = row;

          if( type !== 'article') return;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);

          (refAppendStockTransferArticles.current??emptyFunc)({...defaultStockTransferArticle, id: 0, stockTransferId: _id, articleId: id, 
                articleName: name , articleFilterOption: filterOption  ,option: '', 
                  });
                           
          setOpenProductFilter(false);
      }
  );

  
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IStockTransfer>({defaultValues:defaultStockTransfer});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const [stockTransferArticleIndex, setStockTransferArticleIndex] = useState<number>(-1);

  type SearchPersonUsage = 'supplier';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('supplier');
    

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IStockTransfer>,Error,IStockTransfer>(
      _id>0?updateStockTransfer:createStockTransfer, {   
        onSuccess: (data: IResult<IStockTransfer>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('StockTransfer')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['StockTransfer',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IStockTransfer>(['StockTransfer', _id], () => retrieveEntity('StockTransfer',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'StockTransfer'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_OPERATION_STATUS, Enum_ARTICLE_OPTION, Enum_STOCK_TRANSFER_STATUS_PURPOSE ] ));

    const {data: stores} = useQuery<IStore[]>( ['stores'], () => getStores( { name: '', description: '', location: ''} ));


  const cellEditableArticle = (row: IStockTransferArticle, cellId: keyof IStockTransferArticle) => {
    return true;
  }

  const [openStockTransferArticle, setOpenStockTransferArticle] = useState<boolean>(false);

  
  const getInputAdornmentArticle = (row: IStockTransferArticle, cellId: keyof IStockTransferArticle)  => ({

    toolTip: `${t('Details')} ...`,
    icon: MoreVertIcon,
    //iconDisable: row.resourceId > 0,
    onClickIcon: (event: any, index: number, row: IStockTransferArticle ) => {
      //refArticleIndex.current = index;
      setStockTransferArticleIndex(index);
      setOpenStockTransferArticle(true);
    }  
  })

 const getArticleOptionList = (row: IStockTransferArticle, cellId: keyof IStockTransferArticle, 
    opts: {value: string, name: string}[]) => {        

    const {articleFilterOption} = row;
    
    const enumsFilter = (refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);

    return getAsOptions(enumsFilter ,Enum_ARTICLE_OPTION);
}


  const [headStockTransferArticleCells, setHeadStockTransferArticleCells]  = useState<HeadCell<IStockTransferArticle>[]>([]);
  useEffect(() => {
    setHeadStockTransferArticleCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'articleName', label : t('Name'),  display: true, type: 'string', width: 50, isEditable: cellEditableArticle},
      {id:'option', label : t('Option'),  display: true, type: 'string', width: 40, isEditable: cellEditableArticle,
        getOptions: getArticleOptionList },
      {id:'quantity', label : t('Qty'),  display: true, type: 'numeric', width: 10, 
          isEditable: cellEditableArticle },
    ]  )
  }, [t,i18n])

  const refAppendStockTransferArticles = useRef<(value: Partial<FieldArray<IStockTransfer>> | Partial<FieldArray<IStockTransfer>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateStockTransferArticle = useRef<(index: number,value: Partial<FieldArray<IStockTransfer>> ) => void>(null);
  const refRemoveStockTransferArticle = useRef<(index: number ) => void>(null);
  
  const handleStockTransferArticleSelected = (event: React.MouseEvent<unknown>,index: number,row: IStockTransferArticle) => {
    setStockTransferArticleIndex(index);
  }

  const handleAddStockTransferArticle = (event: any) => {
    setOpenProductFilter(true);      
  }

  const stockTransferArticleRowActionIcon = ( applicationQueryParameter: IStockTransferArticle) : ActionIconTableRow<IStockTransfer,IStockTransferArticle> => {
  
    const res: ActionIconTableRow<IStockTransfer,IStockTransferArticle> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IStockTransferArticle) => {
        
        (refRemoveStockTransferArticle.current??emptyFunc)(index);            
      }
    }
    return res;
}


const refEnumItems = useRef<IEnumerationItem[]>();    
useEffect( () => {   
    refEnumItems.current = enumItems;
  
}, [enumItems]);


useEffect( () => {              
  setCurrentFormNameAtom(t('StockTransfer'));  
  setCurrentBasicTextFilterProps(basicFilterStockTransfer);
}, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    setCurrentFormNameAtom(_id>0?`${t('StockTransfer')} - # ${_id} # -`: t('StockTransfer') );
    if(_id > 0)
      retrieveData('StockTransfer',_id, refetch);  
  }, [_id] );

  useEffect( () => {   
    refArticleIndex.current = stockTransferArticleIndex;
  
  }, [stockTransferArticleIndex]);


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultStockTransfer);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {   
  if(!checkEntitySaveAuthorization('StockTransfer', _id)) {
    setIsSaveLoading(false);
       return;
  }

  const data = getValues(); 

  const {originStoreId, destinationStoreId, reference, description} = data;

  if(originStoreId <= 0 || destinationStoreId <= 0) {
    enqueueSnackbar( t('Origin store and destination store must be specified'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    setIsSaveLoading(false);
    return;
  }

  if(originStoreId === destinationStoreId) {
    enqueueSnackbar( t('Origin store and destination store must be different'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    setIsSaveLoading(false);
    return;
  }

  
  if(reference.trim() === '' || description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  
  if(data.stockTransferArticles.some( x => x.option.trim() === '' || x.quantity === 0 )) {
    enqueueSnackbar( t('All articles must have option and quantity defined'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
    setIsSaveLoading(false);
    return;
  }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('StockTransfer', _id);
}

const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
  openEntityPrintDrawer('StockTransfer', _id);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  queryClient.invalidateQueries(['StockTransfer',_id]);        
  await retrieveData('StockTransfer',_id, refetch);        
  reset(_data);             
}

  return (
        <FormProvider {...methods} >
          <Box sx={{ mx: 0.1 }}>
            <Grid container rowSpacing={4} columnSpacing={1}>
              <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                <Stack flexDirection='column'  >
                    <Box sx={{ mt: 1, width: '100%' }} >
                        <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                        <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                        <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                        <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                        <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                        
                        <TextField sx={{width:'calc(70% - 8px)'}} id="reference" label={t('Reference')} 
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus {...register('reference')} />
                        <Controller control={control}
                          name='stockTransferDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Date')} 
                              onChange={onChange}                      
                              value={new Date(value)}
                              slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                              //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                        />
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t('Store')} :: ${t(('Orgin'))} ==> ${t(('Destination'))}`}
                      </Typography>                                                       
                    </Box>   
                    <Box sx={{ mt: 1, width: '100%' }} > 
                      <Controller 
                          name='originStoreId' control={control}                                     
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="originStoreId"
                              label={`${t(('Orgin'))} :  ${t('Store')} =>`} inputProps={ {readOnly: false}} >
                              {stores && stores.map( 
                                (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                              }
                            </TextField>
                          )}
                        />                      
                      <Controller 
                          name='destinationStoreId' control={control}                                     
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="destinationStoreId"
                              label={`=> ${t(('Destination'))} :  ${t('Store')}`} inputProps={ {readOnly: false}} >
                              {stores && stores.map( 
                                (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                              }
                            </TextField>
                          )}
                        />  
                    </Box> 
                    
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t(('Status of stock transfer'))} `}
                      </Typography>                                                       
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller control={control}
                          name='issueDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Issue date')} 
                              onChange={onChange} readOnly disableOpenPicker                     
                              value={new Date(value)}
                              slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                              //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                      />
                      <Controller 
                          name={`status`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(70% - 8px)'}} id="status"
                                label={t('Status')} inputProps={ {readOnly: true }} >
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                          )}
                      />
                      
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <Controller control={control}
                            name='statusDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Status date')} 
                                onChange={onChange} readOnly disableOpenPicker                     
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                              /> )}
                        />
                        <Controller 
                            name='statusPurpose' control={control}                                     
                            render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(70% - 8px)'}} id="status"
                                label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_STOCK_TRANSFER_STATUS_PURPOSE ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                            )}
                        />
                    </Box>
                </Stack>                        
              </Grid>                   
              <Grid item xs={12}  md={7} component={Paper} >
                  <Stack flexDirection='column'> 
                    
                    <Box key={`${'watchFileName'} - key ${getValues().stockTransferArticles.map(x => x.quantity)}`} sx={{ mt: 1, width: '100%' }} >
                      <ArrayFieldTableEx<IStockTransfer,IStockTransferArticle,'id'> 
                        mainObject={getValues()} fieldKey='id' 
                        headCells={headStockTransferArticleCells} rowsPathName={`stockTransferArticles` }
                        title={t('_Articles')} rowActionIcon={stockTransferArticleRowActionIcon}  
                        onRowSelected={handleStockTransferArticleSelected}
                                            
                        refAppend={refAppendStockTransferArticles as MutableRefObject<(value: Partial<FieldArray<IStockTransfer>> | Partial<FieldArray<IStockTransfer>>[], options?: FieldArrayMethodProps) => void>}
                        refUpdate={refUpdateStockTransferArticle as MutableRefObject<(index: number,value: Partial<FieldArray<IStockTransfer>>) => void>}
                        refRemove={refRemoveStockTransferArticle as MutableRefObject<(index: number) => void>}

                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                        //displayMore={undefined}
                        toolbarActions={[
                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddStockTransferArticle ,icon: AddCircleIcon,  },
                        
                        ]}
                      />  
                      { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                        okText={t('OK')} cancelText={t('Cancel')} title={t('Product filter')} onCancel={()=> {setOpenProductFilter(false);}} 
                        onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                            <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                        </FormDialog> }
                                             
                    </Box>
                      
                  </Stack> 
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
  )
}
