export interface IPackaging {
    id: number,
    
    name: string,
    countMaxPerson: number,
    description: string,
    isActive: boolean,
    priceExpression: string,
    
    
    packagingProducts: IPackagingProduct[]
  }

export interface IPackagingProduct {
  id: number,
  
  packagingId: number,
  productId: number,

  description: string,

  productName: string
}

  
  export const defaultPackaging : IPackaging = {
    id: 0,
    
    name: '',
    countMaxPerson: 1,
    description: '',
    isActive: true,
    priceExpression: '',

    packagingProducts: []
  }

  

  export interface IPackagingSearch {
 
    name: string,
    description: string,
    
  }