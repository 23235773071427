
export type _ResourceType = 'temporary' | 'permanent' | 'exploitation';

export interface IResourceType {
    id: number,
    type: _ResourceType,    

    name: string,
    description: string,
    isActive: boolean,

    categoryCode: string,
    temporaryFilterOption: string,

    resourceTypeTasks: IResourceTypeTask[]
  }

export interface IResourceTypeTask {
  id: number,
  
  resourceTypeId: number,
  taskCode: string,
  part: number
}

  
  export const defaultResourceType : IResourceType = {
    id: 0,
    type: 'temporary',  
    name: '',
    description: '',
    isActive: true,
    
    categoryCode: '',
    temporaryFilterOption: '',

    resourceTypeTasks: []
  }

  

  export interface IResourceTypeSearch {
 
    name: string,
    description: string,
    categoryCode: string
  }