
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState } from 'recoil';
import { FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import Button from '@mui/material/Button';


import {IRole, defaultRole, IRoleEntity, IRoleEntityFeature } from './models/Role';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { FormDialog } from 'components/ui/FormDialog';
import { IEntity, IFeatureDescription, IResult } from 'library/interface';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useRoleService, { useBasicFilterRole } from './services/Role';





export const RoleForm: FC<IRole> = (props: IRole = defaultRole) => {
    
    const navigate = useNavigate();
    const { t, i18n } = useTranslation();
    const {id} = useParams();
    
    const [_id, _setId] = useState<number>( Number( id || 0 ) );

    const { enqueueSnackbar } = useSnackbar();
   
    const [roleEntityIndex, setRoleEntityIndex] = useState<number>(-1);

    const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization} = useEntityService();

    const { createRole, updateRole, getRole, getRoles } = useRoleService();

    const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
    const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

    const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
    const basicFilterRole = useBasicFilterRole( 
        (event: React.MouseEvent<unknown>, row: IRole) => {
            setIsSearchBoxShow(false);
            _setId(row.id);
        }
    );

    const emptyFunc = (obj: any) => {}

    const [selectedFilterEntities, setSelectedFilterEntities] = useState<string[]>([]);
    const [filteredEntities, setFilteredEntities] = useState<IEntity[]>([]);

    const [openEntityFilter, setOpenEntityFilter] = useState(false);
    const basicFilterEntity = useBasicFilterEntity( 
        (event: React.MouseEvent<unknown>, row: IEntity) => {
            const {name, description, id} = row;

            if(getValues().roleEntities.some( b => b.entityName === name)) return;       
            
            (refAppendRoleEntities.current??emptyFunc)({roleId: _id, roleEntityId: id ,entityName: name, entityDescription: description, 
             canCreate: false, canRetrieve:false, canUpdate: false, printAllowed: false, workflowAllowed: false, attachAllowed: false,
             relativeViewAllowed: false, securityAllowed: false, linkAllowed: false, roleEntityFeatures: []     
            }); 
        
            setRoleEntityIndex( Math.max(0, getValues().roleEntities.length-1));        
            setOpenEntityFilter(false);
        }, {
            rowCheckedMode: 'multiple', 
            stateSelected: [selectedFilterEntities, setSelectedFilterEntities],
            stateFiltered: [filteredEntities, setFilteredEntities],
        }
    );

    const handleOkBasicTextFilterEntityForm = () => {
        if(selectedFilterEntities.length === 0) {
            // enque
            setOpenEntityFilter(false);
            return;
        } 

        const entities2Append = filteredEntities.filter(ne => selectedFilterEntities.includes(ne.name || '') &&
             !getValues().roleEntities.some(e => e.entityName === ne.name) );

        if(entities2Append.length === 0) return;

        (refAppendRoleEntities.current??emptyFunc)( entities2Append.map( ({name, description, id}) => 
            ({roleId: _id, roleEntityId: id , entityName: name, entityDescription: description, 
                canCreate: false, canRetrieve: false, printAllowed: false, workflowAllowed: false, attachAllowed: false, canUpdate: false,
                relativeViewAllowed: false, securityAllowed: false, linkAllowed: false,  roleEntityFeatures: []     
            })));

        setOpenEntityFilter(false);
    }


    const [selectedFilterFeatures, setSelectedFilterFeatures] = useState<string[]>([]);
    const [filteredFeatures, setFilteredFeatures] = useState<IFeatureDescription[]>([]);

    const [openFeatureFilter, setOpenFeatureFilter] = useState(false);
    const basicFilterFeature = useBasicFilterFeatureDescription(
        () => {
            if(roleEntityIndex < 0 || getValues().roleEntities.length <= roleEntityIndex ) return 'K--@@--K';  
            return getValues().roleEntities[roleEntityIndex].entityName;
        }, 
        (event: React.MouseEvent<unknown>, row: IFeatureDescription) => {
            const {name, label, entityName} = row;
  
            const roleEntity = getValues().roleEntities.find(b => b.entityName === entityName);
            if(!roleEntity) return;            
    
            if( roleEntity && !roleEntity.roleEntityFeatures.some(f => f.featureName === name) )
                (refAppendRoleEntityFeatures.current??emptyFunc)({featureName: name, featureDescription: label, roleEntityId: -1 });
            
            //setFilteredFeatures([]);
            setOpenFeatureFilter(false);
        }, {
                rowCheckedMode: 'multiple', 
                stateSelected: [selectedFilterFeatures, setSelectedFilterFeatures],
                stateFiltered: [filteredFeatures, setFilteredFeatures],
        }
    );

    
    const handleOkFeatureFilterClick = () => {
        
        if(selectedFilterFeatures.length === 0) {
            // enque
            setOpenFeatureFilter(false);
            return;
        } 

        const featureDescriptions = filteredFeatures.filter(f => selectedFilterFeatures.includes(f.name));
        const entityNames = Array.from( new Set( featureDescriptions.map( f => f.entityName ) || []) );
        if(entityNames.length !== 1) {
            // enque
            setOpenFeatureFilter(false);
            return;
        } 
        const entityName = entityNames[0];
        const roleEntity = getValues().roleEntities.find(b => b.entityName === entityName);
        if(!roleEntity) return;

        const features2Append = featureDescriptions.filter(nf => !roleEntity.roleEntityFeatures.some(f => f.featureName === nf.name) );
        if(features2Append.length === 0) return;

        (refAppendRoleEntityFeatures.current??emptyFunc)( features2Append.map( ({name, label}) => 
                ({featureName: name, featureDescription: label, roleEntityId: -1 })));
                
        setOpenFeatureFilter(false);
    }

    const methods = useForm<IRole>({defaultValues:defaultRole});
    const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

    const queryClient = useQueryClient();
    const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IRole>,Error,IRole>(
        _id>0?updateRole:createRole, {   
            onSuccess: (data: IResult<IRole>) => {
                enqueueSnackbar( 'Operation done !!!', { variant: 'success',
                        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                        
                setIsSaveLoading(false);
                _setId(data.data.id);
                //setCurrentEntityIdForAction(data.data.id);
            
            queryClient.invalidateQueries(['Role',data.data.id]);
            },
            onError: (err: Error) => {          
                enqueueSnackbar( error?.message, { variant: 'error',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
                setIsSaveLoading(false);
            }
        });

    const {data: _data, refetch} = useQuery<IRole>(['Role', _id], () => retrieveEntity('Role',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const [headRoleEntityCells, setHeadRoleEntityCells]  = useState<HeadCell<IRoleEntity>[]>([      
        {id:'id', label : t('Id'),  display: false, type: 'string', },
        {id:'entityName', label : t('Name'),  display: false, type: 'string', },
        {id:'entityDescription', label : t('Description'),  display: true, type: 'string', },
        {id:'canCreate', label : t('Create ?'),  display: true, type: 'boolean', },
        {id:'canRetrieve', label : t('Visualize ?'),  display: true, type: 'boolean', },
        {id:'canUpdate', label : t('Update ?'),  display: true, type: 'boolean', },
        {id:'printAllowed', label : t('Print ?'),  display: true, type: 'boolean', },
        {id:'attachAllowed', label : t('Attach ?'),  display: true, type: 'boolean', },
        {id:'relativeViewAllowed', label : t('Rel. view?'),  display: false, type: 'boolean', },
      ]);
    const refAppendRoleEntities = useRef<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateRoleEntity = useRef<(index: number,value: Partial<FieldArray<IRole>> ) => void>(null);
    const refRemoveRoleEntity = useRef<(index: number ) => void>(null);

    const [selectedRoleEntities, setSelectedRoleEntities] = useState<string[]>([]);

    const handleAddEntities = (event: any) => {
        setOpenEntityFilter(true);
    }

    const handleRoleEntitySelected = (event: React.MouseEvent<unknown>,index: number,row: IRoleEntity) => {      
        setRoleEntityIndex(index);
    }

    const roleEntityRowActionIcon = ( roleEntity: IRoleEntity) : ActionIconTableRow<IRole,IRoleEntity> => {
  
        const res: ActionIconTableRow<IRole,IRoleEntity> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, /*field: FieldArrayWithId<IBilling, ArrayPath<IBilling>, string> ,*/row: IRoleEntity) => {
            
             (refRemoveRoleEntity.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    

    
    const [headRoleEntityFeatureCells, setHeadRoleEntityFeatureCells]  = useState<HeadCell<IRoleEntityFeature>[]>([      
        {id:'id', label : t('Id'),  display: false, type: 'string', },
        {id:'featureName', label : t('Name'),  display: false, type: 'string', },
        {id:'featureDescription', label : t('Description'),  display: true, type: 'string', },
      ]);
    const refAppendRoleEntityFeatures = useRef<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateRoleEntityFeature = useRef<(index: number,value: Partial<FieldArray<IRole>> ) => void>(null);
    const refRemoveRoleEntityFeature = useRef<(index: number ) => void>(null);

    const handleAddFeatures = (event: any) => {
        if(roleEntityIndex < 0 || getValues().roleEntities.length <= roleEntityIndex ) return;
        setOpenFeatureFilter(true);
      }

      const roleEntityFeatureRowActionIcon = ( roleEntity: IRoleEntityFeature) : ActionIconTableRow<IRole,IRoleEntityFeature> => {
  
        const res: ActionIconTableRow<IRole,IRoleEntityFeature> = {
          toolTip: 'viewDetails',
          icon: RemoveCircleIcon,
          hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
          isActionExecuting: true,
          onRowClickIcon: (event : any,index: number, /*field: FieldArrayWithId<IBilling, ArrayPath<IBilling>, string> ,*/row: IRoleEntityFeature) => {
            
             (refRemoveRoleEntityFeature.current??emptyFunc)(index);            
          }
        }
        return res;
    }

    
      useEffect( () => {              
        setCurrentFormNameAtom(t('Role'));  
        setCurrentBasicTextFilterProps(basicFilterRole);
      }, []);


      /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
        // setCurrentFormName(t('Billing'));        
        
        if(_id > 0)
          retrieveData('Role',_id, refetch);  
      }, [_id] );


    useEffect( () => {
       
        if(_data && _data.id > 0) {
        reset(_data);

        setRoleEntityIndex(0);  
    }
    }, [_data]);

  const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
    _setId(0);           
    reset(defaultRole);    
  }
  
  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
    
    if(!checkEntitySaveAuthorization('Role', _id)) {
        setIsSaveLoading(false);         
        return;
      }

      const data = getValues(); 
      if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }
  
      mutate(data);
  }

  const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
    openEntityActionDrawer('Role', _id);
  }
  
  
  
  const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
  //    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
  //    reset(_data);        
   }
    

    return (
        <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box  sx={{ mt: 1, width: '100%' }} >
                                {/* <legend>Jean-François H</legend> component="fieldset"*/}
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(35% - 8px)'}} id="name" label={t('Name')} {...register('name')} />
                                <TextField sx={{width:'calc(55% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={8} component={Paper} >
                        <Stack flexDirection='column'>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <ArrayFieldTableEx<IRole,IRoleEntity,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headRoleEntityCells} rowsPathName='roleEntities' 
                                    title={t('Entities')} rowActionIcon={roleEntityRowActionIcon}  
                                    onRowSelected={handleRoleEntitySelected}
                                                        
                                    refAppend={refAppendRoleEntities as MutableRefObject<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateRoleEntity as MutableRefObject<(index: number,value: Partial<FieldArray<IRole>>) => void>}
                                    refRemove={refRemoveRoleEntity as MutableRefObject<(index: number) => void>}

                                    stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddEntities ,icon: AddCircleIcon,  },
                                    
                                    ]}
                                />
                                { openEntityFilter && <FormDialog open={openEntityFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Entity filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEntityFilter(false);}} onOk={handleOkBasicTextFilterEntityForm}  >
                                        <BasicTextFilterForm<IEntity> {...basicFilterEntity } />
                                </FormDialog> }
                            </Box>
                        </Stack> 
                    </Grid>
                    <Grid item xs={12}  md={4} component={Paper} >
                        <Stack flexDirection='column'>
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                            {
                                getValues().roleEntities.map( (roleEntity,index) => {
                                
                                    return (index === roleEntityIndex) && <ArrayFieldTableEx<IRole,IRoleEntityFeature,"id"> 
                                    key={`roleEntity-${index}`}
                                    rowsPathName={`roleEntities.${index}.roleEntityFeatures`}
                                    headCells={headRoleEntityFeatureCells}  fieldKey='id'                     
                                    title={`${t('Features')} : ${roleEntity.entityDescription} `} rowActionIcon={roleEntityFeatureRowActionIcon}
                                    refAppend={refAppendRoleEntityFeatures as MutableRefObject<(value: Partial<FieldArray<IRole>> | Partial<FieldArray<IRole>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateRoleEntityFeature as MutableRefObject<(index: number,value: Partial<FieldArray<IRole>>) => void>}
                                    refRemove={refRemoveRoleEntityFeature as MutableRefObject<(index: number) => void>}

                                    //onRowSelected={handleBillingDetailTaskSelected}
                                    //displayMore={undefined}
                                    
                                    toolbarActions={[
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddFeatures ,icon: AddCircleIcon,  },
                                        
                                        ]} 
                                
                                    /> 
                                } )  
                            }
                            { openFeatureFilter && <FormDialog open={openFeatureFilter} maxWidth='md'
                                okText={t('OK')} cancelText='' title={t('Feature filter')} onCancel={()=> {}} 
                                onClose={()=> {setOpenFeatureFilter(false);}} onOk={handleOkFeatureFilterClick}  >
                                    <BasicTextFilterForm {...basicFilterFeature} />
                                </FormDialog> }
                            </Box>
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>       
    );
}