
export type MovementType = 'in' | 'out';

export interface IStockMovement {
    id: number,

    storeId: number,

    type: MovementType,
    
    reference: string,
    description: string,
    stockMovementDate: Date,
    issueDate: Date,

    movementPurpose: string,

    status: string,
    statusDate: Date,
    statusPurpose: string,
    
    stockMovementArticles: IStockMovementArticle[],
  }

  export interface IStockMovementArticle {
    id: number, 
    stockMovementId: number,
    articleId: number,
    option: string,

    quantity: number, 
    
    articleName: string,
    articleFilterOption: string,
  }

  

export const defaultStockMovement : IStockMovement = {
  id: 0,

  storeId: 0,

  type: 'in',
  
  reference: '',
  description: '',
  stockMovementDate: new Date(),
  issueDate: new Date(),

  movementPurpose: '',

  status: '95',
  statusDate: new Date(),
  statusPurpose: '',

  stockMovementArticles: [],
  }

  export const defaultStockMovementArticle : IStockMovementArticle = {
    id: 0, 
    stockMovementId: 0,
    articleId: 0,
    option: '',

    quantity: 0, 
    
    articleName: '',
    articleFilterOption: '',
  }

  

  export interface IStockMovementSearch {    
    reference: string,
    description: string
  }

  