
//export type ProductType = 'service' | 'article';

export interface ISharing {
    id: number,
    
    name: string,
    description: string,

    sharingItems: ISharingItem[]
  }

export interface ISharingItem {
  id: number,
  sharingId: number,

  category: string,
  part: number,
  managerPart: number
}

  
  export const defaultSharing : ISharing = {
    id: 0,
    
    name: '',
    description: '',

    sharingItems: []
  }

  export interface ISharingSearch {
 
    name: string,
    description: string
  }