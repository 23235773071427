
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ICertificateAllocation, ICertificateAllocationSearch } from "../models/CertificateAllocation";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createCertificateAllocation = async (certificateallocation: ICertificateAllocation)  =>       
        await (await axios.post('/api/misc/certificateallocation/create', certificateallocation)).data;       
        
    const updateCertificateAllocation = async (certificateallocation: ICertificateAllocation)  =>       
        await (await axios.post('/api/misc/certificateallocation/update', certificateallocation)).data; 
    
    const getCertificateAllocation = async (id  : number )  => {
      const {data} = (await axios.get(`/api/misc/certificateallocation/get-certificateallocation/${id}`));
      return await data;
    }
   

    const getCertificateAllocations = async (criteria: ICertificateAllocationSearch) : Promise<ICertificateAllocation[]> => {

      const {type, intermediaryCode,reference} = criteria;

      const {data} = (await axios.get(`/api/misc/certificateallocation/get-certificateallocations?type=${type}&intermediaryCode=${intermediaryCode}&reference=${reference}`));
      return await data;
    }
    
      
    return {    
      createCertificateAllocation,
      updateCertificateAllocation,
      getCertificateAllocation,
      getCertificateAllocations
      
    } 
}

export default _;

export interface IFilterCertificateAllocationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ICertificateAllocation[], React.Dispatch<React.SetStateAction<ICertificateAllocation[]>>],
}

const defaultFilterCertificateAllocationOption: IFilterCertificateAllocationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterCertificateAllocation = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ICertificateAllocation) => void,
                                            filterOption?: IFilterCertificateAllocationOption  ) => {

  const { getCertificateAllocations } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterCertificateAllocationOption;



  const [headCertificateAllocationCells, setHeadCertificateAllocationCells]  = useState<HeadCell<ICertificateAllocation>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'intermediaryCode', label : t('Inter. code'),  display: true, type: 'string', },
    {id:'reference', label : t('Reference'),  display: true, type: 'string', },
    {id:'startNumber', label : t('Start #'),  display: true, type: 'numeric', },
    {id:'endNumber', label : t('End #'),  display: true, type: 'numeric', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'type', text: t('Type'), value: ''},
      {name: 'intermediaryCode', text: t('Inter. code'), value: ''},
      {name: 'reference', text: t('Reference'), value: ''},
      
    ]);    

  const [filteredCertificateAllocations, ] = useState<ICertificateAllocation[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ICertificateAllocation[]> => {
    
    
    const type = filterElements.find( elt => elt.name === 'type')?.value || '';
    const intermediaryCode = filterElements.find( elt => elt.name === 'intermediaryCode')?.value || '';
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
       

    const arr = await getCertificateAllocations( {type, intermediaryCode,reference} );
   
    return arr;
  }

  const objKey: keyof ICertificateAllocation = 'id';

  return {
    title: t('Certificate allocation'), headCells: headCertificateAllocationCells, objKey,
    filterElements, rows: filteredCertificateAllocations, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
