
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { IStore, defaultStore } from './models/Store';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useStoreService, { useBasicFilterStore } from './services/Store';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem } from '@mui/material';
import NumberFormat from 'react-number-format';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from './services/Enumeration';
import IEnumeration from './models/Enumeration';

import { sum } from 'lodash';

import { carouselImage } from 'themes/commonStyles';
import { isFalsy } from 'utility-types';

import EnhancedTable from 'components/ui/EnhancedTable';
import { IArticleStock } from 'features/configuration/models/Delivery';
import useDeliveryService from 'features/configuration/services/Delivery';

export const StoreForm: FC<IStore> = (props: IStore = defaultStore) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createStore, updateStore } = useStoreService();
  const {getArticlePerStores } = useDeliveryService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterStore = useBasicFilterStore( 
    (event: React.MouseEvent<unknown>, row: IStore) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  

  const methods = useForm<IStore>({defaultValues:defaultStore});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchBase64Picture = watch('base64Picture');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IStore>,Error,IStore>(
      _id>0?updateStore:createStore, {   
        onSuccess: (data: IResult<IStore>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Store')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['Store',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IStore>(['Store', _id], () => retrieveEntity('Store',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
      
      const setFile = (_event: any) => {
        let f = _event.target.files![0];
      
        const fileSizeInKB = f.size / 1024;
        // Check if the file size is within your limit (e.g., 200 KB)
        if (fileSizeInKB > 200) {
          alert(t('File size should be less than 200 KB'));
          return;
        }
      
        var reader = new FileReader();
      
        reader.onload = function () {
      
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
      
            setValue("base64Picture", base64String);
            setValue("fileName", f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };    
          reader.readAsDataURL(f);    
      };

    const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
    useEffect( () => {
      
      async function _() {   
                
        const stocks = _id>0 ? await getArticlePerStores(_id, 0) : [];
        setArticleStocks(stocks);
      }
      _();  
    }, [_id] );

      useEffect( () => {        
        setCurrentFormNameAtom(t('Store'));
        setCurrentBasicTextFilterProps(basicFilterStore);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            setCurrentFormNameAtom(_id>0?`${t('Store')} - # ${_id} # -`: t('Store') );
            if(_id > 0)
              retrieveData('Store',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultStore);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        if(!checkEntitySaveAuthorization('Store', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Store', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                
                                <TextField sx={{width:'calc(60% - 8px)'}} id="name" label={t('Name')} 
                                  {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                <TextField sx={{width:'calc(40% - 8px)'}} id="location" label={t('Location')}
                                  {...register('location')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(75% - 8px)'}} id="description" 
                                  label={t('Description')} {...register('description')} />
                              <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                label={`${t('Showroom')} ?`}
                                control={
                                <Controller
                                    name='isShowroom'
                                    control={control}
                                    render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                              />} /> 
                            </Box>
                            <Box sx={{ mt: 5, width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', }} >
                              <input type="file" id="file-upload" style={{ display: "none" }} onChange={setFile}
                                accept="image/*" />
                              <div key={'step.id'}>
                                <Box
                                  component="img"
                                  sx={carouselImage}
                                  //src={`data:image/png;base64,${watchBase64Picture}`}
                                  src={isFalsy(watchBase64Picture)?`/images/product.jpg`:`data:image/png;base64,${watchBase64Picture}`}
                                  onClick={openFileDialog}
                                  //alt={step.id}
                                ></Box>
                              </div>
                              {/* <img  width='100%'
                                onClick={openFileDialog} height='100%' maxHeight='250px' /> */}
                            </Box>
                        </Stack>                        
                    </Grid> 
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                        <Box sx={{ mt: 1, width: '100%' }} >                        
                          <EnhancedTable<IArticleStock> rows={articleStocks} 
                            headCells={[            
                              //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                              {id:'articleName', label : t('Article'),  display: true, type: 'string', width: 33},
                              {id:'option', label : t('Option'),  display: true, type: 'string', width: 12},

                              {id:'quantityDelivery', label : `${t('_Deli')}(+)`,  display: true, type: 'numeric', width: 10},   
                              {id:'quantityBilling', label : `${t('_Bil')}(-)`,  display: true, type: 'numeric', width: 9},     
                              {id:'quantityTransfertVariation', label : `${t('_Trsf')}`,  display: true, type: 'numeric', width: 9},  
                              {id:'quantityMovementVariation', label : `${t('_Mvt')}`,  display: true, type: 'numeric', width: 8}, 
                                           
                              {id:'quantityInventoryVariation', label : `${t('_Inv')}`,  display: true, type: 'numeric', width: 9},                  
                              {id:'quantity', label : t('Qty'),  display: true, type: 'numeric', width: 10}, 

                            ]} 
                            title={`${t(`Articles in store`)} ...`} objKey='storeName' 
                            stateSelected={undefined} 
                            onRowSelected={undefined} rowCheckedMode='single'
                            onRowCheckedSelectChange={undefined} order='asc' orderBy='storeName'
                            onRowDoubleClick={undefined} 
                            rowActionIcon={undefined}
                            toolbarActions={[
                              // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                            ]}
                          />
                        </Box>                   
                      </Stack>
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

