
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IPayment, IPaymentSearch } from "../models/Payment";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';

import { addMonths, addDays } from 'date-fns';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createPayment = async (payment: IPayment)  =>       
        await (await axios.post('/api/finance/payment/create', payment)).data;       
        
    const updatePayment = async (payment: IPayment)  =>       
        await (await axios.post('/api/finance/payment/update', payment)).data; 
    
    const getPayment = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/payment/get-payment/${id}`));
      return await data;
    }
   

    const getPayments = async (criteria: IPaymentSearch, pagination?: IPagination) : Promise<IPayment[]> => {
      
      const { startDate, endDate, description, ownerName, bearerName} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString().split('T')[0]);
      const sEndDate = encodeURIComponent(endDate.toISOString().split('T')[0]);

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/payment/get-payments?sStartDate=${sStartDate}&sEndDate=${sEndDate}&` +
        `&description=${description}&ownerName=${ownerName}&bearerName=${bearerName}`+
        `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getPaymentsByRegistration = async (registrationId: number) : Promise<IPayment[]> => {
            
      const {data} = (await axios.get(`/api/finance/payment/get-payments-by-registration?registrationId=${registrationId}`));
      return await data;
    }
      
    return {    
      createPayment,
      updatePayment,
      getPayment,
      getPayments,
      
      getPaymentsByRegistration
     
    } 
}

export default _;

export interface IFilterPaymentOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IPayment[], React.Dispatch<React.SetStateAction<IPayment[]>>],
}

const defaultFilterProductOption: IFilterPaymentOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterPayment = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IPayment) => void,
                                            filterOption?: IFilterPaymentOption  ) => {

  const { getPayments } = _();

  const { t, i18n } = useTranslation();   

  //const {schoolYears} = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;



  const [headPaymentCells, setHeadPaymentCells]  = useState<HeadCell<IPayment>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    {id:'ownerFullName', label : t('Beneficiary'),  display: true, type: 'string', },
    {id:'bearerFullName', label : t('Bearer'),  display: true, type: 'string', },

    {id:'totalAmount', label : t('Total'),  display: true, type: 'numeric', },
    {id:'accessories', label : t('Accessories'),  display: true, type: 'numeric', },

    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },
    
    {id:'isCancelled', label : t('Canc.?'),  display: true, type: 'boolean', },

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
    // {name: 'schoolYearId', text: t('School year'), value: '0', dataType: 'enumeration', 
    //   options: [{name: t('All'), value: '0'}, ...schoolYears.map( ({id, name}) => ({value: String(id), name}) )]
    // },

    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'description', text: t('Description'), value: ''},

    {name: 'ownerName', text: t('Beneficiary'), value: ''},
    {name: 'bearerName', text: t('Bearer'), value: ''},      
      
    ]);    

  const [filteredPayments, ] = useState<IPayment[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IPayment[]> => {
    
    // const temp = filterElements.find( elt => elt.name === 'schoolYearId')?.value || '';
    // const schoolYearId = isNumber(temp) ? toNumber(temp) : 0;
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    const ownerName = filterElements.find( elt => elt.name === 'ownerName')?.value || '';
    const bearerName = filterElements.find( elt => elt.name === 'bearerName')?.value || '';
       
    const arr = await getPayments( { startDate, endDate, description,ownerName,bearerName} );
    
    return arr;
  }

  const objKey: keyof IPayment = 'id';

  return {
    title: t('Payment'), headCells: headPaymentCells, objKey,
    filterElements, rows: filteredPayments, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
