
import { PersonType } from "features/production/models/Person";

export interface IStockTransfer {
    id: number,

    originStoreId: number,
    destinationStoreId: number,

    reference: string,
    description: string,
    stockTransferDate: Date,
    issueDate: Date,

    supplierId: number,
    supplierType: PersonType,
    supplierCorporationName: string,  
    supplierFirstName: string,
    supplierLastName: string,

    supplierParticularSign: string, 

    supplierPortable1: string,
    supplierPortable2: string,
    supplierEmail1: string,
    supplierEmail2: string, 

    status: string,
    statusDate: Date,
    statusPurpose: string,
    
    stockTransferArticles: IStockTransferArticle[],
  }

  export interface IStockTransferArticle {
    id: number, 
    stockTransferId: number,
    articleId: number,
    option: string,

    //quantityDemand: number, 
    quantity: number, 
    
    articleName: string,
    articleFilterOption: string,
  }

  

export const defaultStockTransfer : IStockTransfer = {
  id: 0,

  originStoreId: 0,
  destinationStoreId: 0,

  reference: '',
  description: '',
  stockTransferDate: new Date(),
  issueDate: new Date(),

  supplierId: 0,
  supplierType: 'moral',
  supplierCorporationName: '',  
  supplierFirstName: '',
  supplierLastName: '',

  supplierParticularSign: '', 

  supplierPortable1: '',
  supplierPortable2: '',
  supplierEmail1: '',
  supplierEmail2: '', 
  
  

  status: '95',
  statusDate: new Date(),
  statusPurpose: '',

  stockTransferArticles: [],
  }

  export const defaultStockTransferArticle : IStockTransferArticle = {
    id: 0, 
    stockTransferId: 0,
    articleId: 0,
    option: '',

    //quantityDemand: 0, 
    quantity: 0, 
    
    articleName: '',
    articleFilterOption: '',
  }

  

  export interface IStockTransferSearch {    
    reference: string,
    description: string
  }

  