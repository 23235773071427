export type AppointmentProductType = 'service' | 'packaging';

export interface IAppointment {
  id: number,
  
  billingId: number,
  personId: number,

  portable1: string,
  portable2: string,

  email1: string,
  email2: string,

  firstName: string,
  lastName: string,
  defaultLanguage: string,

  appointmentDate: Date,
  notificationDate: Date,
  notificationMode: string,
  
  status: string,
  statusDate: Date
  statusPurpose: string,

  serviceDescription: string,

  appointmentProducts: IAppointmentProduct[]
}

export interface IAppointmentProduct {
  id: number,
  
  type: AppointmentProductType,

  appointmentId: number,
  serviceId: number,
  packagingId: number,
  employeeId: number,

  appointmentDate: Date,

  startTime: Date,
  endTime: Date,
 
  productName: string,
  serviceDurationInMinute: number,
  employeeFirstName: string,
  employeeLastName: string,

  firstName: string,
  lastName: string,

  portable1: string,
  portable2: string,
  email1: string,
  email2: string,

  employeeFullName: string,
}

  
export const defaultAppointment : IAppointment = {
  id: 0,
  
  billingId: 0,
  personId: 0,

  portable1: '',
  portable2: '',

  email1: '',
  email2: '',

  firstName: '',
  lastName: '',
  defaultLanguage: 'fr-FR',

  appointmentDate: new Date(),
  notificationDate: new Date(),
  notificationMode: '',

  status: '95',
  statusDate: new Date(),
  statusPurpose: '',
  
  serviceDescription: '',

  appointmentProducts: []
}

export const defaultAppointmentProduct : IAppointmentProduct = {
  id: 0,
  
  type: 'service',

  appointmentId: 0,
  serviceId: 0,
  packagingId: 0,
  employeeId: 0,

  appointmentDate: new Date(),

  startTime: new Date(),
  endTime: new Date(),
 
  productName: '',
  serviceDurationInMinute: 0,
  employeeFirstName: '',
  employeeLastName: '',

  firstName: '',
  lastName: '',

  portable1: '',
  portable2: '',
  email1: '',
  email2: '',

  employeeFullName: '',
}

export interface IAppointmentSearch {

  startDate: Date,
  endDate: Date,

  name: string,
  portable: string,
  email: string,  
}