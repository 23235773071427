
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import { useSnackbar } from 'notistack';

import {useWindowSize} from 'react-use';
import { MenuItem, useMediaQuery } from '@mui/material';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useUtils from 'library/utils';

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { makeStyles } from '@mui/material';

import { useTheme } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!
import resourceTimeGridPlugin from '@fullcalendar/resource-timegrid';
import listPlugin from '@fullcalendar/list';
import timeGridPlugin from '@fullcalendar/timegrid';

// import '@fullcalendar/common/main.css';
// import '@fullcalendar/resource-timegrid/main.css';
// import '@fullcalendar/daygrid/main.css';
// import '@fullcalendar/timegrid/main.css';


import { addDays, addHours, addMinutes, addMonths, addYears, getWeekOfMonth, getWeek, getWeeksInMonth,
  endOfWeek, startOfWeek,
  formatISO,
  subDays,
  getDay,getMonth,getYear, getDayOfYear,
 } from "date-fns";
// import '@fullcalendar/common/main.css'; 
// import '@fullcalendar/resource-timegrid/main.css';


import interactionPlugin from '@fullcalendar/interaction';


import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

import { MdOutlineAdd , MdArrowDropDownCircle} from 'react-icons/md';


import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useResourceService, { useBasicFilterResource } from 'features/production/services/Resource';
import { IResource, defaultResource  } from 'features/production/models/Resource';

import useEnumerationService from 'features/configuration/services/Enumeration';

import IEnumeration,  { IEnumerationItem, Enum_APPOINTMENT_STATUS, Enum_APPOINTMENT_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

//import EventClickArg  from '@fullcalendar/react'; // Import the types

// import WidgetSummary from './widgets/WidgetSummary';
// import WidgetBarChart from './widgets/WidgetBarChart';
// import WidgetPieChart from './widgets/WidgetPieChart';
import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';
import { DurationInput, EventInput, EventClickArg, } from '@fullcalendar/core';
import { isFalsy } from 'utility-types';
import { useQuery } from 'react-query';


import { isNumber, toNumber } from 'lodash';
import { Controller, useForm } from 'react-hook-form';


import { IBillingRentalResource, defaultBillingRentalResource } from 'features/finance/models/Billing';


interface IResourceDisplay {
  id: string;
  title: string;
}

export const ResourceAllocationTimeTableForm = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const {generateRandomColor} = useUtils();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  
  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup, employeeId ,isTechnician} = useRecoilValue(currentUserSessionAtom);
  

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const { getResourceAllocationsByYear, getResourceAllocationsByMonth, 
      getResourceAllocationsByWeek, getResourceAllocationsByDay} = useResourceService();
  
  const [schoolYearClassName, setSchoolYearClassName] = useState('');
  const [timetableEvents, setTimetableEvents] = useState<EventInput[]>([]);
  const [colors, setColors] = useState<Record<string, string>>({});

  const [weekStart, setWeekStart] = useState<Date>(new Date());
  const [weekEnd, setWeekEnd] = useState<Date>(new Date());

  const [slotMinTime, setSlotMinTime] = useState<DurationInput>({});
  const [slotMaxTime, setSlotMaxTime] = useState<DurationInput>({});

  const [openEventDialog, setOpenEventDialog] = useState<boolean>(false);

  const [openResourceFilter, setOpenResourceFilter] = useState(false);
  const basicFilterResource = useBasicFilterResource( 
      (event: React.MouseEvent<unknown>, row: IResource) => {
          const {id, description,permanentResourceId: resourceTypeId, capacity ,permanentResourceName: resourceTypeName} = row;
        
          // const findResource = getValues().rentalResources.find(x => x.resourceId === id);
          // if(!isFalsy(findResource)) return; 
        if(currentResourceUsage === 'add') {
          if(resources.some(x => x.id === id)) {
            enqueueSnackbar( t('This resource is already there'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 
            return;
          }
          setResources([...resources, {...row}]);
        } else {
          if(resource.id === id)
            {
              enqueueSnackbar( t('This resource is already there'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 3000 }); 
              return;
            }
            setResource( {...row});
        }        
                                    
        setOpenResourceFilter(false);
      }
  );

  const [resources, setResources ] = useState<IResource[]>([]);
  const [resource, setResource] = useState<IResource>({...defaultResource});

  const [date, setDate] = React.useState<Date>(new Date());
  const [status, setStatus] = React.useState<string>('10');
  const [view, setView] = React.useState<string>('day');

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'AppointmentTimeTable'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_APPOINTMENT_STATUS] ));

  const {data: yearAllocations} = useQuery<
    {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}>( ['Allocations', 'Year', date.getFullYear() ], 
      () => getResourceAllocationsByYear (resource.id, status ,date.getFullYear() ),
      {refetchOnWindowFocus: false ,enabled: (view === 'year' && resource.id>0 && date.getFullYear()>0) } );

  const {data: monthAllocations} = useQuery<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}>( ['Allocations', 'Month', date.getFullYear(), date.getMonth()], 
        () => getResourceAllocationsByMonth (resource.id, status ,date.getFullYear(), date.getMonth()+1 ),
        {refetchOnWindowFocus: false ,enabled: (view === 'month' && resource.id>0 && date.getFullYear()>0 && date.getMonth()>=0) } );

const {data: weekAllocations} = useQuery<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}>( ['Allocations', 'Week', 
          formatISO(addDays(startOfWeek(date),1), { representation: 'date' }),formatISO(subDays(endOfWeek(date),1), { representation: 'date' }), resources.map(x => x.id).join('-')], 
        () => getResourceAllocationsByWeek (resources.map(x => x.id), status,addDays(startOfWeek(date),1), subDays(endOfWeek(date),1) ),
        {refetchOnWindowFocus: false ,enabled: (view === 'week' && resources.length>0 && date.getFullYear()>0 && date.getMonth()>=0) } );

    //console.log( addDays(startOfWeek(date),1) );
    //console.log( subDays(endOfWeek(date),1));

const {data: dayAllocations} = useQuery<
      {weekStart: Date, weekEnd: Date, slotMinTime: DurationInput, slotMaxTime: DurationInput, timetableEvents: EventInput[]}>( 
          ['Allocations', 'Day', formatISO(date, { representation: 'date' }), resources.map(x => x.id).join('-') ], 
        () => getResourceAllocationsByDay (resources.map(x => x.id) , status ,date),
        {refetchOnWindowFocus: false ,enabled: (view === 'day' && resources.length>0 && date.getFullYear()>0 && date.getMonth()>=0) } );

  const { control: controlEventInput, setValue: setValueEventInput, getValues: getValuesEventInput, 
    register: registerEventInput, reset: resetEventInput, watch: watchEventInput,  } = useForm<IBillingRentalResource>({defaultValues: defaultBillingRentalResource});


  const [hideContact, ] = useState<boolean>( isTechnician && !applicationSetup.appointmentContactAllowedForTechnician );

  const [filterAppointment4Technician, ] = useState<boolean>( isTechnician && !applicationSetup.appointmentAllAvailableForTechnician );

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);


  
  const handleIconClick = (resourceId: string) => {
    setResources( resources.filter(x => String(x.id) !== resourceId ) );
  };

  const renderResourceLabel = ({ resource }: { resource: IResourceDisplay }) => {
    return (
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', paddingRight: '8px' }}>
        <span style={{flexGrow: 1}}>{resource.title}</span>
        <IconButton 
          color="error" 
          onClick={() => handleIconClick(resource.id)}
        >
          <DeleteIcon />
        </IconButton>
      </div>
    );
  };

  const getWeekVisibleRange = () => {
    const startDate = addDays(startOfWeek(date, { weekStartsOn: 1 }), 1);
    const endDate = subDays(endOfWeek(date, { weekStartsOn: 1 }), 1);
    return { start: formatISO(startDate,{ representation: 'date' }), end: formatISO(endDate,{ representation: 'date' }) };
  };

  // const resources = [
  //   { id: 'a', title: 'Resource A' },
  //   { id: 'b', title: 'Resource B' },
  //   { id: 'c', title: 'Resource C' },
  //   { id: 'd', title: 'Resource C' },
  //   { id: 'e', title: 'Resource C' }
  // ];

  const events = [
    { id: '1', resourceId: 'a', start: '2024-05-17T09:00:00', end: '2024-05-28T18:00:00', title: 'Event 1',backgroundColor: '#ff9f89', 
    borderColor: '#ff9f89',
    textColor: '#000000' },
    { id: '3', resourceId: 'a', start: '2024-05-17T09:00:00', end: '2024-05-28T18:00:00', title: 'Event 3',backgroundColor: '#ffdd57', 
    borderColor: '#ffdd57',
    textColor: '#000000' },
    { id: '2', resourceId: 'b', start: '2024-05-17T10:00:00', end: '2024-05-17T15:00:00', title: 'Event 2', backgroundColor: '#ffdd57', 
    borderColor: '#ff5557',
    textColor: '#000000' }
  ];

  const handleEventClick = (clickInfo: EventClickArg) => {
    alert(`Event: ${clickInfo.event.title}\nResource: ${clickInfo.event.getResources()[0].title}`);
  };
  
  const [currentResourceUsage, setCurrentResourceUsage] = useState<'add' | 'set'>('add');
  const handleClickAddResource = (event: any) => {
    setCurrentResourceUsage('add');       
    setOpenResourceFilter(true);
  }

  const handleClickSetResource = (event: any) => {
    setCurrentResourceUsage('set');       
    setOpenResourceFilter(true);
  }

  useEffect( () => {        
    setCurrentFormName(`:: ${t('Resource')} - ${t('Allocation')} ::`);
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
    
  }, []);     


  useEffect( () => {        
    
    
    // console.log(date.getDay());
    // console.log(getWeek(date));
    // console.log(getWeeksInMonth(date));
    // console.log(getWeekOfMonth(date));

//     console.log( addDays(startOfWeek(date),1 ) );
//     console.log( subDays(endOfWeek(date),1));
console.log( dayAllocations);
// console.log((view === 'day' && dayAllocations) ? 
// dayAllocations!.timetableEvents.map( x => ({...x, 
//   start: getDayOfYear(x.start||date)<getDayOfYear(date)? new Date(getYear(date), getMonth(date),getDay(date),0,0,0):x.start,
//   end: date<(x.end||date) ? new Date(getYear(date), getMonth(date),getDay(date),23,59,59): x.end,
//   resourceId: `${x.extendedProps?x.extendedProps!["ResourceId"] as unknown as string : ''}` })):
// []);
  }, [date, dayAllocations]);  

  

  useEffect(() => {
    async function _() {
             
      // const timeTable = await getTimeTable(date, status,  toNumber(view) );

      // const {timetableEvents, weekStart, weekEnd, slotMaxTime, slotMinTime } = timeTable;
      
      // setSlotMinTime(slotMinTime);
      // setSlotMaxTime(slotMaxTime);

      // setTimetableEvents( !filterAppointment4Technician ? timetableEvents :
      //   timetableEvents.filter(evt => evt.extendedProps && [0, null, undefined, employeeId].includes(evt.extendedProps['EmployeeId']) )
      //   );
      
      // setWeekStart(weekStart);
      // setWeekEnd(weekEnd); 
    }

    _();
  }, [date, status, view]);
  
  // Custom title component for the header
  const HeaderTitle = () => {
    return <Typography variant="h6">School Timetable</Typography>;
  };

  const renderHeader = (info: any) => {
    return (
      <div>
        {info.date.toLocaleDateString(lg, { weekday: 'long' })}
      </div>
    );
  };

  const handleDayCellContent = (arg: any) => {
    // Customize the rendering of individual days
    const day = arg.dayNumberText;

    if (day === '0') {
      return (
        <div className="custom-sunday">
          {day}
        </div>
      );
    }

    return day;
  }

 
  const _handleEventClick = (clickInfo: EventClickArg) => {

    // setValueEventInput('employeeFirstName', clickInfo.event._def.extendedProps['EmployeeFirstName'] as unknown as string);
    // setValueEventInput('employeeLastName', clickInfo.event._def.extendedProps['EmployeeLastName'] as unknown as string);
    // setValueEventInput('productName', clickInfo.event._def.extendedProps['ProductName'] as unknown as string);

    // setValueEventInput('firstName', clickInfo.event._def.extendedProps['FirstName'] as unknown as string);
    // setValueEventInput('lastName', clickInfo.event._def.extendedProps['LastName'] as unknown as string);

    // setValueEventInput('portable1', clickInfo.event._def.extendedProps['Portable1'] as unknown as string);
    // setValueEventInput('portable2', clickInfo.event._def.extendedProps['Portable2'] as unknown as string);
    // setValueEventInput('email1', clickInfo.event._def.extendedProps['Email1'] as unknown as string);
    // setValueEventInput('email2', clickInfo.event._def.extendedProps['Email2'] as unknown as string);

    // setValueEventInput('appointmentDate', clickInfo.event.start || new Date());

    // setValueEventInput('startTime', clickInfo.event.start || new Date());
    // setValueEventInput('endTime', clickInfo.event.end || new Date());
    

    setOpenEventDialog(true);
    

    
    
    //const event: MyEvent = info.event.extendedProps as MyEvent;
    //alert(`Event: ${event.title}, Start: ${event.start}`);
    // You can access other properties of the clicked event using the event object
    // For example: event.id, event.end, etc.
  };

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} key={` display ${view}-${status} `}>
              <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setView(event.target.value); }} 
                value={view} sx={{ml:3 ,width: isSm?'calc(30% - 8px)':'calc(15% - 8px)'}} id="view"
                inputProps={ {readOnly: false}} >
                <MenuItem value='day'>{t('Day')}</MenuItem>
                <MenuItem value='week'>{t('Week')}</MenuItem>
                <MenuItem value='month'>{t('Month')}</MenuItem>
                <MenuItem value='year'>{t('Year')}</MenuItem>
              </TextField>
              <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                {`${t(('Allocation'))} ::: `}
              </Typography>
              <DatePicker  key={` date ${view} `}                    //label={t('Appointment date')} 
                //renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(25% - 8px)':'calc(10% - 8px)'}} />}
                slotProps={{ textField: { sx: {width:isSm?'calc(25% - 8px)':'calc(10% - 8px)'} } }}
                onChange={(newValue) => setDate(newValue||new Date())}    
                views={view==='month'?['month','year'] : view==='year' ? ['year'] : ['year', 'month', 'day']}                
                value={date}                      
              />
              
              <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setStatus(event.target.value); }} 
                value={status} sx={{ml:3 ,width: isSm?'calc(30% - 8px)':'calc(15% - 8px)'}} id="status"
                inputProps={ {readOnly: false}} >
                {enumItems && enumItems.filter( e => 
                      e.enumerationCode === Enum_APPOINTMENT_STATUS ).map( 
                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                }
              </TextField>

              { ['day', 'week'].includes(view) && <Button sx={{ml: '16px'}}>
                {`${t('Add resource to analyse')}`}
                <Box sx={{ ...justifyCenter, ml: 1 }}>
                  <MdOutlineAdd size={24} onClick={handleClickAddResource} />
                </Box>
              </Button>}

              { ['month', 'year'].includes(view) && <Button sx={{ml: '16px'}}>
                {`${t('Resource to analyse')}`}
                <Box sx={{ ...justifyCenter, ml: 1 }}>
                  <MdArrowDropDownCircle size={24} onClick={handleClickSetResource} />
                </Box>
              </Button> }
              {/* <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setView(event.target.value); }} 
                value={view} sx={{ml:3 ,width: isSm?'calc(30% - 8px)':'calc(15% - 8px)'}} id="view"
                inputProps={ {readOnly: false}} >
                <MenuItem value='1'>{t('Service')}</MenuItem>
                <MenuItem value='2'>{t('Employee')}</MenuItem>
              </TextField> */}
                                    
            </Box>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} key={`box calendar ${date} ${status} ${view}`}>
              <div key={`div calendar ${date}`} style={{ width: '100%' }}>
                {/* <FullCalendar
                    schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
                    plugins={[resourceTimeGridPlugin, dayGridPlugin]}
                    headerToolbar={false}
                    initialView='dayGridPlugin'
                    resources={resources}
                    events={events}
                    resourceAreaHeaderContent='Resources'
                    eventClick={handleEventClick}
                  /> */}
                  {/* <FullCalendar
                    schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
                    plugins={[resourceTimeGridPlugin, dayGridPlugin]}
                    initialView='resourceTimeGridDay'
                    views={{
                      resourceTimeGridDay: { buttonText: 'Day' },
                      dayGridMonth: { buttonText: 'Month' }
                    }}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'resourceTimeGridDay,dayGridMonth'
                    }}
                    resources={resources}
                    events={events}
                    resourceAreaHeaderContent='Resources'
                    eventClick={handleEventClick}
                  /> */}
                  {/* <FullCalendar
                    schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
                    plugins={[resourceTimeGridPlugin, dayGridPlugin, timeGridPlugin, listPlugin]}
                    initialView='resourceTimeGridDay'
                    views={{
                      resourceTimeGridDay: { buttonText: 'Day' },
                      resourceTimeGridWeek: { buttonText: 'Week' },
                      dayGridMonth: { buttonText: 'Month' },
                      listYear: { buttonText: 'Year', duration: { years: 1 }, listDayFormat: { year: 'numeric', month: 'long' } }
                    }}
                    headerToolbar={{
                      left: 'prev,next today',
                      center: 'title',
                      right: 'resourceTimeGridDay,resourceTimeGridWeek,dayGridMonth,listYear'
                    }}
                    resources={resources}
                    events={events}
                    resourceAreaHeaderContent='Resources'
                    eventClick={handleEventClick}
                  /> */}
                  <FullCalendar key={`box calendar ${date} ${status} ${view}`}
                    schedulerLicenseKey='GPL-My-Project-Is-Open-Source'
                    firstDay={1}
                    
                    initialDate={date}
                    plugins={[resourceTimeGridPlugin, dayGridPlugin, timeGridPlugin]}
                    locale={lg}
                    initialView={ view === 'year' ? 'dayGridYear' : 
                                  view === 'month' ? 'dayGridMonth' : 
                                  view === 'week' ? 'resourceTimeGridWeek' :  'resourceTimeGridDay'}
                    views={{ // addDays(startOfWeek(date),1), { representation: 'date' }),formatISO(subDays(endOfWeek(date),1)
                      resourceTimeGridDay: { buttonText: 'Day', weekNumberCalculation: 'ISO' },
                      resourceTimeGridWeek: { buttonText: 'Week', visibleRange: getWeekVisibleRange },
                      dayGridMonth: { buttonText: 'Month', weekNumberCalculation: 'ISO' },
                      dayGridYear: {
                        type: 'dayGrid',
                        duration: { years: 1 },
                        buttonText: 'Year',
                        titleFormat: { year: 'numeric' },
                        visibleRange: function(currentDate) {
                          return {
                            start: new Date(currentDate.getFullYear(), 0, 1),
                            end: new Date(currentDate.getFullYear() + 1, 0, 1)
                          };
                        },
                        weekNumberCalculation: 'ISO',
                        firstDay: 1 // Start the year view on Monday
                      }                      
                    }}
                    // headerToolbar={{
                    //   left: 'prev,next today',
                    //   center: 'title',
                    //   right: 'resourceTimeGridDay,resourceTimeGridWeek,dayGridMonth,dayGridYear'
                    // }}
                    headerToolbar={false}
                    height="auto" // Set height to auto
                    resources={resources.map(x => ({id: `${x.id}`, title: x.description}))}

                    events={ (view === 'year' && yearAllocations) ? 
                          yearAllocations!.timetableEvents.map( x => ({...x, resourceId: `${resource.id}` })):
                             (view === 'month' && monthAllocations) ? 
                          monthAllocations!.timetableEvents.map( x => ({...x, resourceId: `${resource.id}` })):
                             (view === 'week' && weekAllocations) ? 
                          weekAllocations!.timetableEvents.map( x => ({...x, resourceId: `${x.extendedProps?x.extendedProps!["ResourceId"] as unknown as string : ''}` })):
                             (view === 'day' && dayAllocations) ? 
                          dayAllocations!.timetableEvents.map( x => ({...x, resourceId: `${x.extendedProps?x.extendedProps!["ResourceId"] as unknown as string : ''}` })):
                          []}

                    resourceAreaHeaderContent='Resources'
                    resourceLabelContent={renderResourceLabel} // Use custom renderer
                    eventClick={handleEventClick}
                    allDaySlot={false} // Hide the full-day slot
                  />
              </div>
            </Box>
            { openResourceFilter && <FormDialog open={openResourceFilter} maxWidth='md' 
                okText='' cancelText='' title={t('Resource')} onCancel={()=> {}} 
                onClose={()=> {setOpenResourceFilter(false);}} onOk={()=> {setOpenResourceFilter(false);}}  >
                    <BasicTextFilterForm<IResource> {...basicFilterResource } />
            </FormDialog> }
            { openEventDialog && <FormDialog open={openEventDialog} maxWidth='sm' height='55vh'
                okText='' cancelText='' title={` ${getValuesEventInput().resourceName} :: `} onCancel={()=> {}} 
                onClose={()=> {setOpenEventDialog(false);}} onOk={()=> {setOpenEventDialog(false);}}  >
                    <Stack flexDirection='column'  >
                      {/* <Box sx={{ mt: 1, width: '100%' }} >
                        <Controller control={controlEventInput}
                            name='appointmentDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Appointment date')} 
                                onChange={onChange} disabled={true}                    
                                value={value}
                                renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                              /> )}
                          />
                          <Controller
                              key={`query--startTime-x`}
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TimePicker label={t('Time')}  
                                  inputFormat="HH:mm:ss"    
                                  views={['hours', 'minutes', 'seconds']}   
                                  onChange={onChange}  disabled={true}                                    
                                  value={value}
                                  renderInput={(params) => <TextField {...params} sx={{width:`calc(20% - 8px)`, textAlign: 'center'}} />}
                                /> )}
                              name={`startTime`}          
                              control={controlEventInput}
                            />
                      </Box> */}
                      {/* <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                        <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                          {`${t('Customer')} ...`}
                        </Typography>
                      </Box> */}
                      {/* <Box sx={{ mt: 0.25, width: '100%' }} >
                        <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                          {...registerEventInput(lg.startsWith('fr')?'lastName':'firstName')} inputProps={{readOnly: true }}  />   
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                          {...registerEventInput(lg.startsWith('fr')?'firstName':'lastName')} inputProps={{readOnly: true }}  />  
                      </Box> */}
                      {/* {!hideContact && <Box sx={{ mt: 0.25, width: '100%' }} >
                        <TextField sx={{width:'calc(25% - 8px)'}} id="fn" label={`${t('Portable')} 1`} 
                          {...registerEventInput('portable1')} inputProps={{readOnly: true }}  />   
                        <TextField sx={{width:'calc(25% - 8px)'}} id="fn" label={`${t('Portable')} 2`} 
                          {...registerEventInput('portable2')} inputProps={{readOnly: true }}  />  
                        <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={`${t('Email')}`} 
                          {...registerEventInput('email1')} inputProps={{readOnly: true }}  />
                      </Box>}
                      <Box sx={{ mt: 1, mb: 0.25, width: '100%' }} >
                        <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                          {`${t('Employee')} ...`}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                          {...registerEventInput(lg.startsWith('fr')?'employeeLastName':'employeeFirstName')} inputProps={{readOnly: true }}  />   
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                          {...registerEventInput(lg.startsWith('fr')?'employeeFirstName':'employeeLastName')} inputProps={{readOnly: true }}  />  
                      </Box> */}
                    </Stack>
            </FormDialog> }
          </Stack>
        </Grid>
      </Grid>
      
    </Box>
  )
}
