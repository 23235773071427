import React, {FC, PropsWithChildren, useState, useEffect} from 'react';

// import library.
import { useFieldArray, useForm, useFormContext, Controller, FormProvider, FieldArrayWithId, ArrayPath, UnpackNestedValue, DeepPartial } from 'react-hook-form';

import NumberFormat from 'react-number-format';
// mui ...
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import MenuItem from '@mui/material/MenuItem';

import { DatePicker } from '@mui/x-date-pickers/';


// import {ITarifInfo} from '../component/crm/model/BankPolicy';

import TextFieldRight from './TextFieldRight';
import { DataType } from 'features/configuration/models/ExtensionType';

type BaseType = 'string' | 'numeric' | 'boolean' | 'date';

//type _DataType = 'text' | 'integer' | 'decimal' | 'float' | 'date' | 'boolean' | 'time' | 'enumeration' | 'entity';

// export interface IField {
  
//   type: BaseType,
//   dataType: DataType,
  
//   options?: {value: string, name: string} [],
// }


export interface ExtensionValueFieldProps {    

    name: string, 
    label: string,

    type: DataType,
    
    options?: {value: string, name: string} [],
    itemsPerRow: 1 | 2 | 3
} 

 

//export function ArrayFieldBox  (props : ArrayFieldBoxProps)  {
export default function ExtensionValueField (props : ExtensionValueFieldProps)  {
    
  const {name, label, type, itemsPerRow, options} = props;

  const _itemsPerRow = (itemsPerRow || 1) as number;

  const w = _itemsPerRow === 3? '33' : _itemsPerRow === 2?  '50'  : '100' as string;

  const {  control  } = useFormContext();  

  
    function displayCell() : React.ReactNode {     
           
      if( (type) === 'boolean')
        return (<Controller 
                name={name}
                control={control}
                render={({ field: {onChange, value} }) => <Checkbox onChange={onChange} checked={value}/>}
                
             />);

      if( ['integer','decimal','float', 'entity'].includes(type) )
        return (<Controller
          render={({ field: {onChange, onBlur, name, value, ref} }) => {
            return (
              <NumberFormat sx={{width:`calc(${w}% - 8px)`,  style: { textAlign: 'right' }}}
                label={label}
                //decimalScale={2}
                allowEmptyFormatting={false}
                control={control}                          
                //fixedDecimalScale={true}              
                thousandSeparator={true}
                decimalScale={2}
                onValueChange={ (v) => onChange(v.floatValue) }
                //{...field}
                customInput={TextFieldRight}
                defaultValue={value}
                value={value}
                //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
              />
            );
          }}
          name={name} 
          control={control}
        />);

        if(type === 'date' )  {// && cell.isEditable && cell.isEditable(field)
          
          return ( <Controller
            key={`key-${type}-${type}`}
            render={({ field: { onChange, onBlur, value, ref } }) => (
              <DatePicker //label={t('Issue date')}
                //disabled={!cell.isEditable || !cell.isEditable(row, cell.id)}    
                //disableOpenPicker={!cell.isEditable || !cell.isEditable(row, cell.id)}    
                label={label}     
                onChange={onChange}                        
                value={new Date(value)}
                slotProps={{ textField: { sx: {width:`calc(${w}% - 8px)`} } }}
                //renderInput={(params) => <TextField {...params}  />}
              /> )}
            name={name}            
            control={control}
          />);
          }

          if(type === 'enumeration' ) {
            //const options = cell.getOptions(row, cell.id, cell.options || []);
              
              return ((<Controller
                  key={`key-${type}-${type}`}
                  render={ ({field: {onChange, value}}) => (
                    <TextField select onChange={onChange} value={value} sx={{width:`calc(${w}% - 8px)`}}
                      id={type as unknown as string} 
                      label={label}
                      //inputProps={ {readOnly: cell.isEditable? !cell.isEditable(row,cell.id): true }}
                      //label={cell.getLabel?cell.getLabel(row,cell.id): row[cell.id]} 
                      >
                    {options && options.map( 
                      (opt,ix) => <MenuItem key={opt.value} value={opt.value}>{opt.name}</MenuItem> )
                    }
                    </TextField>
                  )}
                  
                  name={name}
                  control={control}
                />));
          }

          return (<Controller             
            render={({ field }) => <TextField sx={{width:`calc(${w}% - 8px)`}} {...field} label={label}   />}
            name={name} //defaultValue={param[valueKey]}
            control={control}
        />);
    }

    return (  <>
                {displayCell() }
              </>      
      );    
}

