import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';

import NumberFormat from 'react-number-format';


import { IEntity, IResult } from 'library/interface';

import useTellerDayClosingService, { useBasicFilterTellerDayClosing } from './services/TellerDayClosing';
import {  ITellerDayClosing, defaultTellerDayClosing, IDashboardTellerDayClosing } from './models/TellerDayClosing';

import {  ITellerOperation, defaultTellerOperation } from './models/TellerOperation';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom, currentUserSessionAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';


import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, FormControlLabel, IconButton, InputAdornment } from '@mui/material';
import IEnumeration from 'features/configuration/models/Enumeration';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { DatePicker } from '@mui/x-date-pickers';
import TextFieldRight from 'components/ui/TextFieldRight';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IUserPaymentSource } from 'features/security/models/User';


export const TellerDayClosingForm: FC<ITellerDayClosing> = (props: ITellerDayClosing = defaultTellerDayClosing) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const { enqueueSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createTellerDayClosing, updateTellerDayClosing, getNewTellerDayClosing, getTellerDayClosingDashboard } = useTellerDayClosingService();

  const {getExternalLibraries, getExternalLibrary} = externalLibraryService();

  const {language: lg, taskCodes, userDescription, userName, 
    employeeId, userPaymentSources, applicationSetup, userStores} = useRecoilValue(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterTellerDayClosing = useBasicFilterTellerDayClosing( 
    
    async (event: React.MouseEvent<unknown>, row: ITellerDayClosing) => {
      const {id, closingWarning, closingNote } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );
  
  const [dashboard, setDashboard] = useState<IDashboardTellerDayClosing>({operationsByMode: []});

  const emptyFunc = (obj: any) => {}

  const methods = useForm<ITellerDayClosing>({defaultValues:defaultTellerDayClosing});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchBusinessApplicationId = watch('businessApplicationId');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ITellerDayClosing>,Error,ITellerDayClosing>(
      _id>0?updateTellerDayClosing:createTellerDayClosing, {   
        onSuccess: (data: IResult<ITellerDayClosing>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['TellerDayClosing',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<ITellerDayClosing>(['TellerDayClosing', _id], () => retrieveEntity('TellerDayClosing',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const [currentLibrary, setCurrentLibrary] = useState<IExternalLibrary>(defaultExternalLibrary);
    const [currentLibraryClass, setCurrentLibraryClass] = useState<IExternalLibraryClass>(defaultExternalLibraryClass);

    const [dbmsType, setDbmsType] = useState<string>('');

    const [applicationQueryIndex, setTellerDayClosingIndex] = useState<number>(-1);
    const [tellerOperationIndex, setTellerOperationIndex] = useState<number>(-1);

    const [openAppQueryParamDialog, setOpenAppQueryParamDialog] = useState<boolean>(false);
 
    
  const cellEditableParameter = (row: ITellerOperation, cellId: keyof ITellerOperation) => {
    return true;
  }

   const [headTellerOperationCells, setHeadTellerOperationCells]  = useState<HeadCell<ITellerOperation>[]>([]);
  useEffect(() => {
    setHeadTellerOperationCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'firstName', label : t('First name'),  display: true, type: 'string', width: 22, },
      {id:'lastName', label : t('Last name'),  display: true, type: 'string', width: 22, },
     
      {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', width: 12,  },
      {id:'isCancelledAtDayClosing', label : t('Canc. closing ?'),  display: true, type: 'boolean', width: 15,  },
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 12,  },
      {id:'operationDate', label : t('Date'),  display: true, type: 'date', width: 17, },
    ]  )
  }, [t,i18n])

  const refAppendTellerOperations = useRef<(value: Partial<FieldArray<ITellerDayClosing>> | Partial<FieldArray<ITellerDayClosing>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateTellerOperation = useRef<(index: number,value: Partial<FieldArray<ITellerDayClosing>> ) => void>(null);
  const refRemoveTellerOperation = useRef<(index: number ) => void>(null);

  
  const tellerOperationRowActionIcon = ( tellerOperation: ITellerOperation) : ActionIconTableRow<ITellerDayClosing,ITellerOperation> => {
  
    const res: ActionIconTableRow<ITellerDayClosing,ITellerOperation> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: ITellerOperation) => {
        
        //(refRemoveTellerOperation.current??emptyFunc)(index);            
      }
    }
    return res;
}

const [openPaymentSources, setOpenPaymentSources] = useState<boolean>(false);
const handlePaymentSourceDoubleClick = (event: React.MouseEvent<unknown>, row: IUserPaymentSource) => {
  const {paymentSourceCode, paymentSourceName} = row;

  buildNewTellerDayClosing(paymentSourceCode);
  setOpenPaymentSources(false);
}


// useEffect( () => {
  
//   async function _() {    
//     if(_id>0) {
//       const dash = await getTellerDayClosingDashboard( Math.max(0,_id), '' );
//       setDashboard(dash);
//     }
//   }
  
//   _();
// }, [_id]);

  useEffect( () => {              
    setCurrentFormNameAtom(t('Query'));  
    setCurrentBasicTextFilterProps(basicFilterTellerDayClosing);
  }, []);

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    
    if(_id > 0)
      retrieveData('TellerDayClosing',_id, refetch);  
  }, [_id] );


useEffect( () => {
  async function _() {    
    if(_data && _data.id > 0) {
      
      reset(_data);
      const dash = await getTellerDayClosingDashboard( Math.max(0,_data.id), _data.paymentSourceCode );
      setDashboard(dash);
    }
  }
  
  _();
}, [_data, reset]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  
  if(userPaymentSources.length === 1)
    await buildNewTellerDayClosing(userPaymentSources[0].paymentSourceCode);
  else 
    setOpenPaymentSources(true);     
}

const buildNewTellerDayClosing = async (paymentSourceCode: string) => {
  _setId(0);   
  const newTellerDay = await getNewTellerDayClosing(paymentSourceCode);        
  const dash = await getTellerDayClosingDashboard( Math.max(0,_id), paymentSourceCode );
  setDashboard(dash);
  reset(newTellerDay); 
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
  if(!checkEntitySaveAuthorization('TellerDayClosing', _id)) {
    setIsSaveLoading(false);         
    return;
  }

  const data = getValues(); 
  if(data.closingNote.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
    }

    if(data.tellerOperations.length === 0 || data.id > 0) {
      enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
    }

  mutate( {...data});
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('TellerDayClosing', _id);
}

const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
  openEntityPrintDrawer('TellerDayClosing', _id);
}


const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
//    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
//    await retrieveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
//    reset(_data);        
}

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={1}>
                    <Grid item xs={12} md={4} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller name='paymentSourceCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(60% - 8px)'}} id="paymentSourceCode"
                                    label={t('Payment source')} inputProps={ {readOnly: true}} focused >
                                    {userPaymentSources && userPaymentSources.map( 
                                        (x,idx) => <MenuItem key={x.paymentSourceCode} value={x.paymentSourceCode}>{x.paymentSourceName}</MenuItem> )
                                      }
                                  </TextField>
                                )} />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(25% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                
                                <Controller control={control}
                                  name='closingDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(40% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(40% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat                                                   
                                        disabled={true}
                                          
                                          label={t('# Count')} sx={{width:'calc(35% - 8px)'}} //disabled={true}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`countTotal`}
                                    control={control}
                                />
                                { openPaymentSources && <FormDialog open={openPaymentSources} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Payment source')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenPaymentSources(false);}} onOk={()=> {setOpenPaymentSources(false);}}  >
                                    <Stack flexDirection='column'  >
                                      <Box sx={{ mt: 1, width: '100%' }} >
                                        <EnhancedTable<IUserPaymentSource> 
                                          rows={userPaymentSources} 
                                          headCells={[            
                                            //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                            {id:'paymentSourceCode', label : t('Code'),  display: true, type: 'string', width: 35},
                                            {id:'paymentSourceName', label : t('Name'),  display: true, type: 'string', width: 65},                                            
                                          ]} 
                                          title={t(`Payment source`)} objKey='paymentSourceCode' 
                                          stateSelected={undefined} 
                                          onRowSelected={undefined} rowCheckedMode='single'
                                          onRowCheckedSelectChange={undefined} order='asc' orderBy='paymentSourceName'
                                          onRowDoubleClick={handlePaymentSourceDoubleClick} 
                                          rowActionIcon={undefined}
                                          toolbarActions={[
                                            // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                          ]}
                                        />
                                      </Box>
                                    </Stack>
                                  </FormDialog>  }
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat                                                   
                                          disabled={true}
                                          
                                          label={t('Net amount')} sx={{width:'calc(65% - 8px)'}} //disabled={true}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`netAmount`}
                                    control={control}
                                />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat                                                   
                                        disabled={true}
                                          
                                          label={t('# Count')} sx={{width:'calc(35% - 8px)'}} //disabled={true}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`countTotal`}
                                    control={control}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat                                                   
                                        disabled={true}
                                          
                                          label={t('Cancelled amount')} sx={{width:'calc(65% - 8px)'}} //disabled={true}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`cancelledAmount`}
                                    control={control}
                                />
                                <Controller
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat                                                   
                                        disabled={true}
                                          
                                          label={t('# Count')} sx={{width:'calc(35% - 8px)'}} //disabled={true}
                                          allowEmptyFormatting={false}
                                          control={control}    
                                          thousandSeparator={true}
                                          decimalScale={2}
                                          onValueChange={ (v) => onChange(v.floatValue) }
                                          defaultValue={value}
                                          value={value}
                                          customInput={TextFieldRight}                            
                                        />
                                      );
                                    }}
                                    name={`countCancelled`}
                                    control={control}
                                />
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} 
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                id="query" label={t('Note')} 
                                {...register('closingNote')} multiline={true} rows={3}/>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} 
                                inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                id="query" label={t('Warning')} 
                                {...register('closingWarning')} multiline={true} rows={3}/>
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<{mode: string, netAmount: number, cancelledAmount: number}> 
                                  rows={dashboard.operationsByMode} 
                                  headCells={[            
                                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                    {id:'mode', label : t('Mode'),  display: true, type: 'string', width: 40},
                                    {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 30},
                                    
                                    {id:'cancelledAmount', label : t('Cancelled amount'),  display: true, type: 'numeric', width: 30},
                                  ]} 
                                  title={t(`Financial situation per mode`)} objKey='mode' 
                                  stateSelected={undefined} 
                                  onRowSelected={undefined} rowCheckedMode='single'
                                  onRowCheckedSelectChange={undefined} order='desc' orderBy='mode'
                                  onRowDoubleClick={undefined} 
                                  rowActionIcon={undefined}
                                  toolbarActions={[
                                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                  ]}
                                />
                            </Box>
                        </Stack>                        
                    </Grid>                   
                    <Grid item xs={12}  md={8} component={Paper} >
                        <Stack flexDirection='column'>                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ArrayFieldTableEx<ITellerDayClosing,ITellerOperation,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headTellerOperationCells} rowsPathName={`tellerOperations` }
                                title={`${t('Teller operation')}(s)`} //rowActionIcon={tellerOperationRowActionIcon}  
                                //onRowSelected={handleQuerySelected}
                                                    
                                refAppend={refAppendTellerOperations as MutableRefObject<(value: Partial<FieldArray<ITellerDayClosing>> | Partial<FieldArray<ITellerDayClosing>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateTellerOperation as MutableRefObject<(index: number,value: Partial<FieldArray<ITellerDayClosing>>) => void>}
                                refRemove={refRemoveTellerOperation as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddQueryParameter ,icon: AddCircleIcon,  },
                                
                                ]}
                              />                         
                            </Box>
                             
                        </Stack> 
                    </Grid>
                </Grid>
            </Box>
        </FormProvider>
  )
}
