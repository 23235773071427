
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';

import { ChromePicker, ColorResult } from 'react-color';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useResourceService, { useBasicFilterResource } from './services/Resource';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip, Popover, useTheme } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IResource, IResourceEmployee, IResourceService, defaultResource } from './models/Resource';

import { useBasicFilterPerson } from 'features/production/services/Person';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_RESOURCE_CATEGORY, Enum_OPERATION_STATUS, Enum_EXPENSE_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker } from '@mui/x-date-pickers';
import { IPerson } from './models/Person';
import { useBasicFilterExpense } from './services/Expense';
import { IExpense, IExpenseDetail } from './models/Expense';
import { useBasicFilterResourceType } from 'features/setup/services/ResourceType';
import { IResourceType } from 'features/setup/models/ResourceType';
import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';
import { useBasicFilterProduct } from 'features/setup/services/Product';
import { IProduct } from 'features/setup/models/Product';


export const ResourceForm: FC<IResource> = (props: IResource = defaultResource) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const theme = useTheme();
  const {id} = useParams();

  const {language: lg} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  const { createResource, updateResource } = useResourceService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterResource = useBasicFilterResource( 
    (event: React.MouseEvent<unknown>, row: IResource) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openResourceTypeFilter, setOpenResourceTypeFilter] = useState(false);
  const basicFilterResourceType = useBasicFilterResourceType( 2, 
      (event: React.MouseEvent<unknown>, row: IResourceType) => {
        const {id, name, isActive, temporaryFilterOption} = row;
        
        if(!isActive) {
          enqueueSnackbar( t('Invalid data !!!'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return;
        }
        
        setValue('permanentResourceId', id);
        setValue('permanentResourceName', name);
        setOpenResourceTypeFilter(false);
      }
  );

  const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
  const basicFilterEmployee = useBasicFilterEmployee( 
      (event: React.MouseEvent<unknown>, row: IEmployee) => {
        const {id, firstName, lastName, employeeTasks} = row;                
        
        const resourceEmployee = getValues().resourceEmployees.find( e => e.employeeId === id );        
        if(!isFalsy(resourceEmployee)) return;

        (refAppendResourceEmployees.current??emptyFunc)( {
          employeeId: id, employeeFirstName: firstName, employeeLastName: lastName });
        
        setOpenEmployeeFilter(false);
      }
  );

  const [openServiceFilter, setOpenServiceFilter] = useState(false);
  const basicFilterService = useBasicFilterProduct( 1,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute} = row;

          const resourceService = getValues().resourceServices.find( e => e.serviceId === id );        
          if(!isFalsy(resourceService)) return;

          (refAppendResourceServices.current??emptyFunc)({serviceId: id, serviceName: name, serviceDescription: description });                          
          
          setOpenServiceFilter(false);
      }
  );
   
  

  
  const methods = useForm<IResource>({defaultValues:defaultResource});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchSupplierType = watch('supplierType');
  // const watchBase64Picture = watch('base64Picture');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IResource>,Error,IResource>(
      _id>0?updateResource:createResource, {   
        onSuccess: (data: IResult<IResource>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Resource',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IResource>(['Resource', _id], () => retrieveEntity('Resource',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Resource'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_OPERATION_STATUS, Enum_EXPENSE_STATUS_PURPOSE ] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    const handleClickOpenResourceType = async (event: any) => {
      setOpenResourceTypeFilter(true);
    }
    // const getServiceTaskCode = (row: IMaintenanceResource, cellId: keyof IMaintenanceResource, 
    //   opts: {value: string, name: string}[]) => {        
      
    //     return getAsOptions(refEnumItems.current ?? [],Enum_SERVICE_TASK);
    // }

    const [resourceIndex, setMaintenanceIndex] = useState<number>(-1);
    const getInputAdornmentMaintenanceType = (row: IExpenseDetail, cellId: keyof IExpenseDetail)  => ({
  
      toolTip: 'Subject',
      icon: ArrowDropDownCircleIcon,
      iconDisable: row.resourceId > 0,
      onClickIcon: (event: any, index: number, row: IExpenseDetail ) => {
        setMaintenanceIndex(index);
        //setOpenMaintenanceTypeFilter(true);
      }  
    })

    const cellEditableMaintenanceResource = (row: IExpenseDetail, cellId: keyof IExpenseDetail) => {
      const {resourceId} = row;
      
      if(cellId === 'resourceDescription' && resourceId > 0) 
        return false;

      return true;
    }

    const [headMaintenanceResourceCells, setHeadMaintenanceResourceCells]  = useState<HeadCell<IExpenseDetail>[]>([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'expenseReference', label : t('Reference'),  display: true, type: 'string', width: 30,},
      {id:'expenseDescription', label : t('Expense'),  display: true, type: 'string', width: 30},

      {id:'description', label : t('Description'),  display: true, type: 'string', width: 30, },
      {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 10,  },           
    ]);

    const refAppendMaintenanceResources = useRef<(value: Partial<FieldArray<IResource>> | Partial<FieldArray<IResource>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateMaintenanceResource = useRef<(index: number,value: Partial<FieldArray<IResource>> ) => void>(null);
    const refRemoveMaintenanceResource = useRef<(index: number ) => void>(null);
      
    const handleAddMaintenanceResources = (event: any) => {
      //(refAppendMaintenanceResources.current??emptyFunc)({id: 0, resourceTypeId: _id, taskCode: '', part: 0,  });
      //setOpenMaintenanceFilter(true);
    }

    const maintenanceResourceRowActionIcon = ( tarificationMatrix: IExpenseDetail) : ActionIconTableRow<IResource,IExpenseDetail> => {
  
      const res: ActionIconTableRow<IResource,IExpenseDetail> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IExpenseDetail) => {
          
           (refRemoveMaintenanceResource.current??emptyFunc)(index);            
        }
      }
      return res;
  }


  const [headResourceEmployeeCells, setHeadResourceEmployeeCells]  = useState<HeadCell<IResourceEmployee>[]>([      
    {id:'employeeId', label : t('Id'),  display: true, type: 'numeric', width: 10 },
    
    {id:lg.includes('en')?'employeeFirstName':'employeeLastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 45},
    {id:lg.includes('en')?'employeeLastName':'employeeFirstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 45},
            
  ]);

  const refAppendResourceEmployees = useRef<(value: Partial<FieldArray<IResource>> | Partial<FieldArray<IResource>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateResourceEmployee = useRef<(index: number,value: Partial<FieldArray<IResource>> ) => void>(null);
  const refRemoveResourceEmployee = useRef<(index: number ) => void>(null);
    
  const handleAddEmployees = (event: any) => {
    
    setOpenEmployeeFilter(true);
  }

  const resourceEmployeeRowActionIcon = ( resourceEmployee: IResourceEmployee) : ActionIconTableRow<IResource,IResourceEmployee> => {

    const res: ActionIconTableRow<IResource,IResourceEmployee> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IResourceEmployee) => {
        
         (refRemoveResourceEmployee.current??emptyFunc)(index);            
      }
    }
    return res;
}


const [headResourceServiceCells, setHeadResourceServiceCells]  = useState<HeadCell<IResourceService>[]>([      
  {id:'serviceId', label : t('Id'),  display: true, type: 'numeric', width: 10 },
  {id:'serviceName', label : t('Name'),  display: true, type: 'string', width: 45,},            
  {id:'serviceDescription', label : t('Description'),  display: true, type: 'string', width: 45,},            
]);

const refAppendResourceServices = useRef<(value: Partial<FieldArray<IResource>> | Partial<FieldArray<IResource>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateResourceService = useRef<(index: number,value: Partial<FieldArray<IResource>> ) => void>(null);
const refRemoveResourceService = useRef<(index: number ) => void>(null);
  
const handleAddServices = (event: any) => {
  
  setOpenServiceFilter(true);
}

const resourceServiceRowActionIcon = ( resourceService: IResourceService) : ActionIconTableRow<IResource,IResourceService> => {

  const res: ActionIconTableRow<IResource,IResourceService> = {
    toolTip: 'viewDetails',
    icon: RemoveCircleIcon,
    hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: IResourceService) => {
      
       (refRemoveResourceService.current??emptyFunc)(index);            
    }
  }
  return res;
}

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])


      useEffect( () => {        
        setCurrentFormNameAtom(t('Resource'));
        setCurrentBasicTextFilterProps(basicFilterResource);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Resource',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultResource);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('Resource', _id)) {
          setIsSaveLoading(false);
             return;
        }
        const data = getValues(); 

        if(data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }
              
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Resource', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={5} lg={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                <TextField sx={{width:'calc(20% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(80% - 8px)'}} id="name" label={t('Description')} {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                
                            </Box>                                                                                                           
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(80% - 8px)'}} 
                                id="resourceTypeName" label={t('Resource type')} {...register('permanentResourceName')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">
                                        <IconButton color="primary" onClick={handleClickOpenResourceType}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton>                                                                                                                                     
                                    </InputAdornment>
                                ) 
                              }}
                              />  
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={2}
                                      label={`${t('Capacity')}`} sx={{width:'calc(20% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`capacity`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              />
                              { openResourceTypeFilter && <FormDialog open={openResourceTypeFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Resource type')} onCancel={()=> {}} 
                              onClose={()=> {setOpenResourceTypeFilter(false);}} onOk={()=> {setOpenResourceTypeFilter(false);}}  >
                                  <BasicTextFilterForm<IResourceType> {...basicFilterResourceType } />
                              </FormDialog> }                                                            
                            </Box>
                            <Box sx={{ mt: 2, mb: 2,width: '100%' }} >
                        
                              <Button variant="contained" onClick={(event: React.MouseEvent<HTMLElement>) => { setAnchorEl(event.currentTarget); }}
                                  sx={{ ml: 1, width: '75%', backgroundColor: getValues().allocationColor, color: theme.palette.getContrastText(getValues().allocationColor)}}>
                                  {t('Allocation visualisation color')}
                              </Button>
                              <Popover
                                open={Boolean(anchorEl)}
                                anchorEl={anchorEl}
                                onClose={() => {setAnchorEl(null);}}
                              >
                                <ChromePicker color={getValues().allocationColor} 
                                      onChange={(newColor: ColorResult) => {setValue('allocationColor',newColor.hex); }} />
                              </Popover>
                            </Box>          
                            <Box key={`${'watchFileName'} - key-0`} sx={{ mt: 1, width: '100%' }} >  
                              <ArrayFieldTableEx<IResource,IResourceEmployee,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headResourceEmployeeCells} rowsPathName='resourceEmployees' 
                                  title={t('Employees who use this resource')} rowActionIcon={resourceEmployeeRowActionIcon}  
                                  //onRowSelected={handleRoleEntitySelected}
                                                      
                                  refAppend={refAppendResourceEmployees as MutableRefObject<(value: Partial<FieldArray<IResource>> | Partial<FieldArray<IResource>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateResourceEmployee as MutableRefObject<(index: number,value: Partial<FieldArray<IResource>>) => void>}
                                  refRemove={refRemoveResourceEmployee as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[

                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddEmployees ,icon: AddCircleIcon,  },
                                    // { toolTip: `${t('Add')}...`, onClickIcon: handleAddMaintenanceResources ,icon: AddCircleIcon,  },
                                  
                                  ]}
                                  canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                              />
                              { openEmployeeFilter && <FormDialog open={openEmployeeFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                              onClose={()=> {setOpenEmployeeFilter(false);}} onOk={()=> {setOpenEmployeeFilter(false);}}  >
                                  <BasicTextFilterForm<IEmployee> {...basicFilterEmployee } />
                              </FormDialog> }
                            </Box> 
                            <Box key={`${'watchFileName'} - key-1`} sx={{ mt: 5, width: '100%' }} >  
                              <ArrayFieldTableEx<IResource,IResourceService,'id'> 
                                  mainObject={getValues()} fieldKey='id' 
                                  headCells={headResourceServiceCells} rowsPathName='resourceServices' 
                                  title={t('Services using this resource')} rowActionIcon={resourceServiceRowActionIcon}  
                                  //onRowSelected={handleRoleEntitySelected}
                                                      
                                  refAppend={refAppendResourceServices as MutableRefObject<(value: Partial<FieldArray<IResource>> | Partial<FieldArray<IResource>>[], options?: FieldArrayMethodProps) => void>}
                                  refUpdate={refUpdateResourceService as MutableRefObject<(index: number,value: Partial<FieldArray<IResource>>) => void>}
                                  refRemove={refRemoveResourceService as MutableRefObject<(index: number) => void>}

                                  //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                  //displayMore={undefined}
                                  toolbarActions={[

                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddServices ,icon: AddCircleIcon,  },
                                    // { toolTip: `${t('Add')}...`, onClickIcon: handleAddMaintenanceResources ,icon: AddCircleIcon,  },
                                  
                                  ]}
                                  canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                              />
                              { openServiceFilter && <FormDialog open={openServiceFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Service')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenServiceFilter(false);}} onOk={()=> {setOpenServiceFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterService } />
                              </FormDialog> }
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <ArrayFieldTableEx<IResource,IExpenseDetail,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headMaintenanceResourceCells} rowsPathName='maintenanceExpenses' 
                              title={t('Expenses')} rowActionIcon={maintenanceResourceRowActionIcon}  
                              //onRowSelected={handleRoleEntitySelected}
                                                  
                              refAppend={refAppendMaintenanceResources as MutableRefObject<(value: Partial<FieldArray<IResource>> | Partial<FieldArray<IResource>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateMaintenanceResource as MutableRefObject<(index: number,value: Partial<FieldArray<IResource>>) => void>}
                              refRemove={refRemoveMaintenanceResource as MutableRefObject<(index: number) => void>}

                              
                              toolbarActions={[

                                //{ toolTip: `${t('Add')}...`, onClickIcon: handleAddServices ,icon: AddCircleIcon,  },
                                // { toolTip: `${t('Add')}...`, onClickIcon: handleAddMaintenanceResources ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />
                          
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

