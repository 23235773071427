import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';

import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';
import { useMutation, useQuery, useQueryClient } from 'react-query';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import InsightsIcon from '@mui/icons-material/Insights';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import SyncIcon from '@mui/icons-material/Sync';
import RefreshIcon from '@mui/icons-material/Refresh';
import InventoryIcon from '@mui/icons-material/Inventory';

import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';


import { IEntity, IResult } from 'library/interface';

import usePhysicalInventoryService, { useBasicFilterPhysicalInventory } from './services/PhysicalInventory';

import useStoreService from './services/Store';

import {  IPhysicalInventory, IPhysicalInventoryArticle, defaultPhysicalInventory, defaultPhysicalInventoryArticle } from './models/PhysicalInventory';

import useEntityService, {useBasicFilterEntity, useBasicFilterFeatureDescription} from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import externalLibraryService from 'features/configuration/services/ExternalLibrary';
import {defaultExternalLibrary, defaultExternalLibraryClass, IExternalLibrary, IExternalLibraryClass} from 'features/configuration/models/ExternalLibrary';

import { useBasicFilterProduct } from 'features/setup/services/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { isFalsy } from 'utility-types';
import { FormDialog } from 'components/ui/FormDialog';
import { Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import IEnumeration, { Enum_ARTICLE_OPTION, Enum_STOCK_TRANSFER_STATUS_PURPOSE, IEnumerationItem, Enum_OPERATION_STATUS, Enum_INVENTORY_STATUS_PURPOSE} from 'features/configuration/models/Enumeration';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { DatePicker } from '@mui/x-date-pickers';
import EnhancedTable from 'components/ui/EnhancedTable';
import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from 'features/production/services/Person';

import { IPerson, PersonType } from 'features/production/models/Person';
import { defaultProduct, IProduct } from 'features/setup/models/Product';
import NumberFormat, { NumberFormatValues } from 'react-number-format';
import TextFieldRight from 'components/ui/TextFieldRight';
import { GrClose, GrSearch } from 'react-icons/gr';
import { debounce, sum } from 'lodash';
import { MdOutlineAdd } from 'react-icons/md';
import { IStore } from './models/Store';

import { IArticleStock } from './models/Delivery';
import useDeliveryService from 'features/configuration/services/Delivery';
import { C } from '@fullcalendar/core/internal-common';

export const PhysicalInventoryForm: FC<IPhysicalInventory> = (props: IPhysicalInventory = defaultPhysicalInventory) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {language: lg} = useRecoilValue(currentUserSessionAtom);

  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {retrieveEntity, retrieveData, openEntityActionDrawer, openEntityPrintDrawer, checkEntitySaveAuthorization} = useEntityService();
  const {createPhysicalInventory, updatePhysicalInventory } = usePhysicalInventoryService();

  const {getStores } = useStoreService();
  const {getArticlePerStores } = useDeliveryService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions} = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();


  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterPhysicalInventory = useBasicFilterPhysicalInventory( 
    
    async (event: React.MouseEvent<unknown>, row: IPhysicalInventory) => {
      const {id, reference } = row;
      
      setIsSearchBoxShow(false);
      _setId(row.id);
    }
  );

  const refArticleIndex = useRef<number>(0);

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;
          
          // setParameterEnumeration(id, name);                                      
          // setOpenEnumerationFilter(false);
      }
  );

  const [openArticleOption, setOpenArticleOption] = useState<boolean>(false);
  const [currentArticle2Add, setCurrentArticle2Add] = useState<IProduct>({...defaultProduct});
  const [openProductFilter, setOpenProductFilter] = useState(false);
  const [articleOptions, setArticleOptions] = useState<IEnumerationItem[]>([]);
  const basicFilterProduct = useBasicFilterProduct( 2,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, type, description, filterOption} = row;

          if( type !== 'article') return;

          setCurrentArticle2Add(row);
          //const arr = await getEnumerationItemsByEnumerationCodes([Enum_ARTICLE_OPTION]);          
          //(refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);

          const options = (refEnumItems.current ?? []).filter(e => e.enumerationCode === Enum_ARTICLE_OPTION &&
                                                                 e.parentEnumerationItemCode === filterOption);
          setArticleOptions(options);
          if(options.length === 1) {
            await handleDoubleClickArticleOption(event, options[0], row);
          }
          else          
            setOpenArticleOption(true);

          setOpenProductFilter(false);
                    
          
                           
          
      }
  );

  
  
  const emptyFunc = (obj: any) => {}

  const methods = useForm<IPhysicalInventory>({defaultValues:defaultPhysicalInventory});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchStoreId = watch('storeId');

  const [physicalInventoryArticleIndex, setPhysicalInventoryArticleIndex] = useState<number>(-1);

  type SearchPersonUsage = 'supplier';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('supplier');
    

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IPhysicalInventory>,Error,IPhysicalInventory>(
      _id>0?updatePhysicalInventory:createPhysicalInventory, {   
        onSuccess: (data: IResult<IPhysicalInventory>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          //reset(data.data);
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('Physical inventory')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['PhysicalInventory',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    
    const {data: _data, refetch} = useQuery<IPhysicalInventory>(['PhysicalInventory', _id], () => retrieveEntity('PhysicalInventory',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'PhysicalInventory'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_OPERATION_STATUS, Enum_ARTICLE_OPTION, Enum_INVENTORY_STATUS_PURPOSE ] ));

    const {data: stores} = useQuery<IStore[]>( ['stores'], () => getStores( { name: '', description: '', location: ''} ));


  const cellEditableArticle = (row: IPhysicalInventoryArticle, cellId: keyof IPhysicalInventoryArticle) => {
    return true;
  }

  const [openPhysicalInventoryArticle, setOpenPhysicalInventoryArticle] = useState<boolean>(false);

  
  const getInputAdornmentArticle = (row: IPhysicalInventoryArticle, cellId: keyof IPhysicalInventoryArticle)  => ({

    toolTip: `${t('Details')} ...`,
    icon: MoreVertIcon,
    //iconDisable: row.resourceId > 0,
    onClickIcon: (event: any, index: number, row: IPhysicalInventoryArticle ) => {
      //refArticleIndex.current = index;
      setPhysicalInventoryArticleIndex(index);
      setOpenPhysicalInventoryArticle(true);
    }  
  })

 const getArticleOptionList = (row: IPhysicalInventoryArticle, cellId: keyof IPhysicalInventoryArticle, 
    opts: {value: string, name: string}[]) => {        

    const {articleFilterOption} = row;
    
    const enumsFilter = (refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);

    return getAsOptions(enumsFilter ,Enum_ARTICLE_OPTION);
}


  const [headPhysicalInventoryArticleCells, setHeadPhysicalInventoryArticleCells]  = useState<HeadCell<IPhysicalInventoryArticle>[]>([]);
  useEffect(() => {
    setHeadPhysicalInventoryArticleCells([            
      {id:'id', label : t('Id'),  display: false, type: 'numeric', },
      {id:'articleName', label : t('Name'),  display: true, type: 'string', width: 40},
      {id:'option', label : t('Option'),  display: true, type: 'string', width: 40,
        getOptions: getArticleOptionList },
      {id:'theoreticalQuantity', label : t('Theoretical qty'),  display: true, type: 'numeric', width: 10 },
      {id:'physicalQuantity', label : t('Physical qty'),  display: true, type: 'numeric', width: 10, 
            isEditable: cellEditableArticle },
    ]  )
  }, [t,i18n])

  const refAppendPhysicalInventoryArticles = useRef<(value: Partial<FieldArray<IPhysicalInventory>> | Partial<FieldArray<IPhysicalInventory>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdatePhysicalInventoryArticle = useRef<(index: number,value: Partial<FieldArray<IPhysicalInventory>> ) => void>(null);
  const refRemovePhysicalInventoryArticle = useRef<(index: number ) => void>(null);
  
  const handlePhysicalInventoryArticleSelected = (event: React.MouseEvent<unknown>,index: number,row: IPhysicalInventoryArticle) => {
    setPhysicalInventoryArticleIndex(index);
  }

  const handleAddPhysicalInventoryArticle = (event: any) => {
    setOpenProductFilter(true);      
  }

  const handleDoubleClickArticleOption = async (event: React.MouseEvent<unknown>, row: IEnumerationItem, currentArticle?: IProduct) => {
                       
        //console.log(currentArticle2Add);
        const articleId = isFalsy(currentArticle)? currentArticle2Add.id : currentArticle.id;
        const articleName = isFalsy(currentArticle)? currentArticle2Add.name : currentArticle.name;
        const articleFilterOption = isFalsy(currentArticle)? currentArticle2Add.filterOption : currentArticle.filterOption;

        if(getValues().physicalInventoryArticles.some(x => x.articleId === articleId && x.option === row.code)) {
          setOpenArticleOption(false);
          return;
        }

        const stocks = watchStoreId>0 ? await getArticlePerStores(watchStoreId, 0) : [];   
        const article = stocks.find(x => x.articleId === articleId && x.option === row.code);        

        (refAppendPhysicalInventoryArticles.current??emptyFunc)({...defaultPhysicalInventoryArticle, id: 0, 
              physicalInventoryId: _id, articleId, 
              theoreticalQuantity: isFalsy(article)? 0 : article.quantity,
              articleName, articleFilterOption, option: row.code, });

        setOpenArticleOption(false);
  }

  const handleSyncPhysicalInventoryArticle = async (event: any) => {
    const stocks = watchStoreId>0 ? await getArticlePerStores(watchStoreId, 0) : [];   
    
    const arr1 = getValues().physicalInventoryArticles.map( x => {
                              var article = stocks.find( a => a.articleId === x.articleId && a.option === x.option);
                              var thQty = isFalsy(article) ? x.theoreticalQuantity : article.quantity;

                              return {...x, theoreticalQuantity: thQty};
                        });

    const arr = stocks.filter( article => !getValues().physicalInventoryArticles.some( x => 
                                  x.articleId === article.articleId && x.option === article.option ));
    
    const arr2 = arr.map( x => ({...defaultPhysicalInventoryArticle, physicalInventoryId: _id,
              articleId: x.articleId, option: x.option, articleName: x.articleName, articleFilterOption: x.articleFilterOption, 
              theoreticalQuantity: x.quantity, physicalQuantity: 0,  }) );
    //getValues().physicalInventoryArticles.filter( x => )
    
    setValue('physicalInventoryArticles', [...arr1, ...arr2]);          
  }

  const handleRefreshPhysicalInventoryArticle = async (event: any) => {
    const stocks = watchStoreId>0 ? await getArticlePerStores(watchStoreId, 0) : [];    

    const arr = getValues().physicalInventoryArticles.map( x => {
      var article = stocks.find( a => a.articleId === x.articleId && a.option === x.option);
      var thQty = isFalsy(article) ? x.theoreticalQuantity : article.quantity;

      return {...x, theoreticalQuantity: thQty};
    });

    setValue('physicalInventoryArticles', arr);
  }

   

  const physicalInventoryArticleRowActionIcon = ( applicationQueryParameter: IPhysicalInventoryArticle) : ActionIconTableRow<IPhysicalInventory,IPhysicalInventoryArticle> => {
  
    const res: ActionIconTableRow<IPhysicalInventory,IPhysicalInventoryArticle> = {
      toolTip: 'remove',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IPhysicalInventoryArticle) => {
        
        (refRemovePhysicalInventoryArticle.current??emptyFunc)(index);            
      }
    }
    return res;
}


const refEnumItems = useRef<IEnumerationItem[]>();    
useEffect( () => {   
  //console.log(enumItems);
    refEnumItems.current = enumItems;
  
}, [enumItems]);


useEffect( () => {              
  setCurrentFormNameAtom(t('Physical inventory'));  
  setCurrentBasicTextFilterProps(basicFilterPhysicalInventory);
}, []);




const [articleStocks, setArticleStocks] = useState<IArticleStock[]>([]);
  useEffect( () => {
    
    async function _() {   
              
      const stocks = watchStoreId>0 ? await getArticlePerStores(watchStoreId, 0) : [];
      setArticleStocks(stocks);
    }

    _();  
  }, [watchStoreId] );

  

  /********** This use effect call retrieve data wich will call refetch and _data will be updated. 
and the new useEffect will take place ********************/
useEffect( () => {
    // setCurrentFormName(t('Billing'));        
    setCurrentFormNameAtom(_id>0?`${t('Physical inventory')} - # ${_id} # -`: t('Physical inventory') );
    if(_id > 0)
      retrieveData('PhysicalInventory',_id, refetch);  
  }, [_id] );

  useEffect( () => {   
    refArticleIndex.current = physicalInventoryArticleIndex;
  
  }, [physicalInventoryArticleIndex]);


useEffect( () => {
   
    if(_data && _data.id > 0) {
    reset(_data);
}
}, [_data]);

const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
  _setId(0);           
  reset(defaultPhysicalInventory);    
}

const saveData = async (event: MouseEvent<HTMLButtonElement>) => {   
  if(!checkEntitySaveAuthorization('PhysicalInventory', _id)) {
    setIsSaveLoading(false);
       return;
  }

  const data = getValues(); 

  const {storeId, reference, description} = data;

  if(storeId <= 0 ) {
    enqueueSnackbar( t('Store must be specified'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
    setIsSaveLoading(false);
    return;
  }
    
  if(reference.trim() === '' || description.trim() === '') {
      enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
        anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
      setIsSaveLoading(false);
      return;
    }

  
  if(data.physicalInventoryArticles.some( x => x.theoreticalQuantity === 0 && x.physicalQuantity === 0 )) {
    enqueueSnackbar( t('All articles must have option and quantity defined'), { variant: 'warning',
      anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
    setIsSaveLoading(false);
    return;
  }

  mutate(data);
}

const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
  openEntityActionDrawer('PhysicalInventory', _id);
}

const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
  openEntityPrintDrawer('PhysicalInventory', _id);
}

const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
  queryClient.invalidateQueries(['PhysicalInventory',_id]);        
  await retrieveData('PhysicalInventory',_id, refetch);        
  reset(_data);             
}

  return (
        <FormProvider {...methods} >
          <Box sx={{ mx: 0.1 }}>
            <Grid container rowSpacing={4} columnSpacing={1}>
              <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                <Stack flexDirection='column'  >
                    <Box sx={{ mt: 1, width: '100%' }} >
                        <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                        <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                        <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                        <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                        <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />

                        
                        <TextField sx={{width:'calc(70% - 8px)'}} id="reference" label={t('Reference')} 
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } autoFocus {...register('reference')} />
                        <Controller control={control}
                          name='inventoryDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Date')} 
                              onChange={onChange}                      
                              value={new Date(value)}
                              slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                              //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                        />
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                    </Box>
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t('Store')} :: / ${t(('Date'))}`}
                      </Typography>                                                       
                    </Box>   
                    <Box sx={{ mt: 1, width: '100%' }} > 
                      <Controller 
                          name='storeId' control={control}                                     
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="originStoreId"
                              label={`${t('Store')} =>`} inputProps={ {readOnly: false}} >
                              {stores && stores.map( 
                                (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                              }
                            </TextField>
                          )}
                        />
                        <Controller control={control}
                          name='issueDate' 
                          render={({ field: { onChange, onBlur, value, ref } }) => (
                            <DatePicker label={t('Issue date')} 
                              onChange={onChange} readOnly disableOpenPicker                     
                              value={new Date(value)}
                              slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                              //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                            /> )}
                      />
                    </Box> 
                    
                    <Box sx={{ mt: 0.25, width: '100%' }} > 
                      <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                            sx={{...typographyGroupBoxStyling}}>
                        {`${t(('Status of inventory'))} `}
                      </Typography>                                                       
                    </Box>
                    <Box sx={{ mt: 1, width: '100%' }} >
                      
                      <Controller 
                          name={`status`}
                          control={control}
                          render={ ({field: {onChange, value}}) => (
                            <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                label={t('Status')} inputProps={ {readOnly: true }} >
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                          )}
                      />
                      <Controller control={control}
                            name='statusDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Status date')} 
                                onChange={onChange} readOnly disableOpenPicker                     
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                              /> )}
                        />
                        <Controller 
                            name='statusPurpose' control={control}                                     
                            render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                                label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                {enumItems && enumItems.filter( e => 
                                      e.enumerationCode === Enum_STOCK_TRANSFER_STATUS_PURPOSE ).map( 
                                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                }
                              </TextField>
                            )}
                        />
                    </Box>
                </Stack>                        
              </Grid>                   
              <Grid item xs={12}  md={7} component={Paper} >
                  <Stack flexDirection='column'> 
                    
                    <Box key={`${'watchFileName'} - key ${getValues().physicalInventoryArticles.map(x => x.theoreticalQuantity)}`} sx={{ mt: 1, width: '100%' }} >
                      <ArrayFieldTableEx<IPhysicalInventory,IPhysicalInventoryArticle,'id'> 
                        mainObject={getValues()} fieldKey='id' 
                        headCells={headPhysicalInventoryArticleCells} rowsPathName={`physicalInventoryArticles` }
                        title={t('_Articles')} rowActionIcon={physicalInventoryArticleRowActionIcon}  
                        onRowSelected={handlePhysicalInventoryArticleSelected}
                                            
                        refAppend={refAppendPhysicalInventoryArticles as MutableRefObject<(value: Partial<FieldArray<IPhysicalInventory>> | Partial<FieldArray<IPhysicalInventory>>[], options?: FieldArrayMethodProps) => void>}
                        refUpdate={refUpdatePhysicalInventoryArticle as MutableRefObject<(index: number,value: Partial<FieldArray<IPhysicalInventory>>) => void>}
                        refRemove={refRemovePhysicalInventoryArticle as MutableRefObject<(index: number) => void>}

                        //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                        //displayMore={undefined}
                        toolbarActions={[ 
                          { toolTip: `${t('Sync')}...`, onClickIcon: handleSyncPhysicalInventoryArticle ,icon: InventoryIcon,  },
                          { toolTip: `${t('Add')}...`, onClickIcon: handleAddPhysicalInventoryArticle ,icon: AddCircleIcon,  },
                          { toolTip: `${t('Refresh')}...`, onClickIcon: handleRefreshPhysicalInventoryArticle ,icon: RefreshIcon,  },
                        
                        ]}
                      />  
                      { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                        okText={t('OK')} cancelText={t('Cancel')} title={t('Product filter')} onCancel={()=> {setOpenProductFilter(false);}} 
                        onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                            <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                        </FormDialog> }

                        { openArticleOption && <FormDialog open={openArticleOption} maxWidth='md'
                        okText={''} cancelText={''} title={`${t('Article')} : ${t('Option')} `} onCancel={()=> {setOpenArticleOption(false);}} 
                        onClose={()=> {setOpenArticleOption(false);}} onOk={()=> {setOpenArticleOption(false);}}  >
                            <Stack flexDirection='column'>
                              <Box sx={{ mt: 1, width: '100%' }} >
                                <EnhancedTable<IEnumerationItem> rows={articleOptions} 
                                  headCells={[            
                                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                    {id:'code', label : t('Article'),  display: true, type: 'string', width: 15},
                                    
                                    {id:'name', label : `${t('Name')}`,  display: true, type: 'string', width: 40},   
                                    {id:'description', label : `${t('Description')}`,  display: true, type: 'string', width: 45},  
                                    
                                  ]} 
                                  title={`${t(`Articles in store`)} ...`} objKey='code' 
                                  stateSelected={undefined} 
                                  onRowSelected={undefined} rowCheckedMode='single'
                                  onRowCheckedSelectChange={undefined} order='asc' orderBy='name'
                                  onRowDoubleClick={handleDoubleClickArticleOption} 
                                  rowActionIcon={undefined}
                                  toolbarActions={[
                                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                  ]}
                                />
                              </Box>
                            </Stack>
                        </FormDialog> }
                                             
                    </Box>
                      
                  </Stack> 
              </Grid>
            </Grid>
          </Box>
        </FormProvider>
  )
}
