import { IPerson, PersonType, defaultPerson } from "features/production/models/Person";
import { ICashOut } from "./CashOut";
import { ITellerDayClosing } from "./TellerDayClosing";


export interface ICashControl {
    id: number,
    paymentSourceCode: string,

    amount: number,
   
    initialAmount: number,
    tellerOperationAmount: number,
    cashOutAmount: number,
    remainingAmount: number,
    availableAmount: number,

    operationDate: Date,
    issueDate: Date,    
    
    observation: string,
    
    status: string,
    statusDate: Date
    statusPurpose: string,    

    cashOuts: ICashOut[],
    tellerDayClosings: ITellerDayClosing[],

    operationsByMode: {mode: string, netAmount: number, cancelledAmount: number}[]
  }

  export interface IDashboardCashControl {
    //operationsByCategory: {schoolYearName: string, category: string, amount: number} [],
    operationsByMode: {mode: string, netAmount: number, cancelledAmount: number}[]
  }


  export const defaultCashControl : ICashControl = {
    id: 0,
    paymentSourceCode: '',

    amount: 0,

    initialAmount: 0,
    tellerOperationAmount: 0,
    cashOutAmount: 0,
    remainingAmount: 0,
    availableAmount: 0,

    operationDate: new Date(),
    issueDate: new Date(),    
    
    observation: '',

    status: '95',
    statusDate: new Date(),
    statusPurpose: '',

    cashOuts: [],
    tellerDayClosings: [],
    operationsByMode: []
  }

  export interface ICashControlSearch {
    startDate: Date,
    endDate: Date,

    observation: string,
  }

 
  

