

import React from 'react';
import { Navigate, Outlet, Route, Routes} from 'react-router-dom';


import {  useRecoilValue, } from 'recoil';


///
import { currentUserSessionAtom } from 'library/store';

import { Login } from './Login';
import { Home } from 'features/Home';

import { ApplicationSetupForm, ApplicationSetupWhatsAppForm ,defaultApplicationSetup,
         
         
         ApplicationQueryForm, defaultApplicationQuery,
         
         ResourceTypeForm, defaultResourceType,

         SharingForm, defaultSharing,
         ProductForm, defaultProduct,

         PackagingForm, defaultPackaging,         

         EmployeeForm, defaultEmployee,
         
         TimeTableForm,

         EmployeeTimeTableForm,
         ApplicationSetupEmailForm,
         EntityActionReportForm,
         defaultEntityActionReport
         } from 'features/setup';

import { RoleForm, defaultRole,
         UserForm, defaultUser,
         GroupForm, defaultGroup } from 'features/security';

import { ExternalLibraryForm, defaultExternalLibrary,
         EnumerationForm, defaultEnumeration,
         ReportForm, defaultReport,
         ExtensionTypeForm, defaultExtensionType,
         MatrixForm, defaultMatrix,
         TarificationForm, defaultTarification,
         DeliveryForm, defaultDelivery,
         DeliveryDashboardForm, defaultDashboardDelivery, StoreForm, defaultStore, StockTransferForm, defaultStockTransfer, 
         PhysicalInventoryForm,
         defaultPhysicalInventory,
         StockMovementForm,
         defaultStockMovement} from 'features/configuration';


import { ExpenseForm, defaultExpense,
         ResourceForm, defaultResource,
         PersonForm, defaultPerson, 
         AppointmentForm, defaultAppointment,
         AppointmentTimeTableForm,
         ExpenseDashboardForm, defaultDashboardExpense,
         ResourceAllocationTimeTableForm,
         TicketForm,
         defaultTicket,
         ProblemForm,
         defaultProblem
          //StudentForm,
         //SchoolYearClassSubjectMarkForm, defaultSchoolYearClassSubjectMark,
         
         } from 'features/production';

import { BillingForm, defaultBilling, BillingDeliveryForm, defaultBillingDelivery,
         TellerOperationForm, defaultTellerOperation, //defaultTellerOperationEx,
         TellerDayClosingForm, defaultTellerDayClosing,
         PaymentForm, defaultPayment,
         PaymentOperationForm, defaultPaymentOperation,
         BillingFinanceForm, defaultBillingDashboardMonth, BillingDashboardForm,
         FinanceDashboardForm, defaultFinanceDashboard, CashOutForm, defaultCashOut, defaultCashControl, CashControlForm
         } from 'features/finance';

import { JobForm, defaultJob,
         SmsApiForm, defaultSmsApi,
         CertificateTemplateForm, defaultCertificateTemplate,
         CertificateAllocationForm, defaultCertificateAllocation,
         HookDataTemplateForm, defaultHookDataTemplate,
         HookDataForm, defaultHookData,
         EditionForm, defaultEdition,
         InstantSmsForm,
         defaultReportProcessing} from 'features/misc';


import { PasswordChangeForm, defaultPasswordChange } from 'features/identity';
            

const ProtectedRoute = ( props: {redirectPath?: string} ) : React.ReactElement => {
    
    const {redirectPath} = props;

    const {isAuthenticated } = useRecoilValue(currentUserSessionAtom);
    
    return !isAuthenticated ? <Navigate to={redirectPath || '/login'} replace /> : <Outlet />;
}


export const AppRoute = () => {
    
    const {shouldResetPassword } = useRecoilValue(currentUserSessionAtom);

    return (
        shouldResetPassword?     
        <Routes>
            <Route element={<ProtectedRoute />}>
                <Route path="*" element={<PasswordChangeForm {...defaultPasswordChange} />} />
            </Route>
        </Routes> :
    <Routes>
        <Route index element={<Login />} />
        <Route path="login" element={<Login />} />
        
        <Route element={<ProtectedRoute />}>
          <Route path="home" element={<Home />} />

          {/* ------ Setup ----- */} 
          <Route path="applicationSetup/:id" element={<ApplicationSetupForm {...defaultApplicationSetup} />} />

          <Route path="entityActionReport/:id" element={<EntityActionReportForm {...defaultEntityActionReport} />} />
          
          <Route path="applicationQuery/:id" element={<ApplicationQueryForm {...defaultApplicationQuery} />} />
                   
          
                   
          <Route path="sharing/:id" element={<SharingForm {...defaultSharing} />} />
          <Route path="resourceType/:id" element={<ResourceTypeForm {...defaultResourceType} />} />          

          <Route path="product/:id" element={<ProductForm {...defaultProduct} />} />
          
          <Route path="packaging/:id" element={<PackagingForm {...defaultPackaging} />} />
          <Route path="timeTable/:id" element={<TimeTableForm />} />

         

          <Route path="employee/:id" element={<EmployeeForm {...defaultEmployee} />} />
          <Route path="teacherTimeTable/:id" element={<EmployeeTimeTableForm />} />

          {/* ------ Security ----- */}
          <Route path="role/:id" element={<RoleForm {...defaultRole} />} />
          <Route path="user/:id" element={<UserForm {...defaultUser} />} />
          <Route path="group/:id" element={<GroupForm {...defaultGroup} />} />

          {/* ------ Configuration ----- */}
          <Route path="externalLibrary/:id" element={<ExternalLibraryForm {...defaultExternalLibrary} />} />
          <Route path="extensionType/:id" element={<ExtensionTypeForm {...defaultExtensionType} />} />
          <Route path="enumeration/:id" element={<EnumerationForm {...defaultEnumeration} />} />
          <Route path="report/:id" element={<ReportForm {...defaultReport} />} />

          <Route path="matrix/:id" element={<MatrixForm {...defaultMatrix} />} />
          <Route path="tarification/:id" element={<TarificationForm {...defaultTarification} />} />

          <Route path="store/:id" element={<StoreForm {...defaultStore} />} />
          <Route path="stockTransfer/:id" element={<StockTransferForm {...defaultStockTransfer} />} />
          <Route path="stockMovement/:id" element={<StockMovementForm {...defaultStockMovement} />} />

          <Route path="physicalInventory/:id" element={<PhysicalInventoryForm {...defaultPhysicalInventory} />} />

          <Route path="delivery/:id" element={<DeliveryForm {...defaultDelivery} />} />

          <Route path="deliveryDashboard/:id" element={<DeliveryDashboardForm {...defaultDashboardDelivery} />} />

          <Route path="expenseDashboard/:id" element={<ExpenseDashboardForm {...defaultDashboardExpense} />} />

          {/* ------ Production ----- */}
          <Route path="expense/:id" element={<ExpenseForm {...defaultExpense} />} />

          <Route path="resource/:id" element={<ResourceForm {...defaultResource} />} />
          <Route path="resourceAllocation/:id" element={<ResourceAllocationTimeTableForm />} />

          <Route path="appointment/:id" element={<AppointmentForm {...defaultAppointment} />} />
          <Route path="appointmentTimeTable/:id" element={<AppointmentTimeTableForm />} />

          <Route path="ticket/:id" element={<TicketForm {...defaultTicket} />} />
          <Route path="problem/:id" element={<ProblemForm {...defaultProblem} />} />
          
          <Route path="person/:id" element={<PersonForm {...defaultPerson} />} />
          {/* <Route path="classExamMark/:id" element={<SchoolYearClassSubjectMarkForm {...defaultSchoolYearClassSubjectMark} />} />

          <Route path="student/:id" element={<StudentForm {...defaultRegistration} />} /> */}
          
          {/* ------ Finance ----- */}
        
          {/* <Route path="schoolYearFinance/:id" element={<SchoolYearFinanceForm {...defaultSchoolYear} />} /> // from setup. */}
          <Route path="financeDashboard/:id" element={<FinanceDashboardForm {...defaultFinanceDashboard} />} />
          <Route path="billingDashboard/:id" element={<BillingDashboardForm {...defaultBillingDashboardMonth} />} />

          <Route path="billing/:id" element={<BillingForm {...defaultBilling} />} />
          <Route path="billingDelivery/:id" element={<BillingDeliveryForm {...defaultBillingDelivery} />} />

          <Route path="tellerOperation/:id" element={<TellerOperationForm {...defaultTellerOperation} />} />
          <Route path="tellerDayClosing/:id" element={<TellerDayClosingForm {...defaultTellerDayClosing} />} />

          <Route path="cashOut/:id" element={<CashOutForm {...defaultCashOut} />} />
          <Route path="cashControl/:id" element={<CashControlForm {...defaultCashControl} />} />

          <Route path="payment/:id" element={<PaymentForm {...defaultPayment} />} />
          <Route path="paymentOperation/:id" element={<PaymentOperationForm {...defaultPaymentOperation} />} />


          {/* ------ Misc ----- */}
          <Route path="job/:id" element={<JobForm {...defaultJob} />} />
          <Route path="smsApi/:id" element={<SmsApiForm {...defaultSmsApi} />} />
          <Route path="applicationSetupWhatsApp/:id" element={<ApplicationSetupWhatsAppForm {...defaultApplicationSetup} />} />

          <Route path="applicationSetupEmail/:id" element={<ApplicationSetupEmailForm {...defaultApplicationSetup} />} />

          <Route path="certificateTemplate/:id" element={<CertificateTemplateForm {...defaultCertificateTemplate} />} />
          <Route path="certificateAllocation/:id" element={<CertificateAllocationForm {...defaultCertificateAllocation} />} />

          <Route path="hookDataTemplate/:id" element={<HookDataTemplateForm {...defaultHookDataTemplate} />} />
          <Route path="hookData/:id" element={<HookDataForm {...defaultHookData} />} />

          <Route path="edition/:id" element={<EditionForm {...defaultEdition} />} />

          <Route path="instantSms/:id" element={<InstantSmsForm {...defaultReportProcessing} />} />
          
          {/* ------ User profile ----- */}
          <Route path="passwordChange" element={<PasswordChangeForm {...defaultPasswordChange} />} />
          
        </Route>
        <Route path="*" element={<p>There's nothing here: 404!</p>} />
        <Route path="*" element={<Login />} />
    </Routes>
    );
    
}


