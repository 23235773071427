import { ITellerOperation } from "./TellerOperation"

export interface ITellerDayClosing {
    id: number,

    paymentSourceCode: string,
    
    netAmount: number,
    cancelledAmount: number,
    countNet: number,
    countCancelled: number,
    countTotal: number,
    closingNote: string,
    closingWarning: string,

    tellerOperations: ITellerOperation[],    

    closingDate: Date,
  }
  

  export const defaultTellerDayClosing : ITellerDayClosing = {
    id: 0,

    paymentSourceCode: '',

    netAmount: 0,
    cancelledAmount: 0,
    countNet: 0,
    countCancelled: 0,
    countTotal: 0,
    closingNote: '',
    closingWarning: '',

    tellerOperations: [],    

    closingDate: new Date(),
  }

  export interface IDashboardTellerDayClosing {
    //operationsByCategory: {schoolYearName: string, category: string, amount: number} [],
    operationsByMode: {mode: string, netAmount: number, cancelledAmount: number}[]
  }
  

  export interface ITellerDayClosingSearch {
    startDate: Date,
    endDate: Date,

    closingNote: string,
    closingWarning: string
  }