
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import { DatePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import useDeliveryService from 'features/configuration/services/Delivery';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBilling, IBillingDetail } from 'features/finance/models/Billing';
import { IDashboardDelivery, defaultDashboardDelivery } from './models/Delivery';
import { IExpenseDetail } from 'features/production/models/Expense';



export const DeliveryDashboardForm: FC<IDashboardDelivery> = (props: IDashboardDelivery = defaultDashboardDelivery) => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const {getResourceDashboard} = useDeliveryService();
  const [dashboard, setDashboard] = useState<IDashboardDelivery>(defaultDashboardDelivery);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<ISchoolYearClass[]>([]);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<ISchoolYearClass[]>([]);

  // const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  // const basicFilterSchoolYear = useBasicFilterSchoolYear( 
  //     async (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
  //         const {id, name } = row;

  //         const dash = await getResourceDashboard(id);
  //         setSchoolYearName(name);

  //         setDashboard(dash);
  //         setOpenSchoolYearFilter(false);
  //     }
  // );


  // const handleClickSelectSchoolYear = (event: any) => {
  //   setOpenSchoolYearFilter(true);
  // }


  useEffect( () => {        
    setCurrentFormName(` ${t('Dashboard')} -  ${t('Deliveries')} `);
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  const [date, setDate] = React.useState<Date>(new Date());

  useEffect(() => {
    async function _() {
             
      const dash = await getResourceDashboard(date.getFullYear(), date.getMonth()+1);  // getMonth of js give the month [0-11]
            
      setDashboard(dash);     
    }
    _();
  }, [date]);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The month of')} :::::`}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                views={["year", "month"]}
                renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(35% - 8px)':'calc(15% - 8px)'}} />}
                onChange={(newValue) => setDate(newValue||new Date())}                    
                value={date}                      
              /> 
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Delivery')} - ${t('Year')}`} paletteColor={theme.palette['error']} 
                 iconName='IoSendSharp' total={dashboard.yearDeliveryAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Delivery')} - ${t('Year')} (${t('Paid')}) `} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.yearDeliveryAmountPaid}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Delivery')} - ${t('Year')} (${t('Unpaid')}) `} paletteColor={theme.palette['warning']} 
                   iconName='IoSendSharp' total={dashboard.yearDeliveryUnpaidAmount}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Delivery')} - ${t('Year')} (${t('Cancelled')}) `} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.yearDeliveryCancelledAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Delivery')} - ${t('Year')} (${t('Unvalidated')})`} paletteColor={theme.palette['warning']} 
                 iconName='IoSendSharp' total={dashboard.yearDeliveryUnvalidatedAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Delivery')} - ${t('Month')} `} paletteColor={theme.palette['primary']} 
                  iconName='IoSendSharp' total={dashboard.monthDeliveryAmount}  />
        </Grid> 
        <Grid item xs={12} md={7} >
          <Stack flexDirection='column'>            
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<{articleName: string, articleOption: string, 
                    quantity: number, quantityMonth: number, netAmount: number, amount: number, amountMonth: number}> 
                  rows={dashboard.deliveryArticles} 
                  headCells={[            
                    {id:'articleName', label : t('Article'),  display: true, type: 'string', width: 30 },
                    {id:'articleOption', label : t('Option'),  display: true, type: 'string', width: 30 },
                    
                    {id:'quantity', label : t('Quantity'),  display: true, type: 'numeric', width: 10},
                    {id:'quantityMonth', label : `${t('Quantity')}(${t('Month')})`,  display: true, type: 'numeric', width: 10},

                    {id:'netAmount', label : t('Net amount'),  display: false, type: 'numeric', width: 10},
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 10},                    
                    {id:'amountMonth', label : `${t('Amount')}(${t('Month')})`,  display: true, type: 'numeric', width: 10 },                    
                    
                  ]} 
                  title={t(`Delivery of articles`)} objKey='articleName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='articleName'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                        
                  ]}
                /> 
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={5} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<{articleId: number, articleName: string, articleOption: string, 
                deliveredQuantity: number, quantitySold: number, quantitySoldYear: number, remainingQuantity: number}> 
                  rows={dashboard.articles} 
                  headCells={[            
                    
                    {id:'articleName', label : t('Article'),  display: true, type: 'string', width: 45},
                    {id:'articleOption', label : t('Option'),  display: true, type: 'string', width: 30},

                    {id:'deliveredQuantity', label : `${t('Qty')}`,  display: true, type: 'numeric', width: 9},
                    {id:'quantitySold', label : `${t('Sold')}`,  display: true, type: 'numeric', width: 8},
                    //{id:'quantitySoldYear', label : `${t('Sold')}(${t('Year')})`,  display: true, type: 'numeric', width: 10},
                    {id:'remainingQuantity', label : `${t('Stock')}`,  display: true, type: 'numeric', width: 8},                                     
                    
                  ]} 
                  title={t(`Resource by category`)} objKey='articleName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='articleName'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                        
                  ]}
                />
            </Box>
            
            
          </Stack>
        </Grid>

      </Grid>
      
    </Box>
  )
}
