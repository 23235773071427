
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ICashControl, ICashControlSearch, IDashboardCashControl } from "../models/CashControl";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';
import { addMonths, addDays } from 'date-fns';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createCashControl = async (cashControl: ICashControl)  =>       
        await (await axios.post('/api/finance/cashControl/create', cashControl)).data;       
        
    const updateCashControl = async (cashControl: ICashControl)  =>       
        await (await axios.post('/api/finance/cashControl/update', cashControl)).data; 
    
    const getCashControl = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/cashControl/get-cashControl/${id}`));
      return await data;
    }
   

    const getCashControls = async (criteria: ICashControlSearch, pagination?: IPagination) : Promise<ICashControl[]> => {
      
      const {startDate, endDate, observation} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/cashControl/get-cashControls?observation=${observation}` +
        `&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getNewCashControl = async (paymentSourceCode: string) : Promise<ICashControl> => {
      const {data} = (await axios.get(`/api/finance/cashControl/get-new-cashControl?paymentSourceCode=${paymentSourceCode}`));
      return await data;
    }

    const getCashControlDashboard = async (cashControlId: number, paymentSourceCode: string) : Promise<IDashboardCashControl> => {
      const {data} = (await axios.get(`/api/finance/cashControl/get-cashControl-dashboard?cashControlId=${cashControlId}&paymentSourceCode=${paymentSourceCode}`));
      return await data;
    }

    
    // const getCashControlDashboard = async (cashControlId: number, paymentSourceCode: string) : Promise<IDashboardCashControl> => {
    //   const {data} = (await axios.get(`/api/finance/cashControl/get-cashControl-dashboard?cashControlId=${cashControlId}&paymentSourceCode=${paymentSourceCode}`));
    //   return await data;
    // }
      
    return {    
      createCashControl,
      updateCashControl,
      getCashControl,
      getCashControls,
      
      getNewCashControl,
      getCashControlDashboard
     
    } 
}

export default _;

export interface IFilterCashControlOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [ICashControl[], React.Dispatch<React.SetStateAction<ICashControl[]>>],
}

const defaultFilterProductOption: IFilterCashControlOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterCashControl = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ICashControl) => void,
                                            filterOption?: IFilterCashControlOption  ) => {

  const { getCashControls } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headCashControlCells, setHeadCashControlCells]  = useState<HeadCell<ICashControl>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'operationDate', label : t('Operation date'),  display: true, type: 'date', },
    {id:'issueDate', label : t('Issue date'),  display: true, type: 'date', },
    {id:'initialAmount', label : t('Init. amount'),  display: true, type: 'numeric', },
    {id:'tellerOperationAmount', label : t('Op. amount'),  display: true, type: 'numeric', },
    {id:'cashOutAmount', label : t('Out. amount'),  display: true, type: 'numeric', },
    
    {id:'observation', label : t('Observation'),  display: true, type: 'string', },
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
   
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'personName', text: t('Name'), value: ''},
    {name: 'personPortable', text: t('Portable'), value: ''},
    {name: 'observation', text: t('Observation'), value: ''},
  ]);    

  const [filteredCashControls, ] = useState<ICashControl[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ICashControl[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const observation = filterElements.find( elt => elt.name === 'observation')?.value || '';
           
    const arr = await getCashControls( {startDate, endDate, observation }, pagination );
    
    return arr;
  }

  const objKey: keyof ICashControl = 'id';

  return {
    title: t('Cash control'), headCells: headCashControlCells, objKey,
    filterElements, rows: filteredCashControls, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
