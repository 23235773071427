import { IPaymentOperation } from "./PaymentOperation";
import { IPoint } from "./Point";

export type PaymentType = 'reimbursement' | 'point' | 'commission' | 'delivery' | 'expense' | 'bonus' | 'deposit' ;

export interface IPayment {
    id: number,
    
    ownerId: number,
    bearerId: number,
    description: string,
    issueDate: Date,
    totalAmount: number,
    accessories: number,
    tax: number,
    amount: number,

    isCancelled: boolean,
    cancellationDate: Date,
    cancellationPurpose: string,

    remainingAmount: number,
    
    type: PaymentType,

    tellerOperationId: number,

    ownerFirstName: string,
    ownerLastName: string,
    ownerFullName: string,

    bearerFirstName: string,
    bearerLastName: string,
    bearerFullName: string,

    paymentOperations: IPaymentOperation[],
    points: IPoint[]
  }
  

  export const defaultPayment : IPayment = {
    id: 0,
    
    ownerId: 0,
    bearerId: 0,
    description: '',
    issueDate: new Date(),
    totalAmount: 0,
    accessories: 0,
    tax: 0,
    amount: 0,
    isCancelled: false,
    cancellationDate: new Date(),
    cancellationPurpose: '',

    remainingAmount: 0,
    
    type: 'point',

    tellerOperationId: 0,

    ownerFirstName: '',
    ownerLastName: '',
    ownerFullName: '',

    bearerFirstName: '',
    bearerLastName: '',
    bearerFullName: '',

    paymentOperations: [],
    points: []
  }

  export interface IPaymentSearch {
    //schoolYearId: number,

    startDate: Date,
    endDate: Date,
    
    description: string,
    
    ownerName: string,
    bearerName: string,
  }