
//import { PersonType } from "features/production/models/Person";

export interface IPhysicalInventory {
    id: number,

    storeId: number,
   
    inventoryDate: Date,
    reference: string,
    description: string,
    
    issueDate: Date,
    
    status: string,
    statusDate: Date,
    statusPurpose: string,
    
    physicalInventoryArticles: IPhysicalInventoryArticle[],
  }

  export interface IPhysicalInventoryArticle {
    id: number, 

    physicalInventoryId: number,
    articleId: number,
    option: string,

    theoreticalQuantity: number, 
    physicalQuantity: number, 

    comment: string,
    
    articleName: string,
    articleFilterOption: string,
  }

  

export const defaultPhysicalInventory : IPhysicalInventory = {
  id: 0,

  storeId: 0,
  
  inventoryDate: new Date(),
  reference: '',
  description: '',
  
  issueDate: new Date(),

  status: '95',
  statusDate: new Date(),
  statusPurpose: '',

  physicalInventoryArticles: [],
  }

  export const defaultPhysicalInventoryArticle : IPhysicalInventoryArticle = {
    id: 0, 
    physicalInventoryId: 0,
    articleId: 0,
    option: '',

    theoreticalQuantity: 0, 
    physicalQuantity: 0, 
    
    articleName: '',
    articleFilterOption: '',

    comment: ''    
  }

  

  export interface IPhysicalInventorySearch {    
    reference: string,
    description: string
  }

  