import React, { FC , MouseEvent, useEffect, useState} from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import md5 from 'md5';
import { useSnackbar } from 'notistack';


import InputAdornment from '@mui/material/InputAdornment';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { FormProvider, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next';
import { useMutation, useQueryClient } from 'react-query';
import { useNavigate } from 'react-router-dom';

import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup";

import useVisibilityToggle from 'library/useVisibilityToggle';

import { currentFormNameAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';

import { IPasswordChange, defaultPasswordChange } from './models/User';

import useIdentityUserService from './services/User';
import { useRecoilState, useRecoilValue } from 'recoil';

import { IResult } from 'library/interface';
import { IconButton } from '@mui/material';

export const PasswordChangeForm : FC<IPasswordChange> = props => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const {language, userName, isPasswordComplexityAllowed} = useRecoilValue(currentUserSessionAtom);

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {changePassword, isPasswordStrong, passwordStrength} = useIdentityUserService();

  const { show: showPassword, toggleVisibility: togglePasswordVisibility } = useVisibilityToggle();
  const { show: showPasswordNew, toggleVisibility: togglePasswordVisibilityNew } = useVisibilityToggle();
  const { show: showPasswordConfirm, toggleVisibility: togglePasswordVisibilityConfirm } = useVisibilityToggle();


  // const formSchema = yup.object().shape({
  //   newPassword: yup.string()
  //     .required('Password is mendatory')
  //     .min(3, 'Password must be at 3 char long'),
  //     confirmNewPassword: yup.string()
  //     .required('Password is mendatory')
  //     .oneOf([yup.ref('password')], 'Passwords does not match'),
  // })

  const methods = useForm<IPasswordChange>({defaultValues: {...defaultPasswordChange, userName} });
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchPassword = watch('newPassword');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IPasswordChange>,Error,IPasswordChange>(changePassword, 
    {         
      onSuccess: (data: IResult<IPasswordChange>) => {
        
        if(data.succeeded) {
          setCurrentUserSession({...currentUserSession, shouldResetPassword: false });
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
        }
        else
          enqueueSnackbar( data.messages.join('\n') , { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          
          setIsSaveLoading(false);
        // reset(data);
        // queryClient.invalidateQueries(['BankPolicy',data.policyID]);
      },
      onError: (err: Error) => {        
        enqueueSnackbar( error?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        setIsSaveLoading(false);
      }
    });

    const [currentPasswordStrengthText, setCurrentPasswordStrengthText] = useState('');

    const [currentPasswordStrength, setCurrentPasswordStrength] = useState<number>(0);

    useEffect( () => {        
      setCurrentFormNameAtom(t('Change password'));
      
    }, []);  
    
    useEffect( () => {        
      setCurrentPasswordStrength(passwordStrength(watchPassword));
      
      setCurrentPasswordStrengthText( isPasswordStrong(watchPassword)?'' :
          `${t('Upper')}-${t('Lower')}-${t('Special')}-${t('Digit')}-${t('Length of 6')}` );
      
    }, [watchPassword]);  
    
    

  const saveData = async (event: MouseEvent<HTMLButtonElement>) => {        
    const data = getValues(); 
    if(data.userName.trim() === '') {
        enqueueSnackbar( t('Name or description is not specified'), { variant: 'warning',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
        setIsSaveLoading(false);
        return;
      }
  
    if(data.newPassword !== data.confirmNewPassword) {
        enqueueSnackbar( t('Password and confirmation are not the same'), { variant: 'error',
          anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
          return;
    }

    mutate({...data,password: md5(data.password), newPassword: md5(data.newPassword), confirmNewPassword: md5(data.newPassword) });
}

const styles = {
  helper: {
       color: currentPasswordStrength<=2? 'red':'orange',
       fontSize: '.8em',
  }
}

  return (
    <FormProvider {...methods} >
      <Box sx={{ mx: 0.1 }}>
          <Grid container rowSpacing={3} columnSpacing={3}>
              <Grid item xs={12}  sm={6} md={4} component={Paper}  >                        
                  <Stack flexDirection='column' padding={0} >
                      <Box sx={{ mt: 1, width: '100%' }} >
                                                          
                          <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                          
                          <TextField  sx={{width:'calc(100% - 8px)'}} id="userName" label={t('User name')} {...register('userName')} disabled
                            inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                            
                            /> 
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >                                       
                        <TextField  sx={{width:'calc(100% - 8px)'}} type={showPassword ? 'text' : 'password'}
                          id="password" label={t('Current Password')} {...register('password')}
                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibility} edge="end">
                                  {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }} />     
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >                                       
                        <TextField  sx={{width:'calc(100% - 8px)'}} type={showPasswordNew ? 'text' : 'password'}
                          id="newPassword" label={t('New Password')} {...register('newPassword')}
                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                          helperText={isPasswordComplexityAllowed? currentPasswordStrengthText : ''} 
                          FormHelperTextProps={{ style: styles.helper }}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibilityNew} edge="end">
                                  {showPasswordNew ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }} />     
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >                                       
                        <TextField  sx={{width:'calc(100% - 8px)'}} type={showPasswordConfirm ? 'text' : 'password'}
                          id="confirmNewPassword" label={t('Confirm Password')} {...register('confirmNewPassword')}
                          inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisibilityConfirm} edge="end">
                                  {showPasswordConfirm ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                              </InputAdornment>
                            )
                          }} />     
                      </Box>
                  </Stack>                        
              </Grid>
              
              
          </Grid>
      </Box>
    </FormProvider> 
  )
}

