

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import { IStockTransfer, IStockTransferSearch } from "../models/StockTransfer";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';


const _ = () => {

    const axios = useAxios(); 

    //const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createStockTransfer = async (stockTransfer: IStockTransfer)  =>       
        await (await axios.post('/api/configuration/stockTransfer/create', stockTransfer)).data;       
        
    const updateStockTransfer = async (stockTransfer: IStockTransfer)  =>       
        await (await axios.post('/api/configuration/stockTransfer/update', stockTransfer)).data; 
    
    const getStockTransfer = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/stockTransfer/get-stockTransfer/${id}`));
      return await data;
    }
   
    const getStockTransfers = async (criteria: IStockTransferSearch, pagination?: IPagination) : Promise<IStockTransfer[]> => {

      const {reference, description } = criteria;

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/configuration/stockTransfer/get-stockTransfers?reference=${reference}&description=${description}` +
          `&pageSize=${pageSize}&pageNumber=${pageNumber}`));
          
      return await data;
    }

      
    return {    
      createStockTransfer,
      updateStockTransfer,
      getStockTransfer,
      getStockTransfers,
      
    } 
}

export default _;

export interface IFilterStockTransferOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IStockTransfer[], React.Dispatch<React.SetStateAction<IStockTransfer[]>>],
}

const defaultFilterStockTransferOption: IFilterStockTransferOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterStockTransfer = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IStockTransfer) => void,
                    filterOption?: IFilterStockTransferOption  ) => {

  const { getStockTransfers } = _();

  const { t, i18n } = useTranslation();   

  const { applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterStockTransferOption;

  //const {data: schoolYears} = useQuery<ISchoolYear[]>( ['SchoolYears'], () => getSchoolYears( {year: 0, name: ''}) );

  const [headStockTransferCells, setHeadStockTransferCells]  = useState<HeadCell<IStockTransfer>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    
    {id:'reference', label : t('Reference'),  display: true, type: 'string', width: 20 },
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 20},

    {id:'issueDate', label : t('Date'),  display: true, type: 'date', width: 10},
    {id:'status', label : t('Status'),  display: true, type: 'string', width: 15},


  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
       
    {name: 'reference', text: t('Reference'), value: ''},
    {name: 'description', text: t('Description'), value: ''},
           
  ]);
  
  const [filteredDeliveries, ] = useState<IStockTransfer[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IStockTransfer[]> => {
    
    const reference = filterElements.find( elt => elt.name === 'reference')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getStockTransfers( {reference, description}, pagination );
   
    return arr;
  }

  const objKey: keyof IStockTransfer = 'id';

  return {
    title: `${t('StockTransfers')}`, headCells: headStockTransferCells, objKey,
    filterElements, rows: filteredDeliveries, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
