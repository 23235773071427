import { defaultPerson, IPerson } from "./Person";

export type TicketType = 'whatsApp' | 'email' | 'facebook' | 'twitter' | 'phoneCall' | 'mail' | 'linkedIn' | 'instagram' ;

export interface ITicket {
  id: number,
  
  personId: number,
  person: IPerson,

  problemId: number,
  billingId: number,
  billingDetailId: number,
  storeId: number,


  title: string,
  description: string,
  issueDate: Date,

  effectiveDate: Date,
  dueDate: Date,

  categoryCode: string,
  severityCode: string,
  resolutionCode: string,
  ticketClassCode: string,
  targetServiceCode: string,
  ticketEntityCode: string,

  status: string,
  statusDate: Date,
  statusPurpose: string,

  type: TicketType,

  contactName: string,
  contactDescription: string,
  contactWhatsApp: string,
  assignedUserName: string,
  fullContactId: string,

  problemTitle: string,
  storeName: string,
  billingReference: string,
  productName: string,

  personFullName: string,
  personFullNameFR: string,
  personFullNameEN: string,

  initialContractMessageId: number,
  validateDuringCreation: boolean,

  newAttachedContractMessageId: number,

  ticketEmployees: ITicketEmployee[],
  ticketComments: ITicketComment[],
  ticketResolutions: ITicketResolution[],
  
  ticketMessages: ITicketMessage[]
}

export interface ITicketEmployee {
  id: number,
  ticketId: number,
  employeeId: number,
  ticketEmployeeRoleCode: string,
  workloadPart: number,

  firstName: string,
  lastName: string
}

export interface ITicketComment {
  id: number,
  ticketId: number,
  commentText: string,
  date: Date,
}

export interface ITicketResolution {
  id: number,
  ticketId: number,
  
  originalResolutionCode: string,
  currentResolutionCode: string,

  resolutionReport: string,
  title: string,
}

export interface ITicketMessage {
  id: number,

  ticketId: number,
  contractMessageId: number,

  contractMessageBody: string,
}

export interface INyxisDashboardDay {
  messageCount: number,
  contactCount: number,
  ticketCount: number,
  contractCount: number,
  
  classChatLabels: string[],
  categoryTicketCounts: number[],
  
  ticketCountPerUser: {label: string, value: number}[],

  avgMessageResponseTime: number
}

export interface ITicketDashboardDay {
    
  ticketCount: number,
  validatedTicketCount: number,
  unvalidatedTicketCount: number,
  cancelledTicketCount: number,
    
  validatedTicketCountPerCategory: {label: string, value: number}[],
  validatedTicketCountPerResolution: {label: string, value: number}[],

}

export const defaultTicketDashboardDay : ITicketDashboardDay = {
    
  ticketCount: 0,
  validatedTicketCount: 0,
  unvalidatedTicketCount: 0,
  cancelledTicketCount: 0,

  validatedTicketCountPerCategory: [],
  validatedTicketCountPerResolution: [],
}

export const defaultNyxisDashboardDay : INyxisDashboardDay = {
  messageCount: 0,
  contactCount: 0,
  ticketCount: 0,
  contractCount: 0,
  
  classChatLabels: [],
  categoryTicketCounts: [],
  
  ticketCountPerUser: [],

  avgMessageResponseTime: 0
}



  export const defaultTicket : ITicket = {
    id: 0,
    
    personId: 0,
    person: defaultPerson,

    problemId: 0,
    billingId: 0,
    billingDetailId: 0,
    storeId: 0,
    
    title: '',
    description: '',

    issueDate: new Date(),
    effectiveDate: new Date(),
    dueDate: new Date(),

    categoryCode: '',
    severityCode: '',
    resolutionCode: '',
    ticketClassCode: '',
    targetServiceCode: '',
    ticketEntityCode: '',

    status: '95',
    statusDate: new Date(),
    statusPurpose: '',

    type: 'whatsApp',

    contactName: '',
    contactDescription: '',
    contactWhatsApp: '',
    assignedUserName: '',
    fullContactId: '',

    problemTitle: '',
    storeName: '',
    billingReference: '',
    productName: '',

    personFullName: '',
    personFullNameFR: '',
    personFullNameEN: '',

    initialContractMessageId: 0,
    validateDuringCreation: false,

    newAttachedContractMessageId: 0,


    ticketEmployees: [],
    ticketComments: [],
    ticketResolutions: [],

    ticketMessages: []

  }

  export const defaultTicketEmployee : ITicketEmployee = {
    id: 0,
    ticketId: 0,
    employeeId: 0,
    ticketEmployeeRoleCode: '',
    workloadPart: 0,
  
    firstName: '',
    lastName: ''
  }

  
  export interface ITicketSearch {   
    statusLevel: string,
    
    startDate: Date,
    endDate: Date,  

    title: string,
    description: string,

    personName: string,
  }