
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { debounce, sum } from 'lodash';


import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import CopyAllIcon from '@mui/icons-material/CopyAll';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';


import { MdOutlineAdd, MdOutlineCancel, MdOutlineCheckCircle } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useUtils from 'library/utils';

import useBillingService, { useBasicFilterBilling, useBasicFilterBillingDelivery } from './services/Billing';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IBilling, IBillingDelivery, IBillingDeliveryDetail, IBillingDetail, IBillingPackagingDetail, IBillingServiceTask, ICommissionDistribution, defaultBilling, defaultBillingDelivery, defaultBillingDetail } from './models/Billing';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_SERVICE_TASK, Enum_OPERATION_STATUS, Enum_BILLING_STATUS_PURPOSE,
  Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, Enum_PRICE_PURPOSE, Enum_BILLING_DELIVERY_STATUS_PURPOSE, Enum_BILLING_DELIVERY_STATUS,
  Enum_MAINTENANCE_CATEGORY, Enum_CONTRACT_SCOPE, Enum_CONTRACT_TYPE, Enum_CORPORATION_TYPE } from 'features/configuration/models/Enumeration';

import useProductService, { useBasicFilterProduct } from 'features/setup/services/Product';

import usePersonService, {useBasicFilterPerson, useBasicFilterPersonSearch}  from 'features/production/services/Person';

import { IProduct } from 'features/setup/models/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker, DateTimePicker } from '@mui/x-date-pickers';
import { IPerson, defaultPerson } from 'features/production/models/Person';


import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';
import { IPackaging } from 'features/setup/models/Packaging';
import { useBasicFilterPackaging } from 'features/setup/services/Packaging';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';

import useStoreService from 'features/configuration/services/Store';
import { IStore } from 'features/configuration/models/Store';

export const BillingDeliveryForm: FC<IBillingDelivery> = (props: IBillingDelivery = defaultBillingDelivery) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {addDurationInPeriod} = useUtils();

  const {language: lg, taskCodes, userDescription, userName, employeeId, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization, openEntityPrintDrawer } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount, getBirthDateType } = usePersonService();


  const { createBilling, updateBilling, evaluateAddedProduct, getCommissionDistributionsByBilling,
          createBillingDelivery, updateBillingDelivery, getBillingDeliveriesByBilling } = useBillingService();

  const {getStores } = useStoreService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterBillingDelivey = useBasicFilterBillingDelivery( 
    (event: React.MouseEvent<unknown>, row: IBillingDelivery) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;


          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openBillingFilter, setOpenBillingFilter] = useState(false);
  const basicFilterBilling = useBasicFilterBilling( 
      async (event: React.MouseEvent<unknown>, row: IBilling) => {
          const {id, status} = row;

          if(status !== '10') {
            enqueueSnackbar( t('Invalid status'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
            return;
          }

          const billing = await retrieveEntity('Billing', id);
          setValue('billingId', id);
          setValue('billing', billing);
                                               
          setOpenBillingFilter(false);
      }
  );

  
  const methods = useForm<IBillingDelivery>({defaultValues: {...defaultBillingDelivery}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchCustomerId = watch('billing.customerId');
  const watchCustomerPersonType = watch('billing.customerPersonType');
  const watchBusinessEmployeeId = watch('billing.businessEmployeeId');  

  const watchCustomerFirstName = watch('billing.customerFirstName');
  const watchCustomerLastName = watch('billing.customerLastName');
  const watchCustomerCorporationName = watch('billing.customerCorporationName');
  const watchCustomerBirthDateType = watch('billing.customerBirthDateType');
  const watchCustomerCreationDateType = watch('billing.customerCreationDateType');

    
  type SearchPersonUsage = 'customer' | 'billing-detail-beneficiary' | 'billing-packaging-beneficiary';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('customer');

  const refProductIndex = useRef<number>(0);
  const refBillingPackagingServiceIndex = useRef<number>(0);

    

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IBillingDelivery>,Error,IBillingDelivery>(
      _id>0?updateBillingDelivery:createBillingDelivery, {   
        onSuccess: (data: IResult<IBillingDelivery>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
          
          setIsSaveLoading(false);
          //setValue('id', data.data.id);
          _setId(data.data.id);
          //retrieveData('Billing',data.data.id, refetch);
          setCurrentFormNameAtom(`${t('Delivery')} - # ${data.data.id} # ` );
          
          //queryClient.invalidateQueries(['Billing',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

      
    const {data: _data, refetch} = useQuery<IBillingDelivery>(['BillingDelivery', _id], () => retrieveEntity('BillingDelivery',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'BillingDelivery'], () => getEnumerationItemsByEnumerationCodes
      ( [ Enum_OPERATION_STATUS, Enum_BILLING_STATUS_PURPOSE, 
          Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, Enum_OPERATION_STATUS, Enum_BILLING_DELIVERY_STATUS_PURPOSE, Enum_BILLING_DELIVERY_STATUS,
          Enum_MAINTENANCE_CATEGORY, Enum_CORPORATION_TYPE  ] ));

    const {data: stores} = useQuery<IStore[]>( ['stores'], () => getStores( { name: '', description: '', location: ''} ));

   

    type PersonUsage = 'customer' | 'billing-detail-service' | 'billing-packaging-service' | 'billing-detail-formation';

    const [currentPersonUsage, setCurrentPersonUsage] = useState<PersonUsage>('customer');
      

    
    const [productIndex, setProductIndex] = useState<number>(-1);
        
    const cellEditableBillingDeliveryDetail = (row: IBillingDeliveryDetail, cellId: keyof IBillingDeliveryDetail) => { 
     
      return true;
    }

    const cellAllowedQuantity = (row: IBillingDeliveryDetail, valueAmount: number) => {
      const { initialQuantity, deliveredQuantity} = row;      
      
      if( (valueAmount<0) || (initialQuantity-deliveredQuantity<=0) ) {
        enqueueSnackbar( `${t('Invalid quantity')} ${valueAmount} : [0-${initialQuantity-deliveredQuantity}]` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      if( valueAmount > 0 && initialQuantity-deliveredQuantity<valueAmount) {
        enqueueSnackbar( `${t('The maximum quantity is')} : ${initialQuantity-deliveredQuantity}` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      //debouncedQuantity();
      return true;
    }

  const [headBillingDeliveryDetailCells, setHeadBillingDeliveryDetailCells]  = useState<HeadCell<IBillingDeliveryDetail>[]>([   
    //{id:'taskCode', label : t('Task'),  display: true, type: 'string', width: 35, getOptions: getServiceTaskCode},   
    //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
    // {id:'serviceName', label : t('Service'),  display: true, type: 'string', width: 35, 
    //   isEditable: cellEditableBillingDetail},
    // {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 35, 
    //   getInputAdornment: getInputAdornmentEmployee},

    // {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 15, isEditable: cellEditableBillingDetail},
    {id:'productName', label : t('Name'),  display: true, type: 'string',  width: 55,}, 
    
    {id:'initialQuantity', label : t('Initial quantity'),  display: true, type: 'numeric', width: 15, }, 
    {id:'deliveredQuantity', label : t('Delivered quantity'),  display: true, type: 'numeric', width: 15, }, 

    {id:'quantity', label : t('Quantity'),  display: true, type: 'numeric', width: 15,
       isEditable: cellEditableBillingDeliveryDetail, isAllowed: cellAllowedQuantity},           
  ]);

  const refAppendBillingDeliveryDetails = useRef<(value: Partial<FieldArray<IBillingDelivery>> | Partial<FieldArray<IBillingDelivery>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBillingDeliveryDetail = useRef<(index: number,value: Partial<FieldArray<IBillingDelivery>> ) => void>(null);
  const refRemoveBillingDeliveryDetail = useRef<(index: number ) => void>(null);

  const billingDeliveryDetailRowActionIcon = ( row: IBillingDeliveryDetail) : ActionIconTableRow<IBillingDelivery,IBillingDeliveryDetail> => {
    
    const res: ActionIconTableRow<IBillingDelivery,IBillingDeliveryDetail> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: true , // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBillingDeliveryDetail) => {
        (refRemoveBillingDeliveryDetail.current??emptyFunc)(index);        
      }
    }
    return res;
  }

  const handleBillingDeliveryDetailDoubleClick = (event: React.MouseEvent<unknown>, index: number  ,row: IBillingDeliveryDetail) => {
    
    const billingDeliveryDetail = getValues().billingDeliveryDetails.at(index);
    if(isFalsy(billingDeliveryDetail)) return;

    const {deliveredQuantity, initialQuantity } = billingDeliveryDetail!;

    (refUpdateBillingDeliveryDetail.current??emptyFunc)(index, {...billingDeliveryDetail!,quantity: initialQuantity-deliveredQuantity });
  }

  const [openBillingArticles, setOpenBillingArticles] = useState<boolean>(false);
  const handleAddBillingDeliveryArticles = (event: any) => {   
    if(getValues().billing.id <= 0)
      return;

    setOpenBillingArticles(true);
  }


  const handleDoubleClickRowBillingDetail = (event: React.MouseEvent<unknown>, row: IBillingDetail) => {
    const {id, billingId, productName, quantity, articleOption, articleFilterOption, deliveredQuantity } = row;

    if( getValues().billingDeliveryDetails.some(d => d.billingArticleId === id) )
      return;

        // setValue(`billingDetails.${billingDetailIndex}.personId`, id);
      // setValue(`billingDetails.${billingDetailIndex}.personNameNIC`, personNameNIC || '');
      (refAppendBillingDeliveryDetails.current??emptyFunc)(
        { id: 0, billingDeliveryId: _id, type: 'article', billingArticleId: id, quantity: 0,
        productName, articleOption, articleFilterOption, initialQuantity: quantity, deliveredQuantity }
      );

    setOpenBillingArticles(false);
  }


  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

  
  useEffect( () => {   
    refProductIndex.current = productIndex;
  
  }, [productIndex]);

  

  useEffect( () => {        
    setCurrentFormNameAtom(`${t('Delivery')} - ${t('Client')}`);
    setCurrentBasicTextFilterProps(basicFilterBillingDelivey);
  }, []);    

  /********** This use effect call retreive data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
  useEffect( () => {
      // setCurrentFormName(t('Billing'));        
      setCurrentFormNameAtom(_id>0?`${t('Delivery')} - ${t('Client')} - # ${_id} # -`: `${t('Delivery')} - ${t('Client')}` );

      if(_id > 0 && getValues().id !== _id) 
        retrieveData('BillingDelivery',_id, refetch);  
    }, [_id] );

  useEffect( () => {
      
    if(_data && _data.id > 0) {      
      reset(_data);
      
    }
  }, [_data, reset]);
  
    const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
      _setId(0);      
      reset(defaultBillingDelivery);    
      setOpenBillingFilter(true);
    }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
                
        if(!checkEntitySaveAuthorization('BillingDelivery', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 
        console.log(data);

        if( data.reference.trim() === '' ) {
          enqueueSnackbar( t('Invalid data'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
        
        if( data.billingDeliveryDetails.length <= 0 ) {
          enqueueSnackbar( t('Invalid data'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( !data.billingDeliveryDetails.some(d => d.quantity > 0) ) {
          enqueueSnackbar( t('Invalid data'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( data.billingId <= 0 ) {
          enqueueSnackbar( t('The customer is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('BillingDelivery', _id);
      }

      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('BillingDelivery', _id);
      }

           
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['BillingDelivery',_id]);        
      await retrieveData('BillingDelivery',_id, refetch);        
      reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                  <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Delivery'))} `}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(30% - 8px)'}} id="deliveryNumber" label={t('Number')} 
                                  {...register('deliveryNumber')} inputProps={ {readOnly: true}} />
                            <TextField sx={{width:'calc(30% - 8px)'}} id="reference" label={t('Reference')} 
                                        {...register('reference')} />
                            <Controller control={control}
                                    name='deliveryDate' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                      <DatePicker label={t('Delivery date')} 
                                        onChange={onChange}                    
                                        value={new Date(value)}
                                        slotProps={{ textField: { sx: {width:'calc(20% - 8px)'} } }}
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      /> )}
                                  />
                            <Controller control={control}
                              name='issueDate' 
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('Issue date')} 
                                  onChange={onChange} disableOpenPicker readOnly                   
                                  value={new Date(value)}
                                  slotProps={{ textField: { sx: {width:'calc(20% - 8px)'} } }}
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                /> )}
                            />                            
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(50% - 8px)'}} id="description" label={t('Description')} 
                                  {...register('description')} />
                             <Controller 
                              name={`status`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                      label={t('Type')} inputProps={ {readOnly: false}} >
                                      {enumItems && enumItems.filter( e => e.enumerationCode === Enum_BILLING_DELIVERY_STATUS ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                name='statusDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Status date')} 
                                    onChange={onChange} disableOpenPicker readOnly                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'} } }}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />                           
                          </Box>
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Billing'))} `}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 0.25, width: '100%' }} key={` proformaNumber ${getValues().billing.proformaNumber}`}> 
                            <TextField sx={{width:'calc(50% - 8px)'}} id="proformaNumber" label={t('Proforma number')} 
                              {...register('billing.proformaNumber')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />        
                            <TextField sx={{width:'calc(50% - 8px)'}} id="billingNumber" label={t('Billing number')} 
                              {...register('billing.billingNumber')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                            />                                              
                          </Box>
                                                                                                                                 
                          <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().billing.customerId}`}>
                            <Controller name='billing.customerPersonType' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="customerPersonType"
                                    label={t('Person type')} inputProps={ {readOnly: true}} focused >
                                      <MenuItem value="physical">{t('Physical')}</MenuItem>
                                      <MenuItem value="moral">{t('Moral')}</MenuItem>
                                  </TextField>
                                )}
                              />
                            { (watchCustomerPersonType === 'physical') && <TextField sx={{width:'calc(30% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                              {...register(lg.startsWith('fr')?'billing.customerLastName':'billing.customerFirstName')} inputProps={ {readOnly: true }} 
                               /> }  
                            { (watchCustomerPersonType === 'physical') && <TextField sx={{width:'calc(25% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                              {...register(lg.startsWith('fr')?'billing.customerFirstName':'billing.customerLastName')} inputProps={ {readOnly: true }} /> } 
                            { (watchCustomerPersonType === 'moral') && <TextField sx={{width:'calc(40% - 8px)'}} id="ln" label={t('Name')} 
                              {...register('billing.customerCorporationName')} inputProps={ {readOnly: true }}
                                 /> }
                            { (watchCustomerPersonType === 'moral') && <Controller name='billing.customerCorporationType' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(15% - 8px)'}} id="customerCorporationType"
                                    label={t('Type')} inputProps={ {readOnly: true}} focused >
                                    {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_CORPORATION_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                  </TextField>
                                )} />  }  
                            <Controller name={watchCustomerPersonType === 'physical'? 'billing.customerBirthDateType': 'billing.customerCreationDateType'} control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(12% - 8px)'}} id="customerBirthDateType"
                                    label={t('Date type')} inputProps={ {readOnly: true}} focused >
                                    {getBirthDateType().map( 
                                      (x,idx) => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                              />   
                            {(watchCustomerPersonType === 'physical') && <Controller key={`${watchCustomerBirthDateType} dob`} control={control}
                                name={'billing.customerBirthDate'} 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Birth date')} 
                                    onChange={onChange}  disabled disableOpenPicker
                                    format={watchCustomerBirthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={watchCustomerBirthDateType==='none'?null:new Date(value)}
                                    views={watchCustomerBirthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    //disabled={watchCustomerBirthDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    slotProps={{ textField: { sx: {width:'calc(18% - 8px)'} } }}
                                    //renderInput={(params) => <TextField {...params}  sx={{width:'calc(18% - 8px)'}} />}
                                  /> )}
                              />  }        
                              {(watchCustomerPersonType === 'moral') && <Controller key={`${watchCustomerCreationDateType} dob`} control={control}
                                name={'billing.customerCreationDate'} 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Creation date')} 
                                    onChange={onChange}  disabled disableOpenPicker              
                                    format={watchCustomerCreationDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={watchCustomerCreationDateType==='none'?null:new Date(value)}
                                    views={watchCustomerCreationDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    //disabled={watchCustomerCreationDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    slotProps={{ textField: { sx: {width:'calc(18% - 8px)'} } }}
                                    //renderInput={(params) => <TextField {...params}  sx={{width:'calc(18% - 8px)'}} />}
                                  /> )}
                              />  }                  
                              
                                                        
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} > 
                            <TextField sx={{width:'calc(15% - 8px)'}} id="customerPortable1" label={`${t('Portable')} 1`} 
                              {...register('billing.customerPortable1')} inputProps={ {readOnly: true,  autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                            <TextField sx={{width:'calc(15% - 8px)'}} id="customerPortable2" label={`${t('Portable')} 2`} 
                              {...register('billing.customerPortable2')}  inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                            <TextField sx={{width:'calc(35% - 8px)'}} id="customerEmail1" label={`${t('Email')} 1`} 
                              {...register('billing.customerEmail1')}  inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                            <TextField sx={{width:'calc(35% - 8px)'}} id="customerEmail2" label={`${t('Email')} 2`} 
                              {...register('billing.customerEmail2')}  inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    
                            
                          </Box>        
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`:: ${t(('Total'))} ::`}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                              <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />
                              
                              <TextField sx={{width:'calc(40% - 8px)'}} id="reference" label={t('Reference')} 
                                {...register('billing.reference')} inputProps={ {readOnly: true}} /> 
                              
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Net')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billing.netAmount`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Tax')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billing.tax`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Amount')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billing.amount`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Paid')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billing.amountPaid`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Unpaid')} sx={{width:'calc(12% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billing.unpaidAmount`}
                                control={control}
                            />
                          </Box>        
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller control={control}
                                name='billing.issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker readOnly                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(15% - 8px)'} } }}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}

                                  /> )}
                              />
                            <Controller name='billing.status' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            <Controller control={control}
                              name='billing.statusDate' 
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('Status date')} 
                                  onChange={onChange} disableOpenPicker readOnly                    
                                  value={new Date(value)}
                                  slotProps={{ textField: { sx: {width:'calc(15% - 8px)'} } }}
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                /> )}
                            />
                            <Controller name='billing.statusPurpose' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(45% - 8px)'}} id="status"
                                      label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_BILLING_STATUS_PURPOSE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                          </Box>       
                          <Box sx={{ mt: 2.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t('Commission')} ... ${t('Number')}`}
                            </Typography>                                                       
                          </Box> 
                          <Box sx={{ mt: 0.25, width: '100%' }} key={` employee ${getValues().billing.businessEmployeeFullName}`}> 
                            <TextField sx={{width:'calc(50% - 8px)'}} id="businessEmployeeFullName" label={t('Employee')} 
                                {...register('billing.businessEmployeeFullName')} inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                />        
                              <TextField sx={{width:'calc(50% - 8px)'}} id="businessEmployeeManagerFullName" label={t('Manager')} 
                                {...register('billing.businessEmployeeManagerFullName')} inputProps={ { readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />                                              
                          </Box>
                          { openBillingFilter && <FormDialog open={openBillingFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Billing')} onCancel={()=> {}} 
                                onClose={()=> {setOpenBillingFilter(false);}} onOk={()=> {setOpenBillingFilter(false);}}  >
                                    <BasicTextFilterForm<IBilling> {...basicFilterBilling } />
                            </FormDialog> }            
                      </Stack>                        
                    </Grid>                    
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'storeId'} - key`} sx={{ mt: 1, width: '100%' }} >
                          <Controller 
                            name='storeId' control={control}                                     
                            render={ ({field: {onChange, value}}) => (
                              <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="status"
                                label={t('Default store or showroom for delivery')} inputProps={ {readOnly: false}} >
                                {stores && stores.map( 
                                  (x,idx) => <MenuItem key={x.id} value={x.id}>{x.name}</MenuItem> )
                                }
                              </TextField>
                            )}
                          />
                        </Box>
                        <Box key={`${'billingDeliveryDetails'} - key`} sx={{ mt: 0.25, width: '100%' }} >  
                          <ArrayFieldTableEx<IBillingDelivery,IBillingDeliveryDetail,'id'> 
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headBillingDeliveryDetailCells} rowsPathName={`billingDeliveryDetails`} 
                                title={t('Item(s) of delivery')} rowActionIcon={billingDeliveryDetailRowActionIcon}  
                                //onRowSelected={handleBillingDetailSelected}
                                
                                onRowDoubleClick={handleBillingDeliveryDetailDoubleClick}
                                                    
                                refAppend={refAppendBillingDeliveryDetails as MutableRefObject<(value: Partial<FieldArray<IBillingDelivery>> | Partial<FieldArray<IBillingDelivery>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateBillingDeliveryDetail as MutableRefObject<(index: number,value: Partial<FieldArray<IBillingDelivery>>) => void>}
                                refRemove={refRemoveBillingDeliveryDetail as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                  { toolTip: `${t('Add')}...${t('Article')}(s)`, onClickIcon: handleAddBillingDeliveryArticles ,icon: AddCircleIcon },
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />                         
                            { openBillingArticles && <FormDialog open={openBillingArticles} maxWidth='md'
                                  okText='' cancelText='' title={`${t('Article')}(s)...`} onCancel={()=> {}} 
                                  onClose={()=> {setOpenBillingArticles(false);}} onOk={()=> {setOpenBillingArticles(false);}}  >
                                  <Box sx={{ mt: 0.25, width: '100%' }} >
                                    <EnhancedTable<IBillingDetail> 
                                      rows={getValues().billing.billingDetails.filter(d => d.type === 'article')} 
                                      headCells={[
                                        {id:'productName', label : t('Product'),  display: true, type: 'string', width: 50}, 

                                        {id:'quantity', label : t('Quantity'),  display: true, type: 'numeric', width: 25}, 
                                        {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 25},                                             
                                      ]} 
                                      title={t(`Select delivered articles`)} objKey='id' 
                                      stateSelected={undefined} 
                                      onRowSelected={undefined} onRowDoubleClick={handleDoubleClickRowBillingDetail} rowCheckedMode='multiple'
                                      onRowCheckedSelectChange={undefined} order='desc' orderBy={'productName'}
                                      
                                      toolbarActions={[
                                                              
                                      ]}
                                    />
                                  </Box>
                              </FormDialog> }
                        </Box>                                                               
                      </Stack>
                    </Grid>                     
                </Grid>
            </Box>
        </FormProvider> 
  )
}

