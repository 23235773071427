
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BoltIcon from '@mui/icons-material/Bolt';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useCashControlService, { useBasicFilterCashControl } from './services/CashControl';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { ICashControl, IDashboardCashControl, defaultCashControl } from './models/CashControl';

import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from 'features/production/services/Person';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_CASH_OUT_PURPOSE, Enum_OPERATION_STATUS, 
        Enum_CASH_OUT_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker } from '@mui/x-date-pickers';
import { IPerson, PersonType } from 'features/production/models/Person';
import { useBasicFilterResource } from 'features/production/services/Resource';


import { IResourceType } from 'features/setup/models/ResourceType';
import { debounce, sum } from 'lodash';
import { GrSearch, GrClose } from 'react-icons/gr';
import BasicButtonList from 'components/ui/BasicButtonList';
import { BasicPersonForm } from 'features/production/BasicPersonForm';
import { IUserPaymentSource } from 'features/security/models/User';
import EnhancedTable from 'components/ui/EnhancedTable';
import { ITellerDayClosing } from './models/TellerDayClosing';
import { ICashOut } from './models/CashOut';

export const CashControlForm: FC<ICashControl> = (props: ICashControl = defaultCashControl) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg, userPaymentSources} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();

  const { createCashControl, updateCashControl, getNewCashControl, getCashControlDashboard } = useCashControlService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterExpense = useBasicFilterCashControl( 
    (event: React.MouseEvent<unknown>, row: ICashControl) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
 

  
  const methods = useForm<ICashControl>({defaultValues:defaultCashControl});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  const refExpenseDetailIndex = useRef<number>(0);

  // const watchBase64Picture = watch('base64Picture'); , , 
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });

  type SearchPersonUsage = 'supplier';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('supplier');
  
  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ICashControl>,Error,ICashControl>(
      _id>0?updateCashControl:createCashControl, {   
        onSuccess: (data: IResult<ICashControl>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('CashControl')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['CashControl',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ICashControl>(['CashControl', _id], () => retrieveEntity('CashControl',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'CashControl'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_CASH_OUT_PURPOSE, Enum_OPERATION_STATUS, Enum_CASH_OUT_STATUS_PURPOSE] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);
   
    const [openPaymentSources, setOpenPaymentSources] = useState<boolean>(false);
    const handlePaymentSourceDoubleClick = (event: React.MouseEvent<unknown>, row: IUserPaymentSource) => {
      const {paymentSourceCode, paymentSourceName} = row;

      buildNewCashControl(paymentSourceCode);
      setOpenPaymentSources(false);
    }


  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])

      useEffect( () => {        
        setCurrentFormNameAtom(`${t('CashControl')}`);
        setCurrentBasicTextFilterProps(basicFilterExpense);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
          setCurrentFormNameAtom(_id>0?`${t('CashControl')} - # ${_id} # -`: t('CashControl') );    
            
            if(_id > 0)
              retrieveData('CashControl',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
          async function _() {    
            if(_data && _data.id > 0) {
              reset(_data);
              
            }
          }
          
          _();
        
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultCashControl);    
        if(userPaymentSources.length === 1)
          await buildNewCashControl(userPaymentSources[0].paymentSourceCode);
        else 
          setOpenPaymentSources(true); 
      }

      const buildNewCashControl = async (paymentSourceCode: string) => {
        _setId(0);   
        const newTellerDay = await getNewCashControl(paymentSourceCode);        
        
        reset(newTellerDay); 
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('CashControl', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 

        if(data.observation.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if(data.initialAmount === 0 && data.availableAmount === 0 && data.cashOutAmount === 0 
              && data.tellerOperationAmount === 0 && data.remainingAmount === 0) {
          enqueueSnackbar( `${t('Invalid amount')} !!!`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
                      
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('CashControl', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['CashControl',_id]);        
      await retrieveData('CashControl',_id, refetch);        
      reset(_data);          
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              <Controller name='paymentSourceCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="showroomId"
                                    label={t('Payment source')} inputProps={ {readOnly: true}} focused >
                                    {userPaymentSources && userPaymentSources.map( 
                                        (x,idx) => <MenuItem key={x.paymentSourceCode} value={x.paymentSourceCode}>{x.paymentSourceName}</MenuItem> )
                                      }
                                  </TextField>
                                )} />                              
                              <Controller control={control}
                                name='operationDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Operation date')} 
                                    onChange={onChange}                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Available amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`availableAmount`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              />
                              <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange}                   
                                    value={new Date(value)} readOnly disableOpenPicker
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                              { openPaymentSources && <FormDialog open={openPaymentSources} maxWidth='sm'
                                    okText={t('OK')} cancelText='' title={t('Payment source')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenPaymentSources(false);}} onOk={()=> {setOpenPaymentSources(false);}}  >
                                    <Stack flexDirection='column'  >
                                      <Box sx={{ mt: 1, width: '100%' }} >
                                        <EnhancedTable<IUserPaymentSource> 
                                          rows={userPaymentSources} 
                                          headCells={[            
                                            //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                                            {id:'paymentSourceCode', label : t('Code'),  display: true, type: 'string', width: 35},
                                            {id:'paymentSourceName', label : t('Name'),  display: true, type: 'string', width: 65},                                            
                                          ]} 
                                          title={t(`Payment source`)} objKey='paymentSourceCode' 
                                          stateSelected={undefined} 
                                          onRowSelected={undefined} rowCheckedMode='single'
                                          onRowCheckedSelectChange={undefined} order='asc' orderBy='paymentSourceName'
                                          onRowDoubleClick={handlePaymentSourceDoubleClick} 
                                          rowActionIcon={undefined}
                                          toolbarActions={[
                                            // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                          ]}
                                        />
                                      </Box>
                                    </Stack>
                                  </FormDialog>  }
                          </Box>     
                                                                                                                                
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="observation" multiline rows={2}
                              label={t('Observation')} {...register('observation')} />                                                              
                          </Box>
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Amounts related to cash control'))} `}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Init. amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}     
                                                                                                 
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`initialAmount`}
                                control={control}
                              />
                                        
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Op. amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`tellerOperationAmount`}
                                control={control}
                              />   
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Out. amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`cashOutAmount`}
                                control={control}
                              />     
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Remaining amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`remainingAmount`}
                                control={control}
                              />    
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Available amount')}`} sx={{width:'calc(20% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`availableAmount`}
                                control={control}
                              />                                    
                          </Box>
                                                  
                        
                        <Box sx={{ mt: 0.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t(('Status of cash control'))} `}
                          </Typography>                                                       
                        </Box>          
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker readOnly                    
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                          <Controller name='status' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status')} inputProps={ {readOnly: true}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                          <Controller control={control}
                            name='statusDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Status date')} 
                                onChange={onChange} disableOpenPicker readOnly                    
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                              /> )}
                          />
                          <Controller name='statusPurpose' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_CASH_OUT_STATUS_PURPOSE ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                        </Box>
                            
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'teller day closing'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <EnhancedTable<ITellerDayClosing> rows={getValues().tellerDayClosings} 
                                headCells={[            
                                  {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 10, },
                                  {id:'closingNote', label : t('Note'),  display: true, type: 'string', width: 40},  
                                  {id:'closingWarning', label : t('Warning'),  display: true, type: 'string', width: 40}, 
                                  {id:'netAmount', label : t('Net amount'),  display: true, type: 'numeric', width: 20},
                                  // {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                                  // // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                                  
                                                    
                                  
                                ]} 
                                title={`${t('List of teller day closin')}`} objKey='id'
                                stateSelected={undefined} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                onRowDoubleClick={undefined} 
                                rowActionIcon={undefined}
                                toolbarActions={[
                                  // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                ]}
                              /> 
                        </Box> 
                        <Box key={`${'cash out'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <EnhancedTable<ICashOut> rows={getValues().cashOuts} 
                                headCells={[            
                                  {id:'id', label : t('Id'),  display: false, type: 'numeric', width: 10, },
                                  {id:'personFullNameFR', label : t('Beneficiary name'),  display: lg.includes('fr'), type: 'string', width: 40},  
                                  {id:'personFullNameEN', label : t('Beneficiary name'),  display: lg.includes('en'), type: 'string', width: 40},  
                                  {id:'description', label : t('Description'),  display: true, type: 'string', width: 40}, 
                                  {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 20},
                                  // {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                                  // // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                                  
                                                    
                                  
                                ]} 
                                title={`${t('List of cash outs')}`} objKey='id'
                                stateSelected={undefined} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                onRowDoubleClick={undefined} 
                                rowActionIcon={undefined}
                                toolbarActions={[
                                  // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                ]}
                              />
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

