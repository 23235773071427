
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ITarification, ITarificationSearch } from "../models/Tarification";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createTarification = async (tarification: ITarification)  =>       
        await (await axios.post('/api/configuration/tarification/create', tarification)).data;       
        
    const updateTarification = async (tarification: ITarification)  =>       
        await (await axios.post('/api/configuration/tarification/update', tarification)).data; 
    
    const getTarification = async (id  : number )  => {
      const {data} = (await axios.get(`/api/configuration/tarification/get-tarification/${id}`));
      return await data;
    }
   

    const getTarifications = async (criteria: ITarificationSearch) : Promise<ITarification[]> => {

      const { name, description} = criteria;

      const {data} = (await axios.get(`/api/configuration/tarification/get-tarifications?name=${name}&description=${description}`));
      return await data;
    }
    
      
    return {    
      createTarification,
      updateTarification,
      getTarification,
      getTarifications
      
    } 
}

export default _;

export interface IFilterTarificationOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [ITarification[], React.Dispatch<React.SetStateAction<ITarification[]>>],
}

const defaultFilterTarificationOption: IFilterTarificationOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterTarification = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ITarification) => void,
                                            filterOption?: IFilterTarificationOption  ) => {

  const { getTarifications } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterTarificationOption;



  const [headTarificationCells, setHeadTarificationCells]  = useState<HeadCell<ITarification>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'code', text: t('Code'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredTarifications, ] = useState<ITarification[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<ITarification[]> => {
    
    
    
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
       
    const arr = await getTarifications( { name,description} );
    
    return arr;
  }

  const objKey: keyof ITarification = 'id';

  return {
    title: t('Tarification'), headCells: headTarificationCells, objKey,
    filterElements, rows: filteredTarifications, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
