

import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useEffect, useState } from 'react';
import {IEmployee, IEmployeeFinancialSituation, IEmployeeSearch } from "../models/Employee";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import useSchoolYearService, { useBasicFilterSchoolYear } from 'features/setup/services/SchoolYear';

import { currentUserSessionAtom } from 'library/store';
import { isNumber, toNumber } from 'lodash';
import { useQuery } from 'react-query';
import { ISchoolYear } from 'features/setup/models/SchoolYear';
import { EventInput } from '@fullcalendar/core';


const _ = () => {

    const axios = useAxios(); 

    //const {schoolYears} = useRecoilValue(currentUserSessionAtom);    

    const createEmployee = async (employee: IEmployee)  =>       
        await (await axios.post('/api/setup/employee/create', employee)).data;       
        
    const updateEmployee = async (employee: IEmployee)  =>       
        await (await axios.post('/api/setup/employee/update', employee)).data; 
    
    const getEmployee = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/employee/get-employee/${id}`));
      return await data;
    }
   
    const getEmployees = async (criteria: IEmployeeSearch, pagination?: IPagination) : Promise<IEmployee[]> => {

      const {firstName, lastName } = criteria;const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/setup/employee/get-employees?firstName=${firstName}&lastName=${lastName}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    const getEmployeesBySubjectExamSchedule = async (schoolYearClassId: number, schoolYearTemplateSubjectExamScheduleId: number) : Promise<IEmployee[]> => {

      const {data} = (await axios.get(`/api/setup/employee/get-employees-by-subject-exam-schedule?schoolYearClassId=${schoolYearClassId}&schoolYearTemplateSubjectExamScheduleId=${schoolYearTemplateSubjectExamScheduleId}`));
      return await data;
    }

    const getActiveEmployeeTimeTable = async (employeeId: number) : 
        Promise<{weekStart: Date, weekEnd: Date, timetableEvents: EventInput[]}> => {
      const {data} = (await axios.get(`/api/setup/employee/get-active-employee-time-table?employeeId=${employeeId}`));
      return await data;
    }

    const getEmployeeFinancialSituation = async (employeeId: number) : Promise<IEmployeeFinancialSituation> => {
      const {data} = (await axios.get(`/api/setup/employee/get-employee-financial-situation?employeeId=${employeeId}`));
      return await data;
    }
      
      
    return {    
      createEmployee,
      updateEmployee,
      getEmployee,
      getEmployees,

      getEmployeesBySubjectExamSchedule,

      getActiveEmployeeTimeTable,
      getEmployeeFinancialSituation
    } 
}

export default _;

export interface IFilterEmployeeOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [IEmployee[], React.Dispatch<React.SetStateAction<IEmployee[]>>],
}

const defaultFilterEmployeeOption: IFilterEmployeeOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterEmployee = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEmployee) => void,
                    filterOption?: IFilterEmployeeOption  ) => {

  const { getEmployees } = _();

  const { t, i18n } = useTranslation();   
  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterEmployeeOption;

  
  const [headEmployeeCells, setHeadEmployeeCells]  = useState<HeadCell<IEmployee>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 5 },    

    {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 20 },
    {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 20},

    {id:'birthDate', label : t('Birth date'),  display: true, type: 'date', width: 10},

    {id:'registrationNumber', label : t('Regis. Number'),  display: true, type: 'string', width: 10},
    {id:'hiringDate', label : t('Hiring date'),  display: true, type: 'string', width: 10},
    {id:'status', label : t('Status'),  display: true, type: 'string', width: 15},
    {id:'statusDate', label : t('Status date'),  display: true, type: 'date', width: 10},
    
    
  ]); 
  
  const [filterElements,setFilterElements] = useState<ITextFilterElement[]>([         
        
    {name: 'firstName', text: t('First name'), value: ''},
    {name: 'lastName', text: t('Last name'), value: ''},            
  ]);
  
  const [filteredEmployees, ] = useState<IEmployee[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IEmployee[]> => {
        
    const firstName = filterElements.find( elt => elt.name === 'firstName')?.value || '';
    const lastName = filterElements.find( elt => elt.name === 'lastName')?.value || '';  
    
    const arr = await getEmployees( {firstName,lastName}, pagination );
   
    return arr;
  }

  const objKey: keyof IEmployee = 'id';

  return {
    title: t('Employee'), headCells: headEmployeeCells, objKey,
    filterElements, rows: filteredEmployees, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
