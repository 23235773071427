
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import usePaymentService, { useBasicFilterPayment } from './services/Payment';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IPayment, PaymentType, defaultPayment } from './models/Payment';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration, { Enum_BILLING_CATEGORY, IEnumerationItem } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import { DatePicker } from '@mui/x-date-pickers';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IPoint } from './models/Point';
import { IPaymentOperation } from './models/PaymentOperation';

export const PaymentForm: FC<IPayment> = (props: IPayment = defaultPayment) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();
  const {getEnumerationItemsByEnumerationCodes } = useEnumerationService();

  const { createPayment, updatePayment } = usePaymentService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterPayment = useBasicFilterPayment( 
    (event: React.MouseEvent<unknown>, row: IPayment) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );
  

  // const [openPaymentFilter, setOpenPaymentFilter] = useState(false);
  // const basicFilterPaymentParent = useBasicFilterPayment( 
  //     (event: React.MouseEvent<unknown>, row: IPayment) => {
  //         const {id, name, description} = row;

  //         setValue('parentPaymentName', name);
  //         setValue('parentPaymentId', id);
                           
  //         setOpenPaymentFilter(false);
  //     }
  // );

  
  const methods = useForm<IPayment>({defaultValues:defaultPayment});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchPaymentType = watch('type'); 
  // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IPayment>,Error,IPayment>(
      _id>0?updatePayment:createPayment, {   
        onSuccess: (data: IResult<IPayment>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Payment',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IPayment>(['Payment', _id], () => retrieveEntity('Payment',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const {data: enumItems} = useQuery<IEnumerationItem[]>(
        ['EnumerationItems', 'Payment'], () => 
          getEnumerationItemsByEnumerationCodes( [Enum_BILLING_CATEGORY ] ));

      const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

      const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
        
        setValueTabCoverage(newValue);       
      };

      
      const handlePaymentType = ( event: React.MouseEvent<HTMLElement>, newPaymentType: PaymentType ) => {

        if(newPaymentType === null) return;
        setValue('type', newPaymentType);
  
      };

      const [openEntityExpression, setOpenEntityExpression] = useState(false);
      const [currentExpression, setCurrentExpression] = useState(''); 
      const [currentEntityName, setCurrentEntityName] = useState(''); 
      const [currentReturnType, setCurrentReturnType] = useState(''); 

      type ExpressionType = 'coverage-obligatory' | 'coverage-exclusion' | 'coverage-baseAmount' | 
                'coverage-rawAmount' | 'coverage-tax' | 'coverage-defaultCapital' | 
                'accessories' | 'accessories-tax';
      const [currentExpressionType, setCurrentExpressionType] = useState<ExpressionType>('accessories');

           
            

      useEffect( () => {        
        setCurrentFormNameAtom(t('Payment'));
        setCurrentBasicTextFilterProps(basicFilterPayment);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Payment',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultPayment);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Payment', _id)) {
          setIsSaveLoading(false);
             return;
        }
          
  
          const data = getValues(); 
          if(data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
            setIsSaveLoading(false);
            return;
          mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Payment', _id);
      }

    
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <ToggleButtonGroup value={watchPaymentType} exclusive key={watchPaymentType} size="small" onChange={handlePaymentType} aria-label="text alignment" fullWidth >
                                <ToggleButton value="reimbursement" aria-label="centered">
                                  {t('Reimbursement')}
                                </ToggleButton>
                                <ToggleButton value="point" aria-label="centered">
                                  {t('Point')}
                                </ToggleButton>
                                <ToggleButton value="commission" aria-label="centered">
                                  {t('Commission')}
                                </ToggleButton>    
                                <ToggleButton value="delivery" aria-label="centered">
                                  {t('Delivery')}
                                </ToggleButton>          
                                <ToggleButton value="expense" aria-label="centered">
                                  {t('Expense')}
                                </ToggleButton>         
                                <ToggleButton value="bonus" aria-label="centered">
                                  {t('Bonus')}
                                </ToggleButton>     
                                <ToggleButton value="deposit" aria-label="centered">
                                  {t('Deposit')}
                                </ToggleButton> 
                              </ToggleButtonGroup>                                                             
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <TextField sx={{width:'calc(50% - 8px)'}} id="ownerName" label={t('Beneficiary')} {...register('ownerFullName')} />                                
                              <TextField sx={{width:'calc(50% - 8px)'}} id="bearerName" label={t('Bearer')} {...register('bearerFullName')} />                                
                            </Box>
                            <Box sx={{ mt: 1, width: '100%' }} >
                                {/* <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  /> */}
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(60% - 8px)'}} id="description" label={t('Description')} {...register('description')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <Controller control={control}
                                  name='issueDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Issue date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                    /> )}
                                />                                                        
                            </Box>   
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                    label={t('Total amount')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                                }}
                                name={`totalAmount`}
                                control={control}
                              />
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                    label={t('Accessories')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                                }}
                                name={`accessories`}
                                control={control}
                              />
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                    label={t('Tax')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                                }}
                                name={`tax`}
                                control={control}
                              />
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                    label={t('Amount')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                                }}
                                name={`amount`}
                                control={control}
                              />
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                    label={t('Remaining amount')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                                }}
                                name={`remainingAmount`}
                                control={control}
                              />
                            </Box>                            
                            { getValues().isCancelled && <Box sx={{ mt: 1, width: '100%' }} >
                              <FormControlLabel sx={{width:'calc(30% - 8px)'}}
                                    label={`${t('Cancelled ?')}`}
                                    control={
                                    <Controller
                                        name='isCancelled'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                <Controller control={control}
                                  name='cancellationDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Cancellation date')} 
                                      onChange={onChange}                     
                                      value={new Date(value)}
                                      slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller name='cancellationPurpose' control={control} 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="category"
                                        label={t('Purpose')} inputProps={ {readOnly: false}} focused >
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_BILLING_CATEGORY ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                />                              
                            </Box> }
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <EnhancedTable<IPaymentOperation> rows={getValues().paymentOperations} 
                                headCells={[            
                                  {id:'id', label : t('Id'),  display: true, type: 'numeric', width: 10, },
                                  {id:'reference', label : t('Unpaid amount'),  display: true, type: 'string', width: 40},  
                                  {id:'isCancelled', label : t('Cancelled ?'),  display: true, type: 'boolean', width: 20}, 
                                  {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 30},
                                  // {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                                  // // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                                  
                                                    
                                  
                                ]} 
                                title={`${t('List of operations')}`} objKey='id'
                                stateSelected={undefined} 
                                onRowSelected={undefined} rowCheckedMode='single'
                                onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                onRowDoubleClick={undefined} 
                                rowActionIcon={undefined}
                                toolbarActions={[
                                  // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                ]}
                              />                         
                                
                            </Box>
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                      { getValues().type === 'point' && <Box sx={{ mt: 1, width: '100%' }} >
                          <EnhancedTable<IPoint> rows={getValues().points} 
                            headCells={[            
                              //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                              {id:'count', label : '#',  display: true, type: 'numeric', width: 50},
                              
                              {id:'value', label : t('Value'),  display: true, type: 'numeric', width: 50},
                              // {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                              // // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                              // {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},                  
                              
                            ]} 
                            title={`${t('Points')}`} objKey='id'
                            stateSelected={undefined} 
                            onRowSelected={undefined} rowCheckedMode='single'
                            onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                            onRowDoubleClick={undefined} 
                            rowActionIcon={undefined}
                            toolbarActions={[
                              // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                            ]}
                          />                         
                            
                        </Box> }
                        <Box sx={{ mt: 1, width: '100%' }} >
                          
                        </Box>
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

