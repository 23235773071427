
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {ICashOut, ICashOutSearch } from "../models/CashOut";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';

import { isNumber, toNumber } from 'lodash';

import { currentUserSessionAtom } from 'library/store';
import { addMonths, addDays } from 'date-fns';

const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createCashOut = async (cashOut: ICashOut)  =>       
        await (await axios.post('/api/finance/cashOut/create', cashOut)).data;       
        
    const updateCashOut = async (cashOut: ICashOut)  =>       
        await (await axios.post('/api/finance/cashOut/update', cashOut)).data; 
    
    const getCashOut = async (id  : number )  => {
      const {data} = (await axios.get(`/api/finance/cashOut/get-cashOut/${id}`));
      return await data;
    }
   

    const getCashOuts = async (criteria: ICashOutSearch, pagination?: IPagination) : Promise<ICashOut[]> => {
      
      const {startDate, endDate, personName, personPortable, description} = criteria;

      const sStartDate = encodeURIComponent(startDate.toISOString());
      const sEndDate = encodeURIComponent(endDate.toISOString());

      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;

      const {data} = (await axios.get(`/api/finance/cashOut/get-cashOuts?personName=${personName}&description=${description}` +
        `&personPortable=${personPortable}&sStartDate=${sStartDate}&sEndDate=${sEndDate}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }

    
    // const getCashOutDashboard = async (cashOutId: number, paymentSourceCode: string) : Promise<IDashboardCashOut> => {
    //   const {data} = (await axios.get(`/api/finance/cashOut/get-cashOut-dashboard?cashOutId=${cashOutId}&paymentSourceCode=${paymentSourceCode}`));
    //   return await data;
    // }
      
    return {    
      createCashOut,
      updateCashOut,
      getCashOut,
      getCashOuts,
      
      //getCashOutDashboard
     
    } 
}

export default _;

export interface IFilterCashOutOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [number[], React.Dispatch<React.SetStateAction<number[]>>],
  stateFiltered?: [ICashOut[], React.Dispatch<React.SetStateAction<ICashOut[]>>],
}

const defaultFilterProductOption: IFilterCashOutOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterCashOut = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: ICashOut) => void,
                                            filterOption?: IFilterCashOutOption  ) => {

  const { getCashOuts } = _();

  const { t, i18n } = useTranslation();   

  const {language: lg, applicationSetup } = useRecoilValue(currentUserSessionAtom);

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterProductOption;

  const [headCashOutCells, setHeadCashOutCells]  = useState<HeadCell<ICashOut>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    {id:'operationDate', label : t('Operation date'),  display: true, type: 'date', },
    {id:'issueDate', label : t('Issue date'),  display: true, type: 'date', },
    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', },
    {id:'personFullNameFR', label : t('Person'),  display: lg.includes('fr'), type: 'string', },
    {id:'personFullNameEN', label : t('Person'),  display: lg.includes('en'), type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    
  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
   
    {name: 'startDate', text: t('Start date'), dataType: 'date' ,  value: '', dateValue: addMonths(new Date(), -1)},
    {name: 'endDate', text: t('End date'), dataType: 'date'  , value: '', dateValue: new Date()},

    {name: 'personName', text: t('Name'), value: ''},
    {name: 'personPortable', text: t('Portable'), value: ''},
    {name: 'description', text: t('Description'), value: ''},
  ]);    

  const [filteredCashOuts, ] = useState<ICashOut[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<ICashOut[]> => {
    
    const startDate = filterElements.find( elt => elt.name === 'startDate')?.dateValue || new Date();
    const endDate = filterElements.find( elt => elt.name === 'endDate')?.dateValue || new Date();

    const personName = filterElements.find( elt => elt.name === 'personName')?.value || '';
    const personPortable = filterElements.find( elt => elt.name === 'personPortable')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
           
    const arr = await getCashOuts( {startDate, endDate,personName,personPortable,description }, pagination );
    
    return arr;
  }

  const objKey: keyof ICashOut = 'id';

  return {
    title: t('Cash out'), headCells: headCashOutCells, objKey,
    filterElements, rows: filteredCashOuts, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
