
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IPackaging, IPackagingSearch } from "../models/Packaging";
import { useTranslation  } from 'react-i18next';
import { IPagination, ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';
//import { IPolicyRisk, IPolicyRiskCoverage } from 'features/packagingion/models/Policy';


const _ = () => {

    const axios = useAxios(); 

    const createPackaging = async (packaging: IPackaging)  =>       
        await (await axios.post('/api/setup/packaging/create', packaging)).data;       
        
    const updatePackaging = async (packaging: IPackaging)  =>       
        await (await axios.post('/api/setup/packaging/update', packaging)).data; 
    
    const getPackaging = async (id  : number )  => {
      const {data} = (await axios.get(`/api/setup/packaging/get-packaging/${id}`));
      return await data;
    }
   

    const getPackagings = async (criteria: IPackagingSearch, pagination?: IPagination) : Promise<IPackaging[]> => {

      const {name, description} = criteria;
      const pageSize = pagination?.pageSize ?? 50;
      const pageNumber = pagination?.pageNumber ?? 1;
      
      const {data} = (await axios.get(`/api/setup/packaging/get-packagings?name=${name}&description=${description}&pageSize=${pageSize}&pageNumber=${pageNumber}`));
      return await data;
    }
    
   
    return {    
      createPackaging,
      updatePackaging,
      getPackaging,
      getPackagings,

      
    } 
}

export default _;

export interface IFilterPackagingOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IPackaging[], React.Dispatch<React.SetStateAction<IPackaging[]>>],
}

const defaultFilterPackagingOption: IFilterPackagingOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterPackaging = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IPackaging) => void,
                                            filterOption?: IFilterPackagingOption  ) => {

  const { getPackagings } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterPackagingOption;



  const [headPackagingCells, setHeadPackagingCells]  = useState<HeadCell<IPackaging>[]>([
    {id:'id', label : t('Id'),  display: true, type: 'numeric', },
    
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
    
      {name: 'name', text: t('Name'), value: ''},
      {name: 'description', text: t('Description'), value: ''},
      
    ]);    

  const [filteredPackagings, ] = useState<IPackaging[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[], pagination?: IPagination) : Promise<IPackaging[]> => {
   
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
    
    const arr = await getPackagings( { name,description}, pagination );
    
    return arr;
  }

  const objKey: keyof IPackaging = 'id';

  return {
    title: t('Packaging'), headCells: headPackagingCells, objKey,
    filterElements, rows: filteredPackagings, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
