import { IPerson, PersonType, defaultPerson } from "features/production/models/Person";

export type CashOutType = 'base' | 'payment';

export interface ICashOut {
    id: number,

    type: CashOutType,
    
    paymentSourceCode: string,

    personId: number,
    cashControlId: number,

    amount: number,
    operationDate: Date,
    issueDate: Date,    
    
    purpose: string,
    description: string,

    paymentId: number,
    paymentDescription: string,
    paymentTotalAmount: number,
    paymentOwnerFullName: string,

    person: IPerson,
    personFullNameFR: string,
    personFullNameEN: string,

    status: string,
    statusDate: Date
    statusPurpose: string,    
  }


  export const defaultCashOut : ICashOut = {
    id: 0,
    type: 'base',
    paymentSourceCode: '',

    personId: 0,
    cashControlId: 0,

    amount: 0,
    operationDate: new Date(),
    issueDate: new Date(),    
    
    purpose: '',
    description: '',

    paymentId: 0,
    paymentDescription: '',
    paymentTotalAmount: 0,
    paymentOwnerFullName: '',

    person: defaultPerson,
    personFullNameFR: '',
    personFullNameEN: '',

    status: '95',
    statusDate: new Date(),
    statusPurpose: ''
  }

  export interface ICashOutSearch {
    startDate: Date,
    endDate: Date,

    personName: string,
    personPortable: string,
    description: string,
  }

 
  

