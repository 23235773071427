
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { DatePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useBillingService from 'features/finance/services/Billing';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


import {IAmount, IBillingDetail, IFinanceDashboard, defaultDashboardDate, defaultFinanceDashboard } from 'features/finance/models/Billing';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBilling } from 'features/finance/models/Billing';



export const FinanceDashboardForm: FC<IFinanceDashboard> = (props: IFinanceDashboard = defaultFinanceDashboard) => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const {getFinanceDashboard} = useBillingService();
  const [dashboard, setDashboard] = useState<IFinanceDashboard>(defaultFinanceDashboard);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<IDashboardMonthClass[]>([]);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<IDashboardMonthClass[]>([]);

  // const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  // const basicFilterSchoolYear = useBasicFilterSchoolYear( 
  //     async (event: React.MouseEvent<unknown>, row: IDashboardMonth) => {
  //         const {id, name } = row;

  //         const dash = await getSchoolYearDashboard(id);
  //         setSchoolYearName(name);

  //         setDashboard(dash);
  //         setOpenSchoolYearFilter(false);
  //     }
  // );


  const handleClickSelectSchoolYear = (event: any) => {
    //setOpenSchoolYearFilter(true);
  }


  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  const [date, setDate] = React.useState<Date>(new Date());

  useEffect(() => {
    async function _() {
             
      const dash = await getFinanceDashboard(date.getFullYear(), date.getMonth()+1 ); // getMonth of js give the month [0-11]
            
      setDashboard(dash);  
    }
    _();
  }, [date]);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The month of')} :::::`}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                views={["year", "month"]}
                slotProps={{ textField: { sx: {width:isSm?'calc(35% - 8px)':'calc(15% - 8px)'}  }} }
                //renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(35% - 8px)':'calc(15% - 8px)'}} />}
                onChange={(newValue) => setDate(newValue||new Date())}                    
                value={date}                      
              /> 
            
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={12} >
          <Box sx={{ mt: 0.25, width: '100%' }} > 
            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                  sx={{...typographyGroupBoxStyling}}>
              {`${t(('Year'))} `}
            </Typography>                                                       
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Turnover')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.billingAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unpaid amount')} `} paletteColor={theme.palette['warning']} 
                  iconName='IoSendSharp' total={dashboard.billingUnpaidAmount}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount paid')} ==> ${t('Total')}`} paletteColor={theme.palette['success']} 
                   iconName='IoSendSharp' total={dashboard.tellerOperationAmount}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Expenses')} `} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.expenseAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount to pay')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.paymentAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount to pay')} (${t('Unpaid')})`} paletteColor={theme.palette['warning']} 
                  iconName='IoSendSharp' total={dashboard.paymentUnpaidAmount}  />
        </Grid> 
        <Grid item xs={12} >
          <Box sx={{ mt: 0.25, width: '100%' }} > 
            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                  sx={{...typographyGroupBoxStyling}}>
              {`${t(('Month'))} `}
            </Typography>                                                       
          </Box>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Turnover')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.billingAmountMonth} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unpaid amount')} `} paletteColor={theme.palette['warning']} 
                  iconName='IoSendSharp' total={dashboard.billingUnpaidAmountMonth}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount paid')} ==> ${t('Total')}`} paletteColor={theme.palette['success']} 
                   iconName='IoSendSharp' total={dashboard.tellerOperationAmountMonth}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Expenses')} `} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.expenseAmountMonth} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount to pay')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.paymentAmountMonth} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount to pay')} (${t('Unpaid')})`} paletteColor={theme.palette['warning']} 
                  iconName='IoSendSharp' total={dashboard.paymentUnpaidAmountMonth}  />
        </Grid>
        <Grid item xs={12} md={4} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IAmount> 
                  rows={dashboard.billingsByProductType} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'expression', width: 60, 
                        getExpressionNode: (row: IAmount, cellId: keyof IAmount) => {
                          if(row.type === 'service') return t('Service');
                          if(row.type === 'article') return t('Article');
                          if(row.type === 'packaging') return t('Packaging');
                          if(row.type === 'formation') return t('Formation');
                          if(row.type === 'maintenance') return t('Maintenance');
                          if(row.type === 'rental') return t('Rental');
                          if(row.type === 'contract') return t('Contract');
                          if(row.type === 'fee') return t('Fee');
                          if(row.type === 'consumption') return t('Consumption');

                          return row.type;
                        } },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Turnover')} - ${t('Product type')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.billingsByLineOfBusiness} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Turnover')} - ${t('Line of business')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                />
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
                <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                    rows={dashboard.expensesByType} 
                    headCells={[            
                      
                      {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                      {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                      {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                      
                    ]} 
                    title={`${t('Expenses by type')}`} objKey='type' 
                    stateSelected={undefined} 
                    onRowSelected={undefined} rowCheckedMode='single'
                    onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                    onRowDoubleClick={undefined} 
                    rowActionIcon={undefined}
                    toolbarActions={[
                                        
                    ]}
                  />
              </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
                <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                    rows={dashboard.paymentsByType} 
                    headCells={[            
                      
                      {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                      {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                      {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                      
                    ]} 
                    title={`${t('Payments by type')}`} objKey='type' 
                    stateSelected={undefined} 
                    onRowSelected={undefined} rowCheckedMode='single'
                    onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                    onRowDoubleClick={undefined} 
                    rowActionIcon={undefined}
                    toolbarActions={[
                                        
                    ]}
                  />
              </Box>
            
          </Stack>
        </Grid>
      </Grid>
      
    </Box>
  )
}
