
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject, Fragment}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';

import { IExtensionType, defaultExtensionType } from './models/ExtensionType';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useExtensionTypeService, { useBasicFilterExtensionType } from './services/ExtensionType';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddCircleIcon from '@mui/icons-material/AddCircle';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from './services/Enumeration';
import IEnumeration, {defaultEnumeration, IEnumerationEx, IEnumerationItem, IEnumerationItemEx} from './models/Enumeration';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import ExtensionValueField from 'components/ui/ExtensionValueField';

export const EnumerationForm: FC<IEnumeration> = (props: IEnumeration = defaultEnumeration) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createEnumeration, updateEnumeration, getEnumerationItemsByEnumeration, getAsOptions } = useEnumerationService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
    (event: React.MouseEvent<unknown>, row: IEnumeration) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  const [openExtensionTypeFilter, setOpenExtensionTypeFilter] = useState(false);
  const basicFilterExtensionType = useBasicFilterExtensionType( 
      (event: React.MouseEvent<unknown>, row: IExtensionType) => {
          const {id, name, description, type} = row;

          const minId = Math.min( ...getValues().enumerationExs.map( eEx => eEx.id) ) - 1;
          (refAppendEnumerationExs.current??emptyFunc)({ 
              id: minId, name: '', description: '',
            extensionTypeId: id, extensionTypeName: name, extensionTypeDescription: description,
            extensionTypeType: type,

            enumerationItemExs: [] }); 
                           
          setOpenExtensionTypeFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumerationParent = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          setValue('parentEnumerationName', name);
          setValue('parentEnumerationId', id);
                           
          setOpenEnumerationFilter(false);
      }
  );

  
  const methods = useForm<IEnumeration>({defaultValues:defaultEnumeration});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchParentEnumerationId = watch('parentEnumerationId');
  const watchCurrentParentEnumerationItemCode = watch('currentParentEnumerationItemCode');

  const [parentEnumerationItems, setParentEnumerationItems] = useState<IEnumerationItem[]>([]);

  const [enumerationItemIndex, setEnumerationItemIndex] = useState<number>(-1);

  //const watchType = watch('type');

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IEnumeration>,Error,IEnumeration>(
      _id>0?updateEnumeration:createEnumeration, {   
        onSuccess: (data: IResult<IEnumeration>) => {
          enqueueSnackbar( t('Operation done !!!'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Enumeration',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IEnumeration>(['Enumeration', _id], () => retrieveEntity('Enumeration',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      const handleClickSearchEnumeration = (event: any) => {
        setOpenEnumerationFilter(true);
      }    

      const enumerationExRowActionIcon = ( enumerationEx: IEnumerationEx) : ActionIconTableRow<IEnumeration,IEnumerationEx> => {  
        const res: ActionIconTableRow<IEnumeration,IEnumerationEx> = {
          toolTip: 'remove',
          icon: RemoveCircleIcon,
          hasAction: (index: number,row: IEnumerationEx) => true, 
          isActionExecuting: true,
          onRowClickIcon: (event : any, index: number, row: IEnumerationEx) => {           
            
            (refRemoveEnumerationEx.current??emptyFunc)(index);            
          }
        }
        return res;
      }

      const cellEditableEx = (row: IEnumerationEx, cellId: keyof IEnumerationEx) => {
        return true;
     }
     
    const [headEnumerationExCells, setHeadEnumerationExCells]  = useState<HeadCell<IEnumerationEx>[]>([]);
    useEffect(() => {
      setHeadEnumerationExCells([            
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 35 , isEditable: cellEditableEx},
        {id:'description', label : t('Description'),  display: true, type: 'string', width: 40 , isEditable: cellEditableEx},
        {id:'extensionTypeName', label : t('Type'),  display: true, type: 'string', width: 25 ,} ,      
        //{id:'extensionTypeDescription', label : t('Type description'),  display: true, type: 'string',} ,  
        //{id:'extensionTypeType', label : t('Type'),  display: true, type: 'string',} ,  
      ]  )
    }, [t,i18n])

    const refAppendEnumerationExs = useRef<(value: Partial<FieldArray<IEnumeration>> | Partial<FieldArray<IEnumeration>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateEnumerationEx = useRef<(index: number,value: Partial<FieldArray<IEnumeration>> ) => void>(null);
    const refRemoveEnumerationEx = useRef<(index: number ) => void>(null);

    const handleAddEnumerationExs = (event: any) => {
      setOpenExtensionTypeFilter(true);
    }


    const enumerationItemRowActionIcon = ( enumerationItem: IEnumerationItem) : ActionIconTableRow<IEnumeration,IEnumerationItem> => {  
      const res: ActionIconTableRow<IEnumeration,IEnumerationItem> = {
        toolTip: 'remove',
        icon: RemoveCircleIcon,
        hasAction: (index: number,row: IEnumerationItem) => true, 
        isActionExecuting: true,
        onRowClickIcon: (event : any, index: number, row: IEnumerationItem) => {           
          
          (refRemoveEnumerationItem.current??emptyFunc)(index);            
        }
      }
      return res;
    }

    const cellEditableItem = (row: IEnumerationItem, cellId: keyof IEnumerationItem) => {
      return true;
    }
    const [headEnumerationItemCells, setHeadEnumerationItemCells]  = useState<HeadCell<IEnumerationItem>[]>([]);
    useEffect(() => {
      setHeadEnumerationItemCells([            
        {id:'code', label : t('Code'),  display: true, type: 'string', width: 15 ,isEditable: cellEditableItem},
        {id:'name', label : t('Name'),  display: true, type: 'string', width: 35 ,isEditable: cellEditableItem},
        {id:'description', label : t('Description'),  display: true, width: 50 ,type: 'string', isEditable: cellEditableItem},
         
      ]  )
    }, [t,i18n])
    const refAppendEnumerationItems = useRef<(value: Partial<FieldArray<IEnumeration>> | Partial<FieldArray<IEnumeration>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateEnumerationItem = useRef<(index: number,value: Partial<FieldArray<IEnumeration>> ) => void>(null);
    const refRemoveEnumerationItem = useRef<(index: number ) => void>(null);

    const handleAddEnumerationItems = (event: any) => {
      (refAppendEnumerationItems.current??emptyFunc)({ id: 0, code: '',  name: '', description: '',
            parentEnumerationItemCode: '', enumerationItemExs: [] }); 
    }

    
    const handleEnumerationItemSelected = (event: React.MouseEvent<unknown>,index: number,row: IEnumerationItem) => {      
      enumerationItemSelected(index);
    }

    const enumerationItemSelected = (index: number) => {
      if(enumerationItemIndex === index) return;
      
      setEnumerationItemIndex(index);

      const enumerationExs = getValues().enumerationExs;
      const enumerationItem = getValues().enumerationItems[index];

      setValue("currentParentEnumerationItemCode", enumerationItem.parentEnumerationItemCode);
      
      (refUpdateEnumerationItem.current ??emptyFunc)(index, {
        ...enumerationItem, 
        enumerationItemExs: enumerationExs.map( ({id, name, extensionTypeType, extensionEnumerationItems}, idx) => {

          const enumItemEx = enumerationItem.enumerationItemExs.find(x => x.enumerationExId === id);

          return {
            id: enumItemEx?.id ??0 , 
            enumerationItemId: enumItemEx?.enumerationItemId ?? 0, 
            enumerationExId: id,
            enumerationExName: name, enumerationExType: extensionTypeType,
            enumerationExOptions: getAsOptions(extensionEnumerationItems, ''), 
            extensionValueId: enumItemEx?.extensionValueId ?? 0,
            value: enumItemEx?.value ?? ''
          }
        })
      } );

      
    }

          

//       const handleClickSearchEntity = (event: any) => {
//         setOpenEntityFilter(true);
//         }

//         const handleOkBasicTextFilterEntityForm = () => {
//           setOpenEntityFilter(false);
//       }

//     const handleClickSearchEnumeration = (event: any) => {
//       setOpenEnumerationFilter(true);
//         }

// const handleOkBasicTextFilterEnumerationForm = () => {
//   setOpenEnumerationFilter(false);
//       }

      useEffect( () => {        
        setCurrentFormNameAtom(t('Codification'));
        setCurrentBasicTextFilterProps(basicFilterEnumeration);
      }, []);    


      useEffect( () => {        
        async function loadParentEnumerationItems() {          
          const arr = watchParentEnumerationId > 0 ? await getEnumerationItemsByEnumeration(watchParentEnumerationId) : [];          
          setParentEnumerationItems([...arr]);  
        }  
        loadParentEnumerationItems();

      }, [watchParentEnumerationId] );


    
      useEffect( () => {        
        
        if(enumerationItemIndex < 0 || getValues().enumerationItems.length <= enumerationItemIndex) return;

        const enumerationItem = getValues().enumerationItems[enumerationItemIndex];
        
        (refUpdateEnumerationItem.current??emptyFunc)( enumerationItemIndex,  { ...enumerationItem,
            parentEnumerationItemCode: watchCurrentParentEnumerationItemCode }); 

      }, [watchCurrentParentEnumerationItemCode] ); 

     /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Enumeration',_id, refetch);  
          }, [_id] );
    
    
      useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
          
            if(_data.enumerationItems.length > 0) {
              enumerationItemSelected(0);   
            }                   
          }
          //setEnumerationItemIndex( ( (_data?.enumerationItems || []).length > 0)?0 : -1 );
        }, [_data, reset]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {   
        _setId(0);          
        
        reset(defaultEnumeration);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {      
        if(!checkEntitySaveAuthorization('Enumeration', _id)) {
          setIsSaveLoading(false);         
          return;
        }

          const data = getValues(); 
          if(data.name.trim() === '' || data.description.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }
          
          mutate({...data, childEnumerations:[]});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Enumeration', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />

                                <TextField sx={{width:'calc(20% - 8px)'}} id="id" label="Id" {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(80% - 8px)'}} id="code" label={t('Code')} {...register('code')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                                 
                            </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                           </Box>
                        </Stack>                        
                    </Grid>     
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="description" label={t('Description')} {...register('description')} />
                           </Box>
                        </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={3} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='row'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                
                                <TextField sx={{width:'calc(100% - 8px)'}} id="parentEnumerationName" label={t('Parent codification')} 
                                {...register('parentEnumerationName')} 
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">                                            
                                      <IconButton color="primary" onClick={handleClickSearchEnumeration}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                               
                                  </InputAdornment>
                                )
                              }}/>
                              { openEnumerationFilter && <FormDialog open={openEnumerationFilter} maxWidth='md'
                                    okText={t('OK')} cancelText='' title={t('Core business filter')} onCancel={()=> {}} 
                                    onClose={()=> {setOpenEnumerationFilter(false);}} onOk={()=> {setOpenEnumerationFilter(false);}}  >
                                    <BasicTextFilterForm<IEnumeration> {...basicFilterEnumerationParent} />
                                  </FormDialog>  }
                           </Box>
                        </Stack>                        
                    </Grid>      
                    <Grid item xs={12} md={12} lg={4} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IEnumeration,IEnumerationEx,'id'> 
                                key={`EnumerationEx -`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headEnumerationExCells} rowsPathName='enumerationExs' 
                                title={t(`Codification Ex.`)} rowActionIcon={enumerationExRowActionIcon}  
                                //onRowSelected={handleRoleEntitySelected}
                                                    
                                refAppend={refAppendEnumerationExs as MutableRefObject<(value: Partial<FieldArray<IEnumeration>> | Partial<FieldArray<IEnumeration>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateEnumerationEx as MutableRefObject<(index: number,value: Partial<FieldArray<IEnumeration>>) => void>}
                                refRemove={refRemoveEnumerationEx as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}
                                
                                toolbarActions={[
                                    { toolTip: `${t('Add')}...`, onClickIcon: handleAddEnumerationExs ,icon: AddCircleIcon, },  
                                                          
                                ]}
                            />
                            { openExtensionTypeFilter && <FormDialog open={openExtensionTypeFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Extension type')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenExtensionTypeFilter(false);}} onOk={()=> {setOpenExtensionTypeFilter(false);}}  >
                                      <BasicTextFilterForm<IExtensionType> {...basicFilterExtensionType } />
                              </FormDialog> }
                        </Box>
                      </Stack>   
                    </Grid>      
                    <Grid item xs={12} md={12} lg={5} component={Paper} >
                      <Stack flexDirection='column'>
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <ArrayFieldTableEx<IEnumeration,IEnumerationItem,'id'> 
                                key={`EnumerationItem -`}
                                mainObject={getValues()} fieldKey='id' 
                                headCells={headEnumerationItemCells} rowsPathName='enumerationItems' 
                                title={t(`Items list`)} rowActionIcon={enumerationItemRowActionIcon}  
                                onRowSelected={handleEnumerationItemSelected}
                                                    
                                refAppend={refAppendEnumerationItems as MutableRefObject<(value: Partial<FieldArray<IEnumeration>> | Partial<FieldArray<IEnumeration>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateEnumerationItem as MutableRefObject<(index: number,value: Partial<FieldArray<IEnumeration>>) => void>}
                                refRemove={refRemoveEnumerationItem as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRequestDataItems, setSelectedRequestDataItems]}
                                
                                toolbarActions={[
                                    { toolTip: `${t('Print')}...`, onClickIcon: handleAddEnumerationItems ,icon: AddCircleIcon, },  
                                                          
                                ]}
                            />                            
                        </Box>
                      </Stack>   
                    </Grid> 
                    <Grid item xs={12} md={12} lg={3} component={Paper} >
                      <Stack flexDirection='column'>
                        {
                             getValues().enumerationItems.map( (enumerationItem, index) => { 
                              return (index === enumerationItemIndex) && (
                              <Fragment key={`key-${enumerationItem.name} - ${enumerationItemIndex}`}>
                                <Box sx={{ mt: 1, width: '100%' }} > 
                                  <Typography  variant="h6" id="tableTitle" color="primary" noWrap >
                                    {`${t(('Item'))} - ${(enumerationItemIndex+1)} - ${enumerationItem.name}`}
                                  </Typography>                                                       
                                </Box>
                                { watchParentEnumerationId>0 && <Box sx={{ mt: 1, width: '100%' }} >
                                    <Controller 
                                    
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="dbmsType"
                                        label={t('Parent item')} inputProps={ {readOnly: false}}>
                                        {parentEnumerationItems.map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                      )} 
                                      name={`currentParentEnumerationItemCode`}
                                      control={control}
                                      />
                                </Box>}                                
                                { enumerationItem.enumerationItemExs.map( (enumerationItemEx, idx) => { 
                                  return (
                                  <Box sx={{ mt: 1, width: '100%' }} key={`key-${enumerationItem.name} - ${idx}`} >
                                    <ExtensionValueField 
                                        name={`enumerationItems.${index}.enumerationItemExs.${idx}.value`}
                                        label={enumerationItemEx.enumerationExName}
                                        type={enumerationItemEx.enumerationExType}
                                        options={enumerationItemEx.enumerationExOptions}
                                        itemsPerRow={1}
                                     />                                                              
                                  </Box>)
                                  } )
                                }
                              </Fragment> )})
                        }
                      </Stack>   
                    </Grid>      
                </Grid>
            </Box>
        </FormProvider> 
  )
}

