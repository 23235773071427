
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import { DatePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import useExpenseService from 'features/production/services/Expense';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBilling, IBillingDetail } from 'features/finance/models/Billing';
import { IDashboardExpense, defaultDashboardExpense } from './models/Expense';
import { IExpenseDetail } from 'features/production/models/Expense';



export const ExpenseDashboardForm: FC<IDashboardExpense> = (props: IDashboardExpense = defaultDashboardExpense) => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const {getExpenseDashboard} = useExpenseService();
  const [dashboard, setDashboard] = useState<IDashboardExpense>(defaultDashboardExpense);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<ISchoolYearClass[]>([]);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<ISchoolYearClass[]>([]);

  // const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  // const basicFilterSchoolYear = useBasicFilterSchoolYear( 
  //     async (event: React.MouseEvent<unknown>, row: ISchoolYear) => {
  //         const {id, name } = row;

  //         const dash = await getResourceDashboard(id);
  //         setSchoolYearName(name);

  //         setDashboard(dash);
  //         setOpenSchoolYearFilter(false);
  //     }
  // );


  // const handleClickSelectSchoolYear = (event: any) => {
  //   setOpenSchoolYearFilter(true);
  // }


  useEffect( () => {        
    setCurrentFormName(` ${t('Dashboard')} -  ${t('Expenses')} `);
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  const [date, setDate] = React.useState<Date>(new Date());

  useEffect(() => {
    async function _() {
             
      const dash = await getExpenseDashboard(date.getFullYear(), date.getMonth()+1);  // getMonth of js give the month [0-11]
      
      setDashboard(dash);     
    }
    _();
  }, [date]);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The month of')} :::::`}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                views={["year", "month"]}
                renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(35% - 8px)':'calc(15% - 8px)'}} />}
                onChange={(newValue) => setDate(newValue||new Date())}                    
                value={date}                      
              /> 
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Expense')} - ${t('Year')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.yearExpenseAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Expense')} - ${t('Year')} (${t('Paid')}) `} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.yearExpenseAmountPaid}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Expense')} - ${t('Year')} (${t('Unpaid')}) `} paletteColor={theme.palette['warning']} 
                   iconName='IoSendSharp' total={dashboard.yearExpenseUnpaidAmount}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Expense')} - ${t('Year')} (${t('Cancelled')})`} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.yearExpenseCancelledAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Expense')} - ${t('Year')} (${t('Unvalidated')})`} paletteColor={theme.palette['warning']} 
                 iconName='IoSendSharp' total={dashboard.yearExpenseUnvalidatedAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Expense')} - ${t('Month')} `} paletteColor={theme.palette['primary']} 
                  iconName='IoSendSharp' total={dashboard.monthExpenseAmount}  />
        </Grid> 
        <Grid item xs={12} md={4} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.expensesByType} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses by type')} - ${t('Validated')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.unvalidatedExpensesByType} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses by type')} - ${t('Not validated')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.cancelledExpensesByType} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses by type')} - ${t('Cancelled')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.unvalidatedCancelledExpensesByType} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses by type')} - ${t('Not validated cancelled')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.productionExpenses} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses')} - ${t('Production')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.exploitationExpenses} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses')} - ${t('Exploitation')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.otherExpenses} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses')} - ${t('Other')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={4} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.resourceExpenses} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses')} - ${t('Resource')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
               <EnhancedTable<{type: string, amount: number, amountMonth: number}> 
                  rows={dashboard.maintenanceExpenses} 
                  headCells={[            
                    
                    {id:'type', label : t('Type'),  display: true, type: 'string', width: 60 },

                    {id:'amount', label : t('Year'),  display: true, type: 'numeric', width: 20},
                    {id:'amountMonth', label : t('Month'),  display: true, type: 'numeric', width: 20},                                                        
                    
                  ]} 
                  title={`${t('Expenses')} - ${t('Maintenance')}`} objKey='type' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='type'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                                      
                  ]}
                /> 
            </Box>
            
          </Stack>
        </Grid>
      </Grid>
      
    </Box>
  )
}
