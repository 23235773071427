
import {DataType} from './ExtensionType';

export const Enum_REQUESTDATA_STATUS = 'RequestData_Status';

export const Enum_FTP_PROTOCOL = 'Ftp_Protocol'; 
export const Enum_TLS_SECURITY = 'Tls_Security';  
export const Enum_FTP_MODE = 'Ftp_Mode'; 

export const Enum_REQUEST_CLASS = 'Request_Class'; 
export const Enum_REQUESTCLASS_ENUMERATION = 'RequestClass_Enumeration'; 
export const Enum_REQUESTCLASS_ENUMERATIONITEM = 'RequestClass_EnumerationItem'; 

export const Enum_REQUESTSTATUS_TYPE = 'RequestStatus_Type'; 
export const Enum_REQUESTSTATUS_STATUS = 'RequestStatus_Status'; 

export const Enum_REPORTFIELD_DATA_TYPE = 'ReportField_Data_Type';
export const Enum_REPORTFIELD_DATA_FORMAT = 'ReportField_Data_Format';

export const Enum_JOB_RETENTION_MODE = 'Job_Retention_Mode';
export const Enum_DURATION_UNIT = 'Duration_Unit';

export const Enum_ENDORSEMENT_TYPE = 'Endorsement_Type';

export const Enum_POLICY_STATUS = 'Policy_Status';
export const Enum_PERSON_TYPE = 'Person_Type';
export const Enum_PERSON_CIVILITY = 'Person_Civility';
export const Enum_PERSON_IDENTIFICATION = 'Person_Identification';
export const Enum_CORPORATION_TYPE = 'Corporation_Type';

//export const Enum_PERSON_LANGUAGE = 'Person_Language';

export const Enum_COUNTRY = 'Country';
export const Enum_TOWN = 'Town';

// export const Enum_CONTRACT_TYPE = 'Contract_Type';
export const Enum_CONTRACT_NATURE = 'Contract_Nature';

export const Enum_CAR_USAGE = 'Car_Usage';
export const Enum_CAR_CIRCULATION_ZONE = 'Car_Circulation_Zone';
export const Enum_CAR_MARK = 'Car_Mark';
export const Enum_CAR_TYPE = 'Car_Type';

export const Enum_ENERGY_TYPE = 'Energy_Type';

export const Enum_PAYMENT_FREQUENCY = 'Payment_Frequency';

export const Enum_CONDUCTOR_LICENSE_TYPE = "Conductor_License_Type";

export const Enum_CERTIFICATE_TYPE = "Certificate_Type";
export const Enum_CERTIFICATE_ALLOCATION_STATUS = "Certificate_Allocation_Status";

export const Enum_EDITION_CATEGORY = "Edition_Category";

export const Enum_STUDY_CYCLE = 'Study_Cycle';
export const Enum_STUDY_LEVEL = 'Study_Level';
export const Enum_STUDY_LANGUAGE = 'Study_Language';

export const Enum_DEFAULT_LANGUAGE = 'Default_Language';

export const Enum_STUDY_TYPE = 'Study_Type';

export const Enum_REGISTRATION_STATUS = 'Registration_Status';

export const Enum_BILLING_CATEGORY = 'Billing_Category';

export const Enum_PERSON_GENDER = 'Person_Gender';

export const Enum_EMPLOYEE_STATUS = 'Employee_Status';
export const Enum_EMPLOYEE_DIPLOMA = 'Employee_Diploma';
export const Enum_EMPLOYEE_SPECIALIZATION = 'Employee_Specialization';

export const Enum_EMPLOYEE_SERVICE = 'Employee_Service';
export const Enum_EMPLOYEE_FUNCTION = 'Employee_Function';

export const Enum_EMPLOYEE_TYPE = 'Employee_Type';

export const Enum_EMPLOYEE_CATEGORY = 'Employee_Category';

export const Enum_DAY_WEEK = 'Day_Week';

export const Enum_SUBJECT = 'Subject';
export const Enum_SUBJECT_CATEGORY = 'Subject_Category';

export const Enum_BUS_DESTINATION_OPTION = 'Bus_Destination_Option';

export const Enum_DISCIPLINARY_TYPE = 'Disciplinary_Type';
export const Enum_ABSENCE_TYPE = 'Absence_Type';
export const Enum_ABSENCE_PURPOSE = 'Absence_Purpose';

export const Enum_OBSERVATION_PURPOSE = 'Observation_Purpose';

export const Enum_SANCTION_CATEGORY = 'Sanction_Category';

export const Enum_COMPLAINT_CATEGORY = 'Complaint_Category';

export const Enum_RESOURCE_CATEGORY = 'Resource_Category';

export const Enum_SERVICE_TASK = 'Service_Task';

export const Enum_FORMATION_BILLING_TYPE = 'Formation_Billing_Type';

export const Enum_PRICE_PURPOSE = 'Price_Purpose';

export const Enum_LINE_OF_BUSINESS = 'Line_Of_Business';

//export const Enum_OPERATION_STATUS = 'Delivery_Status';
export const Enum_DELIVERY_STATUS_PURPOSE = 'Delivery_Status_Purpose';
export const Enum_DELIVERY_PAYMENT_ADJUST_PURPOSE = 'Delivery_Payment_Adjust_Purpose';

export const Enum_ARTICLE_OPTION = 'Article_Option';
export const Enum_ARTICLE_OPTION_CLASS = 'Article_Option_Class';

export const Enum_ARTICLE_WRAPPING = 'Article_Wrapping';

export const Enum_TELLER_OPERATION_CANCELLATION_PURPOSE = 'TellerOperation_Cancellation_Purpose';

export const Enum_REGISTRATION_PAYOR = 'Registration_Payor';

//export const Enum_OPERATION_STATUS = 'Expense_Status';
export const Enum_EXPENSE_STATUS_PURPOSE = 'Expense_Status_Purpose';

export const Enum_APPOINTMENT_NOTIFICATION_MODE = 'Appointment_Notification_Mode';

export const Enum_APPOINTMENT_STATUS = 'Appointment_Status';
export const Enum_APPOINTMENT_STATUS_PURPOSE = 'Appointment_Status_Purpose';

export const Enum_PERSON_SERVICE = 'Person_Service'; // CUSTOMER, CHILD1, CHILD...., CONJOINT, FREND1, FREND....

//export const Enum_OPERATION_STATUS = 'Billing_Status';
export const Enum_BILLING_STATUS_PURPOSE = 'Billing_Status_Purpose';

export const Enum_BILLING_DELIVERY_STATUS = 'Billing_Delivery_Status';
export const Enum_BILLING_DELIVERY_STATUS_PURPOSE = 'Billing_Delivery_Status_Purpose';

export const Enum_MOBILE_OPERATOR = 'Mobile_Operator';

export const Enum_EMPLOYEE_DEDUCTION_TYPE = 'EmployeeDeduction_Type';

export const Enum_RESOURCE_OPTION = 'Resource_Option';
export const Enum_RESOURCE_OPTION_CLASS = 'Resource_Option_Class';

export const Enum_MAINTENANCE_CATEGORY_CLASS = 'Maintenance_Category_Class';
export const Enum_CONTRACT_SCOPE_CLASS = 'Contract_Scope_Class';

export const Enum_MAINTENANCE_CATEGORY = 'Maintenance_Category';
export const Enum_CONTRACT_SCOPE = 'Contract_Scope';
export const Enum_CONTRACT_TYPE = 'Contract_Type';


//export const Enum_OPERATION_STATUS = 'Stock_Transfer_Status';
export const Enum_STOCK_TRANSFER_STATUS_PURPOSE = 'Stock_Transfer_Status_Purpose';


export const Enum_PAYMENT_SOURCE = 'Payment_Source';

export const Enum_CASH_OUT_PURPOSE = 'Cash_Out_Purpose';

//export const Enum_OPERATION_STATUS = 'Cash_Out_Status';
export const Enum_CASH_OUT_STATUS_PURPOSE = 'Cash_Out_Status_Purpose';

export const Enum_RENTAL_PERIODICITY = 'Rental_Periodicity';

export const Enum_CURRENCY = 'Currency';
export const Enum_CONSUMPTION_OPTION = 'Consumption_Option';
export const Enum_CONSUMPTION_OPTION_CLASS = 'Consumption_Option_Class';


export const Enum_OPERATION_STATUS = 'Operation_Status';

//export const Enum_OPERATION_STATUS = 'Inventory_Status';
export const Enum_INVENTORY_STATUS_PURPOSE = 'Inventory_Status_Purpose';

//export const Enum_OPERATION_STATUS = 'Stock_Movement_Status';
export const Enum_STOCK_MOVEMENT_PURPOSE_IN = 'Stock_Movement_Purpose_In';
export const Enum_STOCK_MOVEMENT_PURPOSE_OUT = 'Stock_Movement_Purpose_Out';
export const Enum_STOCK_MOVEMENT_STATUS_PURPOSE = 'Stock_Movement_Status_Purpose';


export const Enum_BILLING_TERM_REFERENCE = 'Billing_Term_Reference';


export const Enum_SMTP_SERVER_TYPE = 'Smtp_Server_Type';


export const Enum_TICKET_STATUS = "Ticket_Status";
export const Enum_TICKET_STATUS_PURPOSE = "Ticket_Status_Purpose";
export const Enum_TICKET_RESOLUTION = "Ticket_Resolution";
export const Enum_TICKET_CATEGORY = "Ticket_Category";
export const Enum_TICKET_SEVERITY = "Ticket_Severity";

export const Enum_TICKET_EMPLOYEE_ROLE = "Ticket_Employee_Role";

export const Enum_TICKET_CLASS = "Ticket_Class";
export const Enum_TARGET_SERVICE = "Target_Service";
export const Enum_TICKET_ENTITY = "Ticket_Entity";


// type EnumerationExType = 'baseType' | 'enumeration' | 'entity';
// type EnumerationExItemType = 'text' | 'number' | 'date' | 'boolean' | 'enumItemCode' | 'entityId';

export enum EnumerationExType {
  BaseType = 1,
  Enumeration = 2,
}

export enum EnumerationExItemType {
  Text = 11,
  Number = 12,
  Date = 13,
  Bool = 14,
  ItemCode = 2,
  EntityId = 3
}


export interface IEnumerationItemEx {
  id: number,
  enumerationItemId: number,

  enumerationExId: number,
  enumerationExName: string,
  enumerationExType: DataType,

  enumerationExOptions: {value: string, name: string} [],

  extensionValueId: number,

  value: any,
  
  // type: EnumerationExItemType,

  // valueText: string,
  // valueNumber: string,
  // valueDate: Date,
  // valueBool: boolean,

  // valueEnumItemCode: string,
  // valueEntityId: number


  


//ExtensionValueId	bigint	Checked


}


export interface IEnumerationItem {
  id: number,

  enumerationId: number,
  enumerationCode: string,
  code: string,
  name: string,
  description: string,

  parentEnumerationItemCode: string,
  parentEnumerationCode: string,
      
  enumerationItemExs: IEnumerationItemEx[],

  enumerationName?: string,
  enumerationDescription?: string
}
 

export interface IEnumerationEx {
  id: number,
  
  enumerationId: number,
      
  name: string,
  description: string,

  extensionTypeId: number,

  extensionTypeType: string,
  extensionTypeName: string,
  extensionTypeDescription: string,

  extensionEnumerationItems: IEnumerationItem[],

  enumerationItemExs: IEnumerationItemEx[]
}

export default interface IEnumeration {
  id: number, 
  code: string, 
  name: string, 
  description: string, 
  usage: string, 

  parentEnumerationId: number,
  parentEnumerationName: string,
      
  enumerationExs: IEnumerationEx[],
  enumerationItems: IEnumerationItem[],    

  currentParentEnumerationItemCode: string,

  childEnumerations: any[]
}

export const defaultEnumeration : IEnumeration = {
  id: 0,
  code: '',
  name: '',
  description: '',
  usage: '',

  parentEnumerationId: 0,
  parentEnumerationName: '',

  enumerationExs: [],
  enumerationItems: [],  

  currentParentEnumerationItemCode: '',

  childEnumerations: []
}

export const defaultEnumerationItem : IEnumerationItem = {
  id: 0,

  enumerationId: 0,
  enumerationCode: '',
  code: '',
  name: '',
  description: '',

  parentEnumerationItemCode: '',
  parentEnumerationCode: '',
      
  enumerationItemExs: [],

  enumerationName: '',
  enumerationDescription: ''
}

export interface IEnumerationSearch {
  code: string,
  name: string,
  description: string
}

