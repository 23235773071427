
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import {addDays} from 'date-fns';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import SwitchAccountIcon from '@mui/icons-material/SwitchAccount';


import { globalConfig } from 'config';

import { IReportProcessing, defaultReportProcessing, } from './models/ReportProcessing';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, currentUserSessionAtom, isSaveLoadingAtom } from 'library/store';
import useReportProcessingService, { useBasicFilterSmsReportProcessing, useBasicFilterReportProcessing } from './services/ReportProcessing';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Avatar, Checkbox, Chip, FormControlLabel, IconButton, InputAdornment, Menu, MenuItem, ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import NumberFormat from 'react-number-format';

import useEnumerationService from 'features/configuration/services/Enumeration';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';
import IEnumeration, { IEnumerationItem } from 'features/configuration/models/Enumeration';
import { isFalsy } from 'utility-types';
import { typographyGroupBoxStyling } from 'themes/commonStyles';


export const InstantSmsForm: FC<IReportProcessing> = (props: IReportProcessing = {...defaultReportProcessing,
    validityStartDate :new Date(), validityEndDate: addDays(new Date(), 1)}) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, checkEntitySaveAuthorization } = entityService();

  const { createReportProcessing, updateReportProcessing } = useReportProcessingService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);
  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const {language: lg, } = useRecoilValue(currentUserSessionAtom);
    
  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterReportProcessing = useBasicFilterSmsReportProcessing( 
      (event: React.MouseEvent<unknown>, row: IReportProcessing) => {
          
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const methods = useForm<IReportProcessing>({defaultValues:{...defaultReportProcessing,validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)}});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchRecipientNumber = watch('reportRecipient');

  const watchMessageBody = watch('messageBody');
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IReportProcessing>,Error,IReportProcessing>(
      _id>0?updateReportProcessing:createReportProcessing, {   
        onSuccess: (data: IResult<IReportProcessing>) => {
          enqueueSnackbar( '_Operation_done', { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ReportProcessing',data.data.id]);
          const btn = document.getElementById(`btnNew`);
          btn?.click();
        },
        onError: (err: Error) => {          
          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IReportProcessing>(['ReportProcessing', _id], () => retrieveEntity('ReportProcessing',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    
    const [anchorElContract, setAnchorElContract] = useState<null | HTMLElement>(null);
    const handleContractSwitch = (event: MouseEvent<HTMLButtonElement>) => {
      setAnchorElContract(event.currentTarget);
    };

    
    const [currentContractId, setCurrentContractId] = useState<number>(0);
    const [currentContractDescription, setCurrentContractDescription] = useState<string>('');
        
    const [currentFileName, setCurrentFileName] = useState<string>('');
    const [currentBase64File, setCurrentBase64File] = useState<string>('');

    const [recipients, setRecipients] = useState<string[]>([]);


      function openFileDialog() {
        (document as any).getElementById("file-upload").click();
      }
    
      const setFile = (_event: any) => {
        let f = _event.target.files![0];
        var reader = new FileReader();
    
        reader.onload = function () {
    
            var binaryString = reader.result as string;
        
            const base64String = binaryString
                                        .replace('data:', '')
                                        .replace(/^.+,/, '');
    
            setCurrentBase64File(base64String);
            setCurrentFileName(f.name);
          };
      
          reader.onerror = function () {
            console.log("File load failed");
          };
      
          reader.readAsDataURL(f);    
      };

      

      useEffect( () => {        
        setCurrentFormNameAtom(t('Instant SMS'));
        setCurrentBasicTextFilterProps(basicFilterReportProcessing);
      }, []);

      useEffect( () => {        
        setRecipients([...watchRecipientNumber.split(',')].filter(n => n.trim() !== '') );
      }, [watchRecipientNumber]);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('ReportProcessing',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {        
        _setId(0);  
        reset({...defaultReportProcessing, validityStartDate:new Date(), validityEndDate: addDays(new Date(), 1)  });         
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {    
        
        if(!checkEntitySaveAuthorization('InstantSms', _id)){
          setIsSaveLoading(false);
          return;
        }

          const data = getValues();
          
          //const {id} = currentUserSession;
          if(data.reportRecipient.trim() === '' ) {
              enqueueSnackbar( t('There is no recipient'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
              setIsSaveLoading(false);
              return;
            }

          if(data.messageBody.trim() === '') {
            enqueueSnackbar( t('Message body is not specified'), { variant: 'warning',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
          }
      
          mutate({...data});
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ReportProcessing', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={7} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              
                              <Controller control={control}
                                  name='validityStartDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'} } }}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                <Controller control={control}
                                  name='validityEndDate' 
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Expiration date')} 
                                      
                                      onChange={onChange}                        
                                      value={value}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'} } }}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                />
                                <TextField sx={{width:'calc(50% - 8px)'}} id="reportRecipient" label={t('Recipient')}
                                    {...register('reportRecipient')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } }
                                    />
                            </Box>
                            
                            
                            
                            <Box sx={{ mt: 1, width: '100%' }} >  
                                <FormControlLabel sx={{width:'calc(25% - 8px)'}}
                                    label={t('Is Processed ?')}
                                    control={
                                    <Controller
                                        name='isProcessed'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} disabled />}                        
                                    />} />                              
                                <Controller control={control}
                                    name='processingDate' 
                                    render={({ field: { onChange, onBlur, value, ref } }) => (
                                        <DateTimePicker label={t('Processing date')} 
                                        
                                        onChange={onChange}                        
                                        value={value}
                                        slotProps={{ textField: { sx: {width:'calc(25% - 8px)'} } }}
                                        //renderInput={(params) => <TextField {...params} sx={{width:'calc(25% - 8px)'}} />}
                                        /> )}
                                />
                                <TextField sx={{width:'calc(50% - 8px)'}} id="lastErrorMessage" label={t('Last error message')}
                                    {...register('lastProcessingError')} 
                                    inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'}, readOnly: true } }
                                    />                           
                            </Box>
                                                      
                                                    
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(100% - 8px)'}} id="messageBody" label={t('MessageBody')} multiline rows={5}
                                {...register('messageBody')} 
                                helperText={`${watchMessageBody.length} ${t('character')}(s)`}
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />   
                          </Box>
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={5} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >                                
                              <TextField sx={{width:'calc(80% - 8px)'}} id="messageBody" label={t('Add number by pressing ENTER')} 
                                {...register('currentRecipient')} multiline maxRows={1}
                                InputProps={{
                                  onKeyDown: (event: React.KeyboardEvent<HTMLInputElement>) => {
                                                                       
                                    if(event.key === 'Enter') {
                                      const {currentRecipient, reportRecipients} = getValues();
                                      console.log({currentRecipient, reportRecipients});

                                      const setNumber = new Set( currentRecipient.split('\n').filter(n => 
                                          n.trim() !== '' && !recipients.includes(n.trim()) ) );
                                      const currentRecipients = Array.from(setNumber);

                                      if(currentRecipients.length > 0) {
                                        setValue('reportRecipients', [...reportRecipients,...currentRecipients], {shouldValidate: true} ); //`${recipientNumber},${currentRecipients.join(',')}`.replace(/^,+/, '') 
                                        setValue('reportRecipient', currentRecipients[0]);
                                      }                                        

                                      setValue('currentRecipient', '');                                     
                                    }
                                  },
                                }}                                
                                inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              />   
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={getValues().reportRecipients.join('-')}>        
                            {                              
                              getValues().reportRecipients.map( (num,idx) => 
                                (!isFalsy(num) && <Chip sx={{ ml: 1, mr:1, mb: 1 }}
                                    key={`${idx} - ${num}`} label={num} color="primary" variant="outlined"
                                    onDelete={() => {setValue('reportRecipients', getValues().reportRecipients.filter(recpt => recpt !== num) )}} />) )
                            }
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} key={recipients.length}>
                            <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {` ${getValues().reportRecipients.length} ${t(('Recipient'))}(s) `}
                            </Typography> 
                          </Box>
                        </Stack>                  
                    </Grid>
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

