
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useForm } from 'react-hook-form';
import { useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import TokenIcon from '@mui/icons-material/Token';

import { IApplicationSetup, IMailRequest, defaultApplicationSetup, defaultMailRequest } from './models/ApplicationSetup';

import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useApplicationSetupService, { useBasicFilterApplicationSetup } from './services/ApplicationSetup';
import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import {  IconButton, InputAdornment, MenuItem, Typography } from '@mui/material';
import { Visibility, VisibilityOff } from '@mui/icons-material';
import NumberFormat from 'react-number-format';

import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';

import useVisibilityToggle from 'library/useVisibilityToggle';
import useEnumerationService from 'features/configuration/services/Enumeration';

import IEnumeration, { Enum_DEFAULT_LANGUAGE, Enum_SMTP_SERVER_TYPE, IEnumerationItem } from 'features/configuration/models/Enumeration';

import EntityExpression, {IEntityExpressionProps, defaultEntityExpressionProps} from 'components/ui/EntityExpression';


import { defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

import md5 from 'md5';
import { typographyGroupBoxStyling } from 'themes/commonStyles';
import { isFalsy } from 'utility-types';

export const ApplicationSetupEmailForm: FC<IApplicationSetup> = (props: IApplicationSetup = defaultApplicationSetup) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 999 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const { createApplicationSetup, updateApplicationSetup, sendMail } = useApplicationSetupService();

  const {getEnumerationItemsByEnumerationCodes} = useEnumerationService();

  const { show: showPassword, toggleVisibility: togglePasswordVisibility } = useVisibilityToggle();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterApplicationSetup = useBasicFilterApplicationSetup( 
    (event: React.MouseEvent<unknown>, row: IApplicationSetup) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const [openEntityExpression, setOpenEntityExpression] = useState(false);
  const [currentExpression, setCurrentExpression] = useState(''); 
  const [currentEntityName, setCurrentEntityName] = useState(''); 
  const [expressionField, setExpressionField] = useState<keyof IApplicationSetup>('certificateDocumentExpression');      

  
  const methods = useForm<IApplicationSetup>({defaultValues:defaultApplicationSetup});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchSmtpServer = watch('smtpServer');
  const watchSmtpUsername = watch('smtpUsername');
  const watchSmtpPassword = watch('smtpPassword');


  const methodsMailRequest = useForm<IMailRequest>({defaultValues:defaultMailRequest});
  const { register : registerMailRequest, setValue : setValueMailRequest ,
          getValues : getValuesMailRequest, watch : watchMailRequest, reset : resetMailRequest ,
          control : controlMailRequest , formState: { errors: errorsMailRequest } } = methodsMailRequest;
  
  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'ApplicationSetup'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_SMTP_SERVER_TYPE, Enum_DEFAULT_LANGUAGE ] ));

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IApplicationSetup>,Error,IApplicationSetup>(
      _id>0?updateApplicationSetup:createApplicationSetup, {   
        onSuccess: (data: IResult<IApplicationSetup>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['ApplicationSetup',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });


      const {mutate : mutateSendMail} = useMutation<IResult<boolean>,Error,IMailRequest>(
        sendMail, {   
          onSuccess: (data: IResult<boolean>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                     
                        //setCurrentEntityIdForAction(data.data.id);
            
                      },
          onError: (err: Error) => {          
            enqueueSnackbar( error?.message, { variant: 'error',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            
          }
        });

    const {data: _data, refetch} = useQuery<IApplicationSetup>(['ApplicationSetup', _id], () => retrieveEntity('ApplicationSetup',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

      
    const [openTestMailDialog, setOpenTestMailDialog] = useState<boolean>(false);
    const handleSendTestEmail = (event: any) => {
      setOpenTestMailDialog(true);
    }

    const handleSendTestMailOK = (event: any) => {

      mutateSendMail({...getValuesMailRequest()});
      setOpenTestMailDialog(false);
    }
  
      useEffect( () => {        
        setCurrentFormNameAtom(`${t('Application Setup')} - Email`);
        setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
               
          // if(_id > 0)
          //   retrieveData('ApplicationSetup',_id, refetch);

            retrieveData('ApplicationSetup',999, refetch);  
          }, [/*_id*/] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            console.log(_data);
            _setId(_data.id);
            reset(_data);
        }
        }, [_data]);
    
      
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('ApplicationSetup', _id)){
          setIsSaveLoading(false);
             return;
        }
         

          const data = getValues(); 

          const {whatsAppPassword} = data;

          if(data.companyName.trim() === '') {
              enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                    anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                    setIsSaveLoading(false);
                    return;
            }
      
          mutate({...data });          
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('ApplicationSetup', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={3} columnSpacing={3}>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >                                                                 
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />   
                            </Box>
                            <Box sx={{ mt: 2.25, mb: 0.25, width: '100%' }} >
                              <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                                {`${t('Smtp parameters')} (${t('Send/Receive email')} )`}
                              </Typography>                              
                            </Box>                           
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="smtpServer" label={`${t('Server')} / IP `} {...register('smtpServer')} 
                                inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                />
                            </Box>                            
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <Controller
                                  render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat              
                                      disabled={false}
                                      label={t('Port')} sx={{width:'calc(50% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name='smtpPort'
                                control={control}
                              />
                              <FormControlLabel sx={{width:'calc(40% - 8px)'}}
                                    label={`${t('Use Tls ?')}`}
                                    control={
                                    <Controller
                                        name='smtpUseTls'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="smtpUsername" label={t('Username')} {...register('smtpUsername')} 
                                inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="smtpPassword" label={t('Password')} {...register('smtpPassword')} 
                                type={showPassword ? 'text' : 'password'}
                                inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                InputProps={{
                                  endAdornment: (
                                    <InputAdornment position="end">
                                      <IconButton onClick={togglePasswordVisibility} edge="end">
                                        {showPassword ? <Visibility /> : <VisibilityOff />}
                                      </IconButton>
                                    </InputAdornment>
                                  )
                                }}
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                              <TextField sx={{width:'calc(100% - 8px)'}} id="smtpSenderEmail" label={t('Sender email')} {...register('smtpSenderEmail')} 
                                inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                />
                            </Box>
                            <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >                              
                              <Controller name={`smtpServerType`}
                                      control={control}                                     
                                      render={ ({field: {onChange, value}}) => (
                                        <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id={`smtpServerType`}
                                          label={t('Server type')} >
                                            {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_SMTP_SERVER_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                        </TextField>
                                      )}
                                    />
                            </Box>
                            <Box flexDirection='row' sx={{ mt: 2.25, width: '100%', display: 'flex', alignItems: 'center',justifyContent: 'center', gap: 4 }} > 
                              
                              {!(isFalsy(watchSmtpServer) || isFalsy(watchSmtpUsername) || isFalsy(watchSmtpPassword)) && <Button
                                variant="outlined"
                                component="button"
                                //variant="body2"
                                onClick={handleSendTestEmail}
                                sx={{ ml: 1 }}
                              >
                                {t('Send a test email')}
                              </Button> }
                              { openTestMailDialog && <FormDialog open={openTestMailDialog} maxWidth='sm' height='50vh'
                                  okText={t('OK')} cancelText='' title={t('Query filter')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenTestMailDialog(false);}} onOk={handleSendTestMailOK}  >
                                  <Stack flexDirection='column'  >
                    
                                    <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="recipient" label={t('Recipient')} {...registerMailRequest('recipient')} 
                                        inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                        />
                                    </Box>
                                    <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="recipient" label={t('Email object')} {...registerMailRequest('messageObject')} 
                                        inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                        />
                                    </Box>
                                    <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                                      <TextField sx={{width:'calc(100% - 8px)'}} id="recipient" label={t('Message')} {...registerMailRequest('messageBody')} 
                                        inputProps={  { autoComplete: 'new-password',  style: {textTransform: 'none'} } }
                                        multiline rows={3}
                                        />
                                    </Box>
                                      
                                  </Stack> 
                                </FormDialog>  }                                                                                
                            </Box>
                            
                        </Stack>                        
                    </Grid>  
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            
                            <Box sx={{ mt: 1, width: '100%' }} >
                               
                                                              
                            </Box>
                            
                        </Stack>                        
                    </Grid>                  
                    
                </Grid>
            </Box>
        </FormProvider> 
  )
}

