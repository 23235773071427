
import { atom, atomFamily, selector, selectorFamily, useRecoilState, useRecoilValue, } from 'recoil';
import { getUnixTime } from 'date-fns';

import { defaultUserSession, IUserSession } from 'features/identity/models/User';
import { BasicTextFilterProps, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';
import { defaultAppTheme, IAppTheme } from 'themes/commonStyles';


export const lastUnixTimeStampAtom = atom<number>({
  key: "lastUnixTimeStampState",
  default: getUnixTime(new Date())
});

export const currentFormNameAtom = atom<string>({
  key: "currentFormNameState",
  default: ""
});

export const isActionDrawerOpenAtom = atom<boolean>({
    key: "isActionDrawerOpenState",
    default: false
  });

export const isPrintDrawerOpenAtom = atom<boolean>({
    key: "isPrintDrawerOpenState",
    default: false
  });

export const currentEntityNameForActionDrawerAtom = atom<string>({
    key: "currentEntityNameForActionDrawerState",
    default: ""
  });

export const currentEntityIdForActionDrawerAtom = atom<number>({
    key: "currentEntityIdForActionDrawerState",
    default: 0
  });

  export const currentFunctionEntityParametersForActionDrawerAtom = atom<{name: string, value: any}[]>({
    key: "currentFunctionEntityParametersForActionDrawerState",
    default: []
  });

  export const currentEntityContextualItemsForActionDrawerAtom = atom<{entityName: string, entityId: number}[]>({
    key: "currentEntityIdItemForActionDrawerState",
    default: []
  });
  
  export const isSaveLoadingAtom = atom<boolean>({
    key: "isSaveLoadingState",
    default: false
  });


  export const isSearchBoxShowAtom = atom<boolean>({
    key: "isSearchBoxShowState",
    default: false
  });

  export const isAuthorizationBoxShowAtom = atom<boolean>({
    key: "isAuthorizationBoxShowState",
    default: false
  });

  export const isDesktopPublishingBoxShowAtom = atom<boolean>({
    key: "isDesktopPublishingBoxShowState",
    default: false
  });
  
  export const isExportBoxShowAtom = atom<boolean>({
    key: "isExportBoxShowState",
    default: false
  });

  export const isWaitingBoxShowAtom = atom<boolean>({
    key: "isWaitingBoxShowState",
    default: false
  });

  export const hubConnectionIdAtom = atom<string>({
    key: "hubConnectionIdState",
    default: ''
  });

  export const currentBasicTextFilterPropsAtom = atom<BasicTextFilterProps<any>>({
    key: "currentBasicTextFilterPropsState",
    default: defaultBasicTextFilterProps
  });
 
  export const requestDataSendedIdsAtom = atom<number[]>({
    key: "requestDataSendedIdsState",
    default: []
  });

  export const requestDataSendedOkIdsAtom = atom<number[]>({
    key: "requestDataSendedOkIdsState",
    default: []
  });

  export const requestDataResponseCheckedIdsAtom = atom<number[]>({
    key: "requestDataResponseCheckedIdsState",
    default: []
  });

  export const requestDataResponseCheckedOkIdsAtom = atom<number[]>({
    key: "requestDataResponseCheckedOkIdsState",
    default: []
  });

  export const requestDataResponseArrivedIdsAtom = atom<number[]>({
    key: "requestDataResponseArrivedIdsState",
    default: []
  });

  export const requestDataItemResponseArrivedIdsAtom = atom<number[]>({
    key: "requestDataItemResponseArrivedIdsState",
    default: []
  });

  export const requestDataNotificationKeyAtom = atom<string>({
    key: "requestDataNotificationKeyState",
    default: ''
  });
    
  export const fileTokensAtom = atom<{token:string, name: string, isFileGenerated: boolean, isFileOpened: boolean}[]>({
    key: "fileTokensState",
    default: []
  });

export const currentUserSessionAtom = atom<IUserSession>({
    key: 'currentUserSessionState',
    default: defaultUserSession as IUserSession
  });

export const currentUserSessionSetAuthentication = selector({
    key: 'disconnectUser',
    get: ({get}) => get(currentUserSessionAtom),
    set: ({set, get}) => {
        //const userSession = get(currentUserSessionState);
        set(currentUserSessionAtom,{...get(currentUserSessionAtom), isAuthenticated: false})
    }
});

export const colorsAtom = atom<IAppTheme>({
  key: 'colorsState',
  default: defaultAppTheme,
});

  