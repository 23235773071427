


export type ProductType = 'service' | 'article' | 'packaging' | 'formation' | 'maintenance' | 'contract' | 'fee' | 'rental' | 'consumption';

export type PackagingProductType = 'service' | 'article';

//export type RentalPeriodicityType = 'h' | 'm' | 'd' | 'w' | 'M' | 'y' ; // hour, min, day, week, Month, year

export interface IProduct {
    id: number,
    isActive: boolean,
    name: string,
    code: string,
    description: string,

    sharingId: number,
    sharingName: string,

    priceExpression: string,
    maxDiscountExpression: string,
    maxLoadedExpression: string,
    isDefaultTaxFormula: boolean,
    taxExpression: string,
    isTaxable: boolean,

    lineOfBusinessCode: string,

    customerPointExpression: string,

    type: ProductType,

    serviceBeneficiaryPointExpression: string,

    serviceDurationInMinute: number,
    priceExpressionUnity: string,
    filterOption: string,
    articleWrapping: string,

    fileName: string,
    base64Picture: string,
    
    canSellWithoutStock: boolean,
    allowBillingDeliveryWithoutStock: boolean,
    reorderThreshold: number,

    quantityAvailable: number,
    quantityStock: number,
    quantityDelivery: number,
    quantityBilling: number,


    cumulativeDurationRate: number,
    durationBeforeServiceRenewalInDay: number,

    canBePublicAppointment: boolean,
    appointmentColor: string,

    maintenanceCategoryClass: string,
	  contractScopeClass: string,

    rentalPeriodicity: 'h' | 'm' | 'd' | 'w' | 'M' | 'y', //RentalPeriodicityType,

    consumptionOptionClass: string,
    isConsumptionAvailable: boolean,

    lineOfBusinessName: string,

    serviceTasks: IServiceTask[],
    packagingProducts: IPackagingProduct[],
    formationBillingTypes: IFormationBillingType[],

    rentalResources: IRentalResource[]
  }

export interface IServiceTask {
  id: number,
  isActive: boolean,

  serviceId: number,
  taskCode: string,
  part: number,

  servicePart: number
}

export interface IPackagingProduct {
  id: number,
  
  packagingId: number,
  part: number,
  

  type: PackagingProductType,

  serviceId: number,
  personServiceCode: string,
  serviceBeneficiaryPointExpression: string,

  articleId: number,

  description: string,
  productName: string
}

export interface IFormationBillingType {
  id: number,
  
  formationId: number,
  billingTypeCode: string,
  dueDate: Date,
  amountPart: number,

  billingTypeName: string
}


export interface IRentalResource {
  id: number,

  rentalId: number,
  resourceId: number,

  isCapacityFixed: boolean,
  defaultCapacity: number,

  resourceName: string
}

  
  export const defaultProduct : IProduct = {
    id: 0,
    isActive: true,
    name: '',
    code: '',
    description: '',

    sharingId: 0,
    sharingName: '',

    priceExpression: '',
    maxDiscountExpression: '',
    maxLoadedExpression: '',
    isDefaultTaxFormula: true,
    taxExpression: '',
    isTaxable: true,

    lineOfBusinessCode: '',

    customerPointExpression: '',

    type: 'service',

    serviceBeneficiaryPointExpression: '',

    serviceDurationInMinute: 0,
    priceExpressionUnity: '',
    filterOption: '',
    articleWrapping: '',

    fileName: '',
    base64Picture: '',
    
    canSellWithoutStock: false,
    allowBillingDeliveryWithoutStock: false,
    reorderThreshold: 0,

    quantityAvailable: 0,
    quantityStock: 0,
    quantityDelivery: 0,
    quantityBilling: 0,

    cumulativeDurationRate: 100,
    durationBeforeServiceRenewalInDay: 1,

    appointmentColor: '#305BE6',
    canBePublicAppointment: false,
    
    maintenanceCategoryClass: '',
	  contractScopeClass: '',

    rentalPeriodicity: 'd',

    consumptionOptionClass: '',
    isConsumptionAvailable: false,

    lineOfBusinessName: '',

    serviceTasks: [],
    packagingProducts: [],
    formationBillingTypes: [],

    rentalResources: []
  }

  

  export interface IProductSearch {
 
    isActive: boolean,
    name: string,
    description: string,
    lineOfBusinessCode: string
  }