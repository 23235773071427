
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { debounce, sum } from 'lodash';

import { addMinutes } from 'date-fns';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import CopyAllIcon from '@mui/icons-material/CopyAll';

import CheckIcon from '@mui/icons-material/Check';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import PlaylistAddCircleIcon from '@mui/icons-material/PlaylistAddCircle';
import QueueIcon from '@mui/icons-material/Queue';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import PersonAddAltIcon from '@mui/icons-material/PersonAddAlt';
import BuildCircleOutlinedIcon from '@mui/icons-material/BuildCircleOutlined';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';


import AssignmentIcon from '@mui/icons-material/Assignment';
import LocalMallIcon from '@mui/icons-material/LocalMall';
import SchoolIcon from '@mui/icons-material/School';
import DescriptionIcon from '@mui/icons-material/Description';
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn';
import RoomServiceIcon from '@mui/icons-material/RoomService';
import ArchiveIcon from '@mui/icons-material/Archive';

import FastfoodIcon from '@mui/icons-material/Fastfood';

import { MdOutlineAdd, MdOutlineCancel, MdOutlineCheckCircle } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, 
      isSearchBoxShowAtom, isSaveLoadingAtom, isWaitingBoxShowAtom } from 'library/store';
import useBillingService, { useBasicFilterBilling } from './services/Billing';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { defaultFeatureDescription, IEntity, IFeatureDescription, IFeatureParameter, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Link, FormHelperText } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IBilling, IBillingDelivery, IBillingDeliveryDetail, IBillingDetail, IBillingDetailInfo, 
        IBillingInfo, IBillingPackagingDetail, IBillingRentalResource, IBillingServiceTask, ICommissionDistribution, 
        defaultBilling, defaultBillingDelivery, defaultBillingDetail } from './models/Billing';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration, useEnumerationItemCreateUpdate } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_SERVICE_TASK, Enum_OPERATION_STATUS, Enum_BILLING_STATUS_PURPOSE,
  Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, Enum_PRICE_PURPOSE, 
  Enum_MAINTENANCE_CATEGORY, Enum_CONTRACT_SCOPE, Enum_CONTRACT_TYPE, Enum_CORPORATION_TYPE, 
  Enum_RENTAL_PERIODICITY,
  Enum_CURRENCY, Enum_PAYMENT_SOURCE,
  Enum_CONSUMPTION_OPTION,
  Enum_BILLING_TERM_REFERENCE,
  defaultEnumerationItem} from 'features/configuration/models/Enumeration';

import useProductService, { useBasicFilterProduct } from 'features/setup/services/Product';

import usePersonService, {useBasicFilterPerson, useBasicFilterPersonSearch}  from 'features/production/services/Person';

import { IProduct } from 'features/setup/models/Product';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker } from '@mui/x-date-pickers';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { IPerson, PersonType, defaultPerson } from 'features/production/models/Person';

import useUtils from 'library/utils';

import { useBasicFilterEmployee } from 'features/setup/services/Employee';
import { IEmployee } from 'features/setup/models/Employee';
import { IPackaging } from 'features/setup/models/Packaging';
import { useBasicFilterPackaging } from 'features/setup/services/Packaging';
import { GrClose, GrSearch } from 'react-icons/gr';
import EnhancedTable from 'components/ui/EnhancedTable';
import { NestCamWiredStandTwoTone } from '@mui/icons-material';
import ArrayField from 'components/ui/ArrayField';
import { title } from 'process';
import { DialogEnumerationItemForm } from 'features/configuration/DialogEnumerationItemForm';

export const BillingForm: FC<IBilling> = (props: IBilling = defaultBilling) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {range, addDurationInPeriod} = useUtils();

  const {language: lg, taskCodes, userDescription, userName, 
      employeeId, roleEntities, applicationSetup, userStores} = useRecoilValue(currentUserSessionAtom);
  
  const [isWaitingBoxShow, setIsWaitingBoxShow] = useRecoilState(isWaitingBoxShowAtom);

  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, executeEntityFeature,
      checkEntityExpressionSyntax, checkEntitySaveAuthorization, openEntityPrintDrawer } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount, getBirthDateType } = usePersonService();


  const { createBilling, updateBilling, evaluateAddedProduct, getCommissionDistributionsByBilling, deliverBillingDetail,
          createBillingDelivery, updateBillingDelivery, getBillingDeliveriesByBilling } = useBillingService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterBilling = useBasicFilterBilling( 
    (event: React.MouseEvent<unknown>, row: IBilling) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openEnumerationFilter, setOpenEnumerationFilter] = useState(false);
  const basicFilterEnumeration = useBasicFilterEnumeration( 
      (event: React.MouseEvent<unknown>, row: IEnumeration) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);
                           
          setOpenEnumerationFilter(false);
      }
  );

  const {canAddEnumerationItem, canUpdateEnumerationItem, displayEnumerationItemDialog,
    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
    onAddItemClick, onUpdateItemClick } = useEnumerationItemCreateUpdate();

  const [openPersonFilter, setOpenPersonFilter] = useState(false);
  const basicFilterPerson = useBasicFilterPerson( 
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace,  birthDateType,
            particularSign, corporationName, corporationType, creationDate, creationDateType,
            portable1, portable2, email1, email2} = row;
        
        if(currentPersonUsage === 'customer') {
          setValue('customerId', id);
          setValue('customerPersonType', type);

          setValue('customerFirstName', firstName);
          setValue('customerLastName', lastName); 
          setValue('customerPortable1', portable1);
          setValue('customerPortable2', portable2);
          setValue('customerEmail1', email1);
          setValue('customerEmail2', email2);
          setValue('customerCorporationName', corporationName);
          setValue('customerCorporationType', corporationType);
          setValue('customerBirthDate', birthDate);
          setValue('customerBirthDateType', birthDateType);
          setValue('customerCreationDate', creationDate);
          setValue('customerCreationDateType', creationDateType);

        } else if( ['billing-detail-service', 'billing-detail-formation'].includes(currentPersonUsage)) {
          
          const billingDetail = getValues().billingDetails.at(productIndex);
          if(isFalsy(billingDetail)) return;

          const personId = (currentPersonUsage === 'billing-detail-service') ? id: billingDetail.personId;
          const studentId = (currentPersonUsage === 'billing-detail-formation') ? id: billingDetail.studentId;

          (refUpdateBillingDetail.current??emptyFunc)(productIndex, {...billingDetail!, 
            personId, studentId ,firstName, lastName, portable1, portable2, email1, email2 }); 
          
        } else if(currentPersonUsage === 'billing-packaging-service') {
           
          const billingDetail = getValues().billingDetails.at(productIndex);
          if(isFalsy(billingDetail)) return;
          
          const billingPackagingDetail = billingDetail.billingPackagingDetails.at(billingPackagingServiceIndex);
          if(isFalsy(billingPackagingDetail)) return;

          (refUpdateBillingPackagingDetail.current??emptyFunc)(billingPackagingServiceIndex, {...billingPackagingDetail!, 
            personId: id, firstName, lastName, portable1, portable2, email1, email2 }); 
        }
       
        setOpenPersonFilter(false);
      }
  );

  

  const [openServiceFilter, setOpenServiceFilter] = useState(false);
  const basicFilterService = useBasicFilterProduct( 1,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, isActive ,lineOfBusinessCode} = row;

          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail}});
          setIsWaitingBoxShow(false);

          const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity, billingServiceTasks} = res.data;

          for(var i = 0; i<billingServiceTasks.length; i++ ) {
            const billingServiceTask = billingServiceTasks[i];
            if( employeeId > 0 && taskCodes.includes(billingServiceTask.taskCode)) {
              billingServiceTask.employeeId = employeeId;
              billingServiceTask.employeeFullName = `${userDescription} - ${userName}`;
            }
          }
        
          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'service', serviceId: id,  
            productName: name, lineOfBusinessCode,
            beneficiaryIsCustomer: true, billingServiceTasks, birthDateType: 'date' });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenServiceFilter(false);
      }
  );


  const [openFeeFilter, setOpenFeeFilter] = useState(false);
  const basicFilterFee = useBasicFilterProduct( 7,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, isActive, lineOfBusinessCode} = row;

          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail}});
          setIsWaitingBoxShow(false);

          const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity, billingServiceTasks} = res.data;

          for(var i = 0; i<billingServiceTasks.length; i++ ) {
            const billingServiceTask = billingServiceTasks[i];
            if( employeeId > 0 && taskCodes.includes(billingServiceTask.taskCode)) {
              billingServiceTask.employeeId = employeeId;
              billingServiceTask.employeeFullName = `${userDescription} - ${userName}`;
            }
          }
        
          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'fee', feeId: id,  
            productName: name, lineOfBusinessCode, });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenFeeFilter(false);
      }
  );

  const [openArticleFilter, setOpenArticleFilter] = useState(false);
  const basicFilterArticle = useBasicFilterProduct( 2,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, isActive, filterOption, lineOfBusinessCode} = row;
          
          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail, quantity: 1}});
          setIsWaitingBoxShow(false);

          const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity} = res.data;

          const articleOptions = refEnumItems2.current?.filter(x => x.enumerationCode === Enum_ARTICLE_OPTION &&
                                  x.parentEnumerationItemCode === filterOption);
          const articleOption = (articleOptions && articleOptions.length === 1) ? articleOptions[0].code || '' : '';
          
          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'article', articleId: id,
               productName: name, lineOfBusinessCode, articleFilterOption: filterOption, articleOption }); 

          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenArticleFilter(false);
      }
  );

  const [openPackagingFilter, setOpenPackagingFilter] = useState(false);
  const basicFilterPackaging = useBasicFilterProduct( 3,
    async (event: React.MouseEvent<unknown>, row: IProduct) => {
        const {id, description,name, serviceDurationInMinute, isActive, lineOfBusinessCode} = row;

        if(!isActive) {
          enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
          return;
        }

        setIsWaitingBoxShow(true);
        const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail}});
        setIsWaitingBoxShow(false);

        const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity, billingPackagingDetails} = res.data;

        const countServices = billingPackagingDetails.filter(b => b.type === 'service').length;
        const countArticles = billingPackagingDetails.filter(b => b.type === 'article').length;

        for(var i=0; i< billingPackagingDetails.length; i++) {
          const billingPackagingDetail = billingPackagingDetails[i];
          if(billingPackagingDetail.type === 'article') continue;

          for(var j=0; j< billingPackagingDetail.billingServiceTasks.length; j++) {
            billingPackagingDetail.beneficiaryIsCustomer = true;
            const billingServiceTask = billingPackagingDetail.billingServiceTasks[j];
            if( employeeId > 0 && taskCodes.includes(billingServiceTask.taskCode)) {
              billingServiceTask.employeeId = employeeId;
              billingServiceTask.employeeFullName = `${userDescription} - ${userName}`;
            }
          }
        }
      
        const countBillingDetail = getValues().billingDetails.length;
        (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'packaging', packagingId: id, 
          productName: name, lineOfBusinessCode,
          billingPackagingDetails });                          
          
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenPackagingFilter(false);
    }
  );

  const [openFormationFilter, setOpenFormationFilter] = useState(false);
  const basicFilterFormation = useBasicFilterProduct( 4,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, isActive, lineOfBusinessCode} = row;

          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail}});
          setIsWaitingBoxShow(false);

          const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity, billingServiceTasks} = res.data;

          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'formation', formationId: id,  
            productName: name, lineOfBusinessCode,
            beneficiaryIsCustomer: true, birthDateType: 'date' });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenFormationFilter(false);
      }
  );

  const [openMaintenanceFilter, setOpenMaintenanceFilter] = useState(false);
  const basicFilterMaintenance = useBasicFilterProduct( 5,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, isActive, lineOfBusinessCode, maintenanceCategoryClass} = row;

          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail}});
          setIsWaitingBoxShow(false);

          const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity, billingServiceTasks} = res.data;

          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'maintenance', maintenanceId: id,  
            productName: name, lineOfBusinessCode, maintenanceCategoryClass,
            beneficiaryIsCustomer: true, birthDateType: 'date' });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenMaintenanceFilter(false);
      }
  );

  const [openContractFilter, setOpenContractFilter] = useState(false);
  const basicFilterContract = useBasicFilterProduct( 6,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, serviceDurationInMinute, isActive, lineOfBusinessCode, contractScopeClass} = row;

          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail}});
          setIsWaitingBoxShow(false);

          const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity, billingServiceTasks} = res.data;

          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'contract', contractId: id,  
            productName: name, lineOfBusinessCode, contractScopeClass,
            beneficiaryIsCustomer: true, birthDateType: 'date' });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenContractFilter(false);
      }
  );

  const [openRentalFilter, setOpenRentalFilter] = useState(false);
  const basicFilterRental = useBasicFilterProduct( 8,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, description,name, lineOfBusinessCode, isActive, rentalPeriodicity} = row;

          if(!isActive) {
            enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
            return;
          }

          const eff = new Date();
          const exp = addDurationInPeriod(eff, rentalPeriodicity, 1);

          setIsWaitingBoxShow(true);
          const res = await evaluateAddedProduct({...getValues(), productId: id, 
              billingDetail: {...defaultBillingDetail, rentalEffectiveDate: eff, rentalDuration: 1, rentalExpirationDate: exp}});
          setIsWaitingBoxShow(false);
          
          const countBillingDetail = getValues().billingDetails.length;
          (refAppendBillingDetails.current??emptyFunc)({...res.data,type: 'rental', rentalId: id,  rentalPeriodicity, rentalEffectiveDate: eff, rentalExpirationDate: exp, 
            productName: name, lineOfBusinessCode, rentalReference: '',
            beneficiaryIsCustomer: true, birthDateType: 'date' });                          
                      
          setTotalAmount();
          setProductIndex(countBillingDetail);
          setOpenRentalFilter(false);
      }
  );  

  const [openConsumptionFilter, setOpenConsumptionFilter] = useState(false);
  const basicFilterConsumption = useBasicFilterProduct( 9,
      async (event: React.MouseEvent<unknown>, row: IProduct) => {
        const {id, description,name, serviceDurationInMinute, isActive, consumptionOptionClass, lineOfBusinessCode} = row;

        if(!isActive) {
          enqueueSnackbar( `${t('This product is not active')} : ${name}`, { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });
          return;
        }
          
        setIsWaitingBoxShow(true);
        const res = await evaluateAddedProduct({...getValues(), productId: id, billingDetail: {...defaultBillingDetail, quantity: 1}});
        setIsWaitingBoxShow(false);

        const {baseAmount, discount, netAmount, tax, amount, unityAmount, quantity} = res.data;
        
        const countBillingDetail = getValues().billingDetails.length;
        (refAppendBillingDetails.current??emptyFunc)({...res.data, type: 'consumption', consumptionId: id,
             productName: name, lineOfBusinessCode, consumptionOptionClass }); 

        setTotalAmount();
        setProductIndex(countBillingDetail);
        setOpenConsumptionFilter(false);
      }
  );
  

  const [openEmployeeFilter, setOpenEmployeeFilter] = useState(false);
  const basicFilterEmployee = useBasicFilterEmployee( 
      (event: React.MouseEvent<unknown>, row: IEmployee) => {
        const {id, firstName, lastName, managerFirstName, managerLastName, employeeTasks, } = row;        
        
        if(currentEmployeeUsage === 'business') {

          setValue('businessEmployeeId', id);
          setValue('businessEmployeeFullName', `${lastName} ${firstName}`.trim());
          const mFirstName = isFalsy(managerFirstName)?'':managerFirstName;
          const mLastName = isFalsy(managerLastName)?'':managerLastName;

          setValue('businessEmployeeManagerFullName', `${mLastName} ${mFirstName}`.trim());
          setOpenEmployeeFilter(false);
          return;
        }

        const billingDetail = getValues().billingDetails.at(productIndex);        
        if(isFalsy(billingDetail)) return;

        if(currentEmployeeUsage === 'fee') {

          (refUpdateBillingDetail.current??emptyFunc)(productIndex, {...billingDetail!, 
            employeeId: id, employeeFullName: `${lastName} ${firstName}`.trim() }); 

          setOpenEmployeeFilter(false);
          return;
        }

                
        if(currentEmployeeUsage === 'billing-detail-service') {
          const billingServiceTask = billingDetail.billingServiceTasks.at(billingServiceTaskIndex);
          if(isFalsy(billingServiceTask)) return;
          
          if(!employeeTasks.some(t => t.taskCode === billingServiceTask.taskCode)) {
            enqueueSnackbar( t('This service or package can not be assign to this employee'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
            return;
          } 
          
          (refUpdateBillingServiceTask.current??emptyFunc)(billingServiceTaskIndex, {...billingServiceTask!, 
            employeeId: id, employeeFullName: `${lastName} ${firstName}`.trim() }); 
          
        } else if(currentEmployeeUsage === 'billing-packaging-service') {
                              
          const billingPackagingDetail = billingDetail.billingPackagingDetails.at(billingPackagingServiceIndex);
          if(isFalsy(billingPackagingDetail)) return;

          const billingServiceTask = billingPackagingDetail.billingServiceTasks.at(billingPackagingServiceTaskIndex);
          if(isFalsy(billingServiceTask)) return;

          if(!employeeTasks.some(t => t.taskCode === billingServiceTask.taskCode)) {
            enqueueSnackbar( t('This service or package can not be assign to this employee'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
            return;
          }

          (refUpdateBillingPackagingServiceTask.current??emptyFunc)(billingPackagingServiceTaskIndex, {...billingServiceTask!, 
            employeeId: id, employeeFullName: `${lastName} ${firstName}`.trim() }); 
        } else if(currentEmployeeUsage === 'billing-service') { 
          if(billingDetail.type === 'service') {
            for(var i=0; i<billingDetail.billingServiceTasks.length; i++ ){  
              const billingServiceTask = billingDetail.billingServiceTasks[i];
              if(billingServiceTask.employeeId <=0 && employeeTasks.some( t => t.taskCode === billingServiceTask.taskCode ) ) {
                    
                    setValue(`billingDetails.${productIndex}.billingServiceTasks.${i}.employeeId`, id);
                    setValue(`billingDetails.${productIndex}.billingServiceTasks.${i}.employeeFullName`, `${lastName} ${firstName}`.trim() );
                } 
            }            
          } else if(billingDetail.type === 'packaging') {
            for(var k=0; k< billingDetail.billingPackagingDetails.length; k++) {
              const billingPackagingDetail = billingDetail.billingPackagingDetails[k];
              for(var i=0; i< billingPackagingDetail.billingServiceTasks.length; i++) {
                const billingServiceTask = billingPackagingDetail.billingServiceTasks[i];
                if(billingServiceTask.employeeId <=0 && employeeTasks.some( t => t.taskCode === billingServiceTask.taskCode ) ) {
                    
                  setValue(`billingDetails.${productIndex}.billingPackagingDetails.${k}.billingServiceTasks.${i}.employeeId`, id);
                  setValue(`billingDetails.${productIndex}.billingPackagingDetails.${k}.billingServiceTasks.${i}.employeeFullName`, `${lastName} ${firstName}`.trim() );
                }
              }
            }
          }
        }


        // (refUpdateBillingDetail.current??emptyFunc)(productIndex, 
        //     {...service, employeeId: id, employeeFirstName: firstName, employeeLastName: lastName,
        //       employeeFullName: `${lg.includes('fr')? lastName:firstName} ${lg.includes('fr')? firstName:lastName}`.trim() });  

        setOpenEmployeeFilter(false);
      }
  );
    
  const [commissionDistributions, setCommissionDistributions] = useState<ICommissionDistribution[]>([]);

  const methods = useForm<IBilling>({defaultValues: {...defaultBilling, 
            currencyCode: applicationSetup.defaultCurrencyCode, defaultTaxRate: applicationSetup.defaultTaxRate,
            showroomId: userStores.find(s => s.isShowroom)?.storeId ?? 0  }});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  const watchId = watch('id');
  const watchStatus = watch('status');
  const watchHasNumber = watch('hasNumber');

  const watchDefaultTaxRate = watch('defaultTaxRate');

  const watchCustomerId = watch('customerId');
  const watchCustomerPersonType = watch('customerPersonType');
  const watchBusinessEmployeeId = watch('businessEmployeeId');  

  const watchCustomerFirstName = watch('customerFirstName');
  const watchCustomerLastName = watch('customerLastName');
  const watchCustomerCorporationName = watch('customerCorporationName');
  const watchCustomerBirthDateType = watch('customerBirthDateType');
  const watchCustomerCreationDateType = watch('customerCreationDateType');

  const watchDefaultPaymentSourceCode = watch('defaultPaymentSourceCode');
  const watchTermAndConditionCode = watch('termAndConditionCode');

  const methodsEntityAction = useForm<IFeatureDescription>( {defaultValues:defaultFeatureDescription} );
    const { register: registrationEntityAction, setValue: setEntityActionValue ,getValues: getEntityActionValues ,
            watch: watchEntityAction, reset: resetEntityAction ,control: controlEntityAction , formState: { errors: errorsEntityAction } } = methodsEntityAction;
    let {fields: actionParams} = useFieldArray<IFeatureDescription,'params'>({
    control: controlEntityAction, 
    name: 'params'});

  const { register: registerBillingDelivery, setValue: setValueBillingDelivery ,getValues: getValuesBillingDelivery, watch: watchBillingDelivery, 
    reset: resetBillingDelivery ,control: controlBillingDelivery , formState: { errors: errorsBillingDelivery } } = 
          useForm<IBillingDelivery>({defaultValues: defaultBillingDelivery });


  type SearchPersonUsage = 'customer' | 'billing-detail-beneficiary' | 'billing-packaging-beneficiary';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('customer');

  const refProductIndex = useRef<number>(0);
  const refBillingPackagingServiceIndex = useRef<number>(0);

  const refDefaultPaymentSourceCode = useRef<string>('');
  const refTermAndCondition = useRef<string>('');

  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  const basicFilterPersonSearch = useBasicFilterPersonSearch( 
      () => {
        const { customerFirstName, customerLastName, customerCorporationName, customerPersonType } = getValues();

        const fn = customerPersonType === 'moral' ? customerCorporationName : customerFirstName;
        const ln = customerPersonType === 'moral' ? customerCorporationName : customerLastName;

        if(currentSearchPersonUsage === 'customer')
          return {firstName: fn, lastName: ln, particularSign: ''}; 

        if(currentSearchPersonUsage === 'billing-detail-beneficiary') {
          const billingDetail = getValues().billingDetails.at(refProductIndex.current);
          if(isFalsy(billingDetail))
            return {firstName: '@@@@', lastName: '@@@@', particularSign: '@@@@'}; 

          return {firstName: billingDetail.firstName??'', lastName: billingDetail.lastName??'', particularSign: ''};
        }

        if(currentSearchPersonUsage === 'billing-packaging-beneficiary') {
          const billingDetail = getValues().billingDetails.at(refProductIndex.current);
          
          if(isFalsy(billingDetail))
            return {firstName: '@@@@', lastName: '@@@@', particularSign: '@@@@'}; 

          const billingPackagingService = billingDetail.billingPackagingDetails.at(refBillingPackagingServiceIndex.current);          
          if(isFalsy(billingPackagingService))
            return {firstName: '@@@@', lastName: '@@@@', particularSign: '@@@@'}; 

          return {firstName: billingPackagingService.firstName??'', lastName: billingPackagingService.lastName??'', particularSign: ''};
        }

        
        return {firstName: customerFirstName, lastName: customerLastName, particularSign: ''};
        //return refPersonSearch.current || {firstName: '', lastName: '', particularSign: ''};
      },      
      (event: React.MouseEvent<unknown>, row: IPerson) => {
          const {id, type, firstName, lastName, birthDate, birthPlace, particularSign, corporationName, corporationType,
            portable1, portable2, email1, email2} = row;
        
        
        if(currentSearchPersonUsage === 'customer') {
          
          setValue('customerId', id);
          setValue('customerPersonType', type);
          setValue('customerFirstName', firstName);
          setValue('customerLastName', lastName);  
          setValue('customerCorporationName', corporationName);
          setValue('customerCorporationType', corporationType);
          setValue('customerPortable1', portable1);   
          setValue('customerPortable2', portable2);                    
          setValue('customerEmail1', email1); 
          setValue('customerEmail2', email2); 
        } else if(currentSearchPersonUsage === 'billing-detail-beneficiary') {
          const billingDetail = getValues().billingDetails.at(productIndex);
          if(isFalsy(billingDetail)) return;

          const personId = (getValues().billingDetails[productIndex].type === 'service')? id: billingDetail.personId;
          const studentId = (getValues().billingDetails[productIndex].type === 'formation')? id: billingDetail.studentId;

          (refUpdateBillingDetail.current??emptyFunc)(productIndex, {...billingDetail!, personId, studentId, firstName,
               lastName, particularSign, portable1, portable2, email1, email2 });
          
        } else if(currentSearchPersonUsage === 'billing-packaging-beneficiary') {

          const billingDetail = getValues().billingDetails.at(productIndex);
          if(isFalsy(billingDetail)) return;
          
          const billingPackagingDetail = billingDetail.billingPackagingDetails.at(billingPackagingServiceIndex);
          if(isFalsy(billingPackagingDetail)) return;

          (refUpdateBillingPackagingDetail.current??emptyFunc)(billingPackagingServiceIndex, {...billingPackagingDetail!, 
            personId: id, firstName, lastName, particularSign, portable1, portable2, email1, email2 });         
        }          
                          
        setOpenPersonSearchFilter(false);
      }
  );
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IBilling>,Error,IBilling>(
      _id>0?updateBilling:createBilling, {   
        onSuccess: (data: IResult<IBilling>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
          
          
          setIsSaveLoading(false);
          //setValue('id', data.data.id);
          _setId(data.data.id);
          //retrieveData('Billing',data.data.id, refetch);
          setCurrentFormNameAtom(`${t('Billing')} - # ${data.data.id} # ` );
          
          //queryClient.invalidateQueries(['Billing',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });


      const { mutate: mutateDeliverBillingDetail } = useMutation<IResult<IBillingDetail>,Error, {id: number, deliveryDate: Date, isDelivered: boolean, isDeliveryDateDefine: boolean } >(
          deliverBillingDetail , {   
          onSuccess: (data: IResult<IBillingDetail>) => {
            enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                  anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                        
            const index = getValues().billingDetails.findIndex(b => b.id === data.data.id);
            if(index >= 0) {
              const billingDetail = getValues().billingDetails[index];

              (refUpdateBillingDetail.current??emptyFunc)(index, {...billingDetail, 
                                    isDelivered: data.data.isDelivered, isDeliveryDateDefine: data.data.isDeliveryDateDefine , deliveryDate: data.data.deliveryDate});
            }
            
            setOpenBillingDetailServiceDelivery(false);
          },
          onError: (err: Error) => {          
            enqueueSnackbar( err?.message, { variant: 'error',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
            setOpenBillingDetailServiceDelivery(false);      
          }
        });

      const { mutate: mutateEntityFeature } = useMutation<any,Error,IFeatureDescription>(executeEntityFeature, {        
        onSuccess: (data: any) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 });  
        
                // if add enum item, reload items...   
                refetchEnumerationItems();
        },
        onError: (err: Error) => {
          
          if(error && error.message)
            enqueueSnackbar( `!! ${error?.message}`, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
        }
      });

      
    const {data: _data, refetch} = useQuery<IBilling>(['Billing', _id], () => retrieveEntity('Billing',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems, refetch: refetchEnumerationItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Billing'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_OPERATION_STATUS, Enum_BILLING_STATUS_PURPOSE, Enum_BILLING_TERM_REFERENCE,Enum_PAYMENT_SOURCE,
          Enum_PRICE_PURPOSE, Enum_CURRENCY, Enum_CORPORATION_TYPE ] ));
   
    const {data: enumItems2} = useQuery<IEnumerationItem[]>( ['EnumerationItems2', 'Billing'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_SERVICE_TASK, 
          Enum_ARTICLE_OPTION, Enum_ARTICLE_OPTION_CLASS, Enum_CONSUMPTION_OPTION,
          Enum_MAINTENANCE_CATEGORY, Enum_CONTRACT_SCOPE, Enum_CONTRACT_TYPE, Enum_RENTAL_PERIODICITY  ] ));

    type PersonUsage = 'customer' | 'billing-detail-service' | 'billing-packaging-service' | 'billing-detail-formation';

    const [currentPersonUsage, setCurrentPersonUsage] = useState<PersonUsage>('customer');
    const handleClickOpenPerson = async (event: any, usage: PersonUsage) => {
      setCurrentPersonUsage(usage);
      setOpenPersonFilter(true);
    }

    const handleClickRemovePerson = async (event: any, usage: PersonUsage) => {

      if(usage === 'customer') {
        setValue('customerId', 0);
        setValue('customerFirstName', '');
        setValue('customerLastName', '');
        setValue('customerCorporationName', '');
        setValue('customerCorporationType', '');
        setValue('customerPortable1', '');
        setValue('customerPortable2', '');
        setValue('customerEmail1', '');
        setValue('customerEmail2', '');

      } else if( ['billing-detail-service', 'billing-detail-formation'].includes(usage) ) {

        const billingDetail = getValues().billingDetails.at(productIndex);
        if(isFalsy(billingDetail)) return;

        (refUpdateBillingDetail.current??emptyFunc)(productIndex, {...billingDetail!, 
          personId: -1, studentId: -1 , firstName: '', lastName: '', portable1: '', portable2: '', email1: '', email2: '' }); 

      } else if(usage === 'billing-packaging-service') {
        const billingDetail = getValues().billingDetails.at(productIndex);
        if(isFalsy(billingDetail)) return;
        
        const billingPackagingDetail = billingDetail.billingPackagingDetails.at(billingPackagingServiceIndex);
        if(isFalsy(billingPackagingDetail)) return;

        (refUpdateBillingPackagingDetail.current??emptyFunc)(billingPackagingServiceIndex, {...billingPackagingDetail!, 
          personId: -1, firstName: '', lastName: '', portable1: '', portable2: '', email1: '', email2: '' }); 
      }      
    }

    const [personsCopy, setPersonsCopy] = useState<IPerson[]>([]);
    const [openPersonCopy, setOpenPersonCopy] = useState<boolean>(false);
    const handleClickOpenPersonCopy = async (event: any, usage: PersonUsage) => {
      setCurrentPersonUsage(usage);

      var persons : IPerson[] = [];
      getValues().billingDetails.forEach( billingDetail => {
          if( ['service', 'formation'].includes(billingDetail.type) && billingDetail.personId <= 0 && 
                isFalsy(persons.find( p => p.firstName === billingDetail.firstName && p.lastName === billingDetail.lastName ) ) )
            persons.push( {...defaultPerson, id: -persons.length, firstName: billingDetail.firstName, lastName: billingDetail.lastName} );
          else if(billingDetail.type === 'packaging') {
            billingDetail.billingPackagingDetails.forEach( billingPackagingDetail => {
              if(billingPackagingDetail.type === 'service' && billingPackagingDetail.personId <= 0 && 
                  isFalsy(persons.find( p => p.firstName === billingPackagingDetail.firstName && p.lastName === billingPackagingDetail.lastName ) ) )
                persons.push( {...defaultPerson, id: -persons.length, firstName: billingPackagingDetail.firstName, lastName: billingPackagingDetail.lastName} );
            })
          }
        });

      setPersonsCopy(persons);
      setOpenPersonCopy(true);
    }

    const handlePersonCopyDoubleClick = (event: React.MouseEvent<unknown>, row: IPerson) => {
      const {firstName, lastName, portable1, portable2, email1, email2} = row;

      if(currentPersonUsage === 'billing-detail-service') {
        const billingDetail = getValues().billingDetails.at(productIndex);
        if(isFalsy(billingDetail)) return;

        (refUpdateBillingDetail.current??emptyFunc)(productIndex, {...billingDetail!, 
          personId: 0, firstName, lastName, portable1, portable2, email1, email2 });
      } else if( currentPersonUsage === 'billing-packaging-service') {

        const billingDetail = getValues().billingDetails.at(productIndex);
        if(isFalsy(billingDetail)) return;

        const billingPackagingDetail = billingDetail.billingPackagingDetails.at(billingPackagingServiceIndex);
        if(isFalsy(billingPackagingDetail)) return;

         (refUpdateBillingPackagingDetail.current??emptyFunc)(billingPackagingServiceIndex, {...billingPackagingDetail!, 
            personId: 0, firstName, lastName, portable1, portable2, email1, email2 }); 
      }

      setOpenPersonCopy(false);
    }
    

    type EmployeeUsage = 'business' | 'billing-detail-service' | 'billing-packaging-service' | 'billing-service' | 'billing-packaging' | 'fee';
    const [currentEmployeeUsage, setCurrentEmployeeUsage] = useState<EmployeeUsage>('billing-detail-service');

    const handleClickSelectEmployee = (event: any, employeeUsage: EmployeeUsage) => {
      setCurrentEmployeeUsage(employeeUsage);      
      setOpenEmployeeFilter(true);
    }

    const handleClickResetEmployee = (event: any, employeeUsage: EmployeeUsage) => {
      
        if(employeeUsage === 'business') {
          setValue('businessEmployeeId', 0);
          setValue('businessEmployeeFullName', '');
          setValue('businessEmployeeManagerFullName', '');
        }  else {
          const billingDetail = getValues().billingDetails.at(productIndex);

          if(isFalsy(billingDetail)) return;

          if(employeeUsage === 'fee' ) {
            setValue(`billingDetails.${productIndex}.employeeId`, 0, {shouldValidate: true});
            setValue(`billingDetails.${productIndex}.employeeFullName`, '', {shouldValidate: true});
          } else if(billingDetail.type === 'service') {
            for(var i=0; i<billingDetail.billingServiceTasks.length; i++ ){  
                            
              setValue(`billingDetails.${productIndex}.billingServiceTasks.${i}.employeeId`, 0, {shouldValidate: true});
              setValue(`billingDetails.${productIndex}.billingServiceTasks.${i}.employeeFullName`, `` , {shouldValidate: true});             
            }            
          } else if(billingDetail.type === 'packaging') {
            for(var k=0; k< billingDetail.billingPackagingDetails.length; k++) {
              const billingPackagingDetail = billingDetail.billingPackagingDetails[k];
              for(var i=0; i< billingPackagingDetail.billingServiceTasks.length; i++) {
                                
                setValue(`billingDetails.${productIndex}.billingPackagingDetails.${k}.billingServiceTasks.${i}.employeeId`, 0, {shouldValidate: true});
                setValue(`billingDetails.${productIndex}.billingPackagingDetails.${k}.billingServiceTasks.${i}.employeeFullName`, ``, {shouldValidate: true} );              
              }
            }
          }
      }
    }


    const [openCommissionDistribution, setOpenCommissionDistribution] = useState<boolean>(false);
    const handleClickCommissionDistribution = async (event: any) => {

      const arr = await getCommissionDistributionsByBilling(getValues().id);
      setCommissionDistributions(arr);
      setOpenCommissionDistribution(true);
    }

    const [productIndex, setProductIndex] = useState<number>(-1);
        
    const getArticleOptionBillingDetailList = (row: IBillingDetail, cellId: keyof IBillingDetail, 
      opts: {value: string, name: string}[]) => {        
      
      const {articleFilterOption} = row;
      const enumsFilter = (refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);
    
      return getAsOptions(enumsFilter ,Enum_ARTICLE_OPTION);
    }

    const cellEditableBillingDetail = (row: IBillingDetail, cellId: keyof IBillingDetail) => { 
      
      // if(row.type === 'article' && cellId === 'quantity') return true;
      // if(row.type === 'article' && cellId === 'articleOption') return true;

      return true;
    }

    const cellAllowedDiscount = (row: IBillingDetail, valueAmount: number) => {
      const {type, maxDiscountAmount, loaded, baseAmount, unityAmount} = row;      
      
      if( (valueAmount<0) || (valueAmount>0 && loaded>0) ) {
        enqueueSnackbar( `${t('Invalid amount')} ${valueAmount} : [0-${maxDiscountAmount}]` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      if( valueAmount > 0 && maxDiscountAmount<valueAmount) {
        enqueueSnackbar( `${t('The maximum discount amount is')} : ${maxDiscountAmount}` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      if(['article','consumption', 'rental'].includes(type) && valueAmount > unityAmount){
        enqueueSnackbar( `${t('The maximum discount amount is')} : ${unityAmount}` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      if( !['article','consumption', 'rental'].includes(type) && valueAmount > baseAmount){
        enqueueSnackbar( `${t('The maximum discount amount is')} : ${baseAmount}` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      debouncedAmount();
      return true;
    }

    const cellAllowedLoaded = (row: IBillingDetail, valueAmount: number) => {
      const {maxLoadedAmount, discount} = row;      

      if((valueAmount<0) || (valueAmount>0 && discount > 0) ) {
        enqueueSnackbar( `${t('Invalid amount')} : [0-${maxLoadedAmount}]` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      if( valueAmount > 0 && maxLoadedAmount<valueAmount) {
        enqueueSnackbar( `${t('The maximum loaded amount is')} : ${maxLoadedAmount}` ,
           { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return false;
      }

      debouncedAmount();
      return true;
    }

    const debouncedAmount = useRef(
      debounce( () => {
        const index = refProductIndex.current;

          const billingDetail = getValues().billingDetails.at(index); 
          if(isFalsy(billingDetail)) return; 

          const {type, quantity, rentalDuration ,discount, loaded, baseAmount, 
              netAmount, tax ,unityAmount, isTaxable, isDefaultTaxFormula} = billingDetail!;

          const qtyFactor = ['article','consumption'].includes(type) ? quantity : rentalDuration;
          
          const newNetAmount = ['article','consumption', 'rental'].includes(type) ? (unityAmount-discount+loaded)*qtyFactor : baseAmount-discount+loaded;
          const newBaseAmount = ['article','consumption', 'rental'].includes(type) ? unityAmount*qtyFactor : baseAmount;

          if(newNetAmount < 0)  {
            enqueueSnackbar( `${t('Invalid data !!!')} : ${newNetAmount}` ,
                { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
            return;
          }

          let newTax = isTaxable? (getValues().defaultTaxRate*newNetAmount)/100 : 0;
          if(!isDefaultTaxFormula) {
            // TO DO: Evaluate base on expression of tax for the product.
          } 

          (refUpdateBillingDetail.current??emptyFunc)(index, {...billingDetail!, 
              baseAmount: newBaseAmount, netAmount: newNetAmount, tax: newTax, amount: newNetAmount+newTax  });  
          setTotalAmount();
      }, 700)
    ).current;

    const [headBillingDetailCells, setHeadBillingDetailCells]  = useState<HeadCell<IBillingDetail>[]>([]);
    useEffect(() => {
      setHeadBillingDetailCells([   
        {id:'type', label : t('Type'),  display: false, type: 'string', width: 15},   
        {id:'productName', label : t('Product'),  display: true, type: 'string', width: 40}, 
  
        {id:'baseAmount', label : t('Base'),  display: true, type: 'numeric', width: 12, decimalScale: 2, }, 
        {id:'discount', label : t('Discount'),  display: true, type: 'numeric', width: 12, 
          isEditable: cellEditableBillingDetail, isAllowed: cellAllowedDiscount, decimalScale: 2}, 
        {id:'loaded', label : t('Loaded'),  display: true, type: 'numeric', width: 12, decimalScale: 2, 
          isEditable: cellEditableBillingDetail, isAllowed: cellAllowedLoaded}, 
        {id:'isDelivered', label : t('Done ?'),  display: true, type: 'boolean', width: 12}, 
        
        
        {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 12, decimalScale: 2 },           
      ]);
    }, [t,i18n]);
        

    const refAppendBillingDetails = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateBillingDetail = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
    const refRemoveBillingDetail = useRef<(index: number ) => void>(null);
      
    const handleBillingDetailSelected = (event: React.MouseEvent<unknown>,index: number,row: IBillingDetail) => {
      billingDetailSelected(index);
    }

    const billingDetailSelected = async (index: number) => {
      
      setProductIndex(index);
    }

    const cellEditableBillingInfo = (row: IBillingInfo, cellId: keyof IBillingInfo) => { 
      return true;
  }

  const [headBillingInfoCells, setHeadBillingInfoCells]  = useState<HeadCell<IBillingInfo>[]>([]);
  useEffect(() => {
    setHeadBillingInfoCells([   
      {id:'description', label : t('Description'),  display: true, type: 'string', width: 60, isEditable: cellEditableBillingInfo},   
      {id:'designation', label : t('Designation'),  display: true, type: 'string', width: 40, isEditable: cellEditableBillingInfo}, 
    ]);
  }, [t,i18n]);
      

  const refAppendBillingInfos = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBillingInfo = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveBillingInfo = useRef<(index: number ) => void>(null);
  
  const billingInfoRowActionIcon = ( row: IBillingInfo) : ActionIconTableRow<IBilling,IBillingInfo> => {
      
    
    const res: ActionIconTableRow<IBilling,IBillingInfo> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: (getValues().status === '95'),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBillingInfo) => {
        
        (refRemoveBillingInfo.current??emptyFunc)(index);                        
      }
    }
    return res;
}

    const cellEditableBillingDetailInfo = (row: IBillingDetailInfo, cellId: keyof IBillingDetailInfo) => { 
        return true;
    }

    const [headBillingDetailInfoCells, setHeadBillingDetailInfoCells]  = useState<HeadCell<IBillingDetailInfo>[]>([]);
    useEffect(() => {
      setHeadBillingDetailInfoCells([   
        {id:'description', label : t('Description'),  display: true, type: 'string', width: 60, isEditable: cellEditableBillingDetailInfo},   
        {id:'designation', label : t('Designation'),  display: true, type: 'string', width: 40, isEditable: cellEditableBillingDetailInfo}, 
      ]);
    }, [t,i18n]);
        

    const refAppendBillingDetailInfos = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdateBillingDetailInfo = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
    const refRemoveBillingDetailInfo = useRef<(index: number ) => void>(null);
     
    const billingDetailInfoRowActionIcon = ( row: IBillingDetailInfo) : ActionIconTableRow<IBilling,IBillingDetailInfo> => {
      
    
      const res: ActionIconTableRow<IBilling,IBillingDetailInfo> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: (getValues().status === '95'),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IBillingDetailInfo) => {
          
          (refRemoveBillingDetailInfo.current??emptyFunc)(index);                        
        }
      }
      return res;
  }

    const [openBillingDetailDuplicateDialog, setOpenBillingDetailDuplicateDialog] = useState<boolean>(false);
    const handleBillingDetailRowDoubleClick = (event: React.MouseEvent<unknown>,index: number,row: IBillingDetail) => {
      billingDetailSelected(index);
      const billingDetail = getValues().billingDetails.at(index);
      if(isFalsy(billingDetail)) return;

      if(!['service', 'package'].includes(billingDetail.type)) return;

      setOpenBillingDetailDuplicateDialog(true);
    }

    const handleOkBillingDetailDuplicate = (event: any) => {

      const billingDetail = getValues().billingDetails.at(productIndex);
      if(isFalsy(billingDetail)) return;
      
      if(!['service', 'package'].includes(billingDetail.type)) return;

      const {serviceDuplicateCount } = getValues(); 
      if(serviceDuplicateCount < 2 ) return;

      (refAppendBillingDetails.current??emptyFunc)( range(0, serviceDuplicateCount - 1).map(i => ({...billingDetail})) ); 

      setOpenBillingDetailDuplicateDialog(false);
    }
    

    const handleAddServices = (event: any) => {      
      setOpenServiceFilter(true);
    }

    const handleAddFees = (event: any) => {      
      setOpenFeeFilter(true);
    }
    
    const handleAddArticles = (event: any) => {
      //(refAppendBillingDetails.current??emptyFunc)({id: 0, resourceTypeId: _id, taskCode: '', part: 0,  });
      setOpenArticleFilter(true);
    }

    const handleAddPackagings = (event: any) => {
      //(refAppendBillingDetails.current??emptyFunc)({id: 0, resourceTypeId: _id, taskCode: '', part: 0,  });
      setOpenPackagingFilter(true);
    }

    const handleAddFormations = (event: any) => {      
      setOpenFormationFilter(true);
    }

    const handleAddMaintenances = (event: any) => {      
      setOpenMaintenanceFilter(true);
    }

    const handleAddContracts = (event: any) => {      
      setOpenContractFilter(true);
    }
    
    const handleAddRentals = (event: any) => {      
      setOpenRentalFilter(true);
    }

    const handleAddConsumptions = (event: any) => {      
      setOpenConsumptionFilter(true);
    }

    const handleAddBillingInfos = (event: any) => {      
      if(getValues().proxy.billingInfos.some( x => isFalsy(x.description) && isFalsy(x.designation))) return;

      (refAppendBillingInfos.current??emptyFunc)({id: 0, billingId: 0, description: '', designation: '' });   
    }

    const handleAddBillingDetailInfos = (event: any) => {      
      const billingDetail = getValues().billingDetails.at(productIndex);
      if(isFalsy(billingDetail)) return;

      if(billingDetail.billingDetailInfos.some( x => isFalsy(x.description) && isFalsy(x.designation))) return;

      (refAppendBillingDetailInfos.current??emptyFunc)({id: 0, billingDetailId: billingDetail.id, description: '', designation: '' });   
    }
    // const handleAddNewRessources = (event: any) => {
    //   (refAppendBillingDetails.current??emptyFunc)({id: 0, maintenanceId: _id, resourceId: 0,
    //     resourceDescription: '', resourceTypeId: 0, resourceTypeName: '',
    //     maintenanceReference: '', maintenanceDescription: '', maintenanceSupplierFullName: '',
    //     description: '', amount: 0,  });
    // }
    const  [openBillingDetailServiceDelivery, setOpenBillingDetailServiceDelivery] = useState<boolean>(false);

    const billingDetailRowActionIcon = ( row: IBillingDetail) : ActionIconTableRow<IBilling,IBillingDetail> => {
      
      const roleEntityBilling = roleEntities.find(re => re.entityName === 'Billing');

      const canDeliverService = !isFalsy(roleEntityBilling) && 
                          roleEntityBilling.roleEntityFeatures.some(f => f.featureName === 'DeliverBillingDetailBillingHandler');

      const res: ActionIconTableRow<IBilling,IBillingDetail> = {
        toolTip: 'viewDetails',
        icon: getValues().status === '95'? RemoveCircleIcon : CheckIcon,
        hasAction: (getValues().status === '95') || (getValues().status === '10' && !row.isDelivered && canDeliverService ),  // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IBillingDetail) => {
          
          setProductIndex(index);

          if(getValues().status === '95') {
            if(index>0)
              billingDetailSelected(index-1);  
            else if(getValues().billingDetails.length>1)
              billingDetailSelected(index+1);  
            else billingDetailSelected(index-1); 
          
            (refRemoveBillingDetail.current??emptyFunc)(index);  
            
            setTotalAmount(); 
          } else if(getValues().status === '10') {
            setOpenBillingDetailServiceDelivery(true);
          }                      
        }
      }
      return res;
  }

  

  const handleOkBillingDetailServiceDelivery = async () => {

    if(productIndex < 0 || productIndex >= getValues().billingDetails.length ) return;

    const billingDetail = getValues().billingDetails.at(productIndex);
    if(isFalsy(billingDetail)) return;

    const {id, deliveryDate, isDelivered, isDeliveryDateDefine} = billingDetail;
    mutateDeliverBillingDetail({id, deliveryDate, isDelivered, isDeliveryDateDefine});
  }


  const [billingServiceTaskIndex, setBillingServiceTaskIndex] = useState<number>(-1);

  const getInputAdornmentServiceTaskEmployee = (row: IBillingServiceTask, cellId: keyof IBillingServiceTask)  => ({
  
    toolTip: 'Employee',
    icon: ArrowDropDownCircleIcon,
    //iconDisable: row.resourceId > 0,
    onClickIcon: (event: any, index: number, row: IBillingServiceTask ) => {

      setCurrentEmployeeUsage('billing-detail-service');
      setBillingServiceTaskIndex(index);
      setOpenEmployeeFilter(true);
    }  
  })

  const getServiceTaskCode = (row: IBillingServiceTask, cellId: keyof IBillingServiceTask, 
    opts: {value: string, name: string}[]) => {        
    
      return getAsOptions(refEnumItems2.current ?? [],Enum_SERVICE_TASK);
  }

  const [headBillingServiceTaskCells, setHeadBillingServiceTaskCells]  = useState<HeadCell<IBillingServiceTask>[]>([   
    {id:'taskCode', label : t('Task'),  display: true, type: 'string', width: 35, getOptions: getServiceTaskCode},   
    //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
    // {id:'serviceName', label : t('Service'),  display: true, type: 'string', width: 35, 
    //   isEditable: cellEditableBillingDetail},
    // {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 35, 
    //   getInputAdornment: getInputAdornmentEmployee},

    // {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 15, isEditable: cellEditableBillingDetail},
    {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 60, 
      getInputAdornment: getInputAdornmentServiceTaskEmployee}, 

    {id:'part', label : t('Part'),  display: true, type: 'numeric', width: 5, },           
  ]);

  const refAppendBillingServiceTasks = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBillingServiceTask = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveBillingServiceTask = useRef<(index: number ) => void>(null);

  const billingServiceTaskRowActionIcon = ( row: IBillingServiceTask) : ActionIconTableRow<IBilling,IBillingServiceTask> => {
  
    const res: ActionIconTableRow<IBilling,IBillingServiceTask> = {
      toolTip: 'viewDetails',
      icon: RemoveCircleIcon,
      hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
      isActionExecuting: true,
      onRowClickIcon: (event : any,index: number, row: IBillingServiceTask) => {
        
         (refRemoveBillingServiceTask.current??emptyFunc)(index);            
      }
    }
    return res;
}


const cellAllowedCapacity = (row: IBillingRentalResource, valueCapacity: number) => {
  const {resourceCapacity } = row;

  const allowed = valueCapacity>=0 && valueCapacity<=resourceCapacity;
  debouncedResourceCapacity();

  return allowed;
}

const debouncedResourceCapacity = useRef(
  debounce( () => {

    // const sumAmount = sum( getValues().tellerOperationModes.map( ({amount}) => amount ) );
    // setTotalAmountMode(sumAmount);
  }, 300)
).current;

const cellEditableBillingRentalResource = (row: IBillingRentalResource, cellId: keyof IBillingRentalResource) => { 
      
  //if(row.type === 'article' && cellId === 'quantity') return true;
  // if(row.type === 'article' && cellId === 'articleOption') return true;

  return !row.rentalResourceIsCapacityFixed;
}
const [headBillingRentalResourceCells, setHeadBillingRentalResourceCells]  = useState<HeadCell<IBillingRentalResource>[]>([]);
useEffect(() => {
  setHeadBillingRentalResourceCells([   
    {id:'resourceName', label : t('Name'),  display: true, type: 'string', width: 50},   
    {id:'resourceCapacity', label : t("Resource's capacity"),  display: true, type: 'numeric', width: 25, decimalScale: 2},   
    //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
    // {id:'serviceName', label : t('Service'),  display: true, type: 'string', width: 35, 
    //   isEditable: cellEditableBillingDetail},
    // {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 35, 
    //   getInputAdornment: getInputAdornmentEmployee},

    // {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 15, isEditable: cellEditableBillingDetail},
    

    {id:'capacity', label : t('Allocated capacity'),  display: true, type: 'numeric', width: 25, 
      isEditable: cellEditableBillingRentalResource, isAllowed: cellAllowedCapacity, decimalScale: 2},           
  ]);
}, [t,i18n]);


const refAppendBillingRentalResources = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateBillingRentalResource = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
const refRemoveBillingRentalResource = useRef<(index: number ) => void>(null);

const getArticleOptionBillingPackagingDetailList = (row: IBillingPackagingDetail, cellId: keyof IBillingPackagingDetail, 
  opts: {value: string, name: string}[]) => {        
  
  const {articleFilterOption} = row;
  const enumsFilter = (refEnumItems.current ?? []).filter(x => x.parentEnumerationItemCode === articleFilterOption);

  return getAsOptions(enumsFilter ,Enum_ARTICLE_OPTION);
}

const cellEditableBillingPackagingDetail = (row: IBillingPackagingDetail, cellId: keyof IBillingPackagingDetail) => {   
  if(row.type === 'service') return false;   
  return true;
}

const [headBillingPackagingDetailCells, setHeadBillingPackagingDetailCells]  = useState<HeadCell<IBillingPackagingDetail>[]>([]);
useEffect(() => {
  setHeadBillingPackagingDetailCells([   
    {id:'productName', label : t('Name'),  display: true, type: 'string', width: 55},   
    //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
    // {id:'serviceName', label : t('Service'),  display: true, type: 'string', width: 35, 
    //   isEditable: cellEditableBillingDetail},
    // {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 35, 
    //   getInputAdornment: getInputAdornmentEmployee},
  
    {id:'articleOption', label : t('Option'),  display: true, type: 'string', width: 40, 
      isEditable: cellEditableBillingPackagingDetail, getOptions: getArticleOptionBillingPackagingDetailList},
   
    {id:'part', label : t('Part'),  display: true, type: 'numeric', width: 5, },           
  ]);
}, [t,i18n]);



const refAppendBillingPackagingDetails = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
const refUpdateBillingPackagingDetail = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
const refRemoveBillingPackagingDetail = useRef<(index: number ) => void>(null);


const [billingPackagingServiceIndex, setBillingPackagingServiceIndex] = useState<number>(-1);
const [openBillingPackagingService, setOpenBillingPackagingService] = useState<boolean>(false);
const billingPackagingDetailRowActionIcon = ( row: IBillingPackagingDetail) : ActionIconTableRow<IBilling,IBillingPackagingDetail> => {
    
  const res: ActionIconTableRow<IBilling,IBillingPackagingDetail> = {
    toolTip: 'viewDetails',
    icon: MoreVertIcon,
    hasAction: row.type === 'service' , // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
    isActionExecuting: true,
    onRowClickIcon: (event : any,index: number, row: IBillingPackagingDetail) => {
      
      setBillingPackagingServiceIndex(index);    
      setBillingPackagingServiceTaskIndex(0); 
      setOpenBillingPackagingService(true);       
    }
  }
  return res;
}


  const [billingPackagingServiceTaskIndex, setBillingPackagingServiceTaskIndex] = useState<number>(-1);

  const getInputAdornmentPackagingServiceTaskEmployee = (row: IBillingServiceTask, cellId: keyof IBillingServiceTask)  => ({
  
    toolTip: 'Subject',
    icon: ArrowDropDownCircleIcon,
    //iconDisable: row.resourceId > 0,
    onClickIcon: (event: any, index: number, row: IBillingServiceTask ) => {
      
      setCurrentEmployeeUsage('billing-packaging-service');
      setBillingPackagingServiceTaskIndex(index);
      setOpenEmployeeFilter(true);
    }  
  })

  const getPackagingServiceTaskCode = (row: IBillingServiceTask, cellId: keyof IBillingServiceTask, 
    opts: {value: string, name: string}[]) => {        
    
      return getAsOptions(refEnumItems2.current ?? [],Enum_SERVICE_TASK);
  }

  const [headBillingPackagingServiceTaskCells, setHeadBillingPackagingServiceTaskCells]  = useState<HeadCell<IBillingServiceTask>[]>([]);
  useEffect(() => {
    setHeadBillingPackagingServiceTaskCells([   
      {id:'taskCode', label : t('Task'),  display: true, type: 'string', width: 35, getOptions: getServiceTaskCode},   
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      // {id:'serviceName', label : t('Service'),  display: true, type: 'string', width: 35, 
      //   isEditable: cellEditableBillingDetail},
      // {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 35, 
      //   getInputAdornment: getInputAdornmentEmployee},
  
      // {id:'startTime', label : t('Start'),  display: true, type: 'datetime', width: 15, isEditable: cellEditableBillingDetail},
      {id:'employeeFullName', label : t('Employee'),  display: true, type: 'string', width: 60, 
        getInputAdornment: getInputAdornmentPackagingServiceTaskEmployee}, 
  
      {id:'part', label : t('Part'),  display: true, type: 'numeric', width: 5, },           
    ]);
  }, [t,i18n]);
  

  const refAppendBillingPackagingServiceTasks = useRef<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>(null);
  const refUpdateBillingPackagingServiceTask = useRef<(index: number,value: Partial<FieldArray<IBilling>> ) => void>(null);
  const refRemoveBillingPackagingServiceTask = useRef<(index: number ) => void>(null);


  const handleCustomerType = ( event: React.MouseEvent<HTMLElement>, newCustomerType: PersonType ) => {
  
    if(newCustomerType === null) return;
    setValue('customerPersonType', newCustomerType);
  };

  
  useEffect(() => {
    
      const subscription = watch( async (value, { name, type }) => {
        
        if( name?.startsWith('billingDetails') && !name?.includes('billingPackagingDetails') && (name?.endsWith('firstName') || name?.endsWith('lastName')) ) {
            
          setCurrentSearchPersonUsage('billing-detail-beneficiary');         
          debouncedDetailPersonChange();                          
        } else if(name?.startsWith('billingDetails') && name?.includes('billingPackagingDetails') && (name?.endsWith('firstName') || name?.endsWith('lastName'))) {

          setCurrentSearchPersonUsage('billing-packaging-beneficiary');         
          debouncedPackagingPersonChange();
        } else if(name?.startsWith('billingDetails') && name?.endsWith('quantity')) {
          debouncedQuantityChange();
        } else if(name?.startsWith('billingDetails') && (name?.endsWith('rentalDuration') || name?.endsWith('rentalEffectiveDate')  )) {
          debouncedRentalDurationChange();
        }
      });  
  
      return () => subscription.unsubscribe();
    }, [watch]);


  const setTotalAmount = () => {
    const sumNetAmount = sum( getValues().billingDetails.map( ({netAmount}) => netAmount ) );
    const sumTaxAmount = sum( getValues().billingDetails.map( ({tax}) => tax ) );
    const sumAmount = sum( getValues().billingDetails.map( ({amount}) => amount ) );

    setValue('netAmount', sumNetAmount);
    setValue('tax', sumTaxAmount);
    setValue('amount', sumAmount);
  }

  const [openBillingDetailInfo, setOpenBillingDetailInfo] = useState<boolean>(false);
  const handleLinkClickBillingDetailInfo = (event: any) => {
    setOpenBillingDetailInfo(true);
  }

  const [openBillingInfo, setOpenBillingInfo] = useState<boolean>(false);
  const handleLinkClickBillingInfo = (event: any) => {
    setOpenBillingInfo(true);
  }

  const handleClickBeneficiaryIsCustomer = (event: any,prodIndex: number, beneficiaryIsCustomer: boolean) => {
    const billingDetail = getValues().billingDetails.at(prodIndex);
    if(isFalsy(billingDetail)) return;

    (refUpdateBillingDetail.current??emptyFunc)(prodIndex, {...billingDetail!, beneficiaryIsCustomer }); 
  }
  
  const handleClickPackagingBeneficiaryIsCustomer = (event: any,bilPackServiceIndex: number, beneficiaryIsCustomer: boolean) => {
    const billingDetail = getValues().billingDetails.at(productIndex);
    if(isFalsy(billingDetail)) return;

    const billingPackagingService = billingDetail.billingPackagingDetails.at(bilPackServiceIndex);
    if(isFalsy(billingPackagingService)) return;

    (refUpdateBillingPackagingDetail.current??emptyFunc)(bilPackServiceIndex, {...billingPackagingService!, beneficiaryIsCustomer }); 
  }

  const [currentCrudAction, setCurrentCrudAction] = useState<'C'|'U'>('C');
  const [openActionParams, setOpenActionParams] = useState<boolean>(false);
  const [actionParamsDialogTitle, setActionParamsDialogTitle] = useState<string>('');

  // const [canAddEnumerationItem, setCanAddEnumerationItem] = useState<boolean>(
  //   roleEntities.some(e => e.entityName === 'Enumeration') && 
  //   (roleEntities.find(e => e.entityName === 'Enumeration')?.roleEntityFeatures || []).some(f => 
  //             f.featureName === 'AddBasicEnumerationWithEnumerationItemEnumerationHandler') );

  // const [canUpdateEnumerationItem, setCanUpdateEnumerationItem] = useState<boolean>(
  //   roleEntities.some(e => e.entityName === 'Enumeration') && 
  //   (roleEntities.find(e => e.entityName === 'Enumeration')?.roleEntityFeatures || []).some(f => 
  //             f.featureName === 'UpdateBasicEnumerationItemEnumerationHandler') );


  //   const handleAddEnumerationItemClick = (crudAction: 'C'|'U', 
  //             enumerationCode: string, enumerationName: string, enumerationDescription: string,
  //             enumerationItemCode: string, enumerationItemName: string, enumerationItemDescription: string) => {
    
  //   setCurrentCrudAction(crudAction);
  //   resetEntityAction({...defaultFeatureDescription, 
  //       name: crudAction === 'C' ? 
  //               'AddBasicEnumerationWithEnumerationItemEnumerationHandler' : 
  //               'UpdateBasicEnumerationItemEnumerationHandler', 
  //       label: '', 
  //       inputType: 0, type: 2,

  //       entityName: 'Enumeration', entityId: 0, entityItemName: '',
  //       // 
  //       params: [
  //         {name: 'Code', label: `${t('Codification')} - ${t('Code')} `, isReadonly: true, dataType: 'Base', type: 'string', enumerationCode: '', value: enumerationCode, dateValue: new Date()},
  //         {name: 'Name', label: `${t('Codification')} - ${t('Name')} `, isReadonly: true, dataType: 'Base', type: 'string', enumerationCode: '', value: enumerationName, dateValue: new Date()},
  //         {name: 'Description', label: `${t('Codification')} - ${t('Description')} `, isReadonly: true, dataType: 'Base', type: 'string', enumerationCode: '', value: enumerationDescription, dateValue: new Date()},

  //         {name: 'EnumerationItemCode', label: t('Code'), dataType: 'Base', type: 'string', enumerationCode: '', value: enumerationItemCode, dateValue: new Date()},
  //         {name: 'EnumerationItemName', label: t('Name'), dataType: 'Base', type: 'string', enumerationCode: '', value: enumerationItemName, dateValue: new Date()},
  //         {name: 'EnumerationItemDescription', label: t('Description'), dataType: 'Base', type: 'string', enumerationCode: '', value: enumerationDescription, dateValue: new Date()},
  //         // {name: 'Code', label: 'Code', dataType: 'Base', type: 'string', enumerationCode: '', value: '', dateValue: new Date()},
  //         // {name: 'Code', label: 'Code', dataType: 'Base', type: 'string', enumerationCode: '', value: '', dateValue: new Date()},
  //         // {name: 'Code', label: 'Code', dataType: 'Base', type: 'string', enumerationCode: '', value: '', dateValue: new Date()},
  //       ]
  //     });
  //   setActionParamsDialogTitle(`${t('New')} : ${enumerationName}`)
  //   setOpenActionParams(true);
  // }

  const handleOkActionParams = () => {   
    setOpenActionParams(false);
    
    const actionSelected = getEntityActionValues(); 
    mutateEntityFeature( actionSelected );
}
  

  const debouncedQuantityChange = useRef(
    debounce( async () => {      
      
      const index = refProductIndex.current;

      const billingDetail = getValues().billingDetails.at(index);
      if(isFalsy(billingDetail)) return;
      
      const {type, quantity,  rentalDuration,articleOption, isTaxable, isDefaultTaxFormula,discount, loaded, 
                    baseAmount, unityAmount, isPriceDpendsOnQuantity} = billingDetail!;
      if(isPriceDpendsOnQuantity) {
        alert('price depends on quantity'); // TO DO:
      }
    

      const qtyFactor = ['article','consumption'].includes(type) ? quantity : rentalDuration;

      const newNetAmount = ['article','consumption', 'rental'].includes(type) ? (unityAmount-discount+loaded)*qtyFactor : baseAmount-discount+loaded;
      const newBaseAmount = ['article','consumption', 'rental'].includes(type) ? unityAmount*qtyFactor : baseAmount;

      if(newNetAmount < 0)  {
        enqueueSnackbar( `${t('Invalid data !!!')} : ${newNetAmount}` ,
            { variant: 'warning', anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
        return;
      }

      let newTax = isTaxable ? (getValues().defaultTaxRate*newNetAmount)/100 : 0;
      if(!isDefaultTaxFormula) {
        // TO DO: Evaluate base on expression of tax for the product.
      }

      (refUpdateBillingDetail.current??emptyFunc)(index, {...billingDetail!, 
          baseAmount: newBaseAmount, netAmount: newNetAmount, tax: newTax, amount: newNetAmount+newTax });

      setTotalAmount();

    }, 1500)
  ).current;


  const debouncedRentalDurationChange = useRef(
    debounce( async () => {      
      
      const index = refProductIndex.current;

      const billingDetail = getValues().billingDetails.at(index);
      if(isFalsy(billingDetail)) return;
      
      const {type, rentalDuration, rentalPeriodicity, rentalId, isTaxable, isDefaultTaxFormula} = billingDetail!;
      
      setIsWaitingBoxShow(true);
      const res = await evaluateAddedProduct({...getValues(), productId: rentalId, billingDetail});
      setIsWaitingBoxShow(false);

      const {baseAmount, discount, loaded, tax, unityAmount } = res.data;

      const newNetAmount = (unityAmount-discount+loaded)*rentalDuration;
      let newTax = isTaxable ? (getValues().defaultTaxRate*newNetAmount)/100 : 0;
      if(!isDefaultTaxFormula) {
        // TO DO: Evaluate base on expression of tax for the product.
      }

      const effectiveDate = billingDetail!.rentalEffectiveDate;
      const expirationDate = addDurationInPeriod(effectiveDate, rentalPeriodicity ,rentalDuration);      

      (refUpdateBillingDetail.current??emptyFunc)(index, {...billingDetail!, rentalExpirationDate: expirationDate,
          baseAmount, netAmount: newNetAmount, tax: newTax, amount: newNetAmount+newTax });

      setTotalAmount();

    }, 1500)
  ).current;

  const debouncedDefaultTaxRateChange = useRef(
    debounce( async () => {                  
      
      const {billingDetails, defaultTaxRate } = getValues();

      setValue('billingDetails', 
        billingDetails.map(b => ({...b, 
            tax:  b.isTaxable ? (b.netAmount*defaultTaxRate)/100 : 0, 
            amount: b.isTaxable ? (b.netAmount*(100+defaultTaxRate))/100 : b.netAmount }))
      );     
      
      setTotalAmount();
    }, 1500)
  ).current;
  

  const debouncedCustomerChange = useRef(
    debounce( async () => {      
      const { customerId, customerFirstName, customerLastName, customerCorporationName, customerPersonType } = getValues();

      if(customerId>0) return;

      const fn = customerPersonType === 'physical' ? customerFirstName: customerCorporationName;
      const ln = customerPersonType === 'physical' ? customerLastName: customerCorporationName;
      
      if( (isFalsy(fn) || fn === '') && (isFalsy(ln) || ln === '' )) return;

      const count = await getPersonsSearchCount(fn, ln, '');

      if(!isFalsy(refCustomerSnackbarId.current)) closeSnackbar(refCustomerSnackbarId.current!);
      
      if(count > 0) 
        refCustomerSnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch('customer') } );             

    }, 1500)
  ).current;

  const debouncedDetailPersonChange = useRef(
    debounce( async () => {      

      const billingDetail = getValues().billingDetails.at(productIndex);
      if(isFalsy(billingDetail)) return;

      const { personId, firstName, lastName } = billingDetail!;

      if(personId>0) return;

      if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' )) return;

      const count = await getPersonsSearchCount(firstName??'', lastName??'', '');

      if(!isFalsy(refDetailBeneficiarySnackbarId.current)) closeSnackbar(refDetailBeneficiarySnackbarId.current!);
      
      if(count > 0) 
        refDetailBeneficiarySnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch('billing-detail-beneficiary') } );    
    }, 1500)
  ).current;

  const debouncedPackagingPersonChange = useRef(
    debounce( async () => {      
      
      const billingDetail = getValues().billingDetails.at(refProductIndex.current);
      
      if(isFalsy(billingDetail)) return;

      const billingPackagingService = billingDetail.billingPackagingDetails.at(refBillingPackagingServiceIndex.current);
      
      if(isFalsy(billingPackagingService)) return;

      const { personId, firstName, lastName } = billingPackagingService!;

      if(personId>0) return;

      if( (isFalsy(firstName) || firstName === '') && (isFalsy(lastName) || lastName === '' )) return;

      const count = await getPersonsSearchCount(firstName??'', lastName??'', '');

      if(!isFalsy(refPackagingBeneficiarySnackbarId.current)) closeSnackbar(refPackagingBeneficiarySnackbarId.current!);
      
      if(count > 0) 
        refPackagingBeneficiarySnackbarId.current = enqueueSnackbar( `${t('Identity')} --> ${t('You have to check if person exists before save, verify it in this list of')} : ${count} ${t('_person')}(s)`, { variant: 'warning',
              anchorOrigin : { horizontal: 'left', vertical: 'bottom' }, persist : true, //autoHideDuration : 5000,
              action: xActionPersonSearch('billing-packaging-beneficiary') } );    
    }, 1500)
  ).current;

  const refCustomerSnackbarId = useRef<SnackbarKey>();
  const refDetailBeneficiarySnackbarId = useRef<SnackbarKey>();
  const refPackagingBeneficiarySnackbarId = useRef<SnackbarKey>();

  const xActionPersonSearch = (searchPersonUsage: SearchPersonUsage): SnackbarAction => (snackbarId: SnackbarKey) => (
    <>        
        <Button onClick={() => { 
                // setUrlPdf(`${globalConfig.get().apiUrl}/Download/${fileToken}/${fileName}`); 
                setCurrentSearchPersonUsage(searchPersonUsage);                  
                setOpenPersonSearchFilter(true);
                closeSnackbar(snackbarId); }}>   
            <GrSearch size={24} />
        </Button>
        <Button onClick={() => { closeSnackbar(snackbarId) }}>
            <GrClose size={24} />
        </Button>
    </>
)

  useEffect( () => {     
             
    debouncedDefaultTaxRateChange();      
  }, [watchDefaultTaxRate]);

  useEffect( () => {     
    setCurrentSearchPersonUsage('customer');         
    debouncedCustomerChange();      
  }, [watchCustomerFirstName, watchCustomerLastName, watchCustomerCorporationName ,debouncedCustomerChange]);

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems]);

  const refEnumItems2 = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems2.current = enumItems2;
    
  }, [enumItems2]);


  
  useEffect( () => {   
    refProductIndex.current = productIndex;
  
  }, [productIndex]);

  
  useEffect( () => {   
    refBillingPackagingServiceIndex.current = billingPackagingServiceIndex;
  
  }, [billingPackagingServiceIndex]);

 
  useEffect( () => {        
    setCurrentFormNameAtom(t('Billing'));
    setCurrentBasicTextFilterProps(basicFilterBilling);
  }, []);    

  /********** This use effect call retreive data wich will call refetch and _data will be updated. 
    and the new useEffect will take place ********************/
    useEffect( () => {
      _setId(Number(id || 0));
    }, [id] );

  useEffect( () => {
      // setCurrentFormName(t('Billing'));        
      setCurrentFormNameAtom(_id>0?`${t('Billing')} - # ${_id} # -`: t('Billing') );

      if(_id > 0 && getValues().id !== _id) 
        retrieveData('Billing',_id, refetch);  
    }, [_id] );

  useEffect( () => {
      
    if(_data && _data.id > 0) {      //console.log(_data.customerBirthDate); console.log(typeof _data.customerBirthDate);
      reset(_data);
      if(_data.billingDetails.length > 0) billingDetailSelected(0);
    }
  }, [_data, reset]);
  
    const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
      _setId(0);      
      reset({...defaultBilling, showroomId: userStores.find(s => s.isShowroom)?.storeId ?? 0, 
            currencyCode: applicationSetup.defaultCurrencyCode, defaultTaxRate: applicationSetup.defaultTaxRate});    
    }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        setTotalAmount();

        if(!checkEntitySaveAuthorization('Billing', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 

        if(data.showroomId <= 0 || !userStores.some(s => s.storeId === data.showroomId && s.isShowroom) ) {
          enqueueSnackbar( `${t('Invalid data')} :: ${t('Showroom')}`, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( isFalsy(data.currencyCode) ) {
          enqueueSnackbar( `${t('Invalid data')} :: ${t('Currency')}`, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
        
        if( (data.amount <= 0 || data.billingDetails.length <= 0) ) {
          setIsSaveLoading(false);
          return;
        }

        if( (!applicationSetup.isTaxRateVariable && data.defaultTaxRate !== 0  && data.defaultTaxRate !== applicationSetup.defaultTaxRate  ) ) {
          enqueueSnackbar( `${t('Invalid data')} :: ${t('Tax')} : ${data.defaultTaxRate}`, { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 });
          setIsSaveLoading(false);
          return;
        }

        if( (data.customerPersonType === 'physical' && data.customerFirstName.trim() === '' && data.customerLastName.trim() === '') ) {
          enqueueSnackbar( t('The customer is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( (data.customerPersonType === 'moral' && data.customerCorporationName.trim() === '' ) ) {
          enqueueSnackbar( t('The customer is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }
        
        if(data.billingDetails.some( b => b.type === 'service' && !b.beneficiaryIsCustomer 
            && isFalsy(b.firstName) && isFalsy(b.lastName) ) ) {
              enqueueSnackbar( t('The benificiary of service have to be defined'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if(data.billingDetails.some( b => b.type === 'packaging' && 
              b.billingPackagingDetails.some(bp => bp.type === 'service' && !bp.beneficiaryIsCustomer 
                && isFalsy(bp.firstName) && isFalsy(bp.lastName) ) ) ) {
              enqueueSnackbar( t('The benificiary of service have to be defined'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if(data.billingDetails.some( b => b.type === 'service' && b.isDelivered && b.billingServiceTasks.some(t => t.employeeId <= 0) ) ) {
            enqueueSnackbar( t('Employee of a task have to be defined'), { variant: 'warning',
              anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
            setIsSaveLoading(false);
            return;
        }

        if(data.billingDetails.some( b => b.type === 'packaging' && b.isDelivered &&
            b.billingPackagingDetails.some(bp => bp.type == 'service' && bp.billingServiceTasks.some( t => t.employeeId <= 0) ) )) {
          enqueueSnackbar( t('Employee of a task have to be defined'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
      }

        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Billing', _id);
      }

      const printData = async (event: MouseEvent<HTMLButtonElement>) => {      
        openEntityPrintDrawer('Billing', _id);
      }

     
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['Billing',_id]);        
      await retrieveData('Billing',_id, refetch);        
      reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                  <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Identification'))} `}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <ToggleButtonGroup value={watchCustomerPersonType} exclusive key={watchCustomerPersonType} size="small" onChange={handleCustomerType} aria-label="text alignment" fullWidth >
                              <ToggleButton value="physical" aria-label="centered">
                                {t('Physical person')}
                              </ToggleButton>
                              <ToggleButton value="moral" aria-label="centered">
                                {t('Moral person')}
                              </ToggleButton>                                  
                            </ToggleButtonGroup>                                                             
                          </Box>
                                                                                                                                 
                          <Box sx={{ mt: 1, width: '100%' }} key={` person ${getValues().customerId}`}>
                            
                            { (watchCustomerPersonType === 'physical') && <TextField sx={{width:'calc(35% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                              {...register(lg.startsWith('fr')?'customerLastName':'customerFirstName')} inputProps={ {readOnly: (watchCustomerId>0) }} 
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">  
                                  { (isFalsy(watchCustomerId) ||  watchCustomerId<=0) ?
                                      <IconButton color="primary" onClick={(event: any) => handleClickOpenPerson(event, 'customer')}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton> : 
                                      <IconButton color="primary" onClick={(event: any) => handleClickRemovePerson(event, 'customer')}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    }                                                                                                                                     
                                </InputAdornment>
                              ) 
                            }} /> }  
                            { (watchCustomerPersonType === 'physical') && <TextField sx={{width:'calc(35% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                              {...register(lg.startsWith('fr')?'customerFirstName':'customerLastName')} inputProps={ {readOnly: (watchCustomerId>0) }} /> } 
                            { (watchCustomerPersonType === 'moral') && <TextField sx={{width:'calc(45% - 8px)'}} id="ln" label={t('Name')} 
                              {...register('customerCorporationName')} inputProps={ {readOnly: (watchCustomerId>0) }}
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                    { (isFalsy(watchCustomerId) ||  watchCustomerId<=0) ?
                                        <IconButton color="primary" onClick={(event: any) => handleClickOpenPerson(event, 'customer')}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={(event: any) => handleClickRemovePerson(event, 'customer')}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                                                                     
                                  </InputAdornment>
                                ) 
                              }} /> }
                            { (watchCustomerPersonType === 'moral') && <Controller name='customerCorporationType' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="customerCorporationType"
                                    label={t('Type')} inputProps={ {readOnly: false}} focused >
                                    {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_CORPORATION_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                  </TextField>
                                )} />  }  
                            <Controller name={watchCustomerPersonType === 'physical'? 'customerBirthDateType': 'customerCreationDateType'} control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(12% - 8px)'}} id="customerBirthDateType"
                                    label={t('Date type')} inputProps={ {readOnly: false}} focused >
                                    {getBirthDateType().map( 
                                      (x,idx) => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                              />   
                            {(watchCustomerPersonType === 'physical') && <Controller key={`${watchCustomerBirthDateType} dob`} control={control}
                                name={'customerBirthDate'} 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Birth date')} 
                                    onChange={onChange}                
                                    format={watchCustomerBirthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={watchCustomerBirthDateType==='none'?null: new Date(value)}
                                    views={watchCustomerBirthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    disabled={watchCustomerBirthDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}

                                    //renderInput={(params) => <TextField {...params}  sx={{width:'calc(18% - 8px)'}} />}
                                    slotProps={{ textField: { sx: {width:'calc(18% - 8px)'}  }} }
                                  /> )}
                              />  }        
                              {(watchCustomerPersonType === 'moral') && <Controller key={`${watchCustomerCreationDateType} dob`} control={control}
                                name={'customerCreationDate'} 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Creation date')} 
                                    onChange={onChange}                
                                    format={watchCustomerCreationDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={watchCustomerCreationDateType==='none'?null:new Date(value)}
                                    views={watchCustomerCreationDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    disabled={watchCustomerCreationDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    //renderInput={(params) => <TextField {...params}  sx={{width:'calc(18% - 8px)'}} />}
                                    slotProps={{ textField: { sx: {width:'calc(18% - 8px)'}  }} }
                                  /> )}
                              />  }                  
                              { openPersonFilter && <FormDialog open={openPersonFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                onClose={()=> {setOpenPersonFilter(false);}} onOk={()=> {setOpenPersonFilter(false);}}  >
                                    <BasicTextFilterForm<IPerson> {...basicFilterPerson } />
                            </FormDialog> }
                            { openPersonCopy && <FormDialog open={openPersonCopy} maxWidth='sm' height='50vh'
                                okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                onClose={()=> {setOpenPersonCopy(false);}} onOk={()=> {setOpenPersonCopy(false);}}  >
                                  <Box sx={{ mt: 0.25, width: '100%' }} >
                                    <EnhancedTable<IPerson> 
                                      rows={personsCopy.filter(p => !(isFalsy(p.firstName) && isFalsy(p.lastName)))} 
                                      headCells={[
                                        {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 50 },
                                        {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 50},                                        
                                      ]} 
                                      title={t(`Select the name to copy`)} objKey='id' 
                                      stateSelected={undefined} 
                                      onRowSelected={undefined} onRowDoubleClick={handlePersonCopyDoubleClick} rowCheckedMode='single'
                                      onRowCheckedSelectChange={undefined} order='desc' orderBy={lg.includes('en')?'lastName':'firstName'}
                                      
                                      toolbarActions={[
                                                              
                                      ]}
                                    />
                                  </Box>  
                            </FormDialog> }
                            
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} > 
                            <TextField sx={{width:'calc(15% - 8px)'}} id="customerPortable1" label={`${t('Portable')} 1`} 
                              {...register('customerPortable1')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }  />
                            <TextField sx={{width:'calc(15% - 8px)'}} id="customerPortable2" label={`${t('Portable')} 2`} 
                              {...register('customerPortable2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>   
                            <TextField sx={{width:'calc(35% - 8px)'}} id="customerEmail1" label={`${t('Email')} 1`} 
                              {...register('customerEmail1')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>  
                            <TextField sx={{width:'calc(35% - 8px)'}} id="customerEmail2" label={`${t('Email')} 2`} 
                              {...register('customerEmail2')}  inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }/>                                                    

                            { openPersonSearchFilter && <FormDialog open={openPersonSearchFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Person')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenPersonSearchFilter(false);}} onOk={()=> {setOpenPersonSearchFilter(false);}}  >
                                      <BasicTextFilterForm<IPerson> {...basicFilterPersonSearch } />
                              </FormDialog> } 
                          </Box>                          
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                      <Stack flexDirection='column'  >
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`:: ${t(('Total'))} ::`}
                            </Typography>                                                       
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />                             

                              <Button id='btnPrint' onClick={printData} sx={ {display:'none'}}  />
                              <Controller name='showroomId' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="showroomId"
                                    label={t('Showroom')} inputProps={ {readOnly: false}} focused >
                                    {userStores && userStores.filter( s => s.isShowroom ).map( 
                                        (x,idx) => <MenuItem key={x.storeId} value={x.storeId}>{x.storeName}</MenuItem> )
                                      }
                                  </TextField>
                                )} />
                                <Controller name='currencyCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="currencyCode"
                                      label={t('Currency')} inputProps={ {readOnly: false}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_CURRENCY ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={`${t('Default')} - ${t('Tax')}`} sx={{width:'calc(20% - 8px)'}} disabled={false}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`defaultTaxRate`}
                                control={control}
                            />
                            
                          </Box>        
                          <Box sx={{ mt: 1, width: '100%' }} >
                            
                            <Controller name='status' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="status"
                                      label={t('Status')} inputProps={ {readOnly: true}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            <Controller control={control}
                              name='statusDate' 
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <DatePicker label={t('Status date')} 
                                  onChange={onChange} disableOpenPicker readOnly                    
                                  value={new Date(value)}
                                  //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                  slotProps={{ textField: { sx: {width:'calc(15% - 8px)'}  }} }
                                /> )}
                            />
                            <Controller name='statusPurpose' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                      label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_BILLING_STATUS_PURPOSE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker readOnly                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(15% - 8px)'} } }}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(15% - 8px)'}} />}
                                  /> )}
                              />
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller
                              render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                    label={t('Net')} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                              }}
                              name={`netAmount`}
                              control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Tax')} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`tax`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Amount')} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`amount`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Paid')} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`amountPaid`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={ `${t('Unpaid')} : ${(watchStatus==='10' && value>0)? getValues().dueDate:''}`} 
                                      sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                   
                                    />
                                  );
                                }}
                                name={`unpaidAmount`}
                                control={control}
                            />
                          </Box>
                          
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 0.25, width: '100%' }} >  
                          <ArrayFieldTableEx<IBilling,IBillingDetail,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headBillingDetailCells} rowsPathName='billingDetails' 
                              title={t('Add product(s)')} rowActionIcon={billingDetailRowActionIcon}  
                              onRowSelected={handleBillingDetailSelected}
                              onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                  
                              refAppend={refAppendBillingDetails as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateBillingDetail as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                              refRemove={refRemoveBillingDetail as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={
                                (getValues().status === '95') ?
                                [
                                  { toolTip: `${t('Add')}... ${t('Service')}`, onClickIcon: handleAddServices ,icon: RoomServiceIcon, isThere: applicationSetup.hasService },
                                  { toolTip: `${t('Add')}... ${t('Fee')}`, onClickIcon: handleAddFees ,icon: MonetizationOnIcon, isThere: applicationSetup.hasFee },
                                  { toolTip: `${t('Add')}... ${t('Packaging')}`, onClickIcon: handleAddPackagings ,icon: ArchiveIcon, isThere: applicationSetup.hasPackaging },
                                  { toolTip: `${t('Add')}... ${t('Article')}`, onClickIcon: handleAddArticles ,icon: LocalMallIcon, isThere: applicationSetup.hasArticle  },
                                  { toolTip: `${t('Add')}... ${t('Formation')}`, onClickIcon: handleAddFormations ,icon: SchoolIcon, isThere: applicationSetup.hasFormation  },

                                  { toolTip: `${t('Add')}... ${t('Maintenance')}`, onClickIcon: handleAddMaintenances ,icon: BuildCircleOutlinedIcon, isThere: applicationSetup.hasMaintenance },
                                  { toolTip: `${t('Add')}... ${t('Contract')}`, onClickIcon: handleAddContracts ,icon: DescriptionIcon, isThere: applicationSetup.hasContract },

                                  { toolTip: `${t('Add')}... ${t('Rental')}`, onClickIcon: handleAddRentals ,icon: AssignmentIcon, isThere: applicationSetup.hasRental },
                                  { toolTip: `${t('Add')}... ${t('Consumption')}`, onClickIcon: handleAddConsumptions ,icon: FastfoodIcon, isThere: applicationSetup.hasConsumption },
                                  
                                ].filter(x => x.isThere).map( x => x) : []
                            } 
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          /> 
                          { openBillingDetailDuplicateDialog && <FormDialog open={openBillingDetailDuplicateDialog} maxWidth='xs' height='35vh'
                            okText={t('OK')} cancelText={t('Cancel')} title={t('Duplicate service')} onCancel={()=> {}} 
                            onClose={()=> {setOpenBillingDetailDuplicateDialog(false);}} onOk={handleOkBillingDetailDuplicate}  >
                              <Stack flexDirection='column'>
                                <Box sx={{ mt: 0.25, width: '100%' }} >
                                  <Controller                                
                                    render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                      return (
                                        <NumberFormat      
                                          decimalScale={3}
                                          label={`${t('Number of service')}`} sx={{width:'calc(100% - 8px)'}}
                                          allowEmptyFormatting={false}
                                          control={control}             
                                                        
                                          onValueChange={ (v) => onChange(v.floatValue) }              
                                          //fixedDecimalScale={true} 
                                          thousandSeparator={true}
                                          //{...field}
                                          customInput={TextFieldRight}
                                          defaultValue={value}
                                          value={value}
                                          //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                        />
                                      );
                                    }}
                                    name={`serviceDuplicateCount`}
                                    control={control}
                                  />
                                </Box>
                              </Stack>

                            </FormDialog> }
                          { openServiceFilter && <FormDialog open={openServiceFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Service')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenServiceFilter(false);}} onOk={()=> {setOpenServiceFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterService } />
                              </FormDialog> }
                         { openFeeFilter && <FormDialog open={openFeeFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Fee')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenFeeFilter(false);}} onOk={()=> {setOpenFeeFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterFee } />
                              </FormDialog> }
                          { openArticleFilter && <FormDialog open={openArticleFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Article')} onCancel={()=> {}} 
                              onClose={()=> {setOpenArticleFilter(false);}} onOk={()=> {setOpenArticleFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterArticle } />
                          </FormDialog> }
                          { openPackagingFilter && <FormDialog open={openPackagingFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Packaging')} onCancel={()=> {}} 
                              onClose={()=> {setOpenPackagingFilter(false);}} onOk={()=> {setOpenPackagingFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterPackaging } />
                          </FormDialog> }
                          { openFormationFilter && <FormDialog open={openFormationFilter} maxWidth='md'
                                  okText='' cancelText='' title={t('Formation')} onCancel={()=> {}} 
                                  onClose={()=> {setOpenFormationFilter(false);}} onOk={()=> {setOpenFormationFilter(false);}}  >
                                      <BasicTextFilterForm<IProduct> {...basicFilterFormation } />
                              </FormDialog> }
                          { openMaintenanceFilter && <FormDialog open={openMaintenanceFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Maintenance')} onCancel={()=> {}} 
                              onClose={()=> {setOpenMaintenanceFilter(false);}} onOk={()=> {setOpenMaintenanceFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterMaintenance } />
                          </FormDialog> }
                          { openContractFilter && <FormDialog open={openContractFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Contract')} onCancel={()=> {}} 
                              onClose={()=> {setOpenContractFilter(false);}} onOk={()=> {setOpenContractFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterContract } />
                          </FormDialog> }
                          { openRentalFilter && <FormDialog open={openRentalFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Rental')} onCancel={()=> {}} 
                              onClose={()=> {setOpenRentalFilter(false);}} onOk={()=> {setOpenRentalFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterRental } />
                          </FormDialog> }
                          { openConsumptionFilter && <FormDialog open={openConsumptionFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Consumption')} onCancel={()=> {}} 
                              onClose={()=> {setOpenConsumptionFilter(false);}} onOk={()=> {setOpenConsumptionFilter(false);}}  >
                                  <BasicTextFilterForm<IProduct> {...basicFilterConsumption } />
                          </FormDialog> }
                          
                          { openEmployeeFilter && <FormDialog open={openEmployeeFilter} maxWidth='md'
                              okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                              onClose={()=> {setOpenEmployeeFilter(false);}} onOk={()=> {setOpenEmployeeFilter(false);}}  >
                                  <BasicTextFilterForm<IEmployee> {...basicFilterEmployee } />
                          </FormDialog> }
                          { productIndex>=0 && openBillingDetailServiceDelivery && <FormDialog open={openBillingDetailServiceDelivery} maxWidth='sm' height='35vh'
                                  okText={t('OK')} cancelText={t('Cancel')} title={t('Service execution date')} 
                                  onCancel={()=> {setOpenBillingDetailServiceDelivery(false);}} 
                                  onClose={()=> {setOpenBillingDetailServiceDelivery (false);}} onOk={handleOkBillingDetailServiceDelivery}  >
                                      <Stack flexDirection='column'>
                                        <Box sx={{ mt: 0.25, width: '100%' }} > 
                                          <FormControlLabel sx={{width:'calc(35% - 8px)'}}
                                              label={`${t('Is executed ?')}`}
                                              control={
                                              <Controller
                                                  name={`billingDetails.${productIndex}.isDelivered`}  
                                                  control={control}
                                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} />
                                          { <FormControlLabel sx={{width:'calc(35% - 8px)'}}
                                              label={`${t('Is execution date define ?')}`}
                                              control={
                                              <Controller
                                                  name={`billingDetails.${productIndex}.isDeliveryDateDefine`}  
                                                  control={control}
                                                  render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                          />} /> }
                                          <Controller key={`${getValues().billingDetails[productIndex].deliveryDate}  -`}  
                                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                                <DatePicker label={t('Date of execution')} key={`${getValues().billingDetails[productIndex].deliveryDate} dob- x`}
                                                  onChange={onChange}                
                                                  //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                                  value={new Date(value)}
                                                  slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                                  //renderInput={(params) => <TextField {...params}  sx={{width:'calc(30% - 8px)'}} />}
                                                /> )}
                                              name={`billingDetails.${productIndex}.deliveryDate`}    
                                              control={control}
                                            />
                                        </Box>
                                      </Stack>
                              </FormDialog> }
                        </Box>
                        <Box sx={{ mt: 2.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t('Commission')} ... ${t('Number')}`}
                          </Typography>                                                       
                        </Box> 
                        <Box sx={{ mt: 0.25, width: '100%' }} key={` employee ${getValues().businessEmployeeId}`}> 
                          <TextField sx={{width:'calc(50% - 8px)'}} id="businessEmployeeFullName" label={t('Employee')} 
                              {...register('businessEmployeeFullName')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">  
                                  { (isFalsy(watchBusinessEmployeeId) ||  watchBusinessEmployeeId<=0) ?
                                      <IconButton color="primary" onClick={(event: any) => handleClickSelectEmployee(event, 'business')}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton> : 
                                      <IconButton color="primary" onClick={(event: any) => handleClickResetEmployee(event, 'business')}>
                                        <RemoveCircleIcon />
                                      </IconButton>
                                    }                                                                                                                                     
                                </InputAdornment>
                              ) 
                            }} />        
                            <TextField sx={{width:'calc(50% - 8px)'}} id="businessEmployeeManagerFullName" label={t('Manager')} 
                              {...register('businessEmployeeManagerFullName')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } }
                            />                                              
                        </Box>
                        <Box sx={{ mt: 0.25, width: '100%' }} key={` proformaNumber ${getValues().businessEmployeeId}`}> 
                          <TextField sx={{width:'calc(30% - 8px)'}} id="proformaNumber" label={t('Proforma number')} 
                              {...register('proformaNumber')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } 
                              helperText={`${(watchStatus==='95' && !watchHasNumber && watchId>0)?t('Activate proforma numbering'):''}`} />        
                          <TextField sx={{width:'calc(30% - 8px)'}} id="billingNumber" label={t('Billing number')} 
                            {...register('billingNumber')} inputProps={ {readOnly: true, autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                              
                          <TextField sx={{width:'calc(40% - 8px)'}} id="billingRecipientName" label={t('Billing recipient')} 
                            {...register('billingRecipientName')} inputProps={ {readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } } />
                        </Box>
                        <Box sx={{ mt: 0.25, width: '100%' }} key={` proforma term an reference ${getValues().businessEmployeeId}`}> 
                          <Controller name='termAndConditionCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(100% - 8px)'}} id="status"
                                      label={t('Terms and conditions')} inputProps={ {readOnly: false}} 
                                      helperText={ 
                                        <FormHelperText>                                    
                                          { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_BILLING_TERM_REFERENCE, 
                                                                t('Term and condition'), t('Term and condition'))}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                            {t('Add')}
                                          </Link> }
                                          { (canUpdateEnumerationItem && !isFalsy(watchTermAndConditionCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_BILLING_TERM_REFERENCE, t('Term and condition'), t('Term and condition'),
                                                        refEnumItems.current?.find(x => x.enumerationCode === Enum_BILLING_TERM_REFERENCE && x.code === watchTermAndConditionCode)
                                                      ); }}
                                            sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                            {t('Update')}
                                          </Link> }
                                        </FormHelperText>
                                      }>
                                      {enumItems && enumItems.filter( e => 
                                            e.enumerationCode === Enum_BILLING_TERM_REFERENCE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                          {/* <TextField sx={{width:'calc(100% - 8px)'}} id="termAndCondition" label={t('Terms and conditions')} 
                              {...register('termAndCondition')} inputProps={ {readOnly: false, autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                   */}
                        </Box>
                        <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 1.5, width: '100%' }} > 
                          <Button variant="outlined" onClick={handleLinkClickBillingInfo} sx={{mb:2, ml: 1 }}>
                             {t('More infos')} ...                             
                          </Button>     
                          { openBillingInfo && <FormDialog open={openBillingInfo} maxWidth='md'
                              okText='' cancelText='' title={t('Informations on billing')} onCancel={()=> {}} 
                              onClose={()=> {setOpenBillingInfo(false);}} onOk={()=> {setOpenBillingInfo(false);}}  >
                              <Stack flexDirection='column'>
                                <Box sx={{ mt: 1, width: '100%' }} key={`billing reference  `}  >
                                  <Controller name='defaultPaymentSourceCode' control={control}                                     
                                    render={ ({field: {onChange, value}}) => (
                                      <TextField select onChange={onChange} value={value} sx={{width:'calc(50% - 8px)'}} id="defaultPaymentSourceCode"
                                        label={`${t('Payment source')} - ${t('Default')}`} inputProps={ {readOnly: false}} 
                                        helperText={ 
                                          <FormHelperText>                                    
                                            { canAddEnumerationItem && <Link href="#" onClick={(event) => onAddItemClick(Enum_PAYMENT_SOURCE, 
                                                                t('Payment source'), t('Payment source'))}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue', px: 3 }} >
                                              {t('Add')}
                                            </Link> }
                                            { (canUpdateEnumerationItem && !isFalsy(watchDefaultPaymentSourceCode) ) && <Link href="#" onClick={(event) => {
                                                 onUpdateItemClick( Enum_PAYMENT_SOURCE, t('Payment source'), t('Payment source'),
                                                    refEnumItems.current?.find(x => x.enumerationCode === Enum_PAYMENT_SOURCE && x.code === watchDefaultPaymentSourceCode))}}
                                              sx={{ cursor: 'pointer', textDecoration: 'none', color: 'blue' }} >
                                              {t('Update')}
                                            </Link> }
                                          </FormHelperText>
                                        }>
                                        {enumItems && enumItems.filter( e => 
                                              e.enumerationCode === Enum_PAYMENT_SOURCE ).map( 
                                          (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                        }
                                      </TextField>
                                    )}
                                  />
                                  <Controller                   
                                      key={`reference - :::`}                  
                                      render={({ field }) => <TextField sx={{width:`calc(50% - 8px)`}} {...field} 
                                      label={t('Reference')} inputProps={ {readOnly: false }}
                                          />}
                                      name={`reference`}    
                                      control={control}
                                  />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} key={`billing designation  `}  >
                                  <Controller                   
                                      key={`designation - :::`}                  
                                      render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                      label={t('Designation')} inputProps={ {readOnly: false }}
                                          />}
                                      name={`designation`}    
                                      control={control}
                                  />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} key={`billing description  `}  >
                                  <Controller                   
                                      key={`description - ::`}                  
                                      render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                      label={t('Description')} inputProps={ {readOnly: false }}
                                          />}
                                      name={`description`}    
                                      control={control}
                                  />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} key={`details infos  `}  >
                                  <ArrayFieldTableEx<IBilling,IBillingInfo,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headBillingInfoCells} rowsPathName={`proxy.billingInfos`}
                                    title={t('Informations')} rowActionIcon={billingInfoRowActionIcon}  
                                    //onRowSelected={handleBillingDetailSelected}
                                    //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                        
                                    refAppend={refAppendBillingInfos as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateBillingInfo as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                                    refRemove={refRemoveBillingInfo as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={                                
                                      [
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddBillingInfos ,icon: AddCircleIcon }, 
                                      ]
                                    } 
                                    canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                </Box>                                
                              </Stack>
                            </FormDialog>
                          }                                    
                        </Box>
                        { (getValues().status === '10' ) && 
                        <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 1.5, width: '100%' }} > 
                          <Button variant="outlined" onClick={handleClickCommissionDistribution} sx={{mb:2, ml: 1 }}>
                             {t('Display commissions distribution')} ...                             
                          </Button>   
                          { openCommissionDistribution && <FormDialog open={openCommissionDistribution} maxWidth='sm' height='40vh'
                              okText='' cancelText='' title={t('Commission')} onCancel={()=> {}} 
                              onClose={()=> {setOpenCommissionDistribution(false);}} onOk={()=> {setOpenCommissionDistribution(false);}}  >
                                  <Stack flexDirection='column'>
                                    <Box sx={{ mt: 0.25, width: '100%' }} >
                                      <EnhancedTable<ICommissionDistribution> rows={commissionDistributions} 
                                            headCells={[            

                                              {id:lg.includes('en')?'firstName':'lastName', label : lg.includes('en')?t('First name'):t('Last name'),  display: true, type: 'string', width: 40 },
                                              {id:lg.includes('en')?'lastName':'firstName', label : lg.includes('en')?t('Last name'):t('First name'),  display: true, type: 'string', width: 40},
                                                
                                              {id:'amount', label : t('Amount'),  display: true, type: 'numeric', decimalScale: 4, width: 20},
                                             
                                              
                                            ]} 
                                            title={t(`Amount per product type`)} objKey='type' 
                                            stateSelected={undefined} 
                                            onRowSelected={undefined} rowCheckedMode='single'
                                            onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                                            onRowDoubleClick={undefined} 
                                            rowActionIcon={undefined}
                                            toolbarActions={[
                                              // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                                            ]}
                                          />
                                    </Box>
                                  </Stack>
                          </FormDialog> }                                     
                        </Box> }                                                
                      </Stack>
                    </Grid>     
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box flexDirection='row' sx={{ mt: 0.25, width: '100%', display: 'flex', alignItems: 'center', gap: 4 }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`... ${(productIndex >= 0 && productIndex < getValues().billingDetails.length)?getValues().billingDetails[productIndex].productName: ''}  ...`}
                          </Typography> 
                          {(productIndex >= 0 && productIndex < getValues().billingDetails.length) && <Button
                            variant="outlined"
                            component="button"
                            //variant="body2"
                            onClick={handleLinkClickBillingDetailInfo}
                            sx={{ ml: 1 }}
                          >
                            {t('More infos')}
                          </Button> }  
                          { (openBillingDetailInfo && productIndex >= 0 && productIndex < getValues().billingDetails.length) &&
                            <FormDialog open={openBillingDetailInfo} maxWidth='md'
                              okText='' cancelText='' title={t('Informations on billing item')} onCancel={()=> {}} 
                              onClose={()=> {setOpenBillingDetailInfo(false);}} onOk={()=> {setOpenBillingDetailInfo(false);}}  >
                              <Stack flexDirection='column'>
                                <Box sx={{ mt: 1, width: '100%' }} key={`details designation ${productIndex} ${getValues().billingDetails[productIndex].designation} `}  >
                                  <Controller                   
                                      key={`designation - ${getValues().billingDetails[productIndex].designation} :::`}                  
                                      render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                      label={t('Designation')} inputProps={ {readOnly: false }}
                                          />}
                                      name={`billingDetails.${productIndex}.designation`}    
                                      control={control}
                                  />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} key={`details description ${productIndex} ${getValues().billingDetails[productIndex].description} `}  >
                                  <Controller                   
                                      key={`description - ${getValues().billingDetails[productIndex].description} ::`}                  
                                      render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                      label={t('Description')} inputProps={ {readOnly: false }}
                                          />}
                                      name={`billingDetails.${productIndex}.description`}    
                                      control={control}
                                  />
                                </Box>
                                <Box sx={{ mt: 1, width: '100%' }} key={`details infos ${productIndex} ${getValues().billingDetails[productIndex].designation} `}  >
                                  <ArrayFieldTableEx<IBilling,IBillingDetailInfo,'id'> 
                                    mainObject={getValues()} fieldKey='id' 
                                    headCells={headBillingDetailInfoCells} rowsPathName={`billingDetails.${productIndex}.billingDetailInfos`}
                                    title={t('Informations')} rowActionIcon={billingDetailInfoRowActionIcon}  
                                    //onRowSelected={handleBillingDetailSelected}
                                    //onRowDoubleClick={handleBillingDetailRowDoubleClick}
                                                        
                                    refAppend={refAppendBillingDetailInfos as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                                    refUpdate={refUpdateBillingDetailInfo as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                                    refRemove={refRemoveBillingDetailInfo as MutableRefObject<(index: number) => void>}

                                    //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                    //displayMore={undefined}
                                    toolbarActions={                                
                                      [
                                        { toolTip: `${t('Add')}...`, onClickIcon: handleAddBillingDetailInfos ,icon: AddCircleIcon }, 
                                      ]
                                    } 
                                    canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                </Box>                                
                              </Stack>
                            </FormDialog>
                          }                                                   
                        </Box>
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          &&  !['article','consumption','rental'].includes(getValues().billingDetails[productIndex].type) ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].netAmount} `}  >
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Discount')} sx={{width:'calc(15% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billingDetails.${productIndex}.discount`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Loaded')} sx={{width:'calc(15% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billingDetails.${productIndex}.loaded`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Tax')} sx={{width:'calc(15% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false} 
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billingDetails.${productIndex}.tax`}
                                control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('Net amount')} sx={{width:'calc(15% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false} 
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billingDetails.${productIndex}.netAmount`}
                                control={control}
                            />
                            <Controller 
                              name={`billingDetails.${productIndex}.pricePurpose`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="pricePurpose"
                                      label={`${t('Purpose')} - ${t('Price')}`} inputProps={ {readOnly: false}} >
                                      {enumItems && enumItems.filter( e => e.enumerationCode === Enum_PRICE_PURPOSE && 
                                         e.parentEnumerationItemCode === getValues().billingDetails[productIndex].lineOfBusinessCode ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            
                                                     
                        </Box> }
                        
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length && ['service', 'formation'].includes(getValues().billingDetails[productIndex].type) ) 
                          && <Box sx={{ mt: 0.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t(('Beneficiary'))} `}
                          </Typography> 
                          <Button>                            
                            <Box sx={{ ...justifyCenter, ml: 1 }}>
                            {getValues().billingDetails[productIndex].beneficiaryIsCustomer ? t('The beneficiary is customer, click to change'): t('The beneficiary is different from the customer, click to make them identical')}
                              {getValues().billingDetails[productIndex].beneficiaryIsCustomer?
                                <MdOutlineCancel size={24} onClick={(event) => handleClickBeneficiaryIsCustomer(event,productIndex,false) } />:
                                <MdOutlineCheckCircle size={24} onClick={(event) => handleClickBeneficiaryIsCustomer(event,productIndex,true) } />}
                            </Box>
                          </Button>                                                       
                        </Box> }
                        

                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && ['article','consumption'].includes(getValues().billingDetails[productIndex].type) ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${productIndex} `}  >
                            { getValues().billingDetails[productIndex].type === 'article' && <Controller 
                              name={`billingDetails.${productIndex}.articleOption`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="articleOption"
                                      key={getValues().billingDetails[productIndex].articleOption}
                                      label={t('Option')} inputProps={ {readOnly: false}} >
                                      {enumItems2 && enumItems2.filter( e => e.enumerationCode === Enum_ARTICLE_OPTION && 
                                         e.parentEnumerationItemCode === getValues().billingDetails[productIndex].articleFilterOption ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              /> }
                              { getValues().billingDetails[productIndex].type === 'consumption' && <Controller 
                              name={`billingDetails.${productIndex}.consumptionOption`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="consumptionOption"
                                      key={getValues().billingDetails[productIndex].consumptionOption}
                                      label={t('Option')} inputProps={ {readOnly: false}} >
                                      {enumItems2 && enumItems2.filter( e => e.enumerationCode === Enum_CONSUMPTION_OPTION && 
                                         e.parentEnumerationItemCode === getValues().billingDetails[productIndex].consumptionOptionClass ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              /> }
                            <Controller
                              render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                return (
                                  <NumberFormat    
                                  key={getValues().billingDetails[productIndex].quantity}
                                    label={t('Quantity')} sx={{width:'calc(25% - 8px)'}} disabled={false}
                                    allowEmptyFormatting={false}
                                    control={control}    
                                    thousandSeparator={true}
                                    decimalScale={2}
                                    onValueChange={ (v) => onChange(v.floatValue) }
                                    defaultValue={value}
                                    value={value}
                                    customInput={TextFieldRight}                            
                                  />
                                );
                              }}
                              name={`billingDetails.${productIndex}.quantity`}
                              control={control}
                            />
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                    key={getValues().billingDetails[productIndex].quantity}
                                      label={t('Unity price')} sx={{width:'calc(25% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billingDetails.${productIndex}.unityAmount`}
                                control={control}
                            />
                            
                                                     
                        </Box> }

                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'maintenance'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].personId} `}  >
                            <Controller 
                              name={`billingDetails.${productIndex}.maintenanceCategory`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="maintenanceCategory"
                                      label={t('Category')} inputProps={ {readOnly: false}} >
                                      {enumItems2 && enumItems2.filter( e => e.enumerationCode === Enum_MAINTENANCE_CATEGORY && 
                                         e.parentEnumerationItemCode === getValues().billingDetails[productIndex].maintenanceCategoryClass ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller                   
                                  key={`lastName ${getValues().billingDetails[productIndex].maintenanceReference} `}                  
                                  render={({ field }) => <TextField sx={{width:`calc(25% - 8px)`}} {...field} 
                                  label={t('Reference')} inputProps={ {readOnly: false }}
                                      />}
                                  name={`billingDetails.${productIndex}.maintenanceReference`}    
                                  control={control}
                              />
                            <Controller key={`${getValues().billingDetails[productIndex].maintenanceStartDate}  -`}  
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Start date')} key={`${getValues().billingDetails[productIndex].maintenanceStartDate} dob- x`}
                                    closeOnSelect={false}  
                                    onChange={onChange}                
                                      //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                      value={new Date(value)}
                                      //views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      //disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                      //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                      //renderInput={(params) => <TextField {...params}  sx={{width:'calc(25% - 8px)'}} />}
                                    /> )}
                                  name={`billingDetails.${productIndex}.maintenanceStartDate`}    
                                  control={control}
                                />
                                <Controller key={`${getValues().billingDetails[productIndex].maintenanceEndDate}  -`}  
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('End date')} key={`${getValues().billingDetails[productIndex].maintenanceEndDate} dob- x`}
                                      onChange={onChange}  closeOnSelect={false}              
                                      //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                      value={new Date(value)}
                                      //views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      //disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                      //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      //renderInput={(params) => <TextField {...params}  sx={{width:'calc(25% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                    /> )}
                                  name={`billingDetails.${productIndex}.maintenanceEndDate`}    
                                  control={control}
                                />
                            
                                                     
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'maintenance'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].maintenanceDescription} `}  >                            
                              <Controller                   
                                  key={`lastName ${getValues().billingDetails[productIndex].maintenanceDescription} `}                  
                                  render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                  label={t('Description')} inputProps={ {readOnly: false }}
                                      />}
                                  name={`billingDetails.${productIndex}.maintenanceDescription`}    
                                  control={control}
                              />                           
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'fee'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].employeeId} `}  >                            
                              <Controller                   
                                  key={`lastName ${getValues().billingDetails[productIndex].employeeId} `}                  
                                  render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                  label={`${t('Employee')} ==> ${t('Fee')}`}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">  
                                      { (isFalsy(watchBusinessEmployeeId) ||  watchBusinessEmployeeId<=0) ?
                                          <IconButton color="primary" onClick={(event: any) => handleClickSelectEmployee(event, 'fee')}>
                                            <ArrowDropDownCircleIcon />
                                          </IconButton> : 
                                          <IconButton color="primary" onClick={(event: any) => handleClickResetEmployee(event, 'fee')}>
                                            <RemoveCircleIcon />
                                          </IconButton>
                                        }                                                                                                                                     
                                    </InputAdornment>
                                  ) 
                                }} 
                                      />}
                                  name={`billingDetails.${productIndex}.employeeFullName`}    
                                  control={control}
                              />                           
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'contract'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`contract ${getValues().billingDetails[productIndex].contractType} ${getValues().billingDetails[productIndex].contractScope}`}  >
                            <Controller 
                              name={`billingDetails.${productIndex}.contractType`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="contractType"
                                      label={t('Type')} inputProps={ {readOnly: false}} >
                                      {enumItems2 && enumItems2.filter( e => e.enumerationCode === Enum_CONTRACT_TYPE ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller 
                              name={`billingDetails.${productIndex}.contractScope`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="contractScope"
                                      label={t('Scope')} inputProps={ {readOnly: false}} >
                                      {enumItems2 && enumItems2.filter( e => e.enumerationCode === Enum_CONTRACT_SCOPE && 
                                         e.parentEnumerationItemCode === getValues().billingDetails[productIndex].contractScopeClass ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                            <Controller key={`${getValues().billingDetails[productIndex].contractEffectiveDate}  -`}  
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} key={`${getValues().billingDetails[productIndex].contractEffectiveDate} dob- x`}
                                      onChange={onChange}  closeOnSelect={false}            
                                      //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                      value={new Date(value)}
                                      //views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      //disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                      //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                    /> )}
                                  name={`billingDetails.${productIndex}.contractEffectiveDate`}    
                                  control={control}
                                />
                                <Controller key={`${getValues().billingDetails[productIndex].contractExpirationDate}  -`}  
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Expiration date')} key={`${getValues().billingDetails[productIndex].contractExpirationDate} dob- x`}
                                      onChange={onChange}  closeOnSelect={false}              
                                      //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                      value={new Date(value)}
                                      //views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      //disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                      //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      //renderInput={(params) => <TextField {...params}  sx={{width:'calc(25% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                    /> )}
                                  name={`billingDetails.${productIndex}.contractExpirationDate`}    
                                  control={control}
                                />                   
                        </Box> }

                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'contract'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].contractDescription} `}  >                            
                              <Controller                   
                                  key={`lastName ${getValues().billingDetails[productIndex].contractDescription} `}                  
                                  render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                  label={t('Description')} inputProps={ {readOnly: false }}
                                      />}
                                  name={`billingDetails.${productIndex}.contractDescription`}    
                                  control={control}
                              />                           
                        </Box> }

                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'rental'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`rental ${getValues().billingDetails[productIndex].rentalId} def`}  >
                            <Controller key={`${getValues().billingDetails[productIndex].rentalEffectiveDate}  -`}  
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DateTimePicker label={t('Effective date')} 
                                      //key={`${getValues().billingDetails[productIndex].rentalEffectiveDate} dob- x`}
                                      onChange={onChange}  
                                      //views={['year', 'month', 'day', 'hours', 'minutes', 'seconds']}         
                                      //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                      value={new Date(value)}
                                      //views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      //disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                      //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      //renderInput={(params) => <TextField {...params}  sx={{width:'calc(25% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                    /> )}
                                  name={`billingDetails.${productIndex}.rentalEffectiveDate`}    
                                  control={control}
                                />
                                
                            <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                  <NumberFormat    
                                    key={getValues().billingDetails[productIndex].rentalDuration}
                                      label={t('Duration')} sx={{width:'calc(25% - 8px)'}} disabled={false}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={2}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`billingDetails.${productIndex}.rentalDuration`}
                                control={control}
                            />    
                            <Controller 
                              name={`billingDetails.${productIndex}.rentalPeriodicity`}
                              control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                    <TextField select onChange={onChange} value={value} sx={{width:'calc(25% - 8px)'}} id="rentalPeriodicity"
                                      label={t('Periodicity')} inputProps={ {readOnly: true}} >
                                      {enumItems2 && enumItems2.filter( e => e.enumerationCode === Enum_RENTAL_PERIODICITY ).map( 
                                        (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                      }
                                    </TextField>
                                  )}
                              />
                              <Controller key={`${getValues().billingDetails[productIndex].rentalExpirationDate}  -`}  
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DateTimePicker label={t('Expiration date')} key={`${getValues().billingDetails[productIndex].rentalExpirationDate} dob- y`}
                                    onChange={onChange} closeOnSelect={false}               
                                    //inputFormat={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                    value={new Date(value)}
                                    disabled
                                    //views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                    //disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                    //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                    //renderInput={(params) => <TextField {...params}  sx={{width:'calc(25% - 8px)'}} />}
                                    slotProps={{ textField: { sx: {width:'calc(25% - 8px)'}  }} }
                                  /> )}
                                name={`billingDetails.${productIndex}.rentalExpirationDate`}    
                                control={control}
                              />                   
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && getValues().billingDetails[productIndex].type === 'rental'  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].rentalReference} `}  >                            
                              <Controller                   
                                  key={`rentalReference ${getValues().billingDetails[productIndex].rentalReference} `}                  
                                  render={({ field }) => <TextField sx={{width:`calc(100% - 8px)`}} {...field} 
                                  label={t('Reference')} inputProps={ {readOnly: false }}
                                      />}
                                  name={`billingDetails.${productIndex}.rentalReference`}    
                                  control={control}
                              />                           
                        </Box> }

                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                          && ['service', 'formation'].includes(getValues().billingDetails[productIndex].type) && !getValues().billingDetails[productIndex].beneficiaryIsCustomer  ) && 
                          <Box sx={{ mt: 1, width: '100%' }} key={`info ${getValues().billingDetails[productIndex].personId} ${getValues().billingDetails[productIndex].birthDateType}`}  >
                            <Controller   
                              key={`personId ${getValues().billingDetails[productIndex].personId} ${getValues().billingDetails[productIndex].studentId}`}                               
                              render={({ field }) => <TextField sx={{width:`calc(15% - 8px)`}} {...field} 
                                label={t('Person Id')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                InputProps={{
                                  readOnly: true,
                                  endAdornment: (
                                    <InputAdornment position="end">  
                                    { getValues().billingDetails[productIndex].personId <= 0 ?
                                        <IconButton color="primary" onClick={(event: any) => handleClickOpenPerson(event, 
                                              getValues().billingDetails[productIndex].type==='service'? `billing-detail-service`: `billing-detail-formation`)}>
                                          <ArrowDropDownCircleIcon />
                                        </IconButton> : 
                                        <IconButton color="primary" onClick={(event: any) => handleClickRemovePerson(event, 
                                          getValues().billingDetails[productIndex].type==='service'? `billing-detail-service`: `billing-detail-formation`)}>
                                          <RemoveCircleIcon />
                                        </IconButton>
                                      }                                                                                                                                     
                                  </InputAdornment>
                                ) 
                              }}  />}
                              
                              name={ getValues().billingDetails[productIndex].type==='service'? `billingDetails.${productIndex}.personId` : `billingDetails.${productIndex}.studentId`}                              
                              control={control}
                            />
                            <Controller                     
                                  key={`firstName ${getValues().billingDetails[productIndex].firstName} `}                
                                  render={({ field }) => <TextField sx={{width:`calc(30% - 8px)`}} {...field} 
                                  label={lg.startsWith('fr')?t('Last name'):t('First name')} inputProps={ {readOnly: (getValues().billingDetails[productIndex].personId>0) }}
                                  InputProps={{
                                    readOnly: true,
                                    endAdornment: (
                                      <InputAdornment position="end">  
                                      { getValues().billingDetails[productIndex].personId <= 0 ?
                                          <IconButton color="primary" onClick={(event: any) => handleClickOpenPersonCopy(event, 'billing-detail-service')}>
                                            <CopyAllIcon />
                                          </IconButton> : 
                                          null
                                        }                                                                                                                                     
                                    </InputAdornment>
                                  ) 
                                }}    
                                      />}
                                  name={`billingDetails.${productIndex}.${lg.startsWith('fr')?'lastName':'firstName'}`}
                                  control={control}
                              /> 
                              <Controller                   
                                  key={`lastName ${getValues().billingDetails[productIndex].lastName} `}                  
                                  render={({ field }) => <TextField sx={{width:`calc(25% - 8px)`}} {...field} 
                                  label={lg.startsWith('fr')?t('First name'):t('Last name')} inputProps={ {readOnly: (getValues().billingDetails[productIndex].personId>0) }}
                                      />}
                                  name={`billingDetails.${productIndex}.${lg.startsWith('fr')?'firstName':'lastName'}`}    
                                  control={control}
                              />
                              <Controller                           
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(12% - 8px)'}} id="civility"
                                    label={t('Date type')} inputProps={ {readOnly: false}} focused >
                                    {getBirthDateType().map( 
                                      (x,idx) => <MenuItem key={x.value} value={x.value}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                                name={`billingDetails.${productIndex}.birthDateType`}    
                                control={control}
                              /> 
                              <Controller key={`${getValues().billingDetails[productIndex].birthDateType} dob -`}  
                                  render={({ field: { onChange, onBlur, value, ref } }) => (
                                    <DatePicker label={t('Birth date')} key={`${getValues().billingDetails[productIndex].birthDateType} dob- x`}
                                      onChange={onChange}                
                                      format={getValues().billingDetails[productIndex].birthDateType==='day'?'dd/MM':'dd/MM/yyyy'}
                                      value={getValues().billingDetails[productIndex].birthDateType==='none'?null:new Date(value)}
                                      views={getValues().billingDetails[productIndex].birthDateType==='day'?['month','day']: ['year', 'month', 'day']}
                                      disabled={getValues().billingDetails[productIndex].birthDateType==='none'}
                                      //format={watchBirthDateType==='day'?'MM/dd':'dd/MM/yyyy'}
                                      //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                      slotProps={{ textField: { sx: {width:'calc(18% - 8px)'}  }} }
                                    /> )}
                                  name={`billingDetails.${productIndex}.birthDate`}    
                                  control={control}
                                />                           
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length 
                            && ['service', 'packaging'].includes(getValues().billingDetails[productIndex].type) && !getValues().billingDetails[productIndex].beneficiaryIsCustomer ) && 
                        <Box sx={{ mt: 1, width: '100%' }} key={` contact - ${getValues().billingDetails[productIndex].personId} `}> 
                          <Controller          
                              key={`portable1 ${getValues().billingDetails[productIndex].portable1} `}                           
                              render={({ field }) => <TextField sx={{width:`calc(25% - 8px)`}} {...field} 
                              label={`${t('Portable')} 1`} inputProps={ {readOnly: (getValues().billingDetails[productIndex].personId>0) }}
                                  />}
                              name={`billingDetails.${productIndex}.portable1`}                              
                                                      
                              control={control}
                          />
                          <Controller             
                              key={`portable2 ${getValues().billingDetails[productIndex].portable2} `}                        
                              render={({ field }) => <TextField sx={{width:`calc(25% - 8px)`}} {...field} 
                              label={`${t('Portable')} 2`} inputProps={ {readOnly: (getValues().billingDetails[productIndex].personId>0) }}
                                  />}
                              name={`billingDetails.${productIndex}.portable2`}                              
                                                        
                              control={control}
                          />
                          <Controller               
                              key={`email1 ${getValues().billingDetails[productIndex].email1} `}                      
                              render={({ field }) => <TextField sx={{width:`calc(50% - 8px)`}} {...field} 
                              label={`${t('Email')} 1`} inputProps={ {readOnly: (getValues().billingDetails[productIndex].personId>0) }}
                                  />}
                              name={`billingDetails.${productIndex}.email1`}                              
                                                       
                              control={control}
                          />                                                                                                      
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length && getValues().billingDetails[productIndex].type === 'service' ) 
                          &&<Box key={`billing service ${productIndex} - ${getValues().billingDetails[productIndex].billingServiceTasks.map(x => x.employeeFullName).join('-')}`} sx={{ mt: 0.25, width: '100%' }} >
                              <ArrayFieldTableEx<IBilling,IBillingServiceTask,'id'> 
                                mainObject={getValues()} fieldKey='id' key={`billing service ${productIndex} - task key`}
                                headCells={headBillingServiceTaskCells} rowsPathName={`billingDetails.${productIndex}.billingServiceTasks`} 
                                title={t('Tasks of service')} //rowActionIcon={billingServiceTaskRowActionIcon}  
                                //onRowSelected={handleBillingDetailSelected}
                                                    
                                refAppend={refAppendBillingServiceTasks as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateBillingServiceTask as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                                refRemove={refRemoveBillingServiceTask as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                        </Box>}
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length  && getValues().billingDetails[productIndex].type === 'packaging' ) &&<Box key={`packaging ${productIndex} - key`} sx={{ mt: 0.25, width: '100%' }} >
                            <ArrayFieldTableEx<IBilling,IBillingPackagingDetail,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headBillingPackagingDetailCells} rowsPathName={`billingDetails.${productIndex}.billingPackagingDetails`} 
                              title={t('Service(s) or artcle(s) for packaging')} rowActionIcon={billingPackagingDetailRowActionIcon}  
                              //onRowSelected={handleBillingDetailSelected}
                                                  
                              refAppend={refAppendBillingPackagingDetails as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdateBillingPackagingDetail as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                              refRemove={refRemoveBillingPackagingDetail as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />
                        </Box>}
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length && ['service', 'packaging'].includes(getValues().billingDetails[productIndex].type) ) && 
                        <Box display='flex' flexDirection='row' alignContent='space-around' justifyContent='center' sx={{ mt: 1.5, width: '100%' }} > 
                          <Button variant="outlined" onClick={(event) => handleClickSelectEmployee(event, 'billing-service')} sx={{mb:2, ml: 1 }}>
                             {t('Select employee who did the tasks')} ...                             
                          </Button>    
                          <Button variant="outlined" onClick={(event) => handleClickResetEmployee(event, 'billing-service')} sx={{mb:2, ml: 1 }}>
                             {t('Reset all')}                              
                          </Button>                                                   
                        </Box> }
                        { (productIndex >= 0 && productIndex < getValues().billingDetails.length && getValues().billingDetails[productIndex].type === 'rental' ) 
                          &&<Box key={`billing rental ${productIndex} - ${getValues().billingDetails[productIndex].billingRentalResources.map(x => x.resourceName).join('-')}`} sx={{ mt: 0.25, width: '100%' }} >
                              <ArrayFieldTableEx<IBilling,IBillingRentalResource,'id'> 
                                mainObject={getValues()} fieldKey='id' key={`billing rental ${productIndex} - resource key`}
                                headCells={headBillingRentalResourceCells} rowsPathName={`billingDetails.${productIndex}.billingRentalResources`} 
                                title={t('Tasks of service')} //rowActionIcon={billingServiceTaskRowActionIcon}  
                                //onRowSelected={handleBillingDetailSelected}
                                                    
                                refAppend={refAppendBillingRentalResources as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                                refUpdate={refUpdateBillingRentalResource as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                                refRemove={refRemoveBillingRentalResource as MutableRefObject<(index: number) => void>}

                                //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                //displayMore={undefined}
                                toolbarActions={[
                                ]}
                                canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                            />
                        </Box>}
                        { ((billingPackagingServiceIndex >= 0) && openBillingPackagingService) && <FormDialog open={openBillingPackagingService} maxWidth='sm'
                              okText='' cancelText='' title={t('Employee')} onCancel={()=> {}} 
                              onClose={()=> {setOpenBillingPackagingService(false);}} onOk={()=> {setOpenBillingPackagingService(false);}}  >
                                  <Box sx={{ mt: 0.25, width: '100%' }} > 
                                    <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                          sx={{...typographyGroupBoxStyling}}>
                                      {`${t(('Beneficiary'))} `}
                                    </Typography> 
                                    <Button>                            
                                      <Box sx={{ ...justifyCenter, ml: 1 }}>
                                      {getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].beneficiaryIsCustomer ? t('The beneficiary is customer, click to change'): t('The beneficiary is different from the customer, click to make them identical')}
                                        {getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].beneficiaryIsCustomer?
                                          <MdOutlineCancel size={24} onClick={(event) => handleClickPackagingBeneficiaryIsCustomer(event,billingPackagingServiceIndex,false) } />:
                                          <MdOutlineCheckCircle size={24} onClick={(event) => handleClickPackagingBeneficiaryIsCustomer(event,billingPackagingServiceIndex,true) } />}
                                      </Box>
                                    </Button>                                                      
                                  </Box>
                                  {!getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].beneficiaryIsCustomer &&
                                  <Box sx={{ mt: 1, width: '100%' }} key={` info pack ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId} `}>
                                    <Controller                  
                                        key={`pack personId ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId} `}                   
                                        render={({ field }) => <TextField sx={{width:`calc(20% - 8px)`}} {...field} 
                                          label={t('Person Id')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } }
                                          InputProps={{
                                            readOnly: true,
                                            endAdornment: (
                                              <InputAdornment position="end">  
                                              { getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId <= 0 ?
                                                  <IconButton color="primary" onClick={(event: any) => handleClickOpenPerson(event, 'billing-packaging-service')}>
                                                    <ArrowDropDownCircleIcon />
                                                  </IconButton> : 
                                                  <IconButton color="primary" onClick={(event: any) => handleClickRemovePerson(event, 'billing-packaging-service')}>
                                                    <RemoveCircleIcon />
                                                  </IconButton>
                                                }                                                                                                                                     
                                            </InputAdornment>
                                          ) 
                                        }}  />}
                                        
                                        name={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.personId`}                              
                                        control={control}
                                    />
                                    <Controller                                     
                                      key={`pack firstName ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].firstName} `}
                                      render={({ field }) => <TextField sx={{width:`calc(40% - 8px)`}} {...field} 
                                      label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                                      inputProps={ {readOnly: (getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId>0) }}
                                      InputProps={{
                                        readOnly: true,
                                        endAdornment: (
                                          <InputAdornment position="end">  
                                          { getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId <= 0 ?
                                              <IconButton color="primary" onClick={(event: any) => handleClickOpenPersonCopy(event, 'billing-packaging-service')}>
                                                <CopyAllIcon />
                                              </IconButton> : 
                                              null
                                            }                                                                                                                                     
                                        </InputAdornment>
                                      ) 
                                    }} />}
                                      name={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.${lg.startsWith('fr')?'lastName':'firstName'}`}
                                      control={control}
                                    />
                                    <Controller              
                                        key={`pack lastName ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].lastName} `}                       
                                        render={({ field }) => <TextField sx={{width:`calc(40% - 8px)`}} {...field} 
                                        label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                                        inputProps={ {readOnly: (getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId>0) }}
                                            />}
                                        name={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.${lg.startsWith('fr')?'firstName':'lastName'}`}    
                                        control={control}
                                    />                                      
                                  </Box> }
                                  {!getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].beneficiaryIsCustomer &&
                                  <Box sx={{ mt: 1, width: '100%' }} key={` contact pack - ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId} `} > 
                                    <Controller                                     
                                        key={`pack portable1 ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].portable1} `}
                                        render={({ field }) => <TextField sx={{width:`calc(25% - 8px)`}} {...field} 
                                        label={`${t('Portable')} 1`} 
                                        inputProps={ {readOnly: (getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId>0) }}
                                            />}
                                        name={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.portable1`}                              
                                                                     
                                        control={control}
                                    />
                                    <Controller                           
                                        key={`pack portable2 ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].portable2} `}          
                                        render={({ field }) => <TextField sx={{width:`calc(25% - 8px)`}} {...field} 
                                        label={`${t('Portable')} 2`} 
                                        inputProps={ {readOnly: (getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId>0) }}
                                            />}
                                        name={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.portable2`}                              
                                                                     
                                        control={control}
                                    />
                                    <Controller                                     
                                        key={`pack email1 ${getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].email1} `}
                                        render={({ field }) => <TextField sx={{width:`calc(50% - 8px)`}} {...field} 
                                        label={`${t('Email')} 1`} 
                                        inputProps={ {readOnly: (getValues().billingDetails[productIndex].billingPackagingDetails[billingPackagingServiceIndex].personId>0) }}
                                            />}
                                        name={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.email1`}                              
                                                                     
                                        control={control}
                                    />                                                                                                                          
                                  </Box> }

                                  <Box key={`${'packaging'} - key`} sx={{ mt: 0.25, width: '100%' }} >
                                    <ArrayFieldTableEx<IBilling,IBillingServiceTask,'id'> 
                                      mainObject={getValues()} fieldKey='id' 
                                      headCells={headBillingPackagingServiceTaskCells} rowsPathName={`billingDetails.${productIndex}.billingPackagingDetails.${billingPackagingServiceIndex}.billingServiceTasks`} 
                                      title={t('Tasks of service')} //rowActionIcon={billingPackagingDetailRowActionIcon}  
                                      //onRowSelected={handleBillingDetailSelected}
                                                          
                                      refAppend={refAppendBillingPackagingServiceTasks as MutableRefObject<(value: Partial<FieldArray<IBilling>> | Partial<FieldArray<IBilling>>[], options?: FieldArrayMethodProps) => void>}
                                      refUpdate={refUpdateBillingPackagingServiceTask as MutableRefObject<(index: number,value: Partial<FieldArray<IBilling>>) => void>}
                                      refRemove={refRemoveBillingPackagingServiceTask as MutableRefObject<(index: number) => void>}
        
                                      //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                                      //displayMore={undefined}
                                      toolbarActions={[
                                      ]}
                                      canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                                  />
                                </Box>
                          </FormDialog> }
                          { displayEnumerationItemDialog && <DialogEnumerationItemForm 
                              {...{open: displayEnumerationItemDialog, 
                                    title: '', 
                                    enumerationItem, hideEnumerationItemDialog, saveEnumerationItem,
                                    afterSave: async () => {await refetchEnumerationItems()}}} 
                              />
                          }             
                          { openActionParams && <FormDialog open={openActionParams} 
                              maxWidth={actionParams.length===0?'sm':'xs'} height={actionParams.length<=3?'45vh':'90vh'}
                            okText='Ok' cancelText='Cancel' title={actionParamsDialogTitle} 
                              onCancel={()=> {setOpenActionParams(false);}} onClose={()=> {setOpenActionParams(false);}} onOk={handleOkActionParams}  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                              {actionParams.length===0?
                                t('Confirm this action') :
                                <FormProvider {...methodsEntityAction} >                
                                    <ArrayField<IFeatureParameter> params={actionParams} itemsPerRow={1} paramsName={'params'}
                                        valueKey='value' labelKey='label' dateValueKey='dateValue' disabledKey='isReadonly'   /> 
                                </FormProvider> 
                              }               
                            </Box>
                            </FormDialog> } 
                          
                      </Stack>  
                    </Grid>                            
                </Grid>
            </Box>
        </FormProvider> 
  )
}

