
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { SnackbarAction, SnackbarKey, useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import BoltIcon from '@mui/icons-material/Bolt';
import MoreVertIcon from '@mui/icons-material/MoreVert';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom,currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import useCashOutService, { useBasicFilterCashOut } from './services/CashOut';
import { useRecoilState, useRecoilValue } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { CashOutType, ICashOut, defaultCashOut } from './models/CashOut';

import usePersonService, { useBasicFilterPerson, useBasicFilterPersonSearch } from 'features/production/services/Person';

import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_CASH_OUT_PURPOSE, Enum_OPERATION_STATUS, 
        Enum_CASH_OUT_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { DatePicker } from '@mui/x-date-pickers';
import { IPerson, PersonType } from 'features/production/models/Person';
import { useBasicFilterResource } from 'features/production/services/Resource';


import { IResourceType } from 'features/setup/models/ResourceType';
import { debounce, sum } from 'lodash';
import { GrSearch, GrClose } from 'react-icons/gr';
import BasicButtonList from 'components/ui/BasicButtonList';
import { BasicPersonForm } from 'features/production/BasicPersonForm';
import { IPayment } from './models/Payment';
import { useBasicFilterPayment } from './services/Payment';

export const CashOutForm: FC<ICashOut> = (props: ICashOut = defaultCashOut) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {id} = useParams();

  const {language: lg, userPaymentSources} = useRecoilValue(currentUserSessionAtom);
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const { getPersonsSearchCount } = usePersonService();

  const { createCashOut, updateCashOut } = useCashOutService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterExpense = useBasicFilterCashOut( 
    (event: React.MouseEvent<unknown>, row: ICashOut) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );


  

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openPaymentFilter, setOpenPaymentFilter] = useState(false);
  const basicFilterPayment = useBasicFilterPayment( 
      (event: React.MouseEvent<unknown>, row: IPayment) => {
          const {id, description, ownerFullName, totalAmount} = row;

          setValue('paymentId', id);
          setValue('paymentDescription', description);
          setValue('paymentOwnerFullName', ownerFullName);

          setValue('paymentTotalAmount', totalAmount);
                           
          setOpenPaymentFilter(false);
      }
  );
 

  
  const methods = useForm<ICashOut>({defaultValues: {...defaultCashOut, 
                      paymentSourceCode: userPaymentSources.length > 0 ? userPaymentSources[0].paymentSourceCode : '' }});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  
  const refExpenseDetailIndex = useRef<number>(0);

  const watchType = watch('type'); 
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });

  type SearchPersonUsage = 'supplier';
  const [currentSearchPersonUsage, setCurrentSearchPersonUsage] = useState<SearchPersonUsage>('supplier');
  
  const [openPersonSearchFilter, setOpenPersonSearchFilter] = useState(false);
  
  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<ICashOut>,Error,ICashOut>(
      _id>0?updateCashOut:createCashOut, {   
        onSuccess: (data: IResult<ICashOut>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          setCurrentFormNameAtom(`${t('CashOut')} - # ${data.data.id} # ` );
          //queryClient.invalidateQueries(['CashOut',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<ICashOut>(['CashOut', _id], () => retrieveEntity('CashOut',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'CashOut'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_CASH_OUT_PURPOSE, Enum_OPERATION_STATUS, Enum_CASH_OUT_STATUS_PURPOSE] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);
   
    const handleCashOutType = ( event: React.MouseEvent<HTMLElement>, newCashOutType: CashOutType ) => {

      if(newCashOutType === null) return;
        setValue('type', newCashOutType);

    };


  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])

      useEffect( () => {        
        setCurrentFormNameAtom(`${t('CashOut')}`);
        setCurrentBasicTextFilterProps(basicFilterExpense);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
          setCurrentFormNameAtom(_id>0?`${t('CashOut')} - # ${_id} # -`: t('CashOut') );    
            
            if(_id > 0)
              retrieveData('CashOut',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset({...defaultCashOut, 
          paymentSourceCode: userPaymentSources.length > 0 ? userPaymentSources[0].paymentSourceCode : '' });    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('CashOut', _id)) {
          setIsSaveLoading(false);
          return;
        }
        const data = getValues(); 

        if( ( data.type === 'base' && data.purpose.trim() === '') || (data.type === 'payment' && data.paymentId <= 0) 
                  || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          setIsSaveLoading(false);
          return;
        }

        if( data.amount <= 0 ) {
          enqueueSnackbar( t('Invalid amount'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          setIsSaveLoading(false);
          return;
        }

        if( isFalsy(data.person.firstName) && isFalsy(data.person.lastName) && isFalsy(data.person.corporationName) ) {
          enqueueSnackbar( t('The beneficiary of cash out is required'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          setIsSaveLoading(false);
          return;
        }
              
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('CashOut', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
      queryClient.invalidateQueries(['CashOut',_id]);        
      await retrieveData('CashOut',_id, refetch);        
      reset(_data);          
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <ToggleButtonGroup value={watchType} exclusive key={watchType} size="small" onChange={handleCashOutType} aria-label="text alignment" fullWidth >
                              <ToggleButton value="base" aria-label="centered">
                                {`${t('Base')} - ${t('Bank')} - ${t('Other')} - ...`}
                              </ToggleButton>
                              <ToggleButton value="payment" aria-label="centered">
                                {`${t('Expense')} - ${t('Delivery')} - ${t('Payment')} ...`}
                              </ToggleButton>                              
                            </ToggleButtonGroup>                                                             
                          </Box>
                          <Box sx={{ mt: 1, width: '100%' }} >
                              <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                              <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                              <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                              <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                              <Controller name='paymentSourceCode' control={control}                                     
                                  render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(40% - 8px)'}} id="paymentSourceCode"
                                    label={t('Payment source')} inputProps={ {readOnly: false}} focused >
                                    {userPaymentSources && userPaymentSources.map( 
                                        (x,idx) => <MenuItem key={x.paymentSourceCode} value={x.paymentSourceCode}>{x.paymentSourceName}</MenuItem> )
                                      }
                                  </TextField>
                                )} />                              
                              <Controller control={control}
                                name='operationDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Operation date')} 
                                    onChange={onChange}                   
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                              <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Amount')}`} sx={{width:'calc(20% - 8px)'}}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`amount`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              />
                              <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange}                   
                                    value={new Date(value)} readOnly disableOpenPicker
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                          </Box>     
                          { watchType === 'base' && <Box sx={{ mt: 1, width: '100%' }} >
                            <Controller name='purpose' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(60% - 8px)'}} id="status"
                                    label={t('Purpose')} inputProps={ {readOnly: false}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_CASH_OUT_PURPOSE ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />                                                              
                          </Box> }   
                          { watchType === 'payment' && <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(40% - 8px)'}} id="paymentDescription" inputProps={ {readOnly: true }}
                              label={`${t('Payment')} - ${t('Description')}`} {...register('paymentDescription')}
                              InputProps={{
                                readOnly: true,
                                endAdornment: (
                                  <InputAdornment position="end">
                                      <IconButton color="primary" onClick={(event: any) => {setOpenPaymentFilter(true);}}>
                                        <ArrowDropDownCircleIcon />
                                      </IconButton>                                                                                                                    
                                </InputAdornment>
                              ) 
                            }} />
                            <TextField sx={{width:'calc(40% - 8px)'}} id="paymentOwnerFullName" inputProps={ {readOnly: true }}
                              label={`${t('Full name')}`} {...register('paymentOwnerFullName')} />
                            <Controller                                
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat      
                                      decimalScale={3}
                                      label={`${t('Total amount')}`} sx={{width:'calc(20% - 8px)'}} disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}             
                                                    
                                      onValueChange={ (v) => onChange(v.floatValue) }              
                                      //fixedDecimalScale={true} 
                                      thousandSeparator={true}
                                      //{...field}
                                      customInput={TextFieldRight}
                                      defaultValue={value}
                                      value={value}
                                      //customInput={(props) => <TextField {...props} sx={{width:'calc(20% - 8px)'}} id="roleName" inputProps={{style: { textAlign: 'right' }}} />}
                                    />
                                  );
                                }}
                                name={`paymentTotalAmount`}
                                //name={`${fieldsName}.${idx}.${cell.id}`}
                                control={control}
                              />  
                              { openPaymentFilter && <FormDialog open={openPaymentFilter} maxWidth='md'
                                okText='' cancelText='' title={t('Payment')} onCancel={()=> {}} 
                                onClose={()=> {setOpenPaymentFilter(false);}} onOk={()=> {setOpenPaymentFilter(false);}}  >
                                    <BasicTextFilterForm<IPayment> {...basicFilterPayment } />
                            </FormDialog> }                                                            
                          </Box> }                                                                                                   
                          <Box sx={{ mt: 1, width: '100%' }} >
                            <TextField sx={{width:'calc(100% - 8px)'}} id="description" multiline rows={2}
                              label={t('Description')} {...register('description')} />                                                              
                          </Box>
                          <Box sx={{ mt: 0.25, width: '100%' }} > 
                            <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                  sx={{...typographyGroupBoxStyling}}>
                              {`${t(('Identity of the beneficiary of cash out'))} `}
                            </Typography>                                                       
                          </Box>
                          <BasicPersonForm personKeyPath='personId' personPath='person' isIdentityOnly={false} />
                                                  
                        
                        <Box sx={{ mt: 0.25, width: '100%' }} > 
                          <Typography variant="h6" id="tableTitle" color="primary" noWrap 
                                sx={{...typographyGroupBoxStyling}}>
                            {`${t(('Status of cash out'))} `}
                          </Typography>                                                       
                        </Box>          
                        <Box sx={{ mt: 1, width: '100%' }} >
                          <Controller control={control}
                                name='issueDate' 
                                render={({ field: { onChange, onBlur, value, ref } }) => (
                                  <DatePicker label={t('Issue date')} 
                                    onChange={onChange} disableOpenPicker readOnly                    
                                    value={new Date(value)}
                                    slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                    //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                                  /> )}
                              />
                          <Controller name='status' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status')} inputProps={ {readOnly: true}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_OPERATION_STATUS ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                          <Controller control={control}
                            name='statusDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Status date')} 
                                onChange={onChange} disableOpenPicker readOnly                    
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(20% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(20% - 8px)'}} />}
                              /> )}
                          />
                          <Controller name='statusPurpose' control={control}                                     
                                render={ ({field: {onChange, value}}) => (
                                  <TextField select onChange={onChange} value={value} sx={{width:'calc(30% - 8px)'}} id="status"
                                    label={t('Status purpose')} inputProps={ {readOnly: true}} >
                                    {enumItems && enumItems.filter( e => 
                                          e.enumerationCode === Enum_CASH_OUT_STATUS_PURPOSE ).map( 
                                      (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                                    }
                                  </TextField>
                                )}
                            />
                        </Box>
                            
                      </Stack>                        
                    </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

