
import { useState } from "react";

import {RefetchOptions, RefetchQueryFilters, QueryObserverResult } from "react-query";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useSnackbar } from 'notistack';

import { useSetRecoilState, useRecoilState } from "recoil"; 

import { IEntity, IEntityActionState, IEntityProperty, IFeatureDescription, IFeatureParameter, IQueryFeatureDescription, IReportDescription } from "library/interface";

import useAxios from 'library/axios'; 
import { isActionDrawerOpenAtom, isPrintDrawerOpenAtom, currentEntityNameForActionDrawerAtom, 
  currentEntityIdForActionDrawerAtom, currentFunctionEntityParametersForActionDrawerAtom,
   currentEntityContextualItemsForActionDrawerAtom,
  currentUserSessionAtom, isSearchBoxShowAtom, isAuthorizationBoxShowAtom ,currentBasicTextFilterPropsAtom } from "library/store";
import { BasicTextFilterProps, ITextFilterElement } from "components/ui/BasicTextFilterForm";

import { HeadCell, RowCheckedMode } from "components/ui/EnhancedTable";

import { isFalsy } from "utility-types";
import { boolean } from "yup/lib/locale";
// import { IFeatureDescription, IFeatureParameter } from "../utils/interface";

const _ = () => {

    const axios = useAxios();   

    const { t, i18n } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    const [currentUserSession, setCurrentUserSession] = useRecoilState(currentUserSessionAtom);

    const [isActionDrawerOpen, setIsActionDrawerOpen] = useRecoilState(isActionDrawerOpenAtom);
    const [isPrintDrawerOpen, setIsPrintDrawerOpen] = useRecoilState(isPrintDrawerOpenAtom);
    const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);

    const [isAuthorizationBoxShow, setIsAuthorizationBoxShow] = useRecoilState(isAuthorizationBoxShowAtom);
    
    const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

    const [, setActionEntityName] = useRecoilState(currentEntityNameForActionDrawerAtom);
    const [, setActionEntityId] = useRecoilState(currentEntityIdForActionDrawerAtom);
    const [, setActionFunctionEntityParamters] = useRecoilState(currentFunctionEntityParametersForActionDrawerAtom);
    const [, setActionEntityContextualItems] = useRecoilState(currentEntityContextualItemsForActionDrawerAtom);
        
    const getServerTime = async ()  =>  {
      const {data} = (await axios.get(`api/home/get-server-time`));
      return await data;
    }

    const retrieveEntity = async (entityName: string, entityId: number)  =>  {
        const {data} = (await axios.get(`api/home/retrieve-entity?entityName=${entityName}&entityId=${entityId}`));
        return await data;
      }
  
      const retrieveData = async ( entityName: string, entityId: number,
          refetch: <TPageData>(options?: RefetchOptions & RefetchQueryFilters<TPageData>) => Promise<QueryObserverResult<unknown,unknown>>
      ) : Promise<boolean> => {
        if( (entityId || 0) <= 0) 
            return false;
        //   enqueueSnackbar( `${t('Invalid Id')} : ${_id}`, { variant: 'warning',
        //     anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
      
        if(false) { //if(!canRetreive())
            alert('Can retrieve !!!');
            return false;
            //navigate(`/entity/Enumeration/${entityId}`);
        } else {
          
          await refetch();
          return true;
        }
      }

    const getFeaturesByEntityType = async ( entityName: string, featureSearch : { featureName: string, featureDescription: string}) : Promise<IFeatureDescription[]>  => {        
      const {featureName, featureDescription} = featureSearch;
      const {data} = (await axios.get(`/api/home/get-features-by-entity-type/?entityName=${entityName}&featureName=${featureName}&featureDescription=${featureDescription}`));
      return await data;
    } 

    const getFeaturesByEntity = async (entityName: string, entityId: number) : Promise<IFeatureDescription[]>  => {        
      const {data} = (await axios.get(`/api/home/get-features-by-entity/?entityName=${entityName}&entityId=${entityId}`));
      return await data;
    } 

    const getFeaturesByEntityForUser = async (entityName: string, entityId: number) : Promise<IFeatureDescription[]>  => {        
      const {data} = (await axios.get(`/api/home/get-features-by-entity-for-user/?entityName=${entityName}&entityId=${entityId}`));
      return await data;
    }

    const getReportsByEntityForUser = async (entityName: string, entityId: number) : Promise<IReportDescription[]>  => {        
      
      const {data} = (await axios.get(`/api/home/get-reports-by-entity-for-user/?entityName=${entityName}&entityId=${entityId}`));
      return await data;
    }

    const getParametersByFeature = async (entityName: string, featureName: string) : Promise<IFeatureParameter[]>  => {        
      const {data} = (await axios.get(`/api/home/get-parameters-by-feature/?entityName=${entityName}&featureName=${featureName}`));
      return await data;
    }

    const getAuthorizationByEntityType = async (entityName: string, entityId: number) : Promise<{value: string, name: string}[]>  => {        
      const {data} = (await axios.get(`/api/home/get-authorizations-by-entity-type/?entityName=${entityName}`));
      return await data;
    } 

    const executeEntityFeature = async (entityAction: IFeatureDescription )  => 
      await (await axios.post('/api/home/execute-entity-feature', entityAction)).data;

    const generateEntityReport = async (reportDescription: IReportDescription )  => 
      await (await axios.post('/api/home/generate-entity-report', reportDescription)).data;

    const getRootEntities = async (entitySearch: {name: string, description: string}) : Promise<IEntity[]>  => {    
      const {name, description} = entitySearch;
      const {data} = (await axios.get(`/api/home/get-root-entities/?name=${name}&description=${description}`));
      return await data;
    }

    const getEntities = async (entitySearch: {name: string, description: string}) : Promise<IEntity[]> => {    
      const {name, description} = entitySearch;
      const {data} = (await axios.get(`/api/home/get-entities/?name=${name}&description=${description}`));
      return await data;
    }

    const getEntitiesWithActionState = async (entitySearch: {name: string, description: string}) : Promise<IEntity[]> => {    
      const {name, description} = entitySearch;
      const {data} = (await axios.get(`/api/home/get-entities-with-action-state/?name=${name}&description=${description}`));
      return await data;
    }

    const getEntityActionStates = async (entityName: string) : Promise<IEntityActionState[]>  => {          
      const {data} = (await axios.get(`/api/home/get-entity-actions-by-entity/?entityName=${entityName}`));
     
      return await data;
    }

    const getEntityProperties = async (entityName: string) : Promise<IEntityProperty[]>  => {          
      const {data} = (await axios.get(`/api/home/get-properties-by-entity/?entityName=${entityName}&enumerableOnly=${false}`));
     
      return await data;
    }

    const getEntityPropertiesEx = async (entityName: string, enumerableOnly: boolean) : Promise<IEntityProperty[]>  => {          
      const {data} = (await axios.get(`/api/home/get-properties-by-entity/?entityName=${entityName}&enumerableOnly=${enumerableOnly}`));
     
      return await data;
    }

    const getQueryFeaturesByEntity = async (entityName: string) : Promise<IQueryFeatureDescription[]>  => {          
      const {data} = (await axios.get(`/api/home/get-query-features-by-entity/?entityName=${entityName}`));
     
      return await data;
    }

    const getQueryFeatureInnerObjectProperties = async (entityName: string, queryFeatureName: string) : Promise<IEntityProperty[]>  => {          
      const {data} = (await axios.get(`/api/home/get-query-feature-inner-object-properties/?entityName=${entityName}&queryFeatureName=${queryFeatureName}`));
     
      return await data;
    }

    const checkEntityExpressionSyntax = async (entityName: string, expression: string) : Promise<{syntaxOk: boolean, syntaxError: string, returnType: string}>  => //{          
      await (await axios.post('/api/home/check-entity-expression-syntax', {entityName, expression})).data;
    //   const {data} = (await axios.post(`/api/home/check-entity-expression-syntax/?entityName=${entityName}&expression2Check=${expression2Check}`));
    //   return await data;
    // }

    const openEntityActionDrawer = async (entityName: string, entityId: number, 
                entityContextualItems?: {entityName: string, entityId: number}[]) => {         
      
      if(isFalsy(entityName) || isFalsy(entityId) || entityId<= 0) {
          enqueueSnackbar( t('This action is only taken on valid entity'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
      }

      setActionEntityName(entityName);
      setActionEntityId(entityId);
      setActionEntityContextualItems( entityContextualItems || []);
      setIsActionDrawerOpen(true);      
    }

    const openEntityPrintDrawer = async (entityName: string, entityId: number) => {         
      
      if(isFalsy(entityName) || isFalsy(entityId) || entityId<= 0) {
          enqueueSnackbar( t('This action is only taken on valid entity'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
      }

      setActionEntityName(entityName);
      setActionEntityId(entityId);
      setActionFunctionEntityParamters([]);
      setIsPrintDrawerOpen(true);      
    }

    const openEntityPrintDrawerEx = async (entityName: string, functionEntityParameters: {name: string, value: any}[]) => {         
      
      if(isFalsy(entityName) || isFalsy(functionEntityParameters) ) {
          enqueueSnackbar( t('This action is only taken on valid entity'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
      }

      setActionEntityName(entityName);
      setActionEntityId(0);
      setActionFunctionEntityParamters(functionEntityParameters);
      setIsPrintDrawerOpen(true);      
    }

    const openEntityAuthorizationBox = async (entityName: string, entityId: number) => {         
      
      if(isFalsy(entityName) || isFalsy(entityId) || entityId<= 0) {
          enqueueSnackbar( t('This action is only taken on valid entity'), { variant: 'warning',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 }); 
          return;
      }

      setActionEntityName(entityName);
      setActionEntityId(entityId);
      setIsAuthorizationBoxShow(true);      
    }

    function showEntitySearchBox()  {       
      setIsSearchBoxShow(true);      
    }

    const checkEntitySaveAuthorization = (entityName: string, entityId: number) : boolean => {

      if(isFalsy(entityName)) {
        enqueueSnackbar( t('Invalid entity'), { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return false;
      }

      const {roleEntities} = currentUserSession;
      const roleEntity = roleEntities.find(re => re.entityName === entityName);
      if(isFalsy(roleEntity)) {
        enqueueSnackbar( t('You are not allowed to save this entity'), { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return false;
      }

      const {canCreate, canUpdate} = roleEntity;
      if(!canCreate && entityId <= 0) {
        enqueueSnackbar( ` ${t('You are not allowed to create this entity')} \n ${canUpdate? t('but, you can modify it.'): ''} ` , { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return false;
      }

      if(!canUpdate && entityId > 0) {
        enqueueSnackbar( ` ${t('You are not allowed to modify this entity')} \n ${canCreate? t('but, you can create it.'): ''} ` , { variant: 'error',
            anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
          return false;
      }

      return true;
    }



    // function showEntitySearchBox<T> (basicFilterProps: BasicTextFilterProps<T>)  {      

      
    //   //setCurrentBasicTextFilterProps(basicFilterProps);
    //   setIsSearchBoxShow(true);      
    // }


    // const setCurrentActionsByEntity = async (entityName: string, entityId: number)  => { 
    //   const actions = await getActionsByEntity(entityName,entityId);

    //   setCurrentEntityActions(actions);
    // } 

      
    return {    
        getServerTime,

        retrieveEntity,
        retrieveData,

        getFeaturesByEntityType,
        getFeaturesByEntity,
        getFeaturesByEntityForUser,

        getReportsByEntityForUser,

        getParametersByFeature,

        getRootEntities,
        getEntities,
        getEntitiesWithActionState,

        getEntityActionStates,
        getEntityProperties,
        getEntityPropertiesEx,

        getQueryFeaturesByEntity,
        getQueryFeatureInnerObjectProperties,

        getAuthorizationByEntityType,
        
        checkEntityExpressionSyntax,

        executeEntityFeature,
        generateEntityReport,

        openEntityActionDrawer,
        openEntityPrintDrawer,
        openEntityPrintDrawerEx,

        showEntitySearchBox,

        openEntityAuthorizationBox,

        checkEntitySaveAuthorization
        
        // setCurrentActionsByEntity,
    } 
}

export default _;


export interface IFilterEntityOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IEntity[], React.Dispatch<React.SetStateAction<IEntity[]>>],
}

const defaultFilterEntityOption: IFilterEntityOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}



export const useBasicFilterEntity = ( onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEntity) => void,
                                      filterOption?: IFilterEntityOption,
                                      onlyRoot?: boolean, 
                                      onlyEntityAction?: boolean ) => {

  const { getRootEntities, getEntities, getEntitiesWithActionState } = _();

  const getMethod = onlyEntityAction ? getEntitiesWithActionState : 
                    onlyRoot === false ? getEntities : getRootEntities;

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();

  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterEntityOption;

  const [headEntityCells, setHeadEntityCells]  = useState<HeadCell<IEntity>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'description', label : t('Description'),  display: true, type: 'string', },
  ]);  
  const [filterElements,] = useState( [       
      {name: 'name', text: t('Name'), value: ''}, 
      {name: 'description', text: t('Description'), value: ''},     
    ]);    

  const [filteredEntities, setFilteredEntities] = useState<IEntity[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IEntity[]> => {
        
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
        
    //const arr = await getRootEntities( {name, description} );
    const arr = await getMethod( {name, description} );
    return arr;
  }

  const objKey: keyof IEntity = 'name';

  return {
    title: t('Roles'), headCells: headEntityCells, objKey,
    filterElements, rows: filteredEntities, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

export interface IFilterFeatureOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IFeatureDescription[], React.Dispatch<React.SetStateAction<IFeatureDescription[]>>],
}

const defaultFilterFeatureOption: IFilterFeatureOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}

export interface IFilterPropertyOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IEntityProperty[], React.Dispatch<React.SetStateAction<IEntityProperty[]>>],
}

const defaultFilterPropertyOption: IFilterPropertyOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}

export interface IFilterQueryFeatureOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IQueryFeatureDescription[], React.Dispatch<React.SetStateAction<IQueryFeatureDescription[]>>],
}

const defaultFilterQueryFeatureOption: IFilterQueryFeatureOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}

export const useBasicFilterFeatureDescription = ( getEntityName: () => string,
    onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IFeatureDescription) => void, filterOption?: IFilterFeatureOption  ) => {

  const { getFeaturesByEntityType } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterFeatureOption;  

  const [headFeatureDescriptionCells, setHeadFeatureDescriptionCells]  = useState<HeadCell<IFeatureDescription>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', },
    {id:'label', label : t('Description'),  display: true, type: 'string', },
  ]);  
  const [filterElements,] = useState( [       
    {name: 'featureName', text: t('Name'), value: ''}, 
    {name: 'featureDescription', text: t('Description'), value: ''},   
    ]);    

  const [filteredFeatureDescriptions, setFilteredFeatureDescriptions] = useState<IFeatureDescription[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IFeatureDescription[]> => {
               
    const featureName = filterElements.find( elt => elt.name === 'featureName')?.value || '';
    const featureDescription = filterElements.find( elt => elt.name === 'featureDescription')?.value || '';
        
    const arr = await getFeaturesByEntityType( getEntityName(), {featureName, featureDescription} );
    
    return arr;
  }

  const objKey: keyof IFeatureDescription = 'name';

  return {
    title: t('Features'), headCells: headFeatureDescriptionCells, objKey,
    filterElements, rows: filteredFeatureDescriptions, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}


export const useBasicFilterProperty = ( getEntityNamePropEnum: () => {entityName: string, enumerableOnly: boolean }, 
    onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IEntityProperty) => void, filterOption?: IFilterPropertyOption  ) => {

  const { getEntityPropertiesEx } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterPropertyOption;  

  const [headEntityPropertyCells, setHeadEntityPropertyCells]  = useState<HeadCell<IEntityProperty>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 50, },
    {id:'description', label : t('Description'),  display: true, type: 'string', width: 50, },
  ]);  
  const [filterElements,] = useState( [       
    {name: 'featureName', text: t('Name'), value: ''}, 
    {name: 'featureDescription', text: t('Description'), value: ''},   
    ]);    

  const [filteredEntityProperties, setFilteredEntityProperties] = useState<IEntityProperty[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IEntityProperty[]> => {
               
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
        
    const {entityName,enumerableOnly} = getEntityNamePropEnum();
    console.log({entityName,enumerableOnly});
    const arr = await getEntityPropertiesEx( entityName, enumerableOnly);
    
    return arr;
  }

  const objKey: keyof IEntityProperty = 'name';

  return {
    title: t('Properties'), headCells: headEntityPropertyCells, objKey,
    filterElements, rows: filteredEntityProperties, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}

export const useBasicFilterQueryFeature = ( getEntityNamePropEnum: () => {entityName: string }, 
    onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IQueryFeatureDescription) => void, filterOption?: IFilterQueryFeatureOption  ) => {

  const { getQueryFeaturesByEntity } = _();

  const { t, i18n } = useTranslation();   
  const navigate = useNavigate();
  
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterQueryFeatureOption;  

  const [headQueryFeatureDescriptionCells, setHeadQueryFeatureDescriptionCells]  = useState<HeadCell<IQueryFeatureDescription>[]>([
    {id:'name', label : t('Name'),  display: true, type: 'string', width: 50, },
    {id:'label', label : t('Description'),  display: true, type: 'string', width: 50, },
  ]);  
  const [filterElements,] = useState( [       
    {name: 'queryFeatureName', text: t('Name'), value: ''}, 
    {name: 'queryFeatureDescription', text: t('Description'), value: ''},   
    ]);    

  const [filteredFilterQueryFeatureDescriptions, setFilterQueryFeatureDescriptions] = useState<IQueryFeatureDescription[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]): Promise<IQueryFeatureDescription[]> => {
               
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
    const description = filterElements.find( elt => elt.name === 'description')?.value || '';
        
    const {entityName} = getEntityNamePropEnum();
    
    const arr = await getQueryFeaturesByEntity( entityName);
    
    return arr;
  }

  const objKey: keyof IQueryFeatureDescription = 'name';

  return {
    title: t('Features'), headCells: headQueryFeatureDescriptionCells, objKey,
    filterElements, rows: filteredFilterQueryFeatureDescriptions, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}