import { IApplicationQueryParameter } from "features/setup/models/ApplicationQuery"


export interface IEdition {
        
    id: number,
    name: string,
    description: string,
    isActive: boolean,
    category: string,
    //businessApplicationId: number,
    queryPrepareId?: number,
    queryEmptyId?: number,
    allowByAuthorization: boolean,

    //businessApplicationName: string,
    queryPrepareName: string,
    queryEmptyName: string,
    
    editionExtractions: IEditionExtraction[],
    
    queryPrepareParameters: IApplicationQueryParameter[],
    queryEmptyParameters: IApplicationQueryParameter[],
  }

  export interface IEditionExtraction {
    id: number,
    editionId: number,
    description: string,
    applicationQueryId: number,
    allowByAuthorization: boolean,

    applicationQueryName: string,

    editionExtractionReports: IEditionExtractionReport[],

    applicationQueryParameters: IApplicationQueryParameter[],
  }


  export interface IEditionExtractionReport {
    id: number,
    editionExtractionId: number,
    reportId: number,

    reportName: string,
    reportDescription: string,

    language: string,
  }


  export const defaultEdition : IEdition = {
    id: 0,
    name: '',
    description: '',
    isActive: true,
    category: '',
    //businessApplicationId: 0,
    queryPrepareId: 0,
    queryEmptyId: 0,
    allowByAuthorization: false,

    //businessApplicationName: '',  
    queryPrepareName: '',
    queryEmptyName: '',
    
    editionExtractions: [],

    queryPrepareParameters: [],
    queryEmptyParameters: []
  }

  export interface IEditionSearch {
    
    name: string,
    description: string
  }