
import React, { useEffect, useState } from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';
import { MenuItem, useMediaQuery } from '@mui/material';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';

import useUtils from 'library/utils';

import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { DatePicker, TimePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { makeStyles } from '@mui/material';

import { useTheme } from '@mui/material/styles';


import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';

import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid'; // a plugin!

import timeGridPlugin from '@fullcalendar/timegrid';
import interactionPlugin from '@fullcalendar/interaction';



import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useAppointmentService, { useBasicFilterAppointment } from 'features/production/services/Appointment';
import { IAppointment, IAppointmentProduct, defaultAppointmentProduct } from 'features/production/models/Appointment';

import useEnumerationService from 'features/configuration/services/Enumeration';

import IEnumeration,  { IEnumerationItem, Enum_APPOINTMENT_STATUS, Enum_APPOINTMENT_STATUS_PURPOSE } from 'features/configuration/models/Enumeration';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';

//import EventClickArg  from '@fullcalendar/react'; // Import the types

// import WidgetSummary from './widgets/WidgetSummary';
// import WidgetBarChart from './widgets/WidgetBarChart';
// import WidgetPieChart from './widgets/WidgetPieChart';
import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';
import { DurationInput, EventInput, EventClickArg, } from '@fullcalendar/core';
import { isFalsy } from 'utility-types';
import { useQuery } from 'react-query';


import { isNumber, toNumber } from 'lodash';
import { Controller, useForm } from 'react-hook-form';

export const AppointmentTimeTableForm = () => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const {generateRandomColor} = useUtils();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup, employeeId ,isTechnician} = useRecoilValue(currentUserSessionAtom);
  

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const { getTimeTable} = useAppointmentService();
  
  const [schoolYearClassName, setSchoolYearClassName] = useState('');
  const [timetableEvents, setTimetableEvents] = useState<EventInput[]>([]);
  const [colors, setColors] = useState<Record<string, string>>({});

  const [weekStart, setWeekStart] = useState<Date>(new Date());
  const [weekEnd, setWeekEnd] = useState<Date>(new Date());

  const [slotMinTime, setSlotMinTime] = useState<DurationInput>({});
  const [slotMaxTime, setSlotMaxTime] = useState<DurationInput>({});

  const [openEventDialog, setOpenEventDialog] = useState<boolean>(false);

  const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'AppointmentTimeTable'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_APPOINTMENT_STATUS] ));
      

  const { control: controlEventInput, setValue: setValueEventInput, getValues: getValuesEventInput, 
    register: registerEventInput, reset: resetEventInput, watch: watchEventInput,  } = useForm<IAppointmentProduct>({defaultValues: defaultAppointmentProduct});


  const [hideContact, ] = useState<boolean>( isTechnician && !applicationSetup.appointmentContactAllowedForTechnician );

  const [filterAppointment4Technician, ] = useState<boolean>( isTechnician && !applicationSetup.appointmentAllAvailableForTechnician );

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  // const basicFilterSchoolYearClass = useBasicFilterSchoolYearClass( 
  //   async (event: React.MouseEvent<unknown>, row: ISchoolYearClass) => {
  //       setIsSearchBoxShow(false);

  //       const {id, name} = row;
  //       setSchoolYearClassName(name);     
        
  //       const timeTable = await getSchoolYearClassTimeTable(id);

  //       const {timetableEvents, weekStart, weekEnd} = timeTable;
  //       const colorSet = new Set( timetableEvents.map( ({title, }) => title ));
  //       const colorMap : Record<string, string> = {};

  //       for (let i = 0; i < timetableEvents.length; i++) {
  //         const {title} = timetableEvents[i];
  //         if(isFalsy(title)) continue;

  //         if( isFalsy(colorMap[title]))
  //           colorMap[title] = generateRandomColor();

  //         timetableEvents[i].backgroundColor = colorMap[title];
  //       }
  
  //       setTimetableEvents(timetableEvents);
  //       setWeekStart(weekStart);
  //       setWeekEnd(weekEnd);
  //       //console.log(timeTable);
  //     }
  // );

  

  useEffect( () => {        
    setCurrentFormName(`:: ${t('Appointment')} ::`);
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
    
  }, []);     

  const [date, setDate] = React.useState<Date>(new Date());
  const [status, setStatus] = React.useState<string>('10');
  const [view, setView] = React.useState<string>('1');

  useEffect(() => {
    async function _() {
             
      const timeTable = await getTimeTable(date, status,  toNumber(view) );

      const {timetableEvents, weekStart, weekEnd, slotMaxTime, slotMinTime } = timeTable;
      
      setSlotMinTime(slotMinTime);
      setSlotMaxTime(slotMaxTime);

      setTimetableEvents( !filterAppointment4Technician ? timetableEvents :
        timetableEvents.filter(evt => evt.extendedProps && [0, null, undefined, employeeId].includes(evt.extendedProps['EmployeeId']) )
        );
      
      setWeekStart(weekStart);
      setWeekEnd(weekEnd); 
    }

    _();
  }, [date, status, view]);
  
  // Custom title component for the header
  const HeaderTitle = () => {
    return <Typography variant="h6">School Timetable</Typography>;
  };

  const renderHeader = (info: any) => {
    return (
      <div>
        {info.date.toLocaleDateString(lg, { weekday: 'long' })}
      </div>
    );
  };

  const handleDayCellContent = (arg: any) => {
    // Customize the rendering of individual days
    const day = arg.dayNumberText;

    if (day === '0') {
      return (
        <div className="custom-sunday">
          {day}
        </div>
      );
    }

    return day;
  }

 
  const handleEventClick = (clickInfo: EventClickArg) => {

    setValueEventInput('employeeFirstName', clickInfo.event._def.extendedProps['EmployeeFirstName'] as unknown as string);
    setValueEventInput('employeeLastName', clickInfo.event._def.extendedProps['EmployeeLastName'] as unknown as string);
    setValueEventInput('productName', clickInfo.event._def.extendedProps['ProductName'] as unknown as string);

    setValueEventInput('firstName', clickInfo.event._def.extendedProps['FirstName'] as unknown as string);
    setValueEventInput('lastName', clickInfo.event._def.extendedProps['LastName'] as unknown as string);

    setValueEventInput('portable1', clickInfo.event._def.extendedProps['Portable1'] as unknown as string);
    setValueEventInput('portable2', clickInfo.event._def.extendedProps['Portable2'] as unknown as string);
    setValueEventInput('email1', clickInfo.event._def.extendedProps['Email1'] as unknown as string);
    setValueEventInput('email2', clickInfo.event._def.extendedProps['Email2'] as unknown as string);

    setValueEventInput('appointmentDate', clickInfo.event.start || new Date());

    setValueEventInput('startTime', clickInfo.event.start || new Date());
    setValueEventInput('endTime', clickInfo.event.end || new Date());
    

    setOpenEventDialog(true);
    //console.log(clickInfo);

    
    
    //const event: MyEvent = info.event.extendedProps as MyEvent;
    //alert(`Event: ${event.title}, Start: ${event.start}`);
    // You can access other properties of the clicked event using the event object
    // For example: event.id, event.end, etc.
  };

  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={3}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Typography variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                {`${t(('Appointment'))} ::: `}
              </Typography>
              <DatePicker                      //label={t('Appointment date')} 
                slotProps={{ textField: { sx: {width:isSm?'calc(25% - 8px)':'calc(10% - 8px)'}  }} }
                //renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(25% - 8px)':'calc(10% - 8px)'}} />}
                onChange={(newValue) => setDate(newValue||new Date())}                    
                value={date}                      
              />
              
              <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setStatus(event.target.value); }} 
                value={status} sx={{ml:3 ,width: isSm?'calc(30% - 8px)':'calc(15% - 8px)'}} id="status"
                inputProps={ {readOnly: false}} >
                {enumItems && enumItems.filter( e => 
                      e.enumerationCode === Enum_APPOINTMENT_STATUS ).map( 
                  (x,idx) => <MenuItem key={x.code} value={x.code}>{x.name}</MenuItem> )
                }
              </TextField>

              <TextField select onChange={(event: React.ChangeEvent<HTMLInputElement>) => { setView(event.target.value); }} 
                value={view} sx={{ml:3 ,width: isSm?'calc(30% - 8px)':'calc(15% - 8px)'}} id="view"
                inputProps={ {readOnly: false}} >
                <MenuItem value='1'>{t('Service')}</MenuItem>
                <MenuItem value='2'>{t('Employee')}</MenuItem>
              </TextField>
                                    
            </Box>
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} key={`box calendar ${date} ${status}`}>
              <div key={`div calendar ${date}`} style={{ width: '100%' }}>
                <FullCalendar
                  key={`calendar ${weekStart} ${weekEnd}`}
                  plugins={[timeGridPlugin]} // {[dayGridPlugin, timeGridPlugin, interactionPlugin]}
                  locale={lg}
                  initialView="timeGridWeek"
                  // headerToolbar={{
                  //   //left: 'prev,next today',
                  //   //center: 'title',
                  //   left: '',
                  //   center: '',
                  //   //right: 'dayGridMonth,timeGridWeek,timeGridDay'
                  //   right: ''
                  // }}
                  firstDay={1}
                  dayCellContent={handleDayCellContent}
                  headerToolbar={false}
                  //height="700px"
                  initialDate={weekStart} // Set the initial start date of the week view
                  //weekends={false} // Optional: Hide weekends if desired
                  validRange={{ start: weekStart }} // Set the valid date range for the week view
                  nowIndicator={true} // Show current time indicator
                  height="auto" // Auto-adjust height to content
                  allDaySlot={false} // Hide all-day slot
                  slotMinTime={slotMinTime} // Start time for the timetable (e.g., 08:00 AM)
                  slotMaxTime={slotMaxTime} // End time for the timetable (e.g., 06:00 PM)
                  slotLabelFormat={{
                    hour: 'numeric',
                    minute: '2-digit',
                    omitZeroMinute: false,
                    //meridiem: false,
                  }}
                  // Custom toolbar rendering function
                  events={timetableEvents}
                  dayHeaderContent={renderHeader}
                  //eventClick={e => {  console.log({e, t: typeof e});}}
                  eventClick={handleEventClick}
                />
              </div>
            </Box>
            { openEventDialog && <FormDialog open={openEventDialog} maxWidth='sm' height='55vh'
                okText='' cancelText='' title={` ${getValuesEventInput().productName} :: `} onCancel={()=> {}} 
                onClose={()=> {setOpenEventDialog(false);}} onOk={()=> {setOpenEventDialog(false);}}  >
                    <Stack flexDirection='column'  >
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <Controller control={controlEventInput}
                            name='appointmentDate' 
                            render={({ field: { onChange, onBlur, value, ref } }) => (
                              <DatePicker label={t('Appointment date')} 
                                onChange={onChange} disabled={true}                    
                                value={new Date(value)}
                                slotProps={{ textField: { sx: {width:'calc(30% - 8px)'}  }} }
                                //renderInput={(params) => <TextField {...params} sx={{width:'calc(30% - 8px)'}} />}
                              /> )}
                          />
                          <Controller
                              key={`query--startTime-x`}
                              render={({ field: { onChange, onBlur, value, ref } }) => (
                                <TimePicker label={t('Time')}  
                                  format="HH:mm:ss"    
                                  views={['hours', 'minutes', 'seconds']}   
                                  onChange={onChange}  disabled={true}                                    
                                  value={value}
                                  slotProps={{ textField: { sx: {width:'calc(20% - 8px)', textAlign: 'center'}  }} }
                                  //renderInput={(params) => <TextField {...params} sx={{width:`calc(20% - 8px)`, textAlign: 'center'}} />}
                                /> )}
                              name={`startTime`}          
                              control={controlEventInput}
                            />
                      </Box>
                      <Box sx={{ mt: 0.25, mb: 0.25, width: '100%' }} >
                        <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                          {`${t('Customer')} ...`}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 0.25, width: '100%' }} >
                        <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                          {...registerEventInput(lg.startsWith('fr')?'lastName':'firstName')} inputProps={{readOnly: true }}  />   
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                          {...registerEventInput(lg.startsWith('fr')?'firstName':'lastName')} inputProps={{readOnly: true }}  />  
                      </Box>
                      {!hideContact && <Box sx={{ mt: 0.25, width: '100%' }} >
                        <TextField sx={{width:'calc(25% - 8px)'}} id="fn" label={`${t('Portable')} 1`} 
                          {...registerEventInput('portable1')} inputProps={{readOnly: true }}  />   
                        <TextField sx={{width:'calc(25% - 8px)'}} id="fn" label={`${t('Portable')} 2`} 
                          {...registerEventInput('portable2')} inputProps={{readOnly: true }}  />  
                        <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={`${t('Email')}`} 
                          {...registerEventInput('email1')} inputProps={{readOnly: true }}  />
                      </Box>}
                      <Box sx={{ mt: 1, mb: 0.25, width: '100%' }} >
                        <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                          {`${t('Employee')} ...`}
                        </Typography>
                      </Box>
                      <Box sx={{ mt: 1, width: '100%' }} >
                        <TextField sx={{width:'calc(50% - 8px)'}} id="fn" label={lg.startsWith('fr')?t('Last name'):t('First name')} 
                          {...registerEventInput(lg.startsWith('fr')?'employeeLastName':'employeeFirstName')} inputProps={{readOnly: true }}  />   
                        <TextField sx={{width:'calc(50% - 8px)'}} id="ln" label={lg.startsWith('fr')?t('First name'):t('Last name')} 
                          {...registerEventInput(lg.startsWith('fr')?'employeeFirstName':'employeeLastName')} inputProps={{readOnly: true }}  />  
                      </Box>
                    </Stack>
            </FormDialog> }
          </Stack>
        </Grid>
      </Grid>
      
    </Box>
  )
}
