
import { useSnackbar } from 'notistack';

import { useRecoilValue } from 'recoil';

import useAxios from 'library/axios'; 
import { useState } from 'react';
import {IIntermediary, IIntermediarySearch } from "../models/Intermediary";
import { useTranslation  } from 'react-i18next';
import { ITextFilterElement } from 'components/ui/BasicTextFilterForm';
import { IResult } from 'library/interface';
import { HeadCell, RowCheckedMode } from 'components/ui/EnhancedTable';


import { currentUserSessionAtom } from 'library/store';


const _ = () => {

    const axios = useAxios(); 

    //const {requestTypeBusinessApps} = useRecoilValue(currentUserSessionAtom);    

    const createIntermediary = async (intermediary: IIntermediary)  =>       
        await (await axios.post('/api/production/intermediary/create', intermediary)).data;       
        
    const updateIntermediary = async (intermediary: IIntermediary)  =>       
        await (await axios.post('/api/production/intermediary/update', intermediary)).data; 
    
    const getIntermediary = async (id  : number )  => {
      const {data} = (await axios.get(`/api/production/intermediary/get-intermediary/${id}`));
      return await data;
    }
   
    const getIntermediaryByCode = async (code  : string ) : Promise<IIntermediary>  => {
      const {data} = (await axios.get(`/api/production/intermediary/get-intermediary-by-code?code=${code}`));
      return await data;
    }

    const getIntermediaries = async (criteria: IIntermediarySearch) : Promise<IIntermediary[]> => {

    const {code, name} = criteria;

    const {data} = (await axios.get(`/api/production/intermediary/get-intermediaries?code=${code}&name=${name}`));
    return await data;
    }

    const getMainApplicationIntermediaries = async (criteria: IIntermediarySearch) : Promise<IIntermediary[]>   => {
      const {code, name} = criteria;
      //const { coreKey ,contractNumber,intermediaryCode ,customerName, productName} = criteria;

      // const requestTypeBusinessApp = requestTypeBusinessApps.find( x => x.id === requestTypeBusinessAppId);
      // if(requestTypeBusinessApp === null || requestTypeBusinessApp === undefined)
      //   return [];

      // const { businessApplicationLocalServerApiUrl, isBusinessApplicationDirectConnection } = requestTypeBusinessApp;
      // const localApi = isBusinessApplicationDirectConnection? '':  `${businessApplicationLocalServerApiUrl}/`;

      const {data} = (await axios.get(`api/production/intermediary/get-mainApplication-intermediaries?code=${code}&name=${name}`));
      return await data;
    }
    
      
    return {    
      createIntermediary,
      updateIntermediary,
      getIntermediary,
      getIntermediaryByCode,
      getIntermediaries,

      getMainApplicationIntermediaries
      
    } 
}

export default _;

export interface IFilterIntermediaryOption {
  rowCheckedMode: RowCheckedMode,
  stateSelected?: [string[], React.Dispatch<React.SetStateAction<string[]>>],
  stateFiltered?: [IIntermediary[], React.Dispatch<React.SetStateAction<IIntermediary[]>>],
}

const defaultFilterIntermediaryOption: IFilterIntermediaryOption = {
  rowCheckedMode: 'single'
  //stateSelected: navigate
}


export const useBasicFilterIntermediary = (  onRowDoubleClick: (event: React.MouseEvent<unknown>, row: IIntermediary) => void,
                                            filterOption?: IFilterIntermediaryOption  ) => {

  const { getIntermediaries } = _();

  const { t, i18n } = useTranslation();   
  const {rowCheckedMode, stateSelected, stateFiltered} = filterOption || defaultFilterIntermediaryOption;



  const [headIntermediaryCells, setHeadIntermediaryCells]  = useState<HeadCell<IIntermediary>[]>([
    {id:'id', label : t('Id'),  display: false, type: 'numeric', },
    {id:'code', label : t('Code'),  display: true, type: 'string', },
    {id:'name', label : t('Name'),  display: true, type: 'string', },    
    {id:'countRequestDataDemandToSend', label : `# ${t('RequestDataDemand')}`,  display: true, type: 'string', },    

  ]); 
  const [filterElements,] = useState<ITextFilterElement[]>( [
         
      {name: 'code', text: t('Code'), value: ''},
      {name: 'name', text: t('Name'), value: ''},
      
    ]);    
  const [filteredIntermediarys, ] = useState<IIntermediary[]>([]); 

  const onFilterButtonClick = async (filterElements: ITextFilterElement[]) : Promise<IIntermediary[]> => {
    
    
    const code = filterElements.find( elt => elt.name === 'code')?.value || '';
    const name = filterElements.find( elt => elt.name === 'name')?.value || '';
        
    const arr = await getIntermediaries( {code, name} );
    
    return arr;
  }

  const objKey: keyof IIntermediary = 'id';

  return {
    title: t('Intermediary'), headCells: headIntermediaryCells, objKey,
    filterElements, rows: filteredIntermediarys, 
    onFilterButtonClick, onRowDoubleClick, rowCheckedMode, stateSelected, stateFiltered
  }
}
