
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';

import {useWindowSize} from 'react-use';

import { currentBasicTextFilterPropsAtom, currentFormNameAtom, currentUserSessionAtom } from 'library/store';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';

import { DatePicker } from '@mui/x-date-pickers';
import TextField, { TextFieldProps } from '@mui/material/TextField';

import { useMediaQuery } from '@mui/material';
import { useTheme } from '@mui/material/styles';

import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { flexBetween, flexBetweenCenter, flexCenter, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import useBillingService from 'features/finance/services/Billing';

import { BasicTextFilterForm, defaultBasicTextFilterProps } from 'components/ui/BasicTextFilterForm';


import { Button } from '@mui/material';
import { MdOutlineChangeCircle } from 'react-icons/md';
import { FormDialog } from 'components/ui/FormDialog';


import {IBillingDetail, IBillingDashboardMonth, defaultDashboardDate, defaultBillingDashboardMonth } from 'features/finance/models/Billing';

import WidgetSummaryBasic from 'features/widgets/WidgetSummaryBasic';
import EnhancedTable from 'components/ui/EnhancedTable';
import { IBilling } from 'features/finance/models/Billing';



export const BillingDashboardForm: FC<IBillingDashboardMonth> = (props: IBillingDashboardMonth = defaultBillingDashboardMonth) => {

  const theme = useTheme();

  const { t, i18n } = useTranslation();

  const [currentFormName, setCurrentFormName] = useRecoilState(currentFormNameAtom);

  const {language: lg, applicationSetup} = useRecoilValue(currentUserSessionAtom);
  const [schoolYearName, setSchoolYearName] = useState('');


  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);

  const {height, width} = useWindowSize();
  const isSm = useMediaQuery( theme.breakpoints.down('sm'));

  const {getMonthDashboard} = useBillingService();
  const [dashboard, setDashboard] = useState<IBillingDashboardMonth>(defaultBillingDashboardMonth);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<IDashboardMonthClass[]>([]);

  //const [schoolYearClasses, setSchoolYearClasses] = useState<IDashboardMonthClass[]>([]);

  // const [openSchoolYearFilter, setOpenSchoolYearFilter] = useState(false);
  // const basicFilterSchoolYear = useBasicFilterSchoolYear( 
  //     async (event: React.MouseEvent<unknown>, row: IDashboardMonth) => {
  //         const {id, name } = row;

  //         const dash = await getSchoolYearDashboard(id);
  //         setSchoolYearName(name);

  //         setDashboard(dash);
  //         setOpenSchoolYearFilter(false);
  //     }
  // );


  const handleClickSelectSchoolYear = (event: any) => {
    //setOpenSchoolYearFilter(true);
  }


  useEffect( () => {        
    setCurrentFormName(t('Home'));
    setCurrentBasicTextFilterProps(defaultBasicTextFilterProps);
  }, []);    

  const [date, setDate] = React.useState<Date>(new Date());

  useEffect(() => {
    async function _() {
             
      const dash = await getMonthDashboard(date.getFullYear(), date.getMonth()+1 ); // getMonth of js give the month [0-11]
            
      setDashboard(dash);  
    }
    _();
  }, [date]);
  
  return (
    <Box sx={{ mx: 0.1 }}>
      <Grid container rowSpacing={1} columnSpacing={1}>
        <Grid item xs={12}>
          <Stack flexDirection='column'>
            
            <Box sx={{ ...justifyCenter, mt: 0.25, mb: 0.25 }} >
              <Button>
                <Typography  variant="h6" id="tableTitle" color="primary" noWrap sx={{...typographyGroupBoxStyling}}>
                  {`:::: ${t('The month of')} :::::`}
                </Typography>                
              </Button>              
              <DatePicker                      //label={t('Appointment date')} 
                views={["year", "month"]}
                slotProps={{ textField: { sx: {width:isSm?'calc(35% - 8px)':'calc(15% - 8px)'}  }} }
                //renderInput={(params) => <TextField {...params} sx={{width: isSm?'calc(35% - 8px)':'calc(15% - 8px)'}} />}
                onChange={(newValue) => setDate(newValue||new Date())}                    
                value={new Date(date)}                      
              /> 
            
            </Box>
            
          </Stack>
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount')}`} paletteColor={theme.palette['info']} 
                 iconName='IoSendSharp' total={dashboard.amount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Amount paid')} `} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.amountPaid}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unpaid amount')} `} paletteColor={theme.palette['error']} 
                   iconName='IoSendSharp' total={dashboard.unpaidAmount}  />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Cancelled amount')} `} paletteColor={theme.palette['warning']} 
                   iconName='IoSendSharp' total={dashboard.cancelledAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`- ${t('Unvalidated amount')}`} paletteColor={theme.palette['warning']} 
                 iconName='IoSendSharp' total={dashboard.unvalidatedAmount} />
        </Grid>
        <Grid item xs={6} sm={4} md={2} >
          <WidgetSummaryBasic title={`${t('Amount paid')} ==> ${t('Total')}`} paletteColor={theme.palette['success']} 
                  iconName='IoSendSharp' total={dashboard.tellerOperationAmount}  />
        </Grid> 
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<{employeeFullName: string, amount: number, count: number}> 
                  rows={dashboard.technicianTasks} 
                  headCells={[            
                    
                    {id:'employeeFullName', label : t('Name'),  display: true, type: 'string', width: 70 },
                    
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 20},
                    {id:'count', label : '#',  display: true, type: 'numeric', width: 10},                                  
                    
                  ]} 
                  title={`${t('Employee')} (${t('Tasks')})`} objKey='employeeFullName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='employeeFullName'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<{employeeFullName: string, amount: number, count: number}> 
                  rows={dashboard.technicianCustomers} 
                  headCells={[            
                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                    {id:'employeeFullName', label : t('Name'),  display: true, type: 'string', width: 70 },
                    
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 20},
                    {id:'count', label : '#',  display: true, type: 'numeric', width: 10},                       
                    
                  ]} 
                  title={`${t('Employee')} (${t('Clients')})`} objKey='employeeFullName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='employeeFullName'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>
        </Grid>
        <Grid item xs={12} md={6} >
          <Stack flexDirection='column'>
            
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBillingDetail> rows={dashboard.productTypes} 
                  headCells={[            
                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                    {id:'type', label : t('Name'),  display: true, type: 'expression', width: 55,
                      getExpressionNode: (row: IBillingDetail, cellId: keyof IBillingDetail) => {
                        if(row.type === 'service') return t('Service');
                        if(row.type === 'article') return t('Article');
                        if(row.type === 'packaging') return t('Packaging');
                        if(row.type === 'formation') return t('Formation');
                        if(row.type === 'maintenance') return t('Maintenance');
                        if(row.type === 'rental') return t('Rental');
                        if(row.type === 'contract') return t('Contract');
                        if(row.type === 'fee') return t('Fee');
                        if(row.type === 'consumption') return t('Consumption');
                        
                        return row.type;
                      } },
                      
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 15},
                    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                    // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                    {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},                   
                    
                  ]} 
                  title={t(`Amount per product type`)} objKey='productName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBillingDetail> rows={dashboard.lineOfBusinesses} 
                  headCells={[            
                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                    {id:'lineOfBusinessName', label : t('Name'),  display: true, type: 'string', width: 55},
                    
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 15},
                    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                    // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                    {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},                    
                    
                  ]} 
                  title={t(`Amount per line of business`)} objKey='lineOfBusinessName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='lineOfBusinessName'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
            <Box sx={{ mt: 1, width: '100%' }} >
              <EnhancedTable<IBillingDetail> rows={dashboard.products} 
                  headCells={[            
                    //{id:'id', label : t('Id'),  display: true, type: 'numeric', },
                    {id:'productName', label : t('Name'),  display: true, type: 'string', width: 55},
                    
                    {id:'amount', label : t('Amount'),  display: true, type: 'numeric', width: 15},
                    {id:'amountPaid', label : t('Amount paid'),  display: true, type: 'numeric', width: 15},
                    // {id:'dueAmount', label : t('Due amount'),  display: true, type: 'numeric', width: 18},
                    {id:'unpaidAmount', label : t('Unpaid amount'),  display: true, type: 'numeric', width: 15},                  
                    
                  ]} 
                  title={t(`Amount per product`)} objKey='productName' 
                  stateSelected={undefined} 
                  onRowSelected={undefined} rowCheckedMode='single'
                  onRowCheckedSelectChange={undefined} order='desc' orderBy='id'
                  onRowDoubleClick={undefined} 
                  rowActionIcon={undefined}
                  toolbarActions={[
                    // { toolTip: `${t('Add')}...`, onClickIcon: handleRefeshRequestDataDemand ,icon: RefreshOutlinedIcon,  },                      
                  ]}
                />
            </Box>
          </Stack>
        </Grid>

      </Grid>
      
    </Box>
  )
}
