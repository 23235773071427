
import React, {FC, MouseEvent, useState, useRef, useEffect, ChangeEvent, MutableRefObject}  from 'react';
import {useParams} from 'react-router';
import { useNavigate } from 'react-router-dom';
import { useSnackbar } from 'notistack';

import { Controller, FieldArray, FieldArrayMethodProps, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { StringIfPlural, useTranslation  } from 'react-i18next';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';

import ToggleButton from '@mui/material/ToggleButton';
import ToggleButtonGroup from '@mui/material/ToggleButtonGroup';

import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';

import ArrowDropDownCircleIcon from '@mui/icons-material/ArrowDropDownCircle';
import DeleteIcon from '@mui/icons-material/Delete';
import DeveloperModeIcon from '@mui/icons-material/DeveloperMode';
import { MdOutlineAdd } from 'react-icons/md';


import entityService, { useBasicFilterEntity } from 'features/services/Entity';
import { currentBasicTextFilterPropsAtom, currentFormNameAtom, isSearchBoxShowAtom, isSaveLoadingAtom } from 'library/store';
import usePackagingService, { useBasicFilterPackaging } from './services/Packaging';
import { useBasicFilterProduct } from './services/Product';

import { useRecoilState } from 'recoil';
import { useMutation, useQuery, useQueryClient } from 'react-query';
import { IEntity, IResult } from 'library/interface';
import Button from '@mui/material/Button';
import { Checkbox, FormControlLabel, IconButton, InputAdornment, MenuItem, Tabs, Tab, Typography, Chip } from '@mui/material';
import NumberFormat from 'react-number-format';

import { IPackaging, IPackagingProduct,  defaultPackaging } from './models/Packaging';


import TextFieldRight from 'components/ui/TextFieldRight';
import { FormDialog } from 'components/ui/FormDialog';
import { BasicTextFilterForm } from 'components/ui/BasicTextFilterForm';
import useEnumerationService, { useBasicFilterEnumeration } from 'features/configuration/services/Enumeration';


import IEnumeration,  { IEnumerationItem, Enum_RESOURCE_CATEGORY } from 'features/configuration/models/Enumeration';
import {ITarification} from 'features/configuration/models/Tarification';


import { IExtensionType  } from 'features/configuration/models/ExtensionType';
import useExtensionTypeService, { useBasicFilterExtensionType } from 'features/configuration/services/ExtensionType';

import { carouselImage, justifyCenter, typographyGroupBoxStyling } from 'themes/commonStyles';

import EntityExpression from 'components/ui/EntityExpression';
import { isFalsy } from 'utility-types';
import ArrayFieldTableEx, { ActionIconTableRow, HeadCell } from 'components/ui/ArrayFieldTableEx';
import { IProduct } from './models/Product';

export const PackagingForm: FC<IPackaging> = (props: IPackaging = defaultPackaging) => {

  const navigate = useNavigate();
  const { t, i18n } = useTranslation();  
  const { enqueueSnackbar } = useSnackbar();

  const {id} = useParams();
  
  const [_id, _setId] = useState<number>( Number( id || 0 ) );

  const {retrieveEntity, retrieveData, openEntityActionDrawer, 
      checkEntityExpressionSyntax, checkEntitySaveAuthorization } = entityService();

  const {getEnumerationItemsByEnumerationCodes, getAsOptions } = useEnumerationService();


  const { createPackaging, updatePackaging } = usePackagingService();

  const [currentFormName, setCurrentFormNameAtom] = useRecoilState(currentFormNameAtom);
  const [isSaveLoading, setIsSaveLoading] = useRecoilState(isSaveLoadingAtom);

  const [isSearchBoxShow, setIsSearchBoxShow] = useRecoilState(isSearchBoxShowAtom);
  const [currentBasicTextFilterProps, setCurrentBasicTextFilterProps] = useRecoilState(currentBasicTextFilterPropsAtom);
  const basicFilterPackaging = useBasicFilterPackaging( 
    (event: React.MouseEvent<unknown>, row: IPackaging) => {
        setIsSearchBoxShow(false);
        _setId(row.id);
      }
  );

  const emptyFunc = (obj: any) => {}
  
  const [openEntityFilter, setOpenEntityFilter] = useState(false);
  const basicFilterEntity = useBasicFilterEntity( 
      (event: React.MouseEvent<unknown>, row: IEntity) => {
          const {name, description} = row;

          // setValue('entityName', name || '');
          // setValue('entityDescription', description || '');
                           
          setOpenEntityFilter(false);
      }
  );

  const [openProductFilter, setOpenProductFilter] = useState(false);
  const basicFilterProduct = useBasicFilterProduct( 0,
      (event: React.MouseEvent<unknown>, row: IProduct) => {
          const {id, name, description} = row;

          // setValue('enumerationId', id);
          // setValue('enumerationName', name);

          (refAppendPackagingProducts.current??emptyFunc)({id: 0, packagingId: _id, productId: id, productName: name , description: ''  });
                           
          setOpenProductFilter(false);
      }
  );
  

  
  const methods = useForm<IPackaging>({defaultValues:defaultPackaging});
  const { register, setValue ,getValues, watch, reset ,handleSubmit ,control , formState: { errors } } = methods;

  //const watchPackaging = watch('type');
  // const watchFileName = watch('fileName');
  // const watchBase64Picture = watch('base64Picture');
   // let { fields, append: appendCoverages, update: updateCoverage ,remove: removeCoverage,  } = useFieldArray({//<O, TName>({ //<O,`billingDetails.${number}.billingDetailTasks`>({
  //   name: `productCoverages`,
  //   control,            
  // });
  

  const queryClient = useQueryClient();
  const {isLoading, isError, isSuccess ,error,mutate } = useMutation<IResult<IPackaging>,Error,IPackaging>(
      _id>0?updatePackaging:createPackaging, {   
        onSuccess: (data: IResult<IPackaging>) => {
          enqueueSnackbar( t('_Operation_done'), { variant: 'success',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1000 }); 
                   
          setIsSaveLoading(false);
          _setId(data.data.id);
          //setCurrentEntityIdForAction(data.data.id);
          
          queryClient.invalidateQueries(['Packaging',data.data.id]);
        },
        onError: (err: Error) => {          
          enqueueSnackbar( error?.message, { variant: 'error',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 2000 });
          setIsSaveLoading(false);
        }
      });

    const {data: _data, refetch} = useQuery<IPackaging>(['Packaging', _id], () => retrieveEntity('Packaging',_id), 
      {refetchOnWindowFocus: false ,enabled: false } );

    const {data: enumItems} = useQuery<IEnumerationItem[]>( ['EnumerationItems', 'Packaging'], () => getEnumerationItemsByEnumerationCodes
      ( [Enum_RESOURCE_CATEGORY ] ));

    const [valueTabCoverage, setValueTabCoverage] = useState<number>(0);

    const handleTabCoverageChange = (event: React.SyntheticEvent, newValue: number) => {  
       setValueTabCoverage(newValue);       
    };
      
    
    const getServiceTaskCode = (row: IPackagingProduct, cellId: keyof IPackagingProduct, 
      opts: {value: string, name: string}[]) => {        
      
        return getAsOptions(refEnumItems.current ?? [],Enum_RESOURCE_CATEGORY);
    }

    const cellEditablePackagingProduct = (row: IPackagingProduct, cellId: keyof IPackagingProduct) => {
      return true;
    }

    const [headPackagingProductCells, setHeadPackagingProductCells]  = useState<HeadCell<IPackagingProduct>[]>([      
      //{id:'userId', label : t('Id'),  display: true, type: 'string', width: 5 },
      {id:'productName', label : t('Product'),  display: true, type: 'string', width: 50, },
      {id:'description', label : t('Description'),  display: true, type: 'string', width: 50, isEditable: cellEditablePackagingProduct },
                 
    ]);

    const refAppendPackagingProducts = useRef<(value: Partial<FieldArray<IPackaging>> | Partial<FieldArray<IPackaging>>[], options?: FieldArrayMethodProps) => void>(null);
    const refUpdatePackagingProduct = useRef<(index: number,value: Partial<FieldArray<IPackaging>> ) => void>(null);
    const refRemovePackagingProduct = useRef<(index: number ) => void>(null);
      
    const handleAddPackagingProducts = (event: any) => {
      setOpenProductFilter(true);
      
    }

    const packagingProductRowActionIcon = ( tarificationMatrix: IPackagingProduct) : ActionIconTableRow<IPackaging,IPackagingProduct> => {
  
      const res: ActionIconTableRow<IPackaging,IPackagingProduct> = {
        toolTip: 'viewDetails',
        icon: RemoveCircleIcon,
        hasAction: true, // ((optionPropertyName1 || '') !== '') || ((optionPropertyName2 || '') !== '') || ((optionPropertyName3 || '') !== ''),
        isActionExecuting: true,
        onRowClickIcon: (event : any,index: number, row: IPackagingProduct) => {
          
           (refRemovePackagingProduct.current??emptyFunc)(index);            
        }
      }
      return res;
  }

  const refEnumItems = useRef<IEnumerationItem[]>();    
  useEffect( () => {   
      refEnumItems.current = enumItems;
    
  }, [enumItems])


      useEffect( () => {        
        setCurrentFormNameAtom(t('Packaging'));
        setCurrentBasicTextFilterProps(basicFilterPackaging);
      }, []);    
    
      /********** This use effect call retreive data wich will call refetch and _data will be updated. 
        and the new useEffect will take place ********************/
        useEffect( () => {
            // setCurrentFormName(t('Billing'));        
            
            if(_id > 0)
              retrieveData('Packaging',_id, refetch);  
          }, [_id] );
    
    
        useEffect( () => {
            
        if(_data && _data.id > 0) {
            reset(_data);
        }
        }, [_data]);
    
      const newData = async (event: MouseEvent<HTMLButtonElement>) => {    
        _setId(0);      
        reset(defaultPackaging);    
      }
      
      const saveData = async (event: MouseEvent<HTMLButtonElement>) => {     
        
        if(!checkEntitySaveAuthorization('Packaging', _id)) {
          setIsSaveLoading(false);
             return;
        }
        const data = getValues(); 

        if(data.name.trim() === '' || data.description.trim() === '') {
          enqueueSnackbar( t('Reference is not specified'), { variant: 'warning',
                anchorOrigin : { horizontal: 'center', vertical: 'top' }, autoHideDuration : 1500 }); 
                setIsSaveLoading(false);
                return;
        }
        
        mutate(data);
      }
    
      const actionData = async (event: MouseEvent<HTMLButtonElement>) => {
        openEntityActionDrawer('Packaging', _id);
      }
      
    const afterAction = async (event: MouseEvent<HTMLButtonElement>) => {          
    //    queryClient.invalidateQueries(['RequestType',currentEntityIdForAction]);        
    //    await retreiveData(currentEntityNameForAction,currentEntityIdForAction, refetch);        
    //    reset(_data);        
    }

  return (
    <FormProvider {...methods} >
            <Box sx={{ mx: 0.1 }}>
                <Grid container rowSpacing={0.5} columnSpacing={0.1}>
                    <Grid item xs={12} md={6} lg={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >                        
                        <Stack flexDirection='column'  >
                            <Box sx={{ mt: 1, width: '100%' }} >
                                <Button id='btnNew' onClick={newData} sx={ {display:'none'}}  />                                  
                                <Button id='btnSave' onClick={saveData} sx={ {display:'none'}}  />
                                <Button id='btnAction' onClick={actionData} sx={ {display:'none'}}  />                                                              
                                <Button id='btnAfterAction' onClick={afterAction} sx={ {display:'none'}}  />
                                <TextField sx={{width:'calc(10% - 8px)'}} id="id" label={t('Id')} {...register('id')} inputProps={ {readOnly: true}} /> 
                                <TextField sx={{width:'calc(70% - 8px)'}} id="name" label={t('Name')} {...register('name')} inputProps={ { autoComplete: 'new-password', style: {textTransform: 'none'} } } />                                
                                <FormControlLabel sx={{width:'calc(20% - 8px)'}}
                                    label={`${t('Article ?')}`}
                                    control={
                                    <Controller
                                        name='isActive'
                                        control={control}
                                        render={({field: {value, onChange,...props} }) => <Checkbox {...props} checked={value} onChange={onChange} />}                        
                                />} />
                                
                            </Box>
                            
                                                                               
                            <Box sx={{ mt: 1, width: '100%' }} >

                              <TextField sx={{width:'calc(80% - 8px)'}} id="description" label={t('Description')} {...register('description')} />                                
                              <Controller
                                render={({ field: {onChange, onBlur, name, value, ref} }) => {
                                  return (
                                    <NumberFormat    
                                      label={t('# Beneficiary(ies)')} sx={{width:'calc(20% - 8px)'}} //disabled={true}
                                      allowEmptyFormatting={false}
                                      control={control}    
                                      thousandSeparator={true}
                                      decimalScale={0}
                                      onValueChange={ (v) => onChange(v.floatValue) }
                                      defaultValue={value}
                                      value={value}
                                      customInput={TextFieldRight}                            
                                    />
                                  );
                                }}
                                name={`countMaxPerson`}
                                control={control}
                            />
                            </Box>
                            
                            
                        </Stack>                        
                      </Grid>
                    <Grid item xs={12} md={6} component={Paper} sx={{ borderRadius: 2, ml: 0, }} >
                      <Stack flexDirection='column'>
                        <Box key={`${'watchFileName'} - key`} sx={{ mt: 1, width: '100%' }} >  
                          <ArrayFieldTableEx<IPackaging,IPackagingProduct,'id'> 
                              mainObject={getValues()} fieldKey='id' 
                              headCells={headPackagingProductCells} rowsPathName='packagingProducts' 
                              title={t('Products')} rowActionIcon={packagingProductRowActionIcon}  
                              //onRowSelected={handleRoleEntitySelected}
                                                  
                              refAppend={refAppendPackagingProducts as MutableRefObject<(value: Partial<FieldArray<IPackaging>> | Partial<FieldArray<IPackaging>>[], options?: FieldArrayMethodProps) => void>}
                              refUpdate={refUpdatePackagingProduct as MutableRefObject<(index: number,value: Partial<FieldArray<IPackaging>>) => void>}
                              refRemove={refRemovePackagingProduct as MutableRefObject<(index: number) => void>}

                              //stateSelected={[selectedRoleEntities, setSelectedRoleEntities]}
                              //displayMore={undefined}
                              toolbarActions={[
                              { toolTip: `${t('Add')}...`, onClickIcon: handleAddPackagingProducts ,icon: AddCircleIcon,  },
                              
                              ]}
                              canCheckRow={false} //canFilterColumn={false} //canDisplayColumnHeader={false}
                          />
                          { openProductFilter && <FormDialog open={openProductFilter} maxWidth='md'
                                okText={t('OK')} cancelText={t('Cancel')} title={t('Product filter')} onCancel={()=> {setOpenProductFilter(false);}} 
                                onClose={()=> {setOpenProductFilter(false);}} onOk={()=> {setOpenProductFilter(false);}}  >
                                    <BasicTextFilterForm<IProduct> {...basicFilterProduct } />
                              </FormDialog> }
                        </Box>                        
                      </Stack>
                    </Grid>     
                                                  
                </Grid>
            </Box>
        </FormProvider> 
  )
}

